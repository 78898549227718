import { useEffect, useState } from 'react';
import { CheckSquare, Plus } from 'react-feather';
import {
	LabRequestItem,
	LabRequestProperties,
} from '../../../../../types/labRequest.type';
import EmptyList from '../../../../EmptyList';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import { ReactComponent as LabRequest } from '../../../../../assets/icons/doc-multi-choice-with-pen.svg';
import { theme } from '../../../../../assets/theme';

import { IndividualHealthServiceProperties } from '../../../../../types/individualHealthService.type';
import IconButton from '../../../../Button/IconButton';
import { Button } from '../../../../Button/Button/Button';
import {
	apiCreateLabRequest,
	apiGetLabRequest,
	apiUpdateLabRequest,
} from '../../../../../api/labRequest';
import { useAuth } from '../../../../../context/auth.context';
import moment from 'moment';
import { ICDProperties } from '../../../../../types/icd.type';
import ModalAddProcedure from '../../../RxAndLabRequest/CreateLabRequest/Components/Modal/ModalAddProcedure';
import { PlanProperties } from '../../../../../types/plan.type';
import { apiGetEmr } from '../../../../../api/emr';
import { Checkbox, message } from 'antd';
import { apiCreateTemplateLabRequest } from '../../../../../api/templateLabRequest';
import { LabRequestTemplateProperties } from '../../../../../types/templateLabRequest';
import ModalConfirmation, { EModalConfirmationType } from './ModalConfirmation';
import { EmrProperties } from '../../../../../types/emr.type';

const forms = [
	{
		label: 'Valid Until',
		name: 'validUntil',
		type: EFormType.DATE_PICKER,
		isRequired: true,
	},
];

interface Props {
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	labRequest?: LabRequestProperties;
	step: number;
	setLabRequest: React.Dispatch<
		React.SetStateAction<LabRequestProperties | undefined>
	>;
	setNextStep: () => void;
	setFormName: () => void;
	patientLabReq: any;
	setPatientLabReq: React.Dispatch<React.SetStateAction<any>>;
	setLabRequestId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function FormCreateLabRequest(props: Props) {
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(false);
	const [showModalAddProcedure, setShowModalAddProcedure] = useState(false);
	const [isTemplateChecked, setIsTemplateChecked] = useState(false);
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);
	const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
	const [procedureToRemove, setProcedureToRemove] = useState<string | null>(
		null,
	);
	const todayAfter3Month = new Date();
	todayAfter3Month.setMonth(new Date().getMonth() + 3);

	useEffect(() => {
		props.setLabRequest((old) => ({
			...old,
			validUntil: todayAfter3Month.toString(),
		}));
	}, [props.emr.emrId]);

	function appendProcedures(procedures: IndividualHealthServiceProperties[]) {
		const items: LabRequestItem[] = procedures.map((procedure) => ({
			healthServiceId: procedure.healthServiceId,
			metaHealthService: procedure,
		}));

		props.setLabRequest((old) => {
			const data = { ...old };
			if (data.procedures) {
				for (const procedure of items) {
					const index = data.procedures.findIndex(
						(oldProcedure) =>
							oldProcedure.healthServiceId === procedure.healthServiceId,
					);
					if (index >= 0) {
						data.procedures[index] = procedure;
					} else {
						data.procedures.push(procedure);
					}
				}
			} else {
				data.procedures = items;
			}
			return data;
		});
	}

	function removeProcedure(procedureId: string) {
		props.setLabRequest((old) => {
			const data = { ...old };
			data.procedures = old?.procedures?.filter(
				(procedure) => procedure.healthServiceId !== procedureId,
			);
			return data;
		});
	}

	const clearFormHandler = () => {
		props.setLabRequest((old) => ({
			...old,
			assessmentDiagnosis: '',
			procedures: [],
			specialInstructions: '',
		}));
		setIsModalConfirmationVisible(false);
	};

	const handleTemplateCheckboxChange = (e: any) => {
		setIsTemplateChecked(e.target.checked);
		if (e.target.checked) {
			props.setLabRequest({
				...props.labRequest,
				saveAsTemplate: true,
			});
		} else {
			props.setLabRequest({
				...props.labRequest,
				saveAsTemplate: false,
				templateName: '',
			});
		}
	};

	const saveLabRequestTemplate = async (labRequestId?: string) => {
		let data: LabRequestTemplateProperties = {
			doctorId: user?.userId,
			templateName: props.labRequest?.templateName,
			assessmentDiagnosis: props.labRequest?.assessmentDiagnosis,
			templateProcedures: props.labRequest?.procedures,
			saveFromId: labRequestId,
			specialInstructions: props.labRequest?.specialInstructions,
		};

		try {
			await apiCreateTemplateLabRequest(data);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		async function fetchLabRequest() {
			if (props.emr.labRequest?.labRequestId) {
				try {
					setIsLoading(true);
					const fetchedLabRequest = await apiGetLabRequest(
						props.emr.labRequest.labRequestId,
					);

					if (fetchedLabRequest) {
						props.setLabRequest(fetchedLabRequest);
						props.setPatientLabReq((old: any) => ({
							...old,
							labRequest: fetchedLabRequest,
							labRequestId: fetchedLabRequest.labRequestId,
						}));
					}
				} catch (error) {
					console.error('Failed to fetch labRequest:', error);
				} finally {
					setIsLoading(false);
				}
			}
		}

		fetchLabRequest();

		if (!props.emr.labRequest) {
			props.setLabRequest((old) => ({
				...old,
				validUntil: todayAfter3Month.toString(),
			}));
		}
	}, [props.emr.labRequest]);

	async function saveLabRequest() {
		setIsLoading(true);
		const { emr, setEmr } = props;

		let isSuccess = false;
		let data: LabRequestProperties = {
			...props.labRequest,
			patientId: emr.patientId,
			isTemporary: true,
			doctorId: user?.userId,
			emrId: emr.emrId,
			metaPatient: emr.patient || emr.patientInRelation || {},
		};
		delete data?.saveAsTemplate;
		delete data?.templateName;

		if (props.labRequest?.labRequestId) {
			const res = await apiUpdateLabRequest(
				props.labRequest.labRequestId,
				data,
			);
			if (res) {
				setEmr({
					...emr,
					labRequest: res,
				});

				props.setLabRequest(res);
				props.setLabRequestId(props.labRequest.labRequestId);
				if (props.labRequest?.saveAsTemplate) {
					saveLabRequestTemplate(props.labRequest.labRequestId);
				}

				if (res.labRequestId) {
					props.setPatientLabReq({
						...props.patientLabReq,
						labRequestId: res.labRequestId,
					});
				}
				isSuccess = true;
			}
		} else {
			const res = await apiCreateLabRequest(data);
			if (res) {
				setEmr({
					...emr,
					labRequest: res,
				});

				props.setLabRequest(res);
				if (props.labRequest?.saveAsTemplate) {
					saveLabRequestTemplate(res.labRequestId);
				}
				if (res.labRequestId) {
					props.setPatientLabReq({
						...props.patientLabReq,
						labRequestId: res.labRequestId,
					});
				}
				isSuccess = true;
			}
		}

		if (isSuccess) {
			props.setNextStep();
			props.setFormName();
			message.success('Lab Request Saved Sucessfully');
		}

		setIsLoading(false);
	}

	return (
		<>
			<form>
				<div className="py-6 px-3">
					<div>
						<div className="flex gap-x-3">
							<div className="w-1/4 pt-2">
								<p className="m-0 font-semibold text-4">Lab Request</p>
							</div>
							<div className="flex-1">
								{forms.map((form) => (
									<FormInput
										key={form.label}
										label={form.label}
										name={form.name}
										type={form.type}
										required={form.isRequired}
										value={props.labRequest?.validUntil}
										onChange={(val) => {
											props.setLabRequest((old) => ({
												...old,
												validUntil: val,
											}));
										}}
										disabledDate={(d: any) => !d || d.isBefore(new Date())}
									/>
								))}

								<FormInput
									label="Assessment/Diagnosis"
									placeholder="Write here"
									value={props.labRequest?.assessmentDiagnosis}
									onChange={(val) => {
										if (val.split('\n').length <= 6) {
											props.setLabRequest((old) => ({
												...old,
												assessmentDiagnosis: val,
											}));
										}
									}}
									type={EFormType.MULTI_LINE}
									rows={4}
								/>

								<div className="py-2">
									<div className="flex justify-between">
										<p className="m-0 text-4">Diagnostic Procedure(s) </p>
										<div>
											<button
												onClick={() => setShowModalAddProcedure(true)}
												type="button"
												className="border-none bg-transparent p-0 flex items-center gap-x-1 text-primary text-4 cursor-pointer"
											>
												<Plus width={24} height={24} />
												Add Lab Request
											</button>
										</div>
									</div>
									<div>
										{props.labRequest?.procedures &&
										props.labRequest?.procedures.length > 0 ? (
											<div className="mt-2">
												{props.labRequest.procedures.map((procedure) => (
													<div
														key={procedure.healthServiceId}
														onClick={() => {
															setProcedureToRemove(procedure.healthServiceId!);
															setIsRemoveModalVisible(true);
														}}
														style={{ cursor: 'pointer' }}
														className="py-2 flex gap-x-3"
													>
														<IconButton onClick={() => {}}>
															<CheckSquare
																width={20}
																height={20}
																color={theme.colors.primary}
															/>
														</IconButton>
														<p className="m-0">
															{procedure.metaHealthService.healthServiceName}
														</p>
													</div>
												))}
											</div>
										) : (
											<EmptyList
												icon={
													<LabRequest
														width={48}
														height={48}
														color={theme.colors.black50}
													/>
												}
												title="Lab Request Empty"
												description="Lab requests that you add will appear here"
											/>
										)}
									</div>
								</div>
								<FormInput
									label="Special Instructions"
									placeholder="Write here"
									type={EFormType.MULTI_LINE}
									rows={2}
									value={props.labRequest?.specialInstructions}
									onChange={(val) => {
										if (val.split('\n').length < 5) {
											props.setLabRequest((old) => ({
												...old,
												specialInstructions: val,
											}));
										}
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<Separator />
				<div className="py-3 px-4 flex flex-col">
					<div className="flex items-center mb-2">
						<Checkbox
							checked={isTemplateChecked}
							onChange={handleTemplateCheckboxChange}
							className="mr-2"
						/>
						<p className="m-0">Save this perscription as template</p>
					</div>
					{props.labRequest?.saveAsTemplate ? (
						<div className="mb-3">
							<FormInput
								label="Template name"
								name="templateName"
								type={EFormType.SINGLE_LINE}
								placeholder="Write here"
								value={props.labRequest?.templateName}
								onChange={(val) => {
									props.setLabRequest({
										...props.labRequest,
										templateName: val,
									});
								}}
							/>
						</div>
					) : undefined}
					<div className="flex justify-between w-full">
						<div className="ml-0">
							<Button
								type="SOLIDASH"
								onClick={() => setIsModalConfirmationVisible(true)}
							>
								Clear Form
							</Button>
						</div>
						<div className="mr-0">
							<Button
								type="SOLID"
								onClick={saveLabRequest}
								isLoading={isLoading}
								isDisable={
									!props.labRequest?.validUntil ||
									!props.labRequest?.procedures?.length
								}
							>
								Save Lab Request
							</Button>
						</div>
					</div>
				</div>
			</form>
			<ModalAddProcedure
				isShow={showModalAddProcedure}
				selectedHealthServices={props.labRequest?.procedures}
				onCloseModal={() => setShowModalAddProcedure(false)}
				addProcedures={appendProcedures}
				setLabRequest={props.setLabRequest}
				labRequest={props.labRequest}
			/>
			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={clearFormHandler}
				type={EModalConfirmationType.CLEAR_FORM}
			/>
			<ModalConfirmation
				isVisible={isRemoveModalVisible}
				setVisibility={setIsRemoveModalVisible}
				actionHandler={() => {
					if (procedureToRemove) {
						removeProcedure(procedureToRemove);
					}
					setIsRemoveModalVisible(false);
					setProcedureToRemove(null);
				}}
				type={EModalConfirmationType.REMOVE_ITEM}
				title="Diagnostic Procedure"
			/>
		</>
	);
}
