import React, { useState, useEffect } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../Button/Button/Button';
import DetailEmrs from './DetailEmr';
import Separator from '../../../Separator';
import { EmrProperties } from '../../../../types/emr.type';
import BMIGraph from '../EMRCharts/BMIGraph';
import PediaChart from '../EMRCharts/PediaChart';
import HeartRate from '../EMRCharts/HeartRate';
import Temperature from '../EMRCharts/Temperature';
import SpO2 from '../EMRCharts/SpO2';
import RespiratoryRate from '../EMRCharts/RespiratoryRate';
import BloodPressure from '../EMRCharts/BloodPressure';
import BloodSugar from '../EMRCharts/BloodSugar';
import PreviewDocument from '../../Components/PreviewDocument';
import MonitoringLabResultsTrend from '../DetailEmr/ObjecttiveFindings/MonitoringLabResultsTrend';
import { httpRequest } from '../../../../helpers/api';
import { ApiResponseDefault } from '../../../../types/apiResponse.type';

type LocationState = {
	step?: number;
	selectedPatient?: EmrProperties;
	// backTo?: string;
	// nextStep: (newStep?: number) => void;
};

export default function MainDetailEmr() {
	const history = useHistory();
	const location = useLocation<LocationState>();
	const [title, setTitle] = useState('Detail EMR');
	const [step, setStep] = useState(0);
	const [emrId, setEmrId] = useState<string>();
	const [previewDocument, setPreviewDocument] = useState<{
		[key: string]: any;
	}>();
	const [emr, setEmr] = useState<EmrProperties>();
	const [isLoading, setIsLoading] = useState(true);
	const patient = location.state?.selectedPatient;

	useEffect(() => {
		if (emrId) {
			const fetchEmr = async () => {
				setIsLoading(true);
				try {
					const response = await httpRequest.get<
						ApiResponseDefault<EmrProperties>
					>(`/emr/${emrId}`);
					setEmr(response.data.payload);
				} catch (error) {
					console.error('Error fetching emr:', error);
				} finally {
					setIsLoading(false);
				}
			};

			fetchEmr();
		}
	}, [emrId]);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [step]);

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const id = query.get('emrId');
		setEmrId(id || undefined);
	}, [location.search]);

	function setNextStep(newStep?: number) {
		const _step = newStep || step + 1;
		if (_step === 0) {
			setTitle('Detail EMR');
		} else if (_step === 2) {
			setTitle('BMI');
		} else if (_step === 3) {
			setTitle('Pedia Growth Chart');
		} else if (_step === 4) {
			setTitle('Heart Rate');
		} else if (_step === 5) {
			setTitle('Respiratory Rate');
		} else if (_step === 6) {
			setTitle('SpO2');
		} else if (_step === 7) {
			setTitle('Temperature');
		} else if (_step === 8) {
			setTitle('Blood Pressure');
		} else if (_step === 9) {
			setTitle('Blood Sugar');
		} else if (_step === 10) {
			setTitle('Preview Document');
		} else if (_step === 11) {
			setTitle('Monitoring Lab Results History');
		} else return;
		setStep(_step);
	}

	function onBack() {
		let _step: number;
		if (step >= 2 && step < 10) {
			_step = 0;
			setTitle('Detail EMR');
			history.push({
				pathname: '/app/emr/detail',
				search: `?emrId=${emrId}&tab=2`,
				state: { step: 1, selectedPatient: patient },
			});
		} else if (step === 10) {
			_step = 0;
			setTitle('Detail EMR');
			history.push({
				pathname: '/app/emr/detail',
				search: `?emrId=${emrId}&tab=7`,
				state: { step: 1, selectedPatient: patient },
			});
		} else if (step === 11) {
			_step = 0;
			setTitle('Detail EMR');
			history.push({
				pathname: '/app/emr/detail',
				search: `?emrId=${emrId}&tab=5`,
				state: { step: 1, selectedPatient: patient },
			});
		} else {
			const stepState = location.state?.step;
			if (stepState && patient) {
				history.push({
					pathname: '/app/emr/patient',
					state: { step: stepState, selectedPatient: patient },
				});
			} else {
				history.goBack();
			}
			_step = 0;
		}

		setStep(_step);
	}

	function editEmr() {
		history.push({
			pathname: '/app/emr/edit',
			search: `?emrId=${emrId}`,
		});
	}

	return (
		<div>
			<div className="relative px-6 py-5.5 flex items-center justify-between">
				<button
					onClick={onBack}
					className="border-none bg-transparent p-0 flex items-center gap-x-1 cursor-pointer"
				>
					<ArrowLeft width={20} height={20} />
					<p className="m-0 font-bold text-3.5">Back</p>
				</button>
				<h1 className="font-semibold text-6 m-0 flex-grow text-center">
					{title}
				</h1>
				<div className="ml-4">
					{step <= 1 && (
						<Button type="OUTLINE" className="min-w-24" onClick={editEmr}>
							Edit
						</Button>
					)}
				</div>
			</div>
			<Separator />

			{(() => {
				if (emrId && step === 0) {
					return (
						<DetailEmrs
							patient={emr}
							nextStep={setNextStep}
							setPreviewDocument={setPreviewDocument}
							setPatient={setEmr}
							isLoading={isLoading}
							setIsLoading={setIsLoading}
						/>
					);
				}

				if (emr?.patientId) {
					switch (step) {
						case 2:
							return <BMIGraph patientId={emr.patientId} />;
						case 3: {
							const birthdate =
								emr.patient?.birthdate || emr.patientInRelation?.birthdate;
							if (birthdate) {
								return (
									<PediaChart
										patientId={emr.patientId}
										birthdate={new Date(birthdate)}
									/>
								);
							}
							break;
						}
						case 4:
							return <HeartRate patientId={emr.patientId} />;
						case 5:
							return <RespiratoryRate patientId={emr.patientId} />;
						case 6:
							return <SpO2 patientId={emr.patientId} />;
						case 7:
							return <Temperature patientId={emr.patientId} />;
						case 8:
							return <BloodPressure patientId={emr.patientId} />;
						case 9:
							return <BloodSugar patientId={emr.patientId} />;
						case 10:
							return (
								<div className="px-3">
									<PreviewDocument
										docId={previewDocument?.documentId}
										type={previewDocument?.type}
										buttonType="none"
									/>
								</div>
							);
						case 11:
							return (
								<MonitoringLabResultsTrend
									patientId={emr.patientId}
									emrId={emrId}
								/>
							);
						default:
							break;
					}
				}

				return null;
			})()}
		</div>
	);
}
