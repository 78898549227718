import { Card } from 'antd';
import VitalSignTypeSummary, {
	DetailInformationNumberStyle,
} from './VitalSignTypeSummary';
import React, { Key, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

interface Props {
	data: any;
	typeTitle: string;
	age: any;
	setAge: React.Dispatch<React.SetStateAction<any>>;
	birthdate?: Date | string;
	convertFromAgeDate: (
		date: Date,
		createdAt: Date | undefined,
	) => { year: number; month: number; days: number };
	calculateDaysDifference: (date: Date, createdAt: Date | undefined) => number;
}

export default function PatientDetailVitalSignSummary(props: Props) {
	const {
		data,
		typeTitle,
		age,
		setAge,
		convertFromAgeDate,
		calculateDaysDifference,
		birthdate,
	} = props;

	useEffect(() => {
		if (typeTitle === 'Pedia Growth Chart') {
			const { year, month, days } = convertFromAgeDate(
				new Date(birthdate || ''),
				new Date(data?.currentDate),
			);

			const dayDifference = calculateDaysDifference(
				new Date(birthdate || ''),
				new Date(data?.currentDate),
			);
			setAge({
				year: year,
				month: month,
				days: days,
				ageAsDays: dayDifference,
			});
		}
	}, [data, birthdate]);

	const renderVitalSignList = () => (
		<>
			{typeTitle === 'Blood Pressure' ? (
				<>
					{['systolic', 'diastolic'].map((type) => (
						<SummaryListContainer key={type}>
							{data?.[type]?.summary?.map((item: any, index: Key) => (
								<VitalSignTypeSummary
									key={index}
									bloodPressureParams={type}
									title={item.title}
									value={item.value}
									unit={item.unit}
									status={item.status}
								/>
							))}
						</SummaryListContainer>
					))}
				</>
			) : (
				data?.summary?.map((item: any, index: Key) => (
					<VitalSignTypeSummary
						key={index}
						title={item.title}
						value={item.value}
						unit={item.unit}
						status={item.status}
					/>
				))
			)}
		</>
	);

	return (
		<Card style={DetailInformationCardStyles} bodyStyle={{ padding: 0 }}>
			{typeTitle === 'Pedia Growth Chart' && <AgeSummaryCard age={age} />}
			<DetailInformationContainer>
				{renderVitalSignList()}
			</DetailInformationContainer>
		</Card>
	);
}

const AgeSummaryCard = ({ age }: any) => (
	<Card style={SummaryCardStyle} bodyStyle={{ padding: 12, marginBottom: 12 }}>
		<DetailInformationContainer>
			<AgeInformation title="Age as y+m+d" age={age} />
			<AgeInformation title="Age as days" age={{ days: age?.ageAsDays }} />
		</DetailInformationContainer>
	</Card>
);

const AgeInformation = ({ title, age }: any) => (
	<DetailInformationAgeStyle>
		<DetailInformationTitle>{title}</DetailInformationTitle>
		<div style={{ display: 'flex', gap: '5px' }}>
			{age?.year !== null && age?.year !== undefined && (
				<DetailInformationUnit>
					<span style={DetailInformationNumberStyle}>{age?.year}</span> years
				</DetailInformationUnit>
			)}
			{age?.month !== null && age?.month !== undefined && (
				<DetailInformationUnit>
					<span style={DetailInformationNumberStyle}>{age?.month}</span> month
				</DetailInformationUnit>
			)}
			<DetailInformationUnit>
				<span style={DetailInformationNumberStyle}>{age?.days}</span> days
			</DetailInformationUnit>
		</div>
	</DetailInformationAgeStyle>
);

const SummaryCardStyle: React.CSSProperties = {
	borderRadius: 8,
	border: 0,
	backgroundColor: '#F1F3F5',
};

const DetailInformationContainer = styled.div`
	display: flex;
	width: 100%;
`;

const SummaryListContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const DetailInformationAgeStyle = styled.div`
	text-align: left;
	flex: 1;
`;

const DetailInformationUnit = styled.div`
	color: #748494;
	font-size: 14px;
`;

const DetailInformationTitle = styled.div`
	font-size: 12px;
	color: #748494;
	margin-bottom: 4px;
`;

const DetailInformationCardStyles: React.CSSProperties = {
	marginTop: 12,
	border: 0,
};
