import { Divider, List, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { httpRequest } from '../../../../../helpers/api';
import {
	ApiResponseDefault,
	ApiResponsePagination,
} from '../../../../../types/apiResponse.type';
import { theme } from '../../../../../assets/theme';
import Spinner from '../../../../Spinner';
import EmptyList from '../../../../EmptyList';
import { Info } from 'react-feather';
import { EmrProperties } from '../../../../../types/emr.type';

const { Text } = Typography;

interface Props {
	patientId: string;
	emrId?: string;
}

export default function MonitoringLabResultsTrend(props: Props) {
	const [isLoading, setIsLoading] = useState(false);
	const [emr, setEmr] = useState<EmrProperties>();
	const [monitoringLabResults, setMonitoringLabResults] = useState<any>([]);

	useEffect(() => {
		if (props.patientId || emr?.patientId) {
			const fetchEmr = async () => {
				try {
					setIsLoading(true);
					const response = await httpRequest.get<ApiResponsePagination<any>>(
						`/emr?patientId=${props.patientId || emr?.patientId}`,
					);
					setMonitoringLabResults(
						response.data.payload.results
							.map((item) => item.objectiveFinding?.monitoringLabResult || [])
							.flat(),
					);
				} catch (error) {
					console.error('Error fetching emrs:', error);
				} finally {
					setIsLoading(false);
				}
			};

			fetchEmr();
		}
	}, [props.patientId, emr?.patientId]);

	useEffect(() => {
		if (!props.patientId && props.emrId) {
			const fetchEmr = async () => {
				try {
					setIsLoading(true);
					const response = await httpRequest.get<
						ApiResponseDefault<EmrProperties>
					>(`/emr/${props.emrId}`);
					setEmr(response.data.payload);
				} catch (error) {
					console.error('Error fetching emr:', error);
				} finally {
					setIsLoading(false);
				}
			};

			fetchEmr();
		}
	}, [props.patientId, props.emrId]);

	const formatDate = (date: string, format: string) =>
		moment(date).format(format);

	const groupedData = monitoringLabResults.reduce(
		(acc: any, item: any) => {
			const date = moment(item.date).format('YYYY-MM-DD');
			if (!acc[date]) {
				acc[date] = [];
			}
			acc[date].push(item);
			return acc;
		},
		{} as Record<string, any[]>,
	);

	return (
		<div className="px-4">
			{isLoading ? (
				<div className="py-4 mx-auto w-fit">
					<Spinner size={25} color={theme.colors.primary} />
				</div>
			) : monitoringLabResults.length < 1 ? (
				<EmptyList
					icon={<Info color={theme.colors.black50} />}
					title="Data Empty"
					description="No Monitoring Lab Result data available for this patient."
				/>
			) : (
				Object.keys(groupedData || {}).map((date, index) => (
					<div key={index}>
						<Text
							strong
							style={{
								fontSize: 18,
								marginTop: 10,
								marginBottom: 10,
								display: 'block',
							}}
						>
							{moment(date).isSame(moment(), 'day') ? 'Today, ' : ''}
							{formatDate(date, 'D MMM')}
						</Text>
						<List
							dataSource={groupedData[date]}
							renderItem={(item: any) => (
								<div>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											position: 'relative',
										}}
									>
										<div
											style={{
												position: 'absolute',
												top: '9%',
												left: 7,
												width: 10,
												height: 10,
												borderRadius: 5,
												backgroundColor: `${theme.colors.primary}`,
											}}
										/>
										<div
											style={{
												flex: 1,
												marginLeft: 10,
												rowGap: 5,
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													padding: '10px 0px 0px 16px',
													fontSize: 14,
													borderLeft: `4px solid ${theme.colors.ash400}`,
													height: '100%',
													margin: 0,
												}}
											>
												<Text className="font-medium text-charcoal-300">
													{formatDate(item.date, 'HH:mm')}
												</Text>
												<div className="w-full flex justify-between">
													<Text className="text-4">{item.name}</Text>
													<Text className="text-4">{item.value}</Text>
												</div>

												<Divider
													style={{
														marginTop: 8,
														marginBottom: 8,
														borderColor: `${theme.colors.ash300}`,
													}}
												/>

												<Text className="font-medium text-charcoal-300">
													Notes
												</Text>
												<Text className="text-4">{item.notes}</Text>
											</div>
										</div>
									</div>
								</div>
							)}
						/>
						<Divider
							style={{
								borderWidth: 4,
								marginTop: 10,
								marginBottom: 10,
								borderColor: `${theme.colors.ash300}`,
							}}
						/>
					</div>
				))
			)}
		</div>
	);
}
