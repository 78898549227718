import { ChevronRight, Info, Search } from 'react-feather';
import React, { useEffect, useState } from 'react';
import { theme } from '../../../../../assets/theme';
import EmptyList from '../../../../EmptyList';
import FormInput from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import Spinner from '../../../../Spinner';
import { PatientProperties } from '../../../../../types/patient.type';
import { EmrProperties } from '../../../../../types/emr.type';
import { httpRequest } from '../../../../../helpers/api';
import { ApiResponsePagination } from '../../../../../types/apiResponse.type';

interface Props {
	setNextStep: () => void;
	similarPatients: EmrProperties[];
	isLoading: boolean;
	onItemClick: (emrId: string) => void;
}

export default function ListPlan(props: Props) {
	const { isLoading, similarPatients, setNextStep, onItemClick } = props;
	const [search, setSearch] = useState('');
	const [debouncedSearch, setDebouncedSearch] = useState(search);

	// const filteredPatients = patients.filter(
	// 	(patient) =>
	// 		patient.plan &&
	// 		patient?.patient?.name
	// 			?.toLowerCase()
	// 			.includes(debouncedSearch.toLowerCase()),
	// );

	const formatDate = (dateString: string) => {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		};
		return new Date(dateString).toLocaleDateString('en-US', options);
	};
	const formatTime = (dateString: string) => {
		const date = new Date(dateString);
		return date.toLocaleTimeString('en-US', {
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
		});
	};

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedSearch(search);
		}, 500);
		return () => {
			clearTimeout(handler);
		};
	}, [search]);

	function onClickItem(item: EmrProperties) {
		setNextStep();
		onItemClick(item.emrId || '');
	}

	return (
		<div className="flex-1 flex flex-col">
			<div className="flex-1 min-h-0">
				<div className="px-0">
					{isLoading ? (
						<div className="py-4 mx-auto w-fit">
							<Spinner size={25} color={theme.colors.primary} />
						</div>
					) : similarPatients.length > 0 ? (
						similarPatients.every((patient) => !patient.plan) ? (
							<EmptyList
								icon={<Info color={theme.colors.black50} />}
								title="Data Empty"
								description="You have not added this data yet"
							/>
						) : (
							similarPatients.map(
								(patient, index) =>
									patient.plan && (
										<div
											className={`${
												index !== similarPatients.length - 1 ? 'mb-5' : ''
											}`}
											key={index}
										>
											<button
												onClick={() => onClickItem(patient)}
												className="bg-transparent border-none text-left w-full cursor-pointer flex justify-between"
											>
												<div className="flex-1 flex flex-col">
													<p className="m-0 text-3.5 font-semibold mb-4">
														{formatDate(patient.createdAt?.toString() ?? '')}
													</p>
													<p className="m-0 text-3.5 font-medium text-charcoal-300">
														Special Instructions
													</p>
													<p className="m-0">
														{patient.plan.specialInstruction}
													</p>

													<div className="flex justify-between mt-4">
														<div className="flex-1 ">
															<p className="m-0 text-3.5 font-medium text-charcoal-300">
																Follow Up Next Date
															</p>
															<p className="m-0">
																{formatDate(patient.plan.followUpNextDate)}
															</p>
														</div>
														<div className="flex-1 ">
															<p className="m-0 text-3.5 font-medium text-charcoal-300">
																Time
															</p>
															<p className="m-0">
																{' '}
																{`${formatTime(
																	patient.plan.fromInTime,
																)} - ${formatTime(patient.plan.toInTime)}`}
															</p>
														</div>
													</div>
												</div>
												<ChevronRight
													width={20}
													height={20}
													color={theme.colors.black30}
												/>
											</button>
											<Separator style={{ height: '2px' }} />
										</div>
									),
							)
						)
					) : (
						<EmptyList
							icon={<Info color={theme.colors.black50} />}
							title="No patients found"
						/>
					)}
				</div>
			</div>
		</div>
	);
}
