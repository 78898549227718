import AppLayout from '../../../layout/AppLayout';
import React, { useState } from 'react';
import { Card, Divider, Typography, Row, Spin } from 'antd';
import styled from 'styled-components';
import useFetchList from '../../../hooks/useFetchList';
import { BalanceProperties } from '../../../types/balance.type';
import { httpRequest } from '../../../helpers/api';
import { ApiResponseDefault } from '../../../types/apiResponse.type';
import { useHistory } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { UserBankAccountProperties } from '../../../types/bankAccount.type';
import ModalChangeMainBankAccount from '../../../components/Balance/ModalChangeMainBankAccount';
import BankAccountListItem from '../../../components/BankAccount/BankAccountListItem';
import EmptyList from '../../../components/EmptyList';
import { Info } from 'react-feather';
import { useAuth } from '../../../context/auth.context';
import { formatNumber } from '../../../utils/commons';

const activePath = '/app/balance/withdraw';
const { Text } = Typography;

export default function WithdrawScreen() {
	const { userId } = useAuth();
	const history = useHistory();

	const [
		isShowModalChangeMainBankAccount,
		setIsShowModalChangeMainBankAccount,
	] = useState<boolean>(false);
	const [currentBalance, setCurrentBalance] = useState<number>(0);

	const [selectedBankAccount, setSelectedBankAccount] =
		useState<UserBankAccountProperties>();

	const {
		isLoading: isLoading,
		data: bankAccounts,
		fetchList: bankAccountsFetchList,
	} = useFetchList<UserBankAccountProperties>({
		endpoint: '/user-bank-accounts',
		limit: 100000,
		initialQuery: {
			userId: userId,
		},
	});

	const getCurrentBalance = async () => {
		try {
			const res = await httpRequest.get<ApiResponseDefault<BalanceProperties>>(
				`balances/${userId}`,
			);
			if (res && res.data && res.data.payload) {
				setCurrentBalance(res.data.payload.currentAmount);
			}
		} catch (err) {
			console.error(err);
		}
	};

	React.useEffect(() => {
		getCurrentBalance();
	}, []);

	const mainBankAccount = React.useMemo(() => {
		if (!bankAccounts || bankAccounts.length === 0) return;

		const mainAccount = bankAccounts.filter((bank) => bank?.isDefault)?.[0];
		return mainAccount ? mainAccount : null;
	}, [bankAccounts]);

	React.useEffect(() => {
		if (!mainBankAccount) return;

		setSelectedBankAccount(mainBankAccount);
	}, [mainBankAccount]);

	const withdrawInformation = [
		'The balance will be automatically transferred to your account every week on Monday',
		'The minimum balance to be transferred to your account is ₱1.000',
		'If the balance below the minimum withdrawal, the balance will be sent when the minimum withdrawal has been reached in the next month',
		'Minimum withdrawal is a multiple of ₱1.000',
	];

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 80 }}>
				<PageHeader title="Withdraw" onClick={() => history.goBack()} />
				<Divider />
				{isLoading && <CustomSpin spinning={isLoading} />}
				{!isLoading && (
					<>
						<Row
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: '#ECF0F3',
								height: 195,
								borderRadius: 12,
								marginBottom: 36,
							}}
						>
							<Text style={{ fontSize: 36, fontWeight: 600 }}>
								{formatNumber(currentBalance, true)}
							</Text>
							<Text style={{ fontSize: 16, fontWeight: 600 }}>
								Available Professional fee{' '}
							</Text>
						</Row>
						<Row
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginBottom: 12,
							}}
						>
							<Text style={{ fontSize: 18, fontWeight: 600 }}>
								Withdrawal Information
							</Text>

							<Text style={{ fontSize: 16, fontWeight: 400, color: '#777777' }}>
								Your Weekly Professional Fee (cut off: Monday to Sunday) will be
								automatically transferred to your chosen bank account every
								Wednesday of the following week.
							</Text>
						</Row>

						<Row style={{ display: 'flex', flexDirection: 'column' }}>
							<Text style={{ fontSize: 18, fontWeight: 600 }}>
								Main Bank Account
							</Text>
							{!selectedBankAccount ? (
								<>
									<EmptyList
										icon={<Info />}
										title="Bank Account Empty"
										description="You can add your bank account in Manage Bank Account."
									/>
									<Text
										onClick={() => history.push('/app/setting/bank-account')}
										style={{
											fontSize: 14,
											fontWeight: 600,
											color: '#D81F64',
											textAlign: 'center',
											cursor: 'pointer',
										}}
									>
										Manage Bank Account
									</Text>
								</>
							) : (
								<>
									{selectedBankAccount && (
										<BankAccountListItem
											data={selectedBankAccount}
											onPress={() => setIsShowModalChangeMainBankAccount(true)}
											buttonText="Change"
										/>
									)}
								</>
							)}
						</Row>
					</>
				)}
				<ModalChangeMainBankAccount
					visible={isShowModalChangeMainBankAccount}
					setVisible={setIsShowModalChangeMainBankAccount}
					bankAccountList={bankAccounts}
					selectedBankAccount={selectedBankAccount}
					fetchList={bankAccountsFetchList}
				/>
			</Card>
		</AppLayout>
	);
}

const CustomSpin = styled(Spin)`
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	margin: 20px 0;
`;
