import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ApprovalScreen from '../screens/app/ApprovalScreen';
import BalanceScreen from '../screens/app/BalanceScreen';
import ConsultationScreen from '../screens/app/Consultation/ConsultationScreen';
import DoctorDirectoryScreen from '../screens/app/DoctorDirectoryScreen';
import DoctorDocumentsScreen from '../screens/app/DoctorDocumentScreen';
import DoctorDirectoryDetailScreen from '../screens/app/DoctorDirectoryDetailScreen';
import HistoryScreen from '../screens/app/HistoryScreen';
import HomeScreen from '../screens/app/HomeScreen';
import MedicalCalculatorScreen from '../screens/app/AppCalculator/MedicalCalculatorScreen';
import MedicalCalculatorScreenBmi from '../screens/app/BMI/Index';
import MedicalCalculatorScreenMens from '../screens/app/Menstruation/Index';
import PregnancyDueDate from '../screens/app/PregnancyDueDate';
import RxLabRequestScreen from '../screens/app/RxLabRequestScreen';
import ScheduleScreen from '../screens/app/ScheduleScreen';
import Homepage from '../screens/Index';
import SinglePage from '../screens/SinglePage';
import DoctorRegisterPage from '../screens/app/DoctorRegister';
import AddScheduleScreen from '../screens/app/AddSchedule';
import PrivateRoute from './PrivateRoute';
import DetailScheduleScreen from '../screens/app/DetailSchedule';
import SettingScreen from '../screens/app/Setting';
import ClinicsAndPracticeSetting from '../screens/app/Setting/ClinicAndPractice';
import SecretariesScreen from '../screens/app/Setting/Secretaries';
import DetailSecretary from '../screens/app/Setting/Secretaries/DetailSecretary';
import InviteFriends from '../screens/app/Setting/InviteFriends';
import DoctorInformationSetting from '../screens/app/Setting/DoctorInformation';
import EditDoctorInformation from '../screens/app/Setting/DoctorInformation/EditDoctorInformation';
import AddClinicScreen from '../screens/app/Setting/ClinicAndPractice/AddClinicScreen';
import DetailClinicScreen from '../screens/app/Setting/ClinicAndPractice/DetailClinicScreen';
import DetailDayScreen from '../screens/app/Setting/ClinicAndPractice/DetailDayScreen';
import Referral from '../screens/app/Setting/Referral';
import Reschedule from '../screens/app/Reschedule';
import CardioRiskOption from '../screens/app/CardioRisk';
import CardioRiskACCRisk from '../screens/app/CardioRisk/CardioRiskACCRisk';
import CardioRiskFramingham from '../screens/app/CardioRisk/CardioRiskFramingham';
import CardioRiskRevisedCardiac from '../screens/app/CardioRisk/CardioRiskRevisedCardiac';
import DiabetesRiskOption from '../screens/app/DiabetesRisk';
import DiabetesRiskFindRisc from '../screens/app/DiabetesRisk/DiabetesRiskFindRisc';
import DiabetesRiskHbA1C from '../screens/app/DiabetesRisk/DiabetesRiskHbA1C';
import WithdrawScreen from '../screens/app/BalanceScreen/WithdrawScreen';
import BankAccountScreen from '../screens/app/Setting/BankAccount';
import Articles from '../screens/app/Articles';
import DetailArticle from '../screens/app/Articles/DetailArticle';
import PromoDeals from '../screens/app/PromoDeals';
import VoucherDetail from '../screens/app/Voucher/Detail';
import OwnContent from '../screens/app/OwnContent';
import PointInformationScreen from '../screens/app/BalanceScreen/PointInformationScreen';
import Application from '../screens/Application';
import DetailEvent from '../screens/app/MedicalEvent/Detail';
import ReferralPromoDeals from '../screens/app/Referral';
import ScheduleIconInformation from '../screens/app/ScheduleScreen/ScheduleIconInformation';
import PersonalEventList from '../screens/app/ScheduleScreen/PersonalEvent';
import SyncCalender from '../screens/app/ScheduleScreen/SyncCalender';
import RequestDeleteAccountPage from '../screens/app/Setting/RequestDeleteAccount';
import EmrScreen from '../screens/app/EmrScreen';
import MainPatientList from '../components/Webapp/EMR/PatientList/MainListPatient';

const RootNavigator = () => (
	<Router>
		<Switch>
			<Route exact path="/" component={Homepage} />
			<Route exact path="/our-apps" component={Application} />
			<Route exact path="/articles" component={Articles} />
			<Route exact path="/articles/:articleId" component={DetailArticle} />
			<Route exact path="/promo-deals" component={PromoDeals} />
			<Route exact path="/own-content" component={OwnContent} />
			<Route exact path="/voucher/:voucherId" component={VoucherDetail} />
			<Route exact path="/medical-event/:eventId" component={DetailEvent} />
			<Route exact path="/referral" component={ReferralPromoDeals} />

			<Route exact path="/term-and-conditions">
				<SinglePage type="terms-and-conditions" />
			</Route>
			<Route exact path="/privacy-policy">
				<SinglePage type="privacy-policy" />
			</Route>
			<Route exact path="/register" component={DoctorRegisterPage} />
			<Route
				exact
				path="/request-delete-account"
				component={RequestDeleteAccountPage}
			/>
			<PrivateRoute exact path="/app">
				<HomeScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/schedule">
				<ScheduleScreen />
			</PrivateRoute>
			<Route exact path="/app/schedule/new" component={AddScheduleScreen} />
			<Route
				exact
				path="/app/schedule/new/:id/:type"
				component={AddScheduleScreen}
			/>
			<PrivateRoute exact path="/app/schedule/details">
				<DetailScheduleScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/approval">
				<ApprovalScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/rx-and-lab-request">
				<RxLabRequestScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/rx-and-lab-request/:docType">
				<RxLabRequestScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/rx-and-lab-request/:docType/:docId">
				<RxLabRequestScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/doctor-documents">
				<DoctorDocumentsScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/doctor-documents/:documentType">
				<DoctorDocumentsScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/doctor-documents/:documentType/:docId">
				<DoctorDocumentsScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/emr">
				<EmrScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/emr/:emrType">
				<EmrScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/history">
				<HistoryScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/history/details">
				<DetailScheduleScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/balance">
				<BalanceScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/balance/withdraw">
				<WithdrawScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/balance/point-information">
				<PointInformationScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/doctor-directory">
				<DoctorDirectoryScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/doctor-directory-details">
				<DoctorDirectoryDetailScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/medical-calculator">
				<MedicalCalculatorScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/medical-calculator/bmi">
				<MedicalCalculatorScreenBmi />
			</PrivateRoute>
			<PrivateRoute exact path="/app/medical-calculator/menstruation">
				<MedicalCalculatorScreenMens />
			</PrivateRoute>
			<PrivateRoute exact path="/app/medical-calculator/pregnancy">
				<PregnancyDueDate />
			</PrivateRoute>
			<PrivateRoute exact path="/app/consultation/:transactionConsultationId">
				<ConsultationScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/setting">
				<SettingScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/setting/clinics-and-practice">
				<ClinicsAndPracticeSetting />
			</PrivateRoute>
			<PrivateRoute exact path="/app/setting/clinics-and-practice/add-clinic">
				<AddClinicScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/setting/clinics-and-practice/details">
				<DetailClinicScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/setting/clinics-and-practice/details/edit">
				<DetailDayScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/setting/doctor-information">
				<DoctorInformationSetting />
			</PrivateRoute>
			<PrivateRoute exact path="/app/setting/doctor-information/edit">
				<EditDoctorInformation />
			</PrivateRoute>
			<PrivateRoute exact path="/app/setting/secretaries">
				<SecretariesScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/setting/bank-account">
				<BankAccountScreen />
			</PrivateRoute>
			<PrivateRoute exact path="/app/setting/secretary/:secretaryId">
				<DetailSecretary />
			</PrivateRoute>
			<PrivateRoute exact path="/app/setting/invite-friends">
				<InviteFriends />
			</PrivateRoute>
			<PrivateRoute exact path="/app/setting/referral">
				<Referral />
			</PrivateRoute>
			<PrivateRoute exact path="/app/schedule/reschedule">
				<Reschedule />
			</PrivateRoute>
			<PrivateRoute exact path="/app/schedule/schedule-icon-information">
				<ScheduleIconInformation />
			</PrivateRoute>
			<PrivateRoute exact path="/app/schedule/personal-event">
				<PersonalEventList />
			</PrivateRoute>
			<PrivateRoute exact path="/app/schedule/sync-calender">
				<SyncCalender />
			</PrivateRoute>
			<PrivateRoute exact path="/app/medical-calculator/cardio-risk">
				<CardioRiskOption />
			</PrivateRoute>
			<PrivateRoute exact path="/app/medical-calculator/cardio-risk/framingham">
				<CardioRiskFramingham />
			</PrivateRoute>
			<PrivateRoute
				exact
				path="/app/medical-calculator/cardio-risk/revised-cardiac"
			>
				<CardioRiskRevisedCardiac />
			</PrivateRoute>
			<PrivateRoute exact path="/app/medical-calculator/cardio-risk/aha">
				<CardioRiskACCRisk />
			</PrivateRoute>
			<PrivateRoute exact path="/app/medical-calculator/diabetes-risk">
				<DiabetesRiskOption />
			</PrivateRoute>
			<PrivateRoute
				exact
				path="/app/medical-calculator/diabetes-risk/find-risc"
			>
				<DiabetesRiskFindRisc />
			</PrivateRoute>
			<PrivateRoute exact path="/app/medical-calculator/diabetes-risk/hba1c">
				<DiabetesRiskHbA1C />
			</PrivateRoute>
		</Switch>
	</Router>
);

export default RootNavigator;
