import { SetStateAction, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import MainCreateRx from '../../components/Webapp/RxAndLabRequest/CreateRx/MainCreateRx';
import MainPatientList from '../../components/Webapp/EMR/PatientList/MainListPatient';
import MainRxTemplate from '../../components/Webapp/RxAndLabRequest/RxTemplate/MainRxTemplate';
import AppLayout from '../../layout/AppLayout';
import MainEMR, { EmrNav } from '../../components/Webapp/EMR/MainEmr';
import MainCreateEmr from '../../components/Webapp/EMR/CreateEmr/MainCreateEmr';
import DetailEmr from '../../components/Webapp/EMR/DetailEmr/DetailEmr';
import DetailEmrs from '../../components/Webapp/EMR/PatientList/DetailEmr';
import MainDetailEmr from '../../components/Webapp/EMR/PatientList/MainDetailEmr';
import DocumentPreview from '../../components/Webapp/EMR/CreateEmr/Components/DocumentPreview';

const activePath = '/app/emr';

interface Params {
	emrType?: string;
	patientId?: string;
}

export default function EmrScreen() {
	const history = useHistory();
	const { emrType } = useParams<Params>();
	const [nav, setNav] = useState(EmrNav.Main);

	useEffect(() => {
		if (!emrType) {
			setNav(EmrNav.Main);
			return;
		}

		const nav = emrType
			.split('-')
			.map((d) => d[0].toUpperCase() + d.slice(1))
			.join('-') as EmrNav;
		setNav(nav);
	}, [emrType]);

	function addNav(nav: EmrNav) {
		history.push(`${activePath}/${nav.toLowerCase()}`);
	}

	return (
		<AppLayout activePath={activePath}>
			<Card className="flex flex-col">
				{nav === EmrNav.Main ? (
					<MainEMR addNav={addNav} />
				) : nav === EmrNav.CreateEMR || nav === EmrNav.EditEMR ? (
					<MainCreateEmr />
				) : nav === EmrNav.Patient ? (
					<MainPatientList />
				) : nav === EmrNav.Detail ? (
					<MainDetailEmr />
				) : // : nav === EmrNav.DocumentPreview ? (
				// 	<DocumentPreview />
				// )
				undefined}
			</Card>
		</AppLayout>
	);
}
