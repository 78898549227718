import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import { RxPrescriptionProperties } from '../../../../../types/rxPrescription.type';
import { Button } from '../../../../Button/Button/Button';
import { HPIProperties } from '../../../../../types/hpi.type';
import HPIDetail from './../../DetailEmr/HPI/HPI';
import {
	apiCreateHpi,
	apiDeleteHpiByEMRId,
	apiGetEmr,
} from '../../../../../api/emr';
import ModalConfirmation, { EModalConfirmationType } from './ModalConfirmation';
import { message } from 'antd';
import { EmrProperties } from '../../../../../types/emr.type';

interface Props {
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	step: number;
	setNextStep: () => void;
	setFormName: () => void;
	goBack: () => void;
	patientHpi: any;
	setPatientHpi: React.Dispatch<React.SetStateAction<any>>;
}

export default function FormCreateHPI(props: Props) {
	const [isLoading, setIsLoading] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);

	const isMountedRef = useRef(true);

	const validateForm = () => {
		const { patientHpi } = props;
		const isValid =
			!!patientHpi.chiefComplaint && !!patientHpi.historyOfPresentIllness;

		setIsFormValid(isValid);
	};

	useEffect(() => {
		validateForm();
	}, [props.patientHpi]);

	useEffect(() => {
		return () => {
			isMountedRef.current = false;
		};
	}, []);

	// Initial Value for Edit Emr
	useEffect(() => {
		if (!props.emr.hpiId) return;
		props.setPatientHpi({
			...props.patientHpi,
			chiefComplaint: props.emr.hpi.chiefComplaint,
			historyOfPresentIllness: props.emr.hpi.historyOfPresentIllness,
		});
	}, []);

	const deleteHpi = async () => {
		setIsLoading(true);
		try {
			const emrId = props.emr.emrId || '';
			const resDelete = await apiDeleteHpiByEMRId(emrId);
			if (resDelete.isSuccess) {
				props.setEmr((old: any) => ({
					...old,
					hpiId: undefined,
					hpi: undefined,
				}));
				props.goBack();

				props.setPatientHpi((old: any) => ({
					...old,
					historyOfPresentIllness: undefined,
					chiefComplaint: undefined,
				}));

				message.success('History of Present Illness removed successfully');
			} else {
				throw new Error();
			}
		} catch (e) {
			message.error('Something went wrong');
		} finally {
			setIsLoading(false);
			setIsModalConfirmationVisible(false);
		}
	};

	const handleRemoveHpi = () => {
		if (!props.emr.hpi) {
			props.setPatientHpi((old: any) => ({
				...old,
				historyOfPresentIllness: '',
				chiefComplaint: '',
			}));

			setIsModalConfirmationVisible(false);
		} else {
			deleteHpi();
		}
	};

	const saveHpi = async () => {
		setIsLoading(true);
		let isSuccess = false;

		const { emr, setEmr, patientHpi, setNextStep, setFormName } = props;

		const data: HPIProperties = {
			...emr.hpi,
			emrId: emr.emrId,
			chiefComplaint: patientHpi.chiefComplaint,
			historyOfPresentIllness: patientHpi.historyOfPresentIllness,
		};

		try {
			if (!emr.emrId) {
				throw new Error('EMR ID is required.');
			}

			const res = await apiCreateHpi(emr.emrId, 'hpi', data);
			if (isMountedRef.current && res) {
				setEmr({
					...emr,
					...res,
				});
				isSuccess = true;
			}

			if (isMountedRef.current && isSuccess) {
				message.success('HPI Saved Sucessfully');
				setNextStep();
				setFormName();
			}
		} catch (error) {
			if (isMountedRef.current) {
				console.error('Error saving HPI:', error);
			}
		} finally {
			if (isMountedRef.current) {
				setIsLoading(false);
			}
		}
	};

	return (
		<>
			<form>
				<div className="py-6 px-3">
					<div>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Chief Complaint</p>
							</div>
							<div className="flex-1">
								<FormInput
									label="Chief Complaint"
									name="chiefComplaint"
									required
									type={EFormType.MULTI_LINE}
									value={props.patientHpi?.chiefComplaint || ''}
									onChange={(val) => {
										props.setPatientHpi((old: any) => ({
											...old,
											chiefComplaint: val,
										}));
									}}
								/>
							</div>
						</div>
						<Separator />
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">
									History Of Present Illness
								</p>
							</div>
							<div className="flex-1">
								<FormInput
									label="History Of Present Illness"
									name="historyOfPresentIllness"
									required
									type={EFormType.MULTI_LINE}
									value={props.patientHpi?.historyOfPresentIllness || ''}
									onChange={(val) => {
										props.setPatientHpi((old: any) => ({
											...old,
											historyOfPresentIllness: val,
										}));
									}}
								/>
							</div>
						</div>
					</div>
					<div className="h-6" />
				</div>
				<Separator />
				<div className="flex justify-between w-full py-3 px-4">
					<div className="ml-0 w-44">
						<Button
							type="SOLIDASH"
							onClick={() => {
								setIsModalConfirmationVisible(true);
							}}
							isDisable={isLoading || !props.emr.hpi}
						>
							Remove
						</Button>
					</div>
					<div className="mr-0 w-44">
						<Button
							type="SOLID"
							onClick={saveHpi}
							isLoading={isLoading}
							isDisable={!isFormValid || isLoading}
						>
							Save
						</Button>
					</div>
				</div>
			</form>
			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={handleRemoveHpi}
				type={EModalConfirmationType.CUSTOM}
				title="Remove This Data"
				message="The data you have entered will be removed"
				buttonConfirm="Remove"
			/>
		</>
	);
}
