import EmptyList from '../../../../EmptyList';
import Separator from '../../../../Separator';
import { EmrProperties } from '../../../../../types/emr.type';
import { theme } from '../../../../../assets/theme';
import { Image } from 'antd';
import { createGlobalStyle } from 'styled-components';
import { Info } from 'react-feather';
import Spinner from '../../../../Spinner';

interface Props {
	patient: EmrProperties;
	isLoading: boolean;
	nextStep: (newStep?: number) => void;
}

export default function AssessmentDetail({
	isLoading,
	patient,
	nextStep,
}: Props) {
	const formatDate = (dateString: string) => {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		};
		return new Date(dateString).toLocaleDateString('en-US', options);
	};

	const formatTime = (dateString: string) => {
		const date = new Date(dateString);
		return date.toLocaleTimeString('en-US', {
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
		});
	};

	return (
		<>
			<PreviewImageStyle />
			<div className="flex-1 flex flex-col">
				<div className="py-6 px-3 flex-1">
					{isLoading ? (
						<div className="py-4 mx-auto w-fit">
							<Spinner size={25} color={theme.colors.primary} />
						</div>
					) : !patient?.objectiveFinding ? (
						<EmptyList
							icon={<Info color={theme.colors.black50} />}
							title="Data Empty"
							description="No Ojective Finding data available for this patient."
						/>
					) : (
						<>
							<div className="flex gap-x-3">
								<div className="w-1/4">
									<p className="m-0 font-semibold text-4">Anatomy Marking</p>
								</div>
								<div className="flex-1">
									<div className="mb-4">
										<p className="m-0 text-3.5 font-medium text-charcoal-300">
											Anatomy Marking
										</p>
										{patient?.objectiveFinding.anatomyPictureUrl && (
											<div
												className="border border-solid rounded-4 p-4 mb-0 mt-4 flex justify-center"
												style={{ borderColor: '#a5b2bd' }}
											>
												<Image
													src={patient.objectiveFinding.anatomyPictureUrl}
													alt="Anatomy Marking"
													className="w-full h-auto"
												/>
											</div>
										)}
									</div>

									<div className="mb-4">
										<p className="m-0 text-3.5 font-medium text-charcoal-300">
											Notes
										</p>
										<p className="m-0 mb-1">
											{patient?.objectiveFinding.noteAnatomy || '-'}
										</p>
									</div>
								</div>
							</div>
							<Separator className="mb-4" />
							<div className="flex gap-x-3">
								<div className="w-1/4">
									<p className="m-0 font-semibold text-4">Lab</p>
								</div>
								<div className="flex-1">
									<div className="mb-4">
										<p className="mb-0 text-3.5 font-medium text-charcoal-300">
											Lab
										</p>
										{patient?.objectiveFinding.labTestPictureUrl && (
											<div
												className="border border-solid rounded-4 p-4 mt-4 flex justify-center"
												style={{ borderColor: '#a5b2bd' }}
											>
												<Image
													src={
														patient.objectiveFinding.labTestPictureUrl || '-'
													}
													alt="Lab Test"
													className="w-full h-auto"
												/>
											</div>
										)}
									</div>
									<div className="mb-4">
										<p className="m-0 text-3.5 font-medium text-charcoal-300">
											Notes
										</p>
										<p className="m-0 mb-1">
											{patient?.objectiveFinding.noteLab || '-'}
										</p>
									</div>
								</div>
							</div>
							<Separator className="mb-4" />

							<div className="flex gap-x-3">
								<div className="w-1/4">
									<p className="m-0 font-semibold text-4">
										Monitoring Lab Result
									</p>
									<p
										className="m-0 text-3.5 text-charcoal-300 cursor-pointer hover:text-white"
										onClick={() => nextStep(11)}
									>
										View Trend
									</p>
								</div>
								<div className="flex-1">
									{patient?.objectiveFinding?.monitoringLabResult &&
									patient.objectiveFinding.monitoringLabResult.length > 0 ? (
										patient.objectiveFinding.monitoringLabResult.map(
											(item: any, index: number) => (
												<div key={index}>
													<div className="mb-4 flex">
														<div className="flex-1">
															<p className="m-0 text-3.5 font-medium text-charcoal-300">
																Date
															</p>
															<p className="m-0 mb-1">
																{formatDate(item.date)}
															</p>
														</div>
														<div className="flex-1">
															<p className="m-0 text-3.5 font-medium text-charcoal-300">
																Time
															</p>
															<p className="m-0 mb-1">
																{formatTime(item.time)}
															</p>
														</div>
													</div>
													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															{item.name}
														</p>
														<p className="m-0 mb-1">{item.value}</p>
													</div>
													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Notes
														</p>
														<p className="m-0 mb-1">{item.notes}</p>
													</div>
													{index <
														patient.objectiveFinding.monitoringLabResult
															.length -
															1 && <Separator className="h-1 mb-5" />}
												</div>
											),
										)
									) : (
										<p className="m-0 text-3.5 font-medium text-charcoal-300">
											-
										</p>
									)}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

const PreviewImageStyle = createGlobalStyle`
  .ant-image-preview-operations-wrapper {
    display: flex !important;
    justify-content: center !important;
  }
`;
