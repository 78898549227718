import React, { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import DetailPatient from './DetailPatient';
import Separator from '../../../Separator';
import ListPatient from './ListPatient';
import { Button } from '../../../Button/Button/Button';
import { EmrProperties } from '../../../../types/emr.type';
import Spinner from '../../../Spinner';
import { theme } from '../../../../assets/theme';
import HeartRate from '../EMRCharts/HeartRate';
import BMIGraph from '../EMRCharts/BMIGraph';
import BloodPressure from '../EMRCharts/BloodPressure';
import BloodSugar from '../EMRCharts/BloodSugar';
import RespiratoryRate from '../EMRCharts/RespiratoryRate';
import SpO2 from '../EMRCharts/SpO2';
import Temperature from '../EMRCharts/Temperature';
import PediaChart from '../EMRCharts/PediaChart';

type LocationState = {
	step?: number;
	selectedPatient?: EmrProperties;
	isFromCreateEmr?: boolean;
	patientId?: string;
};

export default function MainPatientList() {
	const history = useHistory();
	const location = useLocation<LocationState>();
	const [title, setTitle] = useState('Patient List');
	const [step, setStep] = useState(0);
	const [selectedPatient, setSelectedPatient] = useState<EmrProperties>();
	const [patientId, setPatientId] = useState('');
	const [isReady, setIsReady] = useState(false);
	const [isFromCreateEmr, setIsFromCreateEmr] = useState(false);
	const [defaultActiveTabKey, setDefaultActiveTabKey] = useState('1');

	useEffect(() => {
		const stepState = location.state?.step;
		const _selectedPatient = location.state?.selectedPatient;
		const _isFromCreateEmr = location.state?.isFromCreateEmr;
		const _patientId = location.state?.patientId;
		if (stepState && _selectedPatient) {
			setSelectedPatient(_selectedPatient);
			setNextStep(stepState);
		}

		if (_isFromCreateEmr) {
			setIsFromCreateEmr(_isFromCreateEmr);
		}

		if (stepState && _patientId) {
			setPatientId(_patientId);
			setStep(stepState);
		}

		setIsReady(true);
	}, [location.state]);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [step]);

	function setNextStep(newStep?: number) {
		const _step = newStep || step + 1;
		if (_step === 1) {
			setTitle('Patient Detail');
			setStep(_step);
		} else if (_step === 2) {
			setTitle('BMI');
			setStep(_step);
		} else if (_step === 3) {
			setTitle('Pedia Growth Chart');
			setStep(_step);
		} else if (_step === 4) {
			setTitle('Heart Rate');
			setStep(_step);
		} else if (_step === 5) {
			setTitle('Respiratory Rate');
			setStep(_step);
		} else if (_step === 6) {
			setTitle('SpO2');
			setStep(_step);
		} else if (_step === 7) {
			setTitle('Temperature');
			setStep(_step);
		} else if (_step === 8) {
			setTitle('Blood Pressure');
			setStep(_step);
		} else if (_step === 9) {
			setTitle('Blood Sugar');
			setStep(_step);
		}
	}

	function onBack() {
		let _step: number;
		if (step >= 2) {
			setDefaultActiveTabKey('3');
			_step = 1;
		} else {
			_step = step - 1;
			setDefaultActiveTabKey('1');
		}

		setStep(_step);

		if (isFromCreateEmr) {
			history.goBack();
		} else if (_step >= 0) {
			if (_step === 0) {
				setTitle('Patient List');
			} else if (_step === 1) {
				setTitle('Patient Detail');
			}
		} else {
			// history.goBack();
			history.replace('/app/emr');
		}
	}

	function createRx() {
		if (!selectedPatient) {
			history.push('/app/emr/create');
		} else {
			history.push({
				pathname: '/app/emr/create',
				state: { selectedPatient: selectedPatient },
			});
		}
	}

	if (!isReady) {
		return (
			<div className="py-4 mx-auto w-fit">
				<Spinner size={25} color={theme.colors.primary} />
			</div>
		);
	}

	return (
		<div>
			<div className="relative px-6 py-5.5 flex items-center justify-between">
				<button
					onClick={onBack}
					className="border-none bg-transparent p-0 flex items-center gap-x-1 cursor-pointer"
				>
					<ArrowLeft width={20} height={20} />
					<p className="m-0 font-bold text-3.5">Back</p>
				</button>
				<h1 className="font-semibold text-6 m-0 flex-grow text-center">
					{title}
				</h1>

				<div className="ml-4">
					{!isFromCreateEmr && step <= 1 && (
						<Button type="SOLID" onClick={createRx}>
							Create EMR
						</Button>
					)}
				</div>
			</div>
			<Separator />
			{step === 0 ? (
				<ListPatient
					setSelectedPatient={setSelectedPatient}
					setNextStep={setNextStep}
					isFromCreateEmr={isFromCreateEmr}
				/>
			) : step === 1 ? (
				<DetailPatient
					patient={selectedPatient}
					patientId={patientId}
					nextStep={setNextStep}
					tabKey={defaultActiveTabKey}
				/>
			) : step === 2 && selectedPatient?.patientId ? (
				<BMIGraph patientId={selectedPatient.patientId} />
			) : step === 3 &&
			  selectedPatient?.patientId &&
			  selectedPatient?.patient?.birthdate ? (
				<PediaChart
					patientId={selectedPatient.patientId}
					birthdate={new Date(selectedPatient.patient.birthdate)}
				/>
			) : step === 4 && selectedPatient?.patientId ? (
				<HeartRate patientId={selectedPatient.patientId} />
			) : step === 5 && selectedPatient?.patientId ? (
				<RespiratoryRate patientId={selectedPatient.patientId} />
			) : step === 6 && selectedPatient?.patientId ? (
				<SpO2 patientId={selectedPatient.patientId} />
			) : step === 7 && selectedPatient?.patientId ? (
				<Temperature patientId={selectedPatient.patientId} />
			) : step === 8 && selectedPatient?.patientId ? (
				<BloodPressure patientId={selectedPatient.patientId} />
			) : step === 9 && selectedPatient?.patientId ? (
				<BloodSugar patientId={selectedPatient.patientId} />
			) : null}
		</div>
	);
}
