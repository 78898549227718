import React, { Key, useEffect } from 'react';
import moment from 'moment';
import {
	EMRFindTrendProperties,
	EMRGraphData,
} from '../../../../types/emr.type';
import { apiGetVitalSignTrend } from '../../../../api/emr';
import { generateScatterData } from '../../../../helpers/generateGraphData';
import Spinner from '../../../Spinner';
import { theme } from '../../../../assets/theme';
import { Card, Divider } from 'antd';
import VitalSignTypeSummary from '../DetailEmr/VitalSign/VitalSignTypeSummary';
import FilterRangeDate from '../../../Form/FilterRangeDate';
import { HistoryData } from '../DetailEmr/VitalSign/VitalSignHistoryData/HistoryData';
import { Chart } from 'react-google-charts';

const BloodSugar = (props: { patientId: string }) => {
	const [data, setData] = React.useState<EMRFindTrendProperties>();
	const [isLoading, setIsLoading] = React.useState(false);
	const [scatterData, setScatterData] = React.useState<EMRGraphData[]>([]);
	const [valueFrom, setValueFrom] = React.useState(
		moment().subtract(3, 'years').toISOString(),
	);
	const [valueTo, setValueTo] = React.useState(
		moment().endOf('month').toISOString(),
	);

	const fetchData = async (from?: string, to?: string) => {
		setIsLoading(true);
		try {
			const res = await apiGetVitalSignTrend(
				props.patientId,
				'blood_sugar',
				undefined,
				from,
				to,
			);
			if (res) {
				setData(res as EMRFindTrendProperties);
			}
		} catch (e) {
			console.log(e);
			setIsLoading(false);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		fetchData(
			moment(valueFrom).format('YYYY-MM-DD'),
			moment(valueTo).format('YYYY-MM-DD'),
		);
	}, [valueTo]);

	useEffect(() => {
		if (data) {
			const scatterData = generateScatterData(data.graphData);
			setScatterData(scatterData);
		}
	}, [data]);

	return isLoading ? (
		<div className="py-5 mx-auto w-fit">
			<Spinner size={25} color={theme.colors.primary} />
		</div>
	) : (
		<div className="p-5 flex flex-col">
			<div className="flex gap-1">
				<div
					className="text-3\.5 mt-2\.5 font-normal leading-7"
					style={{ color: '#748494' }}
				>
					<span className="text-10 font-semibold text-black-100">
						{`${data?.summary.find((item) => item.title === 'Lowest')
							?.value} - ${data?.summary.find(
							(item) => item.title === 'Highest',
						)?.value}`}
					</span>{' '}
					{data?.unit}
				</div>
			</div>
			<FilterRangeDate
				valueFrom={valueFrom}
				valueTo={valueTo}
				onChangeFrom={(val) => setValueFrom(val)}
				onChangeTo={(val) => setValueTo(val)}
			/>

			<Chart
				chartType="ScatterChart"
				data={scatterData}
				options={{
					hAxis: { minValue: 1, maxValue: 30, ticks: [1, 7, 14, 21, 30] },
					vAxis: { minValue: 0, maxValue: 200, ticks: [0, 50, 100, 150, 200] },
					legend: 'none',
					backgroundColor: {
						fill: 'transparent',
						stroke: `${theme.colors.ash300}`,
						strokeWidth: 0,
					},
					pointSize: 10,
					colors: [theme.colors.primary],
					chartArea: {
						left: 30,
						top: 20,
						right: 10,
						bottom: 40,
					},
				}}
				width="100%"
				height="300px"
			/>

			<Card className="min-h-24 rounded-3 mt-2\.5" bodyStyle={{ padding: 16 }}>
				<p className="font-semibold text-4 mb-0">Summary</p>
				<div className="flex justify-between items-center">
					{data?.summary?.map((item: any, index: Key) => (
						<VitalSignTypeSummary
							key={index}
							title={item.title}
							value={item.value}
							unit={item.unit}
							status={item.status}
						/>
					))}
				</div>
			</Card>
			<Divider
				style={{
					borderWidth: 2,
					marginTop: 20,
					marginBottom: 20,
					borderColor: `${theme.colors.ash300}`,
				}}
			/>
			<HistoryData
				data={data}
				dataExtractor={(item: any) => ({
					value: item.bloodSugar,
					unit: item.bloodSugarUnit,
				})}
			/>
		</div>
	);
};

export default BloodSugar;
