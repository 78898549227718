import { useEffect, useRef, useState } from 'react';
import { CheckSquare, Plus } from 'react-feather';
import {
	LabRequestItem,
	LabRequestProperties,
} from '../../../../../types/labRequest.type';
import EmptyList from '../../../../EmptyList';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import { ReactComponent as LabRequest } from '../../../../../assets/icons/doc-multi-choice-with-pen.svg';
import { Info } from 'react-feather';
import { Button } from '../../../../Button/Button/Button';
import { ICDProperties } from '../../../../../types/icd.type';
import { theme } from '../../../../../assets/theme';
import ModalAddICD from '../../../RxAndLabRequest/CreateRx/Components/Modal/ModalAddICD';
import { ICDItem } from '../../../ICD/ICDItem';
import { DiagnosisProperties } from '../../../../../types/diagnosis.type';
import {
	apiCreateDiagnosis,
	apiDeleteDiagnosisByEMRId,
	apiGetEmr,
} from '../../../../../api/emr';
import { message } from 'antd';
import ModalConfirmation, { EModalConfirmationType } from './ModalConfirmation';
import { EmrProperties } from '../../../../../types/emr.type';

interface Props {
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	icds?: ICDProperties[];
	patientDiagnosis: any;
	goBack: () => void;
	setIcds: React.Dispatch<React.SetStateAction<ICDProperties[] | undefined>>;
	setNextStep: () => void;
	setFormName: () => void;
	setPatientDiagnosis: React.Dispatch<React.SetStateAction<any>>;
}

export default function FormCreateDiagnosis(props: Props) {
	const [isLoading, setIsLoading] = useState(false);
	const [showModalAddIcd, setShowModalAddIcd] = useState(false);
	const [
		isModalRemoveConfirmationVisible,
		setIsModalRemoveConfirmationVisible,
	] = useState(false);
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);
	const [icdToRemoveIndex, setIcdToRemoveIndex] = useState<number | null>(null);
	const isMountedRef = useRef(true);
	const [isFormValid, setIsFormValid] = useState(false);

	const validateForm = () => {
		const { patientDiagnosis, icds } = props;
		const isValid = !!patientDiagnosis.diagnosis || (icds?.length ?? 0) > 0;

		setIsFormValid(isValid);
	};

	useEffect(() => {
		validateForm();
	}, [props.patientDiagnosis, props.icds]);

	useEffect(() => {
		return () => {
			isMountedRef.current = false;
		};
	}, []);

	useEffect(() => {
		setShowModalAddIcd(false);
	}, [props.icds]);

	// Initial Value for Edit Emr
	useEffect(() => {
		if (!props.emr.diagnosis) return;

		props.setIcds(props.emr.diagnosis.icds);

		props.setPatientDiagnosis({
			...props.patientDiagnosis,
			diagnosis: props.emr.diagnosis.diagnosis,
			icds: props.icds,
		});
	}, []);

	const showRemoveModal = (index: number) => {
		setIcdToRemoveIndex(index);
		setIsModalConfirmationVisible(true);
	};

	const handleRemoveIcd = () => {
		if (icdToRemoveIndex !== null) {
			const updatedIcds = props.icds ? [...props.icds] : [];
			updatedIcds.splice(icdToRemoveIndex, 1);
			props.setIcds(updatedIcds);
		}
		setIsModalConfirmationVisible(false);
	};

	const deleteDiagnosis = async () => {
		setIsLoading(true);
		try {
			const emrId = props.emr.emrId || '';
			const resDelete = await apiDeleteDiagnosisByEMRId(emrId);
			if (resDelete.isSuccess) {
				props.setEmr({
					...props.emr,
					diagnosisId: undefined,
					diagnosis: undefined,
				});
				props.setIcds(undefined);
				props.goBack();

				props.setPatientDiagnosis((old: any) => ({
					...old,
					diagnosis: undefined,
				}));

				message.success('Diagnosis removed successfully');
			} else {
				throw new Error();
			}
		} catch (e) {
			message.error('Something went wrong');
		} finally {
			setIsLoading(false);
			setIsModalRemoveConfirmationVisible(false);
		}
	};

	const handleRemoveDiagnosis = () => {
		if (!props.emr.diagnosis) {
			props.setPatientDiagnosis((old: any) => ({
				...old,
				diagnosis: '',
			}));

			props.setIcds([]);

			setIsModalRemoveConfirmationVisible(false);
		} else {
			deleteDiagnosis();
		}
	};

	const saveDiagnosis = async () => {
		setIsLoading(true);
		let isSuccess = false;

		const { emr, setEmr, patientDiagnosis, setNextStep, setFormName } = props;

		const data: DiagnosisProperties = {
			...emr.diagnosis,
			emrId: emr.emrId,
			diagnosis: patientDiagnosis.diagnosis,
			icds: props.icds,
		};

		try {
			if (!emr.emrId) {
				throw new Error('EMR ID is required.');
			}

			const res = await apiCreateDiagnosis(emr.emrId, 'diagnosis', data);
			if (isMountedRef.current && res) {
				setEmr({
					...emr,
					...res,
				});
				isSuccess = true;
			}

			if (isMountedRef.current && isSuccess) {
				setNextStep();
				setFormName();
				message.success('Diagnosis Saved Successfully');
			}
		} catch (error) {
			if (isMountedRef.current) {
				console.error('Error saving Diagnosis:', error);
			}
		} finally {
			if (isMountedRef.current) {
				setIsLoading(false);
			}
		}
	};

	return (
		<>
			<form>
				<div className="py-6 px-3">
					<p
						className="text-3.5 mb-2 flex items-center"
						style={{
							backgroundColor: '#03CEA4',
							color: '#FFFFFF',
							padding: '10px',
							borderRadius: '6px',
						}}
					>
						<Info className="mr-1" width={16} height={15} />
						This can be viewed by the patient in the Medeasy app
					</p>
					<div>
						<div className="flex gap-x-3">
							<div className="w-1/4 pt-2">
								<p className="m-0 font-semibold text-4">Diagnosis</p>
							</div>
							<div className="flex-1">
								<FormInput
									label="Diagnosis"
									placeholder="Write here"
									name="diagnosis"
									value={props.patientDiagnosis?.diagnosis}
									onChange={(val) => {
										props.setPatientDiagnosis((old: any) => ({
											...old,
											diagnosis: val,
										}));
									}}
									type={EFormType.MULTI_LINE}
									rows={4}
								/>
							</div>
						</div>

						<Separator className="my-6" />
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">ICD 10</p>
							</div>
							<div className="flex-1 flex flex-col">
								<div className="flex justify-between">
									<p className="m-0 text-4 text-charcoal-300">ICD 10</p>
									<button
										onClick={() => setShowModalAddIcd(true)}
										type="button"
										className="border-none bg-transparent p-0 flex items-center gap-x-1 text-primary text-4 cursor-pointer"
									>
										<Plus width={24} height={24} />
										Add ICD
									</button>
								</div>
								<div className="mb-5">
									{props.icds && props.icds.length > 0 ? (
										<div className="mt-2">
											{props.icds?.map((icd, index) => (
												<ICDItem
													id={icd.id}
													value={icd.value}
													onDeleteItem={() => showRemoveModal(index)}
												/>
											))}
										</div>
									) : (
										<EmptyList
											icon={
												<LabRequest
													width={48}
													height={48}
													color={theme.colors.black50}
												/>
											}
											title="ICD 10 empty"
											description="ICD 10 that you add will appear here"
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Separator />
				<div className="flex justify-between w-full py-3 px-4">
					<div className="ml-0 w-44">
						<Button
							type="SOLIDASH"
							onClick={() => {
								setIsModalRemoveConfirmationVisible(true);
							}}
							isDisable={isLoading || !props.emr.diagnosis}
						>
							Remove
						</Button>
					</div>
					<div className="mr-0 w-44">
						<Button
							type="SOLID"
							onClick={saveDiagnosis}
							isLoading={isLoading}
							isDisable={!isFormValid || isLoading}
						>
							Save
						</Button>
					</div>
				</div>
			</form>
			<ModalAddICD
				isShow={showModalAddIcd}
				onCloseModal={() => setShowModalAddIcd(false)}
				icds={props.icds}
				setIcds={props.setIcds}
			/>

			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={handleRemoveIcd}
				title="ICD Item"
				type={EModalConfirmationType.REMOVE_ITEM}
			/>

			<ModalConfirmation
				isVisible={isModalRemoveConfirmationVisible}
				setVisibility={setIsModalRemoveConfirmationVisible}
				actionHandler={handleRemoveDiagnosis}
				type={EModalConfirmationType.CUSTOM}
				title="Remove This Data"
				message="The data you have entered will be removed"
				buttonConfirm="Remove"
			/>
		</>
	);
}
