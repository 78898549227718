import { useState } from 'react';
import { Tabs } from 'antd';
import EmptyList from '../../../../EmptyList';
import Separator from '../../../../Separator';
import { EmrProperties } from '../../../../../types/emr.type';
import { Info } from 'react-feather';
import { theme } from '../../../../../assets/theme';
import { Vaccine } from '../../../../../types/medicalHistory.type';
import moment from 'moment';
import Spinner from '../../../../Spinner';
const { TabPane } = Tabs;

interface Props {
	patient: EmrProperties;
	isLoading: boolean;
}

export default function MedicalHistoryDetail({ isLoading, patient }: Props) {
	const [activeTab, setActiveTab] = useState('past-medical-history');

	const onTabChange = (key: string) => {
		setActiveTab(key);
	};

	return (
		<div className="flex-1 flex flex-col">
			<div className="py-6 px-3 flex-1">
				{isLoading ? (
					<div className="py-4 mx-auto w-fit">
						<Spinner size={25} color={theme.colors.primary} />
					</div>
				) : !patient?.medicalHistory ? (
					<EmptyList
						icon={<Info color={theme.colors.black50} />}
						title="Data Empty"
						description="No Patient History data available for this patient."
					/>
				) : (
					<>
						<Tabs activeKey={activeTab} onChange={onTabChange} centered>
							{/* Past Medical History Tab */}
							<TabPane tab="Past Medical History" key="past-medical-history">
								<div className="flex gap-x-3">
									<div className="w-1/4">
										<p className="m-0 font-semibold text-4">
											Past Medical History
										</p>
									</div>
									<div className="flex-1">
										{/* Hypertension section */}
										<div className="mb-4">
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Hypertension
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory
													.isHypertenstion
													? 'No'
													: 'Yes'}
											</p>
										</div>

										<div>
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Notes{' '}
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory
													.noteHypertension || '-'}
											</p>
										</div>
										<Separator className="mb-4 h-1" />
										<div className="mb-4">
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Diabetes
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory.isDiabetes
													? 'Yes'
													: 'No'}
											</p>
										</div>
										<div>
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Notes
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory
													.noteDiabetes || '-'}
											</p>
										</div>
										<Separator className="mb-4 h-1" />
										<div className="mb-4">
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Asthma
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory.isAsthma
													? 'Yes'
													: 'No'}
											</p>
										</div>
										<div>
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Notes
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory
													.noteAsthma || '-'}
											</p>
										</div>
										<Separator className="mb-4 h-1" />
										<div className="mb-4">
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Chronic Kidney Disease (CKD)
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory.isCkd
													? 'Yes'
													: 'No'}
											</p>
										</div>
										<div>
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Notes
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory.noteCkd ||
													'-'}
											</p>
										</div>
										<Separator className="mb-4 h-1" />
										<div className="mb-4">
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Coronary Artery Disease (CAD)
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory.isCad
													? 'Yes'
													: 'No'}
											</p>
										</div>
										<div>
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Notes
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory.noteCad}
											</p>
										</div>
										<Separator className="mb-4 h-1" />

										<div className="mb-4">
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Allergies
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory.isAllergies
													? 'Yes'
													: 'No'}
											</p>
										</div>
										<div>
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Notes
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory
													.noteAllergies || '-'}
											</p>
										</div>

										<Separator className="mb-4 h-1" />
										<div className="mb-4">
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Surgical Procedure
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory
													.isSurgicalProcedure
													? 'Yes'
													: 'No'}
											</p>
										</div>
										<div>
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Notes
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory
													.noteSurgicalProcedure || '-'}
											</p>
										</div>

										<Separator className="mb-4 h-1" />
										<div className="mb-4">
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Trauma Accident
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory
													.isTraumaAccident
													? 'Yes'
													: 'No'}
											</p>
										</div>
										<div>
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Notes
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory
													.noteTraumaAccident || '-'}
											</p>
										</div>

										<Separator className="mb-4 h-1" />
										<div className="mb-4">
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Immunization
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory
													.isImmunization
													? 'Yes'
													: 'No'}
											</p>
										</div>
										<div className="mb-3">
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Vaccines List
											</p>
											{patient?.medicalHistory?.postMedicalHistory?.vaccines
												.length === 0 && (
												<p className="m-0 mb-1 text-4.5">
													No significant medical history.
												</p>
											)}
										</div>
										{patient?.medicalHistory?.postMedicalHistory?.vaccines
											?.length > 0 &&
											patient.medicalHistory.postMedicalHistory.vaccines.map(
												(vaccine: Vaccine) => (
													<>
														<div className="font-semibold text-4.5 mb-2">
															{vaccine.vaccineName}
														</div>
														<div className="mb-1 flex text-3.5">
															<p className="m-0 text-charcoal-300 flex-1">
																Date Vaccines
															</p>
															<p className="m-0 mb-1 text-4.5 flex-2">
																{moment(vaccine.date).format('D MMM YYYY')}
															</p>
														</div>
														<div className="mb-1 flex text-3.5">
															<p className="m-0 text-charcoal-300 flex-1">
																Next Doses
															</p>
															<p className="m-0 mb-1 text-4.5 flex-2">
																{moment(vaccine.nextDose).format('D MMM YYYY')}
															</p>
														</div>
														<div className="mb-2 text-3.5">
															<p className="m-0 text-charcoal-300">
																Adverse reaction
															</p>
															<p className="m-0 mb-1 text-4.5">
																{vaccine.adverseReaction}
															</p>
														</div>

														<Separator className="mb-4 h-1" />
													</>
												),
											)}
										<div className="mb-4">
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Others
											</p>
											<p className="m-0 mb-1 text-4.5">
												{patient?.medicalHistory.postMedicalHistory.others ||
													'-'}
											</p>
										</div>
									</div>
								</div>
							</TabPane>

							{/* OB/GYN Tab */}

							{patient?.patient?.gender !== 'male' &&
								patient?.patientInRelation?.gender !== 'male' && (
									<TabPane tab="OB/GYN" key="obgyn">
										<div className="flex gap-x-3">
											<div className="w-1/4">
												<p className="m-0 font-semibold text-4">OB/GYN</p>
											</div>
											<div className="flex-1 flex gap-x-3">
												<div className="flex-1">
													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Menarche
														</p>
														<p className="m-0 mb-1 text-4.5">
															{patient?.medicalHistory.obgyn.menarche}
														</p>
													</div>

													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Cycle
														</p>
														<p className="m-0 mb-1 text-4.5">
															{patient?.medicalHistory.obgyn.cycle}
														</p>
													</div>

													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Duration
														</p>
														<p className="m-0 mb-1 text-4.5">
															{patient?.medicalHistory.obgyn.duration}
														</p>
													</div>

													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Menstrual Symptoms
														</p>
														<p className="m-0 mb-1 text-4.5">
															{patient?.medicalHistory.obgyn.menstrualSymptoms}
														</p>
													</div>

													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Last Menstrual Periods(LMP)
														</p>
														<p className="m-0 mb-1 text-4.5">
															No significant OB/GYN history.
														</p>
													</div>

													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Previous Normal Menstruation Period
														</p>
														<p className="m-0 mb-1 text-4.5">
															No significant OB/GYN history.
														</p>
													</div>

													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Age of menopause
														</p>
														<p className="m-0 mb-1 text-4.5">
															{patient?.medicalHistory.obgyn.ageOfMenopause}
														</p>
													</div>

													<Separator className="mb-4 h-1" />
													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Contraceptive History
														</p>
														<p className="m-0 mb-1 text-4.5">
															{patient?.medicalHistory.obgyn
																.isContraceptiveHistory
																? 'Yes'
																: 'No'}
														</p>
													</div>

													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Notes
														</p>
														<p className="m-0 mb-1 text-4.5">
															{
																patient?.medicalHistory.obgyn
																	.noteContraceptiveHistory
															}
														</p>
													</div>
													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Gravida
														</p>
														<p className="m-0 mb-1 text-4.5">
															{patient?.medicalHistory.obgyn.gravida}
														</p>
													</div>
													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Para
														</p>
														<p className="m-0 mb-1 text-4.5">
															{patient?.medicalHistory.obgyn.para}
														</p>
													</div>
													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															FullTerm
														</p>
														<p className="m-0 mb-1 text-4.5">
															{patient?.medicalHistory.obgyn.fullTerm}
														</p>
													</div>
													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															PreTerm
														</p>
														<p className="m-0 mb-1 text-4.5">
															{patient?.medicalHistory.obgyn.preTerm}
														</p>
													</div>
													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Abortion
														</p>
														<p className="m-0 mb-1 text-4.5">
															{patient?.medicalHistory.obgyn.abortion}
														</p>
													</div>
													<div className="mb-4">
														<p className="m-0 text-3.5 font-medium text-charcoal-300">
															Living Babies
														</p>
														<p className="m-0 mb-1 text-4.5">
															{patient?.medicalHistory.obgyn.livingBabies}
														</p>
													</div>
												</div>
											</div>
										</div>
									</TabPane>
								)}

							{/* Social History Tab */}
							<TabPane tab="Social History" key="social-history">
								<div className="flex gap-x-3">
									<div className="w-1/4">
										<p className="m-0 font-semibold text-4">Social History</p>
									</div>
									<div className="flex-1 flex gap-x-3">
										<div className="flex-1">
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Alcohol
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.socialHistory.isAlcohol
														? 'Yes'
														: 'No'}
												</p>
											</div>
											<div>
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Notes
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.socialHistory.alcohol || '-'}
												</p>
											</div>

											<Separator className="mb-4 h-1" />
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Smoking
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.socialHistory.isSmoking
														? 'Yes'
														: 'No'}
												</p>
											</div>
											<div>
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Notes
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.socialHistory.smoking || '-'}
												</p>
											</div>

											<Separator className="mb-4 h-1" />
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Illicit Drug Use
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.socialHistory.isDrugUse
														? 'Yes'
														: 'No'}
												</p>
											</div>
											<div>
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Notes
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.socialHistory.drugUse || '-'}
												</p>
											</div>

											<Separator className="mb-4 h-1" />
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Others
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.socialHistory.others}
												</p>
											</div>
										</div>
									</div>
								</div>
							</TabPane>

							{/* Social History Tab */}
							<TabPane tab="Family History" key="family-history">
								<div className="flex gap-x-3">
									<div className="w-1/4">
										<p className="m-0 font-semibold text-4">Family History</p>
									</div>
									<div className="flex-1 flex gap-x-3">
										<div className="flex-1">
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Bronchial Asthma
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory
														.isBronchialAsthma
														? 'Yes'
														: 'No'}
												</p>
											</div>
											<div>
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Notes
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory
														.bronchialAsthma || '-'}
												</p>
											</div>

											<Separator className="mb-4 h-1" />
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Diabetes
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory.isDiabetes
														? 'Yes'
														: 'No'}
												</p>
											</div>
											<div>
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Notes
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory.diabetes ||
														'-'}
												</p>
											</div>
											<Separator className="mb-4 h-1" />
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Hypertension
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory.isHypertension
														? 'Yes'
														: 'No'}
												</p>
											</div>
											<div>
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Notes
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory.hypertension ||
														'-'}
												</p>
											</div>
											<Separator className="mb-4 h-1" />
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Ischemic Heart Disease (IHD)
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory.isIhd
														? 'Yes'
														: 'No'}
												</p>
											</div>
											<div>
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Notes
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory.ihd || '-'}
												</p>
											</div>
											<Separator className="mb-4 h-1" />
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Cancer
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory.isCancer
														? 'Yes'
														: 'No'}
												</p>
											</div>
											<div>
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Notes
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory.cancer || '-'}
												</p>
											</div>
											<Separator className="mb-4 h-1" />
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Kidney Disease
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory.isKidneyDisease
														? 'Yes'
														: 'No'}
												</p>
											</div>
											<div>
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Notes
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory
														.kidneyDisease || '-'}
												</p>
											</div>
											<Separator className="mb-4 h-1" />
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Thyroid Problems
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory
														.isThyroidProblems
														? 'Yes'
														: 'No'}
												</p>
											</div>
											<div>
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Notes
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory
														.thyroidProblems || '-'}
												</p>
											</div>
											<Separator className="mb-4 h-1" />
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Blood Dyscrasia
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory
														.isBloodDyscrasia
														? 'Yes'
														: 'No'}
												</p>
											</div>
											<div>
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Notes
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory
														.bloodDyscrasia || '-'}
												</p>
											</div>
											<Separator className="mb-4 h-1" />
											<div className="mb-4">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Others
												</p>
												<p className="m-0 mb-1 text-4.5">
													{patient?.medicalHistory.familyHistory.others || '-'}
												</p>
											</div>
										</div>
									</div>
								</div>
							</TabPane>
						</Tabs>
					</>
				)}
			</div>
		</div>
	);
}
