import React, { Key, useEffect } from 'react';
import moment from 'moment';
import {
	BloodPressureTrendProperties,
	EMRFindTrendProperties,
	EMRGraphData,
} from '../../../../types/emr.type';
import { apiGetVitalSignTrend } from '../../../../api/emr';
import { generateScatterData } from '../../../../helpers/generateGraphData';
import Spinner from '../../../Spinner';
import { theme } from '../../../../assets/theme';
import { Card, Divider } from 'antd';
import VitalSignTypeSummary from '../DetailEmr/VitalSign/VitalSignTypeSummary';
import FilterRangeDate from '../../../Form/FilterRangeDate';
import { Chart } from 'react-google-charts';
import { VitalSignTrendProperties } from '../../../../types/vitalSign.type';
import { HistoryDataBloodPressure } from '../DetailEmr/VitalSign/VitalSignHistoryData/HistoryDataBloodPressure';

const BloodPressure = (props: { patientId: string }) => {
	const [data, setData] = React.useState<BloodPressureTrendProperties>();
	const [isLoading, setIsLoading] = React.useState(false);
	const [scatterData, setScatterData] = React.useState<
		(string | { role: string })[][]
	>([]);
	const [valueFrom, setValueFrom] = React.useState(
		moment().subtract(3, 'years').toISOString(),
	);
	const [valueTo, setValueTo] = React.useState(
		moment().endOf('month').toISOString(),
	);

	const fetchData = async (from?: string, to?: string) => {
		setIsLoading(true);
		try {
			const res = await apiGetVitalSignTrend(
				props.patientId,
				'blood_pressure',
				undefined,
				from,
				to,
			);
			if (res) {
				setData(res as BloodPressureTrendProperties);
			}
		} catch (e) {
			console.log(e);
			setIsLoading(false);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		fetchData(
			moment(valueFrom).format('YYYY-MM-DD'),
			moment(valueTo).format('YYYY-MM-DD'),
		);
	}, [valueTo]);

	useEffect(() => {
		if (data) {
			const systolicScatterData = generateScatterData(
				data?.systolic?.graphData,
			);
			const diastolicScatterData = generateScatterData(
				data?.diastolic?.graphData,
			);
			const combinedScatterData = combineScatterData(
				systolicScatterData,
				diastolicScatterData,
			);
			setScatterData(combinedScatterData);
		}
	}, [data]);

	const combineScatterData = (
		data1: EMRGraphData[],
		data2: EMRGraphData[],
	): (string | { role: string })[][] => {
		const points1 = data1.slice(1).map(([x, y]: any) => ({
			x,
			y,
			color: `${theme.colors.primary}`,
		}));

		const points2 = data2.slice(1).map(([x, y]: any) => ({
			x,
			y,
			color: '#1292EE',
		}));

		const allPoints = [...points1, ...points2];

		allPoints.sort((a, b) => a.x - b.x || a.y - b.y);

		const combinedData: (string | { role: string })[][] = [
			['date', 'value', { role: 'style' }],
		];

		allPoints.forEach((point) => {
			combinedData.push([point.x, point.y, point.color]);
		});

		return combinedData;
	};

	return isLoading ? (
		<div className="py-5 mx-auto w-fit">
			<Spinner size={25} color={theme.colors.primary} />
		</div>
	) : (
		<div className="p-5 flex flex-col">
			<div
				className="flex gap-3 text-3\.5 mt-2\.5 font-normal leading-7"
				style={{ color: '#748494' }}
			>
				<TrendInfo
					label="Systolic"
					color={theme.colors.primary}
					summary={data?.systolic?.summary}
					unit={data?.systolic?.unit}
				/>
				<TrendInfo
					label="Diastolic"
					color="#1292EE"
					summary={data?.diastolic?.summary}
					unit={data?.diastolic?.unit}
				/>
			</div>
			<FilterRangeDate
				valueFrom={valueFrom}
				valueTo={valueTo}
				onChangeFrom={(val) => setValueFrom(val)}
				onChangeTo={(val) => setValueTo(val)}
			/>

			<Chart
				chartType="ScatterChart"
				data={scatterData}
				options={{
					hAxis: { minValue: 1, maxValue: 30, ticks: [1, 7, 14, 21, 30] },
					vAxis: { minValue: 0, maxValue: 200, ticks: [0, 50, 100, 150, 200] },
					legend: 'none',
					backgroundColor: {
						fill: 'transparent',
						stroke: `${theme.colors.ash300}`,
						strokeWidth: 0,
					},
					pointSize: 10,
					colors: [theme.colors.primary],
					chartArea: {
						left: 30,
						top: 20,
						right: 10,
						bottom: 40,
					},
				}}
				width="100%"
				height="300px"
			/>

			<Card className="min-h-24 rounded-3 mt-2\.5" bodyStyle={{ padding: 16 }}>
				<p className="font-semibold text-4 mb-0">Summary</p>
				<div className="flex items-center">
					<div className="flex-1 flex flex-col gap-y-3">
						{data?.systolic?.summary?.map((item: any, index: Key) => (
							<VitalSignTypeSummary
								key={index}
								bloodPressureParams="systolic"
								title={item.title}
								value={item.value}
								unit={item.unit}
								status={item.status}
							/>
						))}
					</div>
					<div className="flex-1 flex flex-col gap-y-3">
						{data?.diastolic?.summary?.map((item: any, index: Key) => (
							<VitalSignTypeSummary
								key={index}
								bloodPressureParams="diastolic"
								title={item.title}
								value={item.value}
								unit={item.unit}
								status={item.status}
							/>
						))}
					</div>
				</div>
			</Card>
			<Divider
				style={{
					borderWidth: 2,
					marginTop: 20,
					marginBottom: 20,
					borderColor: `${theme.colors.ash300}`,
				}}
			/>
			<HistoryDataBloodPressure
				systolicData={data?.systolic}
				diastolicData={data?.diastolic}
				dataExtractor={(item: any) => ({
					systolicValue: item.systolic,
					diastolicValue: item.diastolic,
				})}
			/>
		</div>
	);
};

export default BloodPressure;

const generateSummary = (
	summary: VitalSignTrendProperties[],
	title: string,
) => {
	const item = summary?.find((item) => item.title === title);
	return item ? item.value : '';
};

const TrendInfo = ({
	label,
	color,
	summary,
	unit,
}: {
	label: string;
	color: string;
	summary?: VitalSignTrendProperties[];
	unit?: string;
}) => {
	const lowest = generateSummary(summary!, 'Lowest');
	const highest = generateSummary(summary!, 'Highest');

	return (
		<div>
			<div
				className="mb-2"
				style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
			>
				<div
					style={{
						position: 'absolute',
						top: '29%',
						left: 7,
						width: 10,
						height: 10,
						borderRadius: 5,
						backgroundColor: color,
					}}
				/>
				<div className="pl-6">{label}</div>
			</div>
			<span className="text-9 font-semibold text-black-100">
				{label === 'Diastolic' && <span>/ </span>}
				{`${lowest} - ${highest}`}
			</span>{' '}
			{unit}
		</div>
	);
};
