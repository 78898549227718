import { Modal, Row, Col, Typography } from 'antd';
import { Button } from '../../../../Button/Button/Button';
const { Text, Paragraph } = Typography;

interface ModalSaveDraftProps {
	isModalVisible: boolean;
	setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	handleConfirmChange: () => void;
}

const ModalSaveDraft = (props: ModalSaveDraftProps) => {
	return (
		<Modal
			open={props.isModalVisible}
			footer={null}
			closable={false}
			width={360}
		>
			<div className="flex flex-col items-center">
				<Text style={{ marginBottom: 16 }} className="text-5 font-semibold">
					{'Save EMR as Draft'}
				</Text>
				<Paragraph style={{ marginBottom: 0, textAlign: 'center' }}>
					{'Are you sure you want to save this EMR as draft?'}
				</Paragraph>
			</div>

			<Row gutter={24} className="mt-6">
				<Col span={12}>
					<Button
						type="TRANSPARENT"
						onClick={() => props.setModalVisible(false)}
					>
						{'Cancel'}
					</Button>
				</Col>
				<Col span={12}>
					<Button
						type="SOLID"
						onClick={props.handleConfirmChange}
						className="rounded-3 bg-primary w-full"
					>
						{'Save'}
					</Button>
				</Col>
			</Row>
		</Modal>
	);
};

export default ModalSaveDraft;
