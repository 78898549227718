import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../context/auth.context';
import {
	DoctorDocumentProperties,
	EMetaType,
	MetaClearence,
	MetaFitToWork,
	MetaMedicalCertificate,
	MetaOthers,
} from '../../../../../types/doctorDocument.type';
import { Button } from '../../../../Button/Button/Button';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import {
	apiCreateDoctorDocument,
	apiGetDoctorDocument,
	apiUpdateDoctorDocument,
} from '../../../../../api/doctorDocument';
import { IUser } from '../../../../../types/user.type';
import { getName } from '../../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../../helpers/profile';
import moment from 'moment';
import ModalConfirmation, { EModalConfirmationType } from './ModalConfirmation';
import { EmrProperties } from '../../../../../types/emr.type';

const forms = [
	{
		formTitle: 'Create Document',
		type: 'ownTemplate',
		forms: [
			{
				label: 'Document title',
				name: 'title',
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.OTHERS],
				isRequired: true,
			},
			{
				label: 'Custom Content',
				name: 'content',
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.OTHERS],
				rows: 12,
				isRequired: true,
			},
		],
	},
];

interface Props {
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	onBack: () => void;
	setNextStep: () => void;
	setFormName: () => void;
}

export default function FormCreateOwnTemplate(props: Props) {
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(false);
	const [patientProfile, setPatientProfile] = useState<{
		[key: string]: any;
	}>();
	const [ownTemplate, setOwnTemplate] = useState<{
		[key: string]: any;
	}>();
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);
	const [isFormValid, setIsFormValid] = useState(false);
	const todayAfter3Month = new Date();
	todayAfter3Month.setMonth(new Date().getMonth() + 3);

	const validateForm = () => {
		const isValid = !!ownTemplate?.meta?.content && !!ownTemplate?.meta?.title;

		setIsFormValid(isValid);
	};

	useEffect(() => {
		validateForm();
	}, [ownTemplate]);

	useEffect(() => {
		(async () => {
			const ownTemplate = props.emr.doctorDocuments?.find(
				(doc) => doc.type === EMetaType.OTHERS,
			);

			if (!ownTemplate) return;

			setIsLoading(true);

			const res = await apiGetDoctorDocument(
				ownTemplate.doctorDocumentId || '',
			);
			if (res) {
				const metaPatient = res.metaPatient as IUser;
				setPatientProfile({
					patientName: getName(metaPatient),
					gender: getGender(metaPatient)?.toLowerCase(),
					age: moment().diff(getBirthdate(metaPatient), 'years', false),
					phone: metaPatient?.phone,
				});
				setOwnTemplate(res);
			}

			setIsLoading(false);
		})();
	}, [props.emr.doctorDocuments]);

	async function saveOwnTemplate() {
		setIsLoading(true);

		const { emr, setEmr } = props;

		const patientName = patientProfile?.patientName
			? patientProfile?.patientName.split(' ')
			: [];
		const birthdate = new Date();
		birthdate.setUTCFullYear(
			birthdate.getUTCFullYear() - (patientProfile?.age ?? 0),
		);

		let isSuccess = false;
		let data: DoctorDocumentProperties = {
			transactionConsultationId: ownTemplate?.transactionConsultationId,
			doctorId: user?.userId,
			type: EMetaType.OTHERS,
			patientId: emr.patientId,
			// metaPatient: {
			// 	firstName: patientName[0],
			// 	middleName:
			// 		patientName.length > 2 ? patientName.slice(1, -1).join(' ') : '',
			// 	lastName:
			// 		patientName.length > 1 ? patientName[patientName.length - 1] : '',
			// 	birthdate,
			// 	gender: patientProfile?.gender?.toLowerCase(),
			// },
			metaPatient: emr.patient || emr.patientInRelation || {},
			validUntil: ownTemplate?.validUntil ?? todayAfter3Month.toString(),
			price: ownTemplate?.price ?? 0,
			meta: ownTemplate?.meta,
			isTemporary: true,
			emrId: emr.emrId,
		};

		if (ownTemplate?.doctorDocumentId) {
			data = {
				...data,
				meta: ownTemplate?.meta as
					| MetaMedicalCertificate
					| MetaClearence
					| MetaFitToWork
					| MetaOthers,
				emrId: emr.emrId,
			};

			const res = await apiUpdateDoctorDocument(
				ownTemplate.doctorDocumentId,
				data,
			);
			if (res) {
				setEmr({
					...emr,
					doctorDocuments: (emr.doctorDocuments || []).map((doc) =>
						doc.doctorDocumentId === res.doctorDocumentId ? res : doc,
					),
				});
				setOwnTemplate(res);
				isSuccess = true;
			}
		} else {
			data = {
				...data,
				transactionConsultationId: ownTemplate?.transactionConsultationId,
				doctorId: user?.userId,
				type: EMetaType.OTHERS,
				meta: ownTemplate?.meta as
					| MetaMedicalCertificate
					| MetaClearence
					| MetaFitToWork
					| MetaOthers,
				emrId: emr.emrId,
			};

			const res = await apiCreateDoctorDocument(data);
			if (res) {
				setEmr({
					...emr,
					doctorDocuments: [...(emr.doctorDocuments || []), res],
				});
				setOwnTemplate(res);
				isSuccess = true;
			}
		}

		if (isSuccess) {
			props.setNextStep();
			props.setFormName();
			message.success('Own Template Saved Sucessfully');
		}

		setIsLoading(false);
	}

	const clearFormHandler = () => {
		setOwnTemplate({
			meta: {
				title: '',
				content: '',
			},
		});
		setIsModalConfirmationVisible(false);
	};

	return (
		<div>
			<form>
				<div className="py-6 px-3">
					{forms.map((formsData, index) => (
						<div key={index}>
							<div className="flex gap-x-3">
								<div className="pt-2 w-1/4">
									<p className="m-0 font-semibold text-4">
										{formsData.formTitle}
									</p>
								</div>
								<div className="flex-1">
									{formsData.forms.map((form) => (
										<FormInput
											key={form.label}
											label={form.label}
											type={form.type}
											required={form.isRequired}
											value={ownTemplate?.meta?.[form.name] || ''} // Ensure value is a string
											rows={form.rows}
											onChange={(val) =>
												setOwnTemplate((old) => ({
													...old,
													meta: {
														...old?.meta,
														[form.name]: val,
													},
												}))
											}
										/>
									))}
								</div>
							</div>
							{index < forms.length - 1 && (
								<div className="my-1">
									<Separator />
								</div>
							)}
						</div>
					))}
				</div>
				<Separator />
				<div className="py-3 px-4 flex justify-between">
					<div className="w-44">
						<Button
							type="SOLIDASH"
							onClick={() => setIsModalConfirmationVisible(true)}
						>
							Clear Form
						</Button>
					</div>
					<div className="w-44">
						<Button
							type="SOLID"
							onClick={saveOwnTemplate}
							isLoading={isLoading}
							isDisable={!isFormValid || isLoading}
						>
							Save
						</Button>
					</div>
				</div>
			</form>
			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={clearFormHandler}
				type={EModalConfirmationType.CLEAR_FORM}
			/>
		</div>
	);
}
function setSearchPhone(phone: string | undefined) {
	throw new Error('Function not implemented.');
}
