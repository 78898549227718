import { useMemo, useState } from 'react';
import { ArrowRight } from 'react-feather';
import styled from 'styled-components';
import { theme } from '../assets/theme';
import Card from '../components/Card';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import {
	INotification,
	MetaNotificationConsultation,
} from '../types/notification.type';
import Moment from '../utils/moment';
import { ReactComponent as Spinner } from '../assets/icons/spinner.svg';
import { useAuth } from '../context/auth.context';
import {
	EConsultationType,
	ETransactionStatusType,
	TransactionConsultationProperties,
} from '../types/transactionConsultation.type';
import { ReactComponent as AppointmentReschedule } from '../assets/icons/appointment-reschedule.svg';
import { ReactComponent as TelemedicineReschedule } from '../assets/icons/telemedicine-reschedule.svg';
import { ReactComponent as Calendar } from '../assets/icons/sidebar-icon-calendar.svg';
import { ReactComponent as Clock } from '../assets/icons/clock.svg';
import { ReactComponent as TelemedicineCircleFilled } from '../assets/icons/telemedicine-circle-filled.svg';
import { ReactComponent as AppointmentCircleFilled } from '../assets/icons/in-person-appointment-circle-filled.svg';
import { ReactComponent as Logo } from '../assets/icons/logo.svg';
import { getName } from '../helpers/name';
import { calculateAge } from '../helpers/datetime';
import { EPWIdStatus } from '../types/patient.type';
import { getBirthdate, getGender } from '../helpers/profile';
import moment from 'moment';
import { EScheduleTypes } from '../types/schedule.type';
import { getScheduleType } from '../constants';
import useWindowSize from '../hooks/useWindowSize';
import CustomScrollDiv from '../components/CustomScrollDiv';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import useFetchList from '../hooks/useFetchList';

export default function Notification() {
	const height = useWindowSize().height;
	const { user } = useAuth();

	const [selectedTab, setSelectedTab] = useState(0);

	const {
		data: notifications,
		setData: setNotifications,
		setQuery: setQueryNotif,
		isLoading: isLoadingNotif,
	} = useFetchList<INotification>({
		endpoint: '/notifications',
		limit: 5,
	});

	const { data: needAction, isLoading: isLoadingNeedAct } =
		useFetchList<TransactionConsultationProperties>({
			endpoint: '/transaction-consults',
			initialQuery: {
				doctorId: user?.userId,
				filterTransactionStatuses: `${ETransactionStatusType.WAITING_APPROVAL}`,
			},
		});

	const isLoading = useMemo(
		() => isLoadingNeedAct && isLoadingNotif,
		[isLoadingNeedAct, isLoadingNotif],
	);

	async function markAsRead(id: string) {
		try {
			await httpRequest.patch('notifications/mark-as-read', {
				notificationIds: [id],
			});

			setNotifications((oldState) => {
				const newNotif = [...oldState];

				const index = newNotif.findIndex(
					(notif) => notif.notificationId === id,
				);
				if (index >= 0) {
					newNotif[index].isRead = true;
				}

				return newNotif;
			});
		} catch (err) {
			const error = getErrorMessage(err);
			console.error(error);
			message.error(error);
		}
	}

	async function markAllAsRead() {
		try {
			await httpRequest.patch('notifications/mark-all-as-read');

			setNotifications((oldState) => {
				const newNotif = [...oldState];

				for (const item of newNotif) {
					const index = newNotif.findIndex(
						(notif) => notif.notificationId === item.notificationId,
					);

					if (index >= 0) {
						newNotif[index].isRead = true;
					}
				}

				return newNotif;
			});
		} catch (err) {
			const error = getErrorMessage(err);
			console.error(error);
			message.error(error);
		}
	}

	const unreadNotif = useMemo(() => {
		if (!notifications || notifications.length == 0) return 0;

		let unread = 0;
		for (const notif of notifications) {
			if (!notif.isRead) {
				++unread;
			}
		}

		return unread;
	}, [notifications]);

	return (
		<Card className="px-3 py-6">
			<SideBarContainer>
				<NotificationTabContainer>
					<NotificationTabButton
						onClick={() => setSelectedTab(0)}
						style={
							selectedTab === 0
								? {
										backgroundColor: theme.colors.primary,
										color: theme.colors.white,
								  }
								: undefined
						}
					>
						Notification{unreadNotif ? ` (${unreadNotif})` : ''}
					</NotificationTabButton>
					<NotificationTabButton
						onClick={() => setSelectedTab(1)}
						style={
							selectedTab === 1
								? {
										backgroundColor: theme.colors.primary,
										color: theme.colors.white,
								  }
								: undefined
						}
					>
						Need Action{needAction.length ? ` (${needAction.length})` : ''}
					</NotificationTabButton>
				</NotificationTabContainer>
				<Separator />
				<CustomScrollDiv className="flex-1 min-h-0 w-full">
					<div className="w-full flex flex-col gap-y-1">
						{!isLoading ? (
							selectedTab === 0 ? (
								<>
									{unreadNotif ? (
										<MarkAllAsReadButton onClick={() => markAllAsRead()}>
											Mark All As Read
										</MarkAllAsReadButton>
									) : null}
									{notifications.map((notification) => (
										<NotificationItem
											key={notification.notificationId}
											notification={notification}
											onPress={(id) => markAsRead(id)}
										/>
									))}
									{notifications.length === 5 && (
										<SeeAllNotificationButton
											onClick={() =>
												setQueryNotif((oldVal) => ({
													...oldVal,
													limit: oldVal.limit && oldVal.limit + 100000,
												}))
											}
										>
											See All Notification
										</SeeAllNotificationButton>
									)}
								</>
							) : needAction.length == 0 ? (
								<div style={{ width: '350px' }} />
							) : (
								needAction.map((trx) => (
									<NeedActionItem
										key={trx.transactionConsultationId}
										trx={trx}
									/>
								))
							)
						) : (
							<div className="block py-4 w-full w-fit h-fit mx-auto">
								<Spinner
									width={27.5}
									height={27.5}
									color={theme.colors.primary}
								/>
							</div>
						)}
					</div>
				</CustomScrollDiv>
			</SideBarContainer>
		</Card>
	);
}

function NotificationItem(props: {
	notification: INotification;
	onPress: (id: string) => void;
}) {
	let Icon: React.FunctionComponent<
		React.SVGProps<SVGSVGElement> & {
			title?: string;
		}
	>;

	switch (
		(props.notification.meta as MetaNotificationConsultation)?.consultationType
	) {
		case EConsultationType.CONSULT_NOW_CHAT_CONSULTATION:
		case EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION:
		case EConsultationType.TELEMEDICINE_CHAT_CONSULTATION:
		case EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION:
			Icon = TelemedicineCircleFilled;
			break;
		case EConsultationType.FACE_TO_FACE_CONSULTATION:
			Icon = AppointmentCircleFilled;
			break;
		default:
			Icon = Logo;
	}
	return (
		<NotificationCard
			onClick={() => props.onPress?.(props.notification.notificationId)}
			style={
				!props.notification.isRead
					? { backgroundColor: 'rgba(253, 218, 231, 0.5)', cursor: 'pointer' }
					: undefined
			}
		>
			<div>{Icon ? <Icon width={24} height={24} /> : undefined}</div>
			<div className="flex-1">
				<NotificationTitle>{props.notification.title}</NotificationTitle>
				<NotificationBody>{props.notification.message}</NotificationBody>
				<NotificationTime>
					{Moment.moment(props.notification.createdAt!)}
				</NotificationTime>
			</div>
		</NotificationCard>
	);
}

function NeedActionItem(props: { trx: TransactionConsultationProperties }) {
	let oldStartAt, oldEndAt, newStartAt, newEndAt;
	const history = useHistory();

	let type: EScheduleTypes;

	if (
		props.trx.consultationType.valueOf() ===
			EConsultationType.TELEMEDICINE_CHAT_CONSULTATION ||
		props.trx.consultationType.valueOf() ===
			EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION
	) {
		type = EScheduleTypes.telemedicine;
	} else if (
		props.trx.consultationType.valueOf() ===
		EConsultationType.FACE_TO_FACE_CONSULTATION
	) {
		type = EScheduleTypes.appointment;
	} else if (
		props.trx.consultationType.valueOf() ===
			EConsultationType.CONSULT_NOW_CHAT_CONSULTATION ||
		props.trx.consultationType.valueOf() ===
			EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION
	) {
		type = EScheduleTypes['consult-now'];
	}

	if (
		[
			ETransactionStatusType.REJECTED_CHANGE_SCHEDULE,
			ETransactionStatusType.APPROVED,
			ETransactionStatusType.COMPLETED,
		].includes(props.trx.transactionStatus) &&
		props.trx.history
	) {
		const found = props.trx.history.find(
			(item: any) =>
				item.newData.transactionStatus ===
				ETransactionStatusType.REJECTED_CHANGE_SCHEDULE,
		);

		const type = getScheduleType(props.trx);

		if (
			found?.newData &&
			(type === EScheduleTypes.telemedicine ||
				type === EScheduleTypes.appointment ||
				type === EScheduleTypes['consult-now'])
		) {
			oldStartAt = found.newData.oldScheduledStartAt;
			oldEndAt = found.newData.oldScheduledEndAt;
			newStartAt = found.newData.newScheduledStartAt;
			newEndAt = found.newData.newScheduledEndAt;
		}
	}

	let Icon;
	switch (props.trx.consultationType) {
		case EConsultationType.FACE_TO_FACE_CONSULTATION:
			Icon = AppointmentReschedule;
			break;
		case EConsultationType.TELEMEDICINE_CHAT_CONSULTATION:
		case EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION:
		case EConsultationType.CONSULT_NOW_CHAT_CONSULTATION:
		case EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION:
			Icon = TelemedicineReschedule;
			break;
	}

	return (
		<div className="p-3 border border-solid border-gray-10 rounded-3">
			<div className="px-0.5 py-1 flex items-center gap-x-2">
				<div className="flex w-8 h-8">{Icon && <Icon />}</div>
				<div>
					<p className="m-0 font-medium text-4 leading-4">
						{getTitle({
							consultationType: props.trx.consultationType,
							transactionStatus: props.trx.transactionStatus,
						})}
					</p>
				</div>
			</div>
			<div className="py-1.5">
				<Separator style={{ height: 2 }} />
			</div>
			<div>
				<p className="m-0 font-semibold text-3.5">Patient</p>
				<div className="mt-1">
					<p className="m-0 text-4 leading-4">
						{getName(props.trx.metaPatient)}
					</p>
					<p className="m-0 text-3.5 text-charcoal-300">
						{calculateAge(getBirthdate(props.trx?.metaPatient))} Years Old •{' '}
						{getGender(props.trx.metaPatient)}
						{props.trx.metaPatient?.pwIdStatus === EPWIdStatus.APPROVED
							? ' • PWD/Senior'
							: ''}
					</p>
				</div>
			</div>
			<div className="mt-3">
				<p className="m-0 font-semibold text-3.5">
					{props.trx.transactionStatus ===
						ETransactionStatusType.REJECTED_CHANGE_SCHEDULE && 'Reschedule '}
					Time
				</p>
				<div className="mt-1.5 flex items-center justify-between gap-x-1">
					{getTime({
						isChangeSchedule:
							props.trx.transactionStatus ===
							ETransactionStatusType.REJECTED_CHANGE_SCHEDULE,
						startAt: oldStartAt,
						endAt: oldEndAt,
						newStartAt: newStartAt,
						newEndAt: newEndAt,
					})}
				</div>
			</div>
			<div className="mt-2.5">
				<button
					onClick={() => {
						history.push('/app/schedule/details', {
							type: type,
							id: props.trx.transactionConsultationId,
							from: '/app/approval',
						});
					}}
					className="py-1.5 w-full border-none bg-ash-300 rounded-2.5 cursor-pointer font-semibold text-3.5"
				>
					View Detail
				</button>
			</div>
		</div>
	);
}

function getTitle({
	consultationType,
	transactionStatus,
}: {
	consultationType: EConsultationType;
	transactionStatus: ETransactionStatusType;
}) {
	let title = '';
	switch (consultationType) {
		case EConsultationType.CONSULT_NOW_CHAT_CONSULTATION:
		case EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION:
			title = 'Consult Now';
			break;
		case EConsultationType.TELEMEDICINE_CHAT_CONSULTATION:
		case EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION:
			title = 'Telemedicine';
			break;
		case EConsultationType.FACE_TO_FACE_CONSULTATION:
			title = 'In Person Consultation';
	}

	switch (transactionStatus) {
		case ETransactionStatusType.REJECTED_CHANGE_SCHEDULE:
			title += ' Reschedule';
			break;
		case ETransactionStatusType.WAITING_APPROVAL:
			title += ' Waiting Approval';
	}

	return title;
}

function getTime({
	isChangeSchedule,
	startAt,
	endAt,
	newStartAt,
	newEndAt,
}: {
	isChangeSchedule: boolean;
	startAt?: any;
	endAt?: any;
	newStartAt?: any;
	newEndAt?: any;
}) {
	return (
		<>
			<div className="w-fit gap-y-1.5">
				<div className="flex gap-x-1">
					<Calendar width={18} height={18} color={theme.colors.gray70} />
					<p className="m-0 text-3.5 text-gray-70">
						{moment(startAt).format('ddd, MMM DD YYYY')}{' '}
					</p>
				</div>
				<div className="flex gap-x-1">
					<Clock width={18} height={18} color={theme.colors.gray70} />
					<p className="m-0 text-3.5 text-gray-70">
						{moment(endAt).format('HH:mm')}
					</p>
				</div>
			</div>
			{isChangeSchedule && (
				<>
					<ArrowRight width={16} height={16} />
					<div className="w-fit gap-y-1.5">
						<div className="flex gap-x-1">
							<Calendar width={18} height={18} color={theme.colors.gray70} />
							<p className="m-0 text-3.5 text-gray-70">
								{moment(newStartAt).format('ddd, MMM DD YYYY')}
							</p>
						</div>
						<div className="flex gap-x-1">
							<Clock width={18} height={18} color={theme.colors.gray70} />
							<p className="m-0 text-3.5 text-gray-70">
								{moment(newEndAt).format('HH:mm')}
							</p>
						</div>
					</div>
				</>
			)}
		</>
	);
}

const SideBarContainer = styled.div`
	/* width: 300px; */
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
`;

const NotificationTabContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-end;
	padding: 4px;
	gap: 4px;
	border: 1px solid ${({ theme }) => theme.colors.black10};
	border-radius: 22px;
`;

const NotificationTabButton = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	padding: 10px 16px;
	border: none;
	border-radius: 24px;
	cursor: pointer;
	font-size: 16px;
	&:hover {
		color: ${({ theme }) => theme.colors.primary};
		background-color: ${({ theme }) => theme.colors.primary10};
	}
`;

const Separator = styled.div`
	height: 2px;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.gray10};
`;

const NotificationCard = styled.div`
	display: flex;
	align-items: flex-start;
	column-gap: 6px;
	padding: 12px;
	border-radius: 8px;
`;

const NotificationTitle = styled.p`
	margin: 0;
	font-weight: 500;
	font-size: 16px;
	color: ${({ theme }) => theme.colors.gray100};
`;

const NotificationBody = styled.p`
	margin: 0;
	font-size: 14px;
	color: ${({ theme }) => theme.colors.gray60};
`;

const NotificationTime = styled.p`
	margin: 0;
	font-size: 12px;
	color: ${({ theme }) => theme.colors.ash800};
`;

const MarkAllAsReadButton = styled.button`
	background: ${({ theme }) => theme.colors.primary};
	width: 100%;
	border-style: none;
	border-width: 0;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 9.5px 12px;
	gap: 5px;
	font-weight: 600;
	font-size: 14px;
	cursor: pointer;
	color: white;
`;

const SeeAllNotificationButton = styled.button`
	background: ${({ theme }) => theme.colors.ash300};
	width: 100%;
	border-style: none;
	border-width: 0;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 9.5px 12px;
	gap: 5px;
	font-weight: 600;
	font-size: 14px;
	cursor: pointer;
`;
