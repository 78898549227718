import { useState, useEffect } from 'react';
import { Tabs, Avatar, Tag, Card } from 'antd';
import './styles.less';
import VitalSignDetail from '../DetailEmr/VitalSign/MainVitalSignDetail';
import { EmrProperties } from '../../../../types/emr.type';
import { calculateAge } from '../../../../helpers/datetime';
import { httpRequest } from '../../../../helpers/api';
import { ApiResponsePagination } from '../../../../types/apiResponse.type';
import ListHPI from '../DetailEmr/HPI/ListHPI';
import { useHistory } from 'react-router-dom';
import ListDiagnosis from '../DetailEmr/Diagnosis/ListDiagnosis';
import ListObjectiveFindings from '../DetailEmr/ObjecttiveFindings/ListObjectiveFindings';
import ListPatientHistory from '../DetailEmr/PatientHistory/ListPatientHistory';
import ListPlan from '../DetailEmr/Plan/ListPlan';
import ListHistory from '../DetailEmr/History/ListEmrHistory';
import ListBilling from '../DetailEmr/Billing/ListBilling';
import DEFAULT_USER from '../../../../assets/default-user2.png';
import { getName } from '../../../../helpers/name';
import { capitalizeWords } from '../../../../helpers/text';
import styled from 'styled-components';
import { theme } from '../../../../assets/theme';

const { TabPane } = Tabs;

interface Props {
	patient?: EmrProperties;
	patientId?: string;
	nextStep: (newStep?: number) => void;
	tabKey: string;
}

export default function DetailPatient(props: Props) {
	const { patient, nextStep, tabKey, patientId } = props;
	const [similarPatients, setSimilarPatients] = useState<EmrProperties[]>([]);
	const [title, setTitle] = useState('Patient List');
	const [isLoading, setIsLoading] = useState(false);
	const [step, setStep] = useState(0);
	const history = useHistory();
	const [activeKey, setActiveKey] = useState('1');

	const handleHPIItemClick = (emrId: string) => {
		history.push({
			pathname: '/app/emr/detail',
			search: `?emrId=${emrId}&tab=3`,
			state: { step: 1, selectedPatient: patient },
		});
	};

	const handleTabChange = (key: string) => {
		setActiveKey(key);
	};

	useEffect(() => {
		if (tabKey === '3') setActiveKey(tabKey);
	}, [tabKey]);

	const handleMedicalHistoryItemClick = (emrId: string) => {
		history.push({
			pathname: '/app/emr/detail',
			search: `?emrId=${emrId}&tab=4`,
			state: { step: 1, selectedPatient: patient },
		});
	};

	const handleObjectiveFindingsItemClick = (emrId: string) => {
		history.push({
			pathname: '/app/emr/detail',
			search: `?emrId=${emrId}&tab=5`,
			state: { step: 1, selectedPatient: patient },
		});
	};

	const handleDiagnosisItemClick = (emrId: string) => {
		// history.push(`/app/emr/detail?emrId=${emrId}&tab=6`);
		history.push({
			pathname: '/app/emr/detail',
			search: `?emrId=${emrId}&tab=6`,
			state: { step: 1, selectedPatient: patient },
		});
	};

	const handlePlanItemClick = (emrId: string) => {
		// history.push(`/app/emr/detail?emrId=${emrId}&tab=7`);
		history.push({
			pathname: '/app/emr/detail',
			search: `?emrId=${emrId}&tab=7`,
			state: { step: 1, selectedPatient: patient },
		});
	};

	const handleHistoryItemClick = (emrId: string) => {
		// history.push(`/app/emr/detail?emrId=${emrId}&tab=1`);
		history.push({
			pathname: '/app/emr/detail',
			search: `?emrId=${emrId}&tab=1`,
			state: { step: 1, selectedPatient: patient },
		});
	};
	const handleBillingItemClick = (emrId: string) => {
		history.push(`/app/emr/detail?emrId=${emrId}&tab=8`);
		history.push({
			pathname: '/app/emr/detail',
			search: `?emrId=${emrId}&tab=8`,
			state: { step: 1, selectedPatient: patient },
		});
	};

	function setNextStep(newStep?: number) {
		const _step = newStep || step + 1;

		if (_step === 1) {
			setTitle('Patient Detail');
			setStep(_step);
		}
	}

	useEffect(() => {
		const fetchSimilarPatients = async () => {
			setIsLoading(true);
			try {
				const response = await httpRequest.get<
					ApiResponsePagination<EmrProperties>
				>(`/emr?patientId=${patient?.patientId || patientId}`);
				setSimilarPatients(response.data.payload.results);
			} catch (error) {
				console.error('Error fetching similar patients:', error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchSimilarPatients();
	}, [patient?.patientId, patientId]);

	return (
		<div className="patient-detail-container">
			<TabStyle>
				<Tabs
					centered
					activeKey={activeKey}
					onChange={handleTabChange}
					renderTabBar={(props, DefaultTabBar) => (
						<DefaultTabBar {...props} style={{ padding: '0 16px' }} />
					)}
				>
					<TabPane tab="All History Data" key="1">
						<ListHistory
							setNextStep={setNextStep}
							similarPatients={similarPatients}
							isLoading={isLoading}
							onItemClick={handleHistoryItemClick}
						/>
					</TabPane>

					<TabPane tab="Profile" key="2">
						{' '}
						<div className="overview-tab">
							<Card
								style={{ minHeight: 100, borderRadius: 12, width: '100%' }}
								bodyStyle={{ padding: 16 }}
							>
								<div
									className="profile-section"
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
									}}
								>
									<Avatar
										size={187}
										src={
											similarPatients?.[0]?.patient?.profilePic ||
											similarPatients?.[0]?.patientInRelation
												?.profilePictureUrl ||
											DEFAULT_USER
										}
										alt={
											similarPatients?.[0]?.patient?.name ||
											getName(similarPatients?.[0]?.patientInRelation)
										}
										style={{ borderRadius: '10px' }}
									/>
								</div>
								<div
									className="contact-info"
									style={{ textAlign: 'left', marginTop: '20px' }}
								>
									<div className="mb-3">
										<p className="m-0 text-3.5 font-medium text-charcoal-200">
											Name
										</p>
										<p className="m-0 text-4\.5">
											{similarPatients?.[0]?.patient?.name ||
												getName(similarPatients?.[0]?.patientInRelation) ||
												'N/A'}
										</p>
									</div>
									<div className="mb-3">
										<p className="m-0 text-3.5 font-medium text-charcoal-200">
											Gender
										</p>
										<p className="m-0 text-4\.5">
											{capitalizeWords(
												similarPatients?.[0]?.patient?.gender ||
													similarPatients?.[0]?.patientInRelation?.gender ||
													'N/A',
											)}
										</p>
									</div>
									<div className="mb-3">
										<p className="m-0 text-3.5 font-medium text-charcoal-200">
											Age
										</p>
										<p className="m-0 text-4\.5">
											{calculateAge(
												similarPatients?.[0]?.patient?.birthdate,
											) !== undefined
												? calculateAge(similarPatients?.[0]?.patient?.birthdate)
												: calculateAge(
														similarPatients?.[0]?.patientInRelation?.birthdate,
												  )}{' '}
											years
										</p>
									</div>
									<div className="mb-3">
										<p className="m-0 text-3.5 font-medium text-charcoal-200">
											Status
										</p>
										<p className="m-0 text-4\.5">
											{capitalizeWords(
												similarPatients?.[0]?.patient?.maritalStatus ||
													similarPatients?.[0]?.patientInRelation
														?.maritalStatus ||
													'-',
											)}
										</p>
									</div>
									<div className="mb-3">
										<p className="m-0 text-3.5 font-medium text-charcoal-200">
											Phone Number
										</p>
										<p className="m-0 text-4\.5 text-blue">
											{similarPatients?.[0]?.patient?.phone
												? `+63 ${similarPatients?.[0]?.patient?.phone?.substring(
														2,
												  )}`
												: similarPatients?.[0]?.patientInRelation?.patient?.user
														?.phone
												? `+63 ${similarPatients?.[0]?.patientInRelation?.patient?.user?.phone?.substring(
														2,
												  )}`
												: '-'}
										</p>
									</div>
									<div className="mb-3">
										<p className="m-0 text-3.5 font-medium text-charcoal-200">
											Address
										</p>
										<p className="m-0 text-4\.5">
											{capitalizeWords(
												similarPatients?.[0]?.patient?.userAddress
													?.addressDetail ||
													similarPatients?.[0]?.patientInRelation?.patient?.user
														?.userAddress?.addressDetail ||
													'-',
											)}
										</p>
									</div>
								</div>
							</Card>
						</div>
					</TabPane>

					<TabPane tab="Vital Signs" key="3">
						{' '}
						<VitalSignDetail
							nextStep={nextStep}
							labRequest={{ labRequestId: undefined }}
							pageType="detail-patient"
							patientId={patient?.patientId || patientId || ''}
							patient={patient}
						/>
					</TabPane>
					<TabPane tab="HPI" key="4">
						<ListHPI
							setNextStep={setNextStep}
							similarPatients={similarPatients}
							isLoading={isLoading}
							onItemClick={handleHPIItemClick}
						/>
					</TabPane>
					<TabPane tab="Patient History" key="5">
						{' '}
						<ListPatientHistory
							setNextStep={setNextStep}
							similarPatients={similarPatients}
							isLoading={isLoading}
							onItemClick={handleMedicalHistoryItemClick}
						/>
					</TabPane>
					<TabPane tab="Objective Findings" key="6">
						{' '}
						<ListObjectiveFindings
							setNextStep={setNextStep}
							similarPatients={similarPatients}
							isLoading={isLoading}
							onItemClick={handleObjectiveFindingsItemClick}
						/>
					</TabPane>
					<TabPane tab="Diagnosis" key="7">
						{' '}
						<ListDiagnosis
							setNextStep={setNextStep}
							similarPatients={similarPatients}
							isLoading={isLoading}
							onItemClick={handleDiagnosisItemClick}
						/>
					</TabPane>
					<TabPane tab="Plan" key="8">
						{' '}
						<ListPlan
							setNextStep={setNextStep}
							similarPatients={similarPatients}
							isLoading={isLoading}
							onItemClick={handlePlanItemClick}
						/>
					</TabPane>
					<TabPane tab="Billing" key="9">
						{' '}
						<ListBilling
							setNextStep={setNextStep}
							similarPatients={similarPatients}
							isLoading={isLoading}
							onItemClick={handleBillingItemClick}
						/>
					</TabPane>
				</Tabs>
			</TabStyle>
		</div>
	);
}

const TabStyle = styled.div`
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: black !important;
		font-weight: 500 !important;
	}

	.ant-tabs-tab {
		color: ${theme.colors.charcoal300} !important;
		font-weight: 500 !important;
	}

	.ant-tabs-ink-bar {
		height: 4px !important;
	}
`;
