import { useEffect, useState } from 'react';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import { Button } from '../../../../Button/Button/Button';
import EmptyList from '../../../../EmptyList';
import { ReactComponent as LabRequest } from '../../../../../assets/icons/picture.svg';
import { ReactComponent as Add } from '../../../../../assets/icons/icon-add.svg';
import { ReactComponent as Upload } from '../../../../../assets/icons/icon-upload.svg';
import { ReactComponent as Draw } from '../../../../../assets/icons/icon-draw.svg';
import { theme } from '../../../../../assets/theme';
import ImageModal from './ModalAnatomy';
import { ObjectiveFindingProperties } from '../../../../../types/objectiveFindings.type';
import {
	apiCreateObjectiveFinding,
	apiDeleteObjectiveFindingByEMRId,
	apiUpdateAnatomyPicture,
	apiUpdateLabPicture,
} from '../../../../../api/emr';
import { PlusCircle, Trash2 } from 'react-feather';
import useAuth from '../../../../../hooks/useAuth';
import { message } from 'antd';
import ModalConfirmation, { EModalConfirmationType } from './ModalConfirmation';
import { EmrProperties } from '../../../../../types/emr.type';

interface Props {
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	step: number;
	setNextStep: () => void;
	setFormName: (formname: string) => void;
	goBack: () => void;
	patientObjectiveFinding: any;
	setPatientObjectiveFinding: React.Dispatch<React.SetStateAction<any>>;
}

export default function FormCreateObjectiveFindings(props: Props) {
	const { userId, isDoctor } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [imageFile, setImageFile] = useState<File | null>(null);
	const [uploadedImages, setUploadedImages] = useState<File[]>([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);
	const [
		isModalRemoveConfirmationVisible,
		setIsModalRemoveConfirmationVisible,
	] = useState(false);
	const [modalAction, setModalAction] = useState<() => void>(() => () => {});
	const [modalTitle, setModalTitle] = useState('');
	const [finalImage, setFinalImage] = useState<string | null>(null);
	const [monitorResults, setMonitorResults] = useState<any[]>([]);
	const [isFormValid, setIsFormValid] = useState(false);

	const validateForm = () => {
		const hasEmptyMonitorResult = monitorResults.some(
			(monitor) =>
				!monitor.name.trim() ||
				!monitor.date.trim() ||
				!monitor.time.trim() ||
				!monitor.value.trim(),
		);

		const isValid =
			!!imageFile ||
			!!finalImage ||
			!!props.patientObjectiveFinding?.noteAnatomy ||
			!!props.patientObjectiveFinding?.noteLab ||
			(monitorResults.length > 0 && !hasEmptyMonitorResult);

		setIsFormValid(isValid);
	};

	useEffect(() => {
		validateForm();
	}, [props.patientObjectiveFinding, monitorResults, imageFile, finalImage]);

	const fetchImageAsFile = async (imageUrl: string): Promise<File> => {
		const response = await fetch(imageUrl);
		const blob = await response.blob();
		const file = new File([blob], 'anatomy-image.png', { type: blob.type });
		return file;
	};

	// Initial Value for Edit Emr
	useEffect(() => {
		if (!props.emr.objectiveFinding) return;

		setMonitorResults(props.emr.objectiveFinding.monitoringLabResult);

		props.setPatientObjectiveFinding({
			...props.patientObjectiveFinding,
			noteAnatomy: props.emr.objectiveFinding.noteAnatomy,
			noteLab: props.emr.objectiveFinding.noteLab,
			labTestPictureUrl: props.emr.objectiveFinding.labTestPictureUrl || null,
			anatomyPictureUrl: props.emr.objectiveFinding.anatomyPictureUrl || null,
		});

		if (props.emr.objectiveFinding.labTestPictureUrl) {
			setImageFile(props.emr.objectiveFinding.labTestPictureUrl);
		}
		if (props.emr.objectiveFinding.anatomyPictureUrl) {
			setFinalImage(props.emr.objectiveFinding.anatomyPictureUrl);
		}
	}, []);

	const saveObjectiveFinding = async () => {
		setIsLoading(true);
		let isSuccess = false;

		const { emr, setEmr, patientObjectiveFinding, setNextStep, setFormName } =
			props;

		const data: ObjectiveFindingProperties = {
			...emr.objectiveFinding,
			emrId: emr.emrId,
			noteAnatomy: patientObjectiveFinding.noteAnatomy,
			noteLab: patientObjectiveFinding.noteLab,
			monitoringLabResult: monitorResults.map((monitor) => ({
				name: monitor.name,
				date: monitor.date,
				time: monitor.time,
				value: monitor.value,
				notes: monitor.notes,
			})),
		};

		try {
			if (!emr.emrId) {
				throw new Error('EMR ID is required.');
			}

			const res = await apiCreateObjectiveFinding(
				emr.emrId,
				'objective_findings',
				data,
			);
			if (res) {
				setEmr({
					...emr,
					...res,
				});
				isSuccess = true;

				if (
					imageFile &&
					emr.emrId &&
					imageFile !== emr.objectiveFinding?.labTestPictureUrl
				) {
					const resultLabPicture = await apiUpdateLabPicture(
						emr.emrId,
						imageFile,
					);
					setEmr({
						...emr,
						...resultLabPicture,
					});
				} else if (!imageFile && emr.emrId) {
					const resultLabPicture = await apiCreateObjectiveFinding(
						emr.emrId,
						'objective_findings',
						{
							labTestPictureUrl: '',
						},
					);
					setEmr({
						...emr,
						...resultLabPicture,
					});
				}

				if (finalImage && emr.emrId) {
					const anatomyFile = await fetchImageAsFile(finalImage);
					const resultAnatomy = await apiUpdateAnatomyPicture(
						emr.emrId,
						anatomyFile,
					);
					setEmr({
						...emr,
						...resultAnatomy,
					});
				} else if (!finalImage && emr.emrId) {
					const resultAnatomy = await apiCreateObjectiveFinding(
						emr.emrId,
						'objective_findings',
						{
							anatomyPictureUrl: '',
						},
					);
					setEmr({
						...emr,
						...resultAnatomy,
					});
				}
			}

			if (isSuccess) {
				setNextStep();
				setFormName('EMR');
				message.success('Objective Finding Saved Successfully');
			}
		} catch (error) {
			console.error('Error saving Objective Findings:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const addMonitorResult = () => {
		setMonitorResults([
			...monitorResults,
			{ name: '', date: '', time: '', value: '', notes: '' },
		]);
	};

	const handleMonitorResultChange = (
		index: number,
		field: string,
		value: string,
	) => {
		const updatedMonitorResults = [...monitorResults];
		updatedMonitorResults[index][field] = value;
		setMonitorResults(updatedMonitorResults);
	};

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			setImageFile(file);
			props.setPatientObjectiveFinding((old: any) => ({
				...old,
				labTestPictureUrl: file,
			}));
		}
	};

	const confirmDelete = (action: () => void, title: string) => {
		setModalAction(() => action);
		setModalTitle(title);
		setIsModalConfirmationVisible(true);
	};

	const handleDeleteMonitorResult = (index: number) => {
		confirmDelete(() => {
			const updatedMonitorResults = monitorResults.filter(
				(_, i) => i !== index,
			);
			setMonitorResults(updatedMonitorResults);
		}, 'Monitor Result');
	};

	const handleDeleteImage = () => {
		confirmDelete(() => {
			setImageFile(null);
			props.setPatientObjectiveFinding((old: any) => ({
				...old,
				labTestPictureUrl: null,
			}));
		}, 'Uploaded Image');
	};

	const handleDeleteFinalImage = () => {
		confirmDelete(() => {
			setFinalImage(null);
		}, 'Anatomy Image');
	};

	const deleteObjectiveFinding = async () => {
		setIsLoading(true);
		try {
			const emrId = props.emr.emrId || '';
			const resDelete = await apiDeleteObjectiveFindingByEMRId(emrId);
			if (resDelete.isSuccess) {
				props.setEmr({
					...props.emr,
					objectiveFinding: undefined,
					objectiveFindingId: undefined,
				});
				props.goBack();

				props.setPatientObjectiveFinding((old: any) => ({
					...old,
					noteAnatomy: undefined,
					noteLab: undefined,
				}));

				setMonitorResults([]);

				message.success('Objective Finding removed successfully');
			} else {
				throw new Error();
			}
		} catch (e) {
			message.error('Something went wrong');
		} finally {
			setIsLoading(false);
			setIsModalRemoveConfirmationVisible(false);
		}
	};

	const handleRemoveObjectiveFinding = () => {
		if (!props.emr.objectiveFinding) {
			props.setPatientObjectiveFinding((old: any) => ({
				...old,
				noteAnatomy: '',
				noteLab: '',
			}));

			setImageFile(null);

			props.setPatientObjectiveFinding((old: any) => ({
				...old,
				labTestPictureUrl: null,
			}));

			setFinalImage(null);

			setMonitorResults([]);

			setIsModalRemoveConfirmationVisible(false);
		} else {
			deleteObjectiveFinding();
		}
	};

	return (
		<>
			<form>
				<div className="py-6 px-3">
					<div>
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="font-semibold text-4">
									Physical Examination Findings
								</p>
							</div>

							<div className="flex-1">
								<p className="text-4">Anatomy Marking</p>
								{!finalImage ? (
									<div className="flex flex-col items-center">
										<EmptyList
											icon={
												<LabRequest
													width={48}
													height={48}
													color={theme.colors.black50}
												/>
											}
											title="Anatomy Picture empty"
											description="Add Anatomy picture for remarking"
										/>
										<div style={{ width: '100%' }}>
											<Button
												type="OUTLINE"
												className="w-full mt-4 flex items-center justify-center"
												onClick={() => setIsModalVisible(true)}
											>
												<Add
													width={17}
													height={17}
													color={theme.colors.black50}
													className="mr-2"
												/>
												Add Picture
											</Button>
										</div>
									</div>
								) : (
									<div className="relative">
										<div
											className="border border-solid rounded-4 p-4 mt-4 flex justify-center"
											style={{ borderColor: '#a5b2bd' }}
										>
											<img
												src={finalImage}
												alt="Anatomy Image"
												className="max-w-full h-auto"
											/>
											<div
												onClick={handleDeleteFinalImage}
												style={{
													borderRadius: '50%',
													backgroundColor: theme.colors.primary,
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													padding: 5,
													position: 'absolute',
													top: 0,
													right: 0,
													margin: 5,
													cursor: 'pointer',
												}}
											>
												<Trash2 size={20} color={theme.colors.white} />
											</div>
										</div>
										<div style={{ width: '100%' }}>
											<Button
												type="OUTLINE"
												className="w-full mt-4"
												onClick={() => setIsModalVisible(true)}
											>
												<Draw
													width={15}
													height={15}
													color={theme.colors.black50}
													className="mr-1"
												/>
												Draw
											</Button>
										</div>
									</div>
								)}

								<FormInput
									label="Notes"
									name="noteAnatomy"
									type={EFormType.MULTI_LINE}
									value={props.patientObjectiveFinding?.noteAnatomy}
									onChange={(val) => {
										props.setPatientObjectiveFinding((old: any) => ({
											...old,
											noteAnatomy: val,
										}));
									}}
								/>
							</div>
						</div>
						<Separator />
						<div className="flex gap-x-3 mt-4">
							<div className="w-1/4">
								<p className="font-semibold text-4">LAB</p>
							</div>
							<div className="flex-1">
								<p className="text-4">Picture</p>
								{!imageFile ? (
									<div className="flex flex-col items-center">
										<EmptyList
											icon={
												<LabRequest
													width={48}
													height={48}
													color={theme.colors.black50}
												/>
											}
											title="Picture empty"
											description="Your Picture will appear here"
										/>
										<div style={{ width: '100%' }}>
											<Button
												type="OUTLINE"
												className="w-full mt-4 flex items-center justify-center"
												onClick={() =>
													document.getElementById('labTestPictureUrl')?.click()
												}
											>
												<Upload
													width={17}
													height={17}
													color={theme.colors.black50}
													className="mr-1"
												/>
												Upload Image
											</Button>
										</div>

										<input
											type="file"
											id="labTestPictureUrl"
											accept="image/*"
											onChange={handleImageChange}
											className="hidden"
										/>
									</div>
								) : (
									<div className="mb-4">
										{imageFile && (
											<div
												className="relative border border-solid rounded-4 p-4 mt-4 flex justify-center items-center"
												style={{
													borderColor: '#a5b2bd',
													overflow: 'hidden',
												}}
											>
												<img
													src={
														typeof imageFile === 'string'
															? imageFile
															: URL.createObjectURL(imageFile)
													}
													alt="Uploaded Image"
													className="max-w-full max-h-full"
												/>
												<div
													onClick={() => handleDeleteImage()}
													style={{
														borderRadius: '50%',
														backgroundColor: theme.colors.primary,
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														padding: 5,
														position: 'absolute',
														top: 0,
														right: 0,
														margin: 5,
														cursor: 'pointer',
													}}
												>
													<Trash2 size={20} color={theme.colors.white} />
												</div>
											</div>
										)}

										<div style={{ width: '100%' }}>
											<Button
												type="OUTLINE"
												className="w-full mt-4 flex items-center justify-center"
												onClick={() =>
													document.getElementById('labTestPictureUrl')?.click()
												}
											>
												<Upload
													width={17}
													height={17}
													color={theme.colors.black50}
													className="mr-1"
												/>
												Upload Image
											</Button>
										</div>
										<input
											type="file"
											id="labTestPictureUrl"
											accept="image/*"
											onChange={handleImageChange}
											className="hidden"
										/>
									</div>
								)}

								<FormInput
									label="Notes"
									name="noteLab"
									type={EFormType.MULTI_LINE}
									value={props.patientObjectiveFinding?.noteLab}
									onChange={(val) => {
										props.setPatientObjectiveFinding((old: any) => ({
											...old,
											noteLab: val,
										}));
									}}
								/>
							</div>
						</div>
						<Separator />
						<div className="flex gap-x-3 mt-4">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">Monitor Lab Results</p>
								<p
									className="m-0 mt-1 text-3.5 font-medium text-charcoal-300 cursor-pointer hover:text-white"
									onClick={() => props.setFormName('MonitoringLabResults')}
								>
									View Trend
								</p>
							</div>
							<div className="flex-1">
								{monitorResults.map((monitor, index) => (
									<div key={index} className="relative">
										<div className="absolute top-0 right-0 cursor-pointer">
											<Trash2
												size={20}
												color={theme.colors.primary}
												onClick={() => handleDeleteMonitorResult(index)} // Delete the monitor result
											/>
										</div>

										<FormInput
											label="Name"
											name={`monitorName-${index}`}
											type={EFormType.SINGLE_LINE}
											value={monitor.name}
											required
											onChange={(val) =>
												handleMonitorResultChange(index, 'name', val)
											}
										/>

										<div className="flex gap-x-3">
											<div className="flex-1 w-1/3">
												<FormInput
													label="Date"
													name={`monitorDate-${index}`}
													type={EFormType.DATE_PICKER}
													value={monitor.date}
													required
													onChange={(val) =>
														handleMonitorResultChange(index, 'date', val)
													}
												/>
											</div>
											<div className="flex-1 w-1/3">
												<FormInput
													label="Time"
													name={`monitorTime-${index}`}
													type={EFormType.TIME_PICKER}
													value={monitor.time}
													required
													onChange={(val) =>
														handleMonitorResultChange(index, 'time', val)
													}
												/>
											</div>
										</div>
										<FormInput
											label="Value"
											name={`monitorValue-${index}`}
											type={EFormType.SINGLE_LINE}
											value={monitor.value}
											required
											onChange={(val) =>
												handleMonitorResultChange(index, 'value', val)
											}
										/>
										<FormInput
											label="Notes"
											name={`monitorNotes-${index}`}
											type={EFormType.MULTI_LINE}
											value={monitor.notes}
											onChange={(val) =>
												handleMonitorResultChange(index, 'notes', val)
											}
										/>

										{index < monitorResults.length - 1 && (
											<Separator className="mt-4 mb-4" />
										)}
									</div>
								))}

								<div>
									<Button type="OUTLINE" onClick={addMonitorResult}>
										<PlusCircle
											width={17}
											height={17}
											color={theme.colors.primary}
											className="mr-1"
										/>
										Add Monitor Result
									</Button>
								</div>
							</div>
						</div>
					</div>
					<div className="h-6" />
				</div>
				<Separator />
				<div className="flex justify-between w-full py-3 px-4">
					<div className="ml-0 w-44">
						<Button
							type="SOLIDASH"
							onClick={() => {
								setIsModalRemoveConfirmationVisible(true);
							}}
							isDisable={isLoading || !props.emr.objectiveFinding}
						>
							Remove
						</Button>
					</div>
					<div className="mr-0 w-44">
						<Button
							type="SOLID"
							onClick={saveObjectiveFinding}
							isLoading={isLoading}
							isDisable={!isFormValid || isLoading}
						>
							Save
						</Button>
					</div>
				</div>
				<div className="py-3 px-4">
					<div className="w-44 ml-auto"></div>
				</div>
			</form>

			<ImageModal
				visible={isModalVisible}
				onClose={() => setIsModalVisible(false)}
				uploadedImages={uploadedImages}
				setUploadedImages={setUploadedImages}
				setFinalImage={setFinalImage}
				finalImage={finalImage}
				doctorId={isDoctor ? userId || '' : ''}
			/>

			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={() => {
					modalAction();
					setIsModalConfirmationVisible(false);
				}}
				title={modalTitle}
				type={EModalConfirmationType.DELETE}
			/>

			<ModalConfirmation
				isVisible={isModalRemoveConfirmationVisible}
				setVisibility={setIsModalRemoveConfirmationVisible}
				actionHandler={handleRemoveObjectiveFinding}
				type={EModalConfirmationType.CUSTOM}
				title="Remove This Data"
				message="The data you have entered will be removed"
				buttonConfirm="Remove"
			/>
		</>
	);
}
