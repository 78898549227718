import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CheckCircle, ChevronRight } from 'react-feather';
import { theme } from '../../../../assets/theme';
import { getInitialName, getName } from '../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../helpers/profile';
import { EConsultationType } from '../../../../hooks/useBookConsultation';
import {
	ETransactionStatusType,
	TransactionConsultationProperties,
} from '../../../../types/transactionConsultation.type';
import Separator from '../../../Separator';

export enum EConsultSidebarContent {
	MEDICAL_ID = 'MEDICAL_ID',
	HISTORY_PHYSICAL_FINDINGS = 'HISTORY_PHYSICAL_FINDINGS',
	ASSESSMENT_DIAGNOSIS = 'ASSESSMENT_DIAGNOSIS',
	PLAN_FOR_MANAGEMENT = 'PLAN_FOR_MANAGEMENT',
	PRESCRIPTION = 'PRESCRIPTION',
	LAB_REQUEST = 'LAB_REQUEST',
	MEDICAL_CERTIFICATE = 'MEDICAL_CERTIFICATE',
	CLEARANCE = 'CLEARANCE',
	FIT_TO_WORK = 'FIT_TO_WORK',
	OWN_TEMPLATE = 'OWN_TEMPLATE',
}

interface Props {
	transactionConsultation?: TransactionConsultationProperties;
	isExistHistoryPhysicalFindings: boolean;
	isExistAssesmentDiagnosis: boolean;
	isExistPlanForManagement: boolean;
	isExistRxPrescription: boolean;
	isExistLabRequest: boolean;
	isExistMedicalCertificate: boolean;
	isExistClearance: boolean;
	isExistFitToWork: boolean;
	isExistOwnTemplate: boolean;
	onClickMenu: (menu: EConsultSidebarContent) => void;
	setIsModalEndConsultVisible: any;
}

export default function SidebarConsultationDefault(props: Props) {
	return (
		<div className="w-full">
			<p className="m-0 text-black-60 text-4">Patient Information</p>
			<div className="mt-4 flex gap-x-2">
				<div className="w-15 h-15 rounded-full bg-charcoal-300 flex items-center justify-center">
					<span className="font-bold text-white">
						{getInitialName(props.transactionConsultation?.metaPatient)}
					</span>
				</div>
				<div className="flex-1 flex flex-col justify-center">
					<p className="m-0 font-medium">
						{getName(props.transactionConsultation?.metaPatient)}
					</p>
					<p className="m-0 text-charcoal-200 text-3.5">
						{getGender(props.transactionConsultation?.metaPatient)} •{' '}
						{moment().diff(
							getBirthdate(props.transactionConsultation?.metaPatient),
							'years',
							false,
						)}{' '}
						yo
					</p>
				</div>
			</div>
			<div className="mt-4.5">
				<p className="m-0 text-black-60 text-4">Reason for consultation</p>
				<p
					className={
						'm-0 text-4' +
						(props.transactionConsultation?.reasonOfConsultation
							? ''
							: ' text-black-80')
					}
				>
					{props.transactionConsultation?.reasonOfConsultation || '-'}
				</p>
			</div>
			<Separator className="mt-4" />
			<ConsultationItem
				onClick={() => props.onClickMenu(EConsultSidebarContent.MEDICAL_ID)}
			>
				Medical ID
			</ConsultationItem>
			<Separator style={{ margin: 0, height: 6 }} />
			<div className="mt-4">
				<p className="m-0 text-black-60 text-4">Medical Notes</p>
				<p className="text-4 text-charcoal-300 text-4">
					These notes will not be shared with patients and only doctors can see
					these notes
				</p>
				<ConsultationItem
					isExist={props.isExistHistoryPhysicalFindings}
					onClick={() =>
						props.onClickMenu(EConsultSidebarContent.HISTORY_PHYSICAL_FINDINGS)
					}
				>
					History/Physical Findings
				</ConsultationItem>
				<Separator style={{ margin: 0 }} />
				<ConsultationItem
					isExist={props.isExistAssesmentDiagnosis}
					onClick={() =>
						props.onClickMenu(EConsultSidebarContent.ASSESSMENT_DIAGNOSIS)
					}
				>
					Assessment/Diagnosis
				</ConsultationItem>
				<Separator style={{ margin: 0 }} />
				<ConsultationItem
					isExist={props.isExistPlanForManagement}
					onClick={() =>
						props.onClickMenu(EConsultSidebarContent.PLAN_FOR_MANAGEMENT)
					}
				>
					Plan for Management
				</ConsultationItem>
			</div>
			<Separator style={{ margin: 0, height: 6 }} />
			<div className="mt-4">
				<p className="m-0 text-black-60">Prescription & Lab Request</p>
				<ConsultationItem
					isExist={props.isExistRxPrescription}
					onClick={() => props.onClickMenu(EConsultSidebarContent.PRESCRIPTION)}
				>
					Prescription
				</ConsultationItem>
				<Separator style={{ margin: 0 }} />
				<ConsultationItem
					isExist={props.isExistLabRequest}
					onClick={() => props.onClickMenu(EConsultSidebarContent.LAB_REQUEST)}
				>
					Lab Request
				</ConsultationItem>
			</div>
			<Separator style={{ margin: 0, height: 6 }} />
			<div className="mt-4">
				<p className="m-0 text-black-60 text-4">Doctor Documents</p>
				<ConsultationItem
					isExist={props.isExistMedicalCertificate}
					onClick={() =>
						props.onClickMenu(EConsultSidebarContent.MEDICAL_CERTIFICATE)
					}
				>
					Medical Certificate
				</ConsultationItem>
				<Separator style={{ margin: 0 }} />
				<ConsultationItem
					isExist={props.isExistClearance}
					onClick={() => props.onClickMenu(EConsultSidebarContent.CLEARANCE)}
				>
					Clearance
				</ConsultationItem>
				<Separator style={{ margin: 0 }} />
				<ConsultationItem
					isExist={props.isExistFitToWork}
					onClick={() => props.onClickMenu(EConsultSidebarContent.FIT_TO_WORK)}
				>
					Fit to Work
				</ConsultationItem>
				<Separator style={{ margin: 0 }} />
				<ConsultationItem
					isExist={props.isExistOwnTemplate}
					onClick={() => props.onClickMenu(EConsultSidebarContent.OWN_TEMPLATE)}
				>
					<div>
						<p className="m-0">Own Template</p>
						<p className="m-0 text-4 text-charcoal-300">
							Use this document if you want to create custom doctor documents
						</p>
					</div>
				</ConsultationItem>
			</div>
			<Separator style={{ height: 6 }} />
			<div className="mt-4">
				<p className="m-0 text-black-60 text-4">Document Preview</p>
				<p className="text-4 text-charcoal-300">
					See a preview of the document you created by clicking the button
					below.
				</p>
				<button
					style={{ cursor: 'pointer' }}
					className="text-4 w-full p-2.5 border border-solid border-primary rounded-4 bg-white font-semibold text-primary"
				>
					See Document preview
				</button>
				<Separator className="my-3" />
				<div className="flex items-center gap-x-2">
					<InfoCircleOutlined style={{ color: theme.colors.primary }} />
					<p className="m-0 text-4 text-charcoal-300">
						Before saving data, make sure all information is filled in
						correctly.
					</p>
				</div>
				<button
					style={{ cursor: 'pointer' }}
					className="text-4 w-full p-2.5 border-none rounded-4 bg-primary font-semibold text-white"
				>
					Save
				</button>
				{props.transactionConsultation?.transactionStatus !==
					ETransactionStatusType.COMPLETED &&
					props.transactionConsultation?.consultationType ===
						EConsultationType.CONSULT_NOW_CHAT_CONSULTATION && (
						<button
							style={{ cursor: 'pointer' }}
							onClick={() => props.setIsModalEndConsultVisible(true)}
							className="text-4 mt-2 w-full p-2.5 border-none rounded-4 bg-primary font-semibold text-white"
						>
							End Consultation
						</button>
					)}
				{props.transactionConsultation?.transactionStatus ===
					ETransactionStatusType.COMPLETED ||
				props.transactionConsultation?.consultationType ===
					EConsultationType.FACE_TO_FACE_CONSULTATION ? (
					<button
						style={{ cursor: 'pointer' }}
						className="text-4 mt-2 w-full p-2.5 border-none rounded-4 bg-primary font-semibold text-white"
					>
						Send to Patient
					</button>
				) : null}
			</div>
		</div>
	);
}

function ConsultationItem(props: {
	children: any;
	onClick: any;
	isExist?: boolean;
}) {
	return (
		<button
			onClick={props.onClick}
			className="w-full flex items-center gap-x-3 py-4.5 bg-white border-none cursor-pointer"
		>
			<div className="flex-1 text-left">{props.children}</div>
			{props.isExist && <CheckCircle color={theme.colors.green} />}
			<ChevronRight color={theme.colors.black20} />
		</button>
	);
}
