import { message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
	apiCreateDoctorDocument,
	apiGetDoctorDocument,
	apiUpdateDoctorDocument,
} from '../../../../../api/doctorDocument';
import { getName } from '../../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../../helpers/profile';
import {
	DoctorDocumentProperties,
	EMetaType,
	MetaClearence,
	MetaFitToWork,
	MetaMedicalCertificate,
	MetaOthers,
} from '../../../../../types/doctorDocument.type';
import { IUser } from '../../../../../types/user.type';
import { Button } from '../../../../Button/Button/Button';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import ModalInfoPatient from '../../../RxAndLabRequest/CreateRx/Components/Modal/ModalInfoPatient';
import { useAuth } from '../../../../../context/auth.context';
import { ICDProperties } from '../../../../../types/icd.type';
import ModalConfirmation, { EModalConfirmationType } from './ModalConfirmation';
import { EmrProperties } from '../../../../../types/emr.type';
import { capitalizeWords } from '../../../../../helpers/text';
import { theme } from '../../../../../assets/theme';
import { calculateAge } from '../../../../../helpers/datetime';
import DEFAULT_USER from '../../../../../assets/default-user2.png';
import { httpRequest } from '../../../../../helpers/api';
import { ApiResponseDefault } from '../../../../../types/apiResponse.type';
import Spinner from '../../../../Spinner';

const forms = [
	{
		formTitle: 'Clearance',
		type: 'DoctorDocument',
		forms: [
			{
				label: 'Valid Until',
				name: 'validUntil',
				type: EFormType.DATE_PICKER,
				prefix: '',
				isRequired: true,
			},

			{
				label: 'Clearance Title',
				name: 'title',
				maxLength: 50,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Comorbid Conditions',
				name: 'comorbidConditions',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Procedure',
				name: 'procedures',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Symptoms',
				name: 'symptoms',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Functional Capacity',
				name: 'functionalCapacity',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Chest Xray',
				name: 'chestXRay',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: '12-L ECG',
				name: 'lecg12',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Blood Tests',
				name: 'bloodTest',
				maxLength: 50,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Other Tests',
				name: 'otherTest',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Patient Risk Stratification',
				name: 'patientRiskStratification',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Recommendations',
				name: 'recommendations',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
		],
	},
];

interface Props {
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	type?: EMetaType;
	step: number;
	setNextStep: () => void;
	setFormName: () => void;
}

export default function FormCreateClearance(props: Props) {
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(false);

	const [patientProfile, setPatientProfile] = useState<{
		[key: string]: any;
	}>();
	const [doctorDocument, setDoctorDocument] = useState<{
		[key: string]: any;
	}>();
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);
	const [isModalInfoPatientVisible, setModalInfoPatientVisible] =
		useState(false);
	const [foundPatient, setFoundPatient] = useState<any>();
	const [foundRelatives, setFoundRelatives] = useState<any[]>([]);
	const [searchPhone, setSearchPhone] = useState<string | undefined>('');
	const [isPhoneDisable, setIsPhoneDisable] = useState(false);
	const [showModalAddIcd, setShowModalAddIcd] = useState(false);
	const [icds, setIcds] = useState<ICDProperties[]>();
	const [isFormValid, setIsFormValid] = useState(false);
	const todayAfter3Month = new Date();
	todayAfter3Month.setMonth(new Date().getMonth() + 3);

	const validateForm = () => {
		// const hasOtherValues =
		// 	!!doctorDocument?.meta?.title ||
		// 	!!doctorDocument?.meta?.comorbidConditions ||
		// 	!!doctorDocument?.meta?.procedures ||
		// 	!!doctorDocument?.meta?.symptoms ||
		// 	!!doctorDocument?.meta?.functionalCapacity ||
		// 	!!doctorDocument?.meta?.chestXRay ||
		// 	!!doctorDocument?.meta?.lecg12 ||
		// 	!!doctorDocument?.meta?.bloodTest ||
		// 	!!doctorDocument?.meta?.otherTest ||
		// 	!!doctorDocument?.meta?.patientRiskStratification ||
		// 	!!doctorDocument?.meta?.recommendations;

		// const isValid = !!doctorDocument?.meta?.validUntil && hasOtherValues;
		const isValid = !!doctorDocument?.meta?.validUntil;

		setIsFormValid(isValid);
	};

	useEffect(() => {
		validateForm();
	}, [doctorDocument]);

	const defaultDoctorDocument = {
		validUntil: todayAfter3Month.toString(),
		title: '',
		comorbidConditions: '',
		procedures: '',
		symptoms: '',
		functionalCapacity: '',
		chestXRay: '',
		lecg12: '',
		bloodTest: '',
		otherTest: '',
		patientRiskStratification: '',
		recommendations: '',
	};

	const clearFormHandler = () => {
		setDoctorDocument((old) => ({
			...old,
			meta: defaultDoctorDocument,
		}));
		setIsModalConfirmationVisible(false);
	};

	useEffect(() => {
		setShowModalAddIcd(false);
	}, [icds]);

	useEffect(() => {
		(async () => {
			const clearance = props.emr.doctorDocuments?.find(
				(doc) => doc.type === EMetaType.CLEARANCE,
			);

			if (!clearance) {
				setDoctorDocument((old) => ({
					...old,
					meta: {
						...old?.meta,
						validUntil: todayAfter3Month.toString(),
					},
				}));
				return;
			}

			setIsLoading(true);

			const res = await apiGetDoctorDocument(clearance.doctorDocumentId || '');
			if (res) {
				const metaPatient = res.metaPatient as IUser;
				setPatientProfile({
					patientName: getName(metaPatient),
					gender: getGender(metaPatient)?.toLowerCase(),
					age: moment().diff(getBirthdate(metaPatient), 'years', false),
					phone: metaPatient?.phone,
				});
				setSearchPhone(metaPatient?.phone);
				setDoctorDocument(res);
				setIcds(res.icds);
			}

			setIsLoading(false);
		})();

		if (!props.emr.patient && !props.emr.patientInRelation) {
			const fetchEmr = async () => {
				try {
					setIsLoading(true);
					const response = await httpRequest.get<
						ApiResponseDefault<EmrProperties>
					>(`/emr/${props.emr.emrId}`);
					props.setEmr(response.data.payload);
				} catch (error) {
					console.error('Error fetching similar patients:', error);
				} finally {
					setIsLoading(false);
				}
			};

			fetchEmr();
		}
	}, [props.emr]);

	function onNext() {
		if (
			props.type === EMetaType.OTHERS &&
			(!doctorDocument?.title || !doctorDocument?.content)
		) {
			message.warning('Warning: Doctor document data is not yet complete!');
			return false;
		}

		saveDoctorDocument();
		return;
	}

	async function saveDoctorDocument() {
		setIsLoading(true);
		const { emr, setEmr } = props;

		let isSuccess = false;
		let data: DoctorDocumentProperties = {
			transactionConsultationId: doctorDocument?.transactionConsultationId,
			doctorId: user?.userId,
			type: EMetaType.CLEARANCE,
			patientId: emr.patientId,
			validUntil: doctorDocument?.validUntil ?? new Date().toISOString(),
			meta: doctorDocument?.meta,
			isTemporary: true,
			emrId: emr.emrId,
			metaPatient: emr.patient || emr.patientInRelation || {},
		};

		if (doctorDocument?.doctorDocumentId) {
			data = {
				...data,
				meta: doctorDocument?.meta as
					| MetaMedicalCertificate
					| MetaClearence
					| MetaFitToWork
					| MetaOthers,
				emrId: emr.emrId,
			};

			const res = await apiUpdateDoctorDocument(
				doctorDocument.doctorDocumentId,
				data,
			);
			if (res) {
				setEmr({
					...emr,
					doctorDocuments: (emr.doctorDocuments || []).map((doc) =>
						doc.doctorDocumentId === res.doctorDocumentId ? res : doc,
					),
				});
				setDoctorDocument(res);
				isSuccess = true;
			}
		} else {
			data = {
				...data,
				transactionConsultationId: doctorDocument?.transactionConsultationId,
				doctorId: user?.userId,
				type: EMetaType.CLEARANCE,
				meta: doctorDocument?.meta as
					| MetaMedicalCertificate
					| MetaClearence
					| MetaFitToWork
					| MetaOthers,
				emrId: emr.emrId,
			};

			const res = await apiCreateDoctorDocument(data);
			if (res) {
				setEmr({
					...emr,
					doctorDocuments: [...(emr.doctorDocuments || []), res],
				});
				setDoctorDocument(res);
				isSuccess = true;
			}
		}

		if (isSuccess) {
			props.setNextStep();
			props.setFormName();
			message.success('Clearance Saved Sucessfully');
		}

		setIsLoading(false);
	}

	return (
		<form>
			<div className="py-6 px-3">
				{forms.map((formsData, index) => (
					<div key={index}>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">
									{formsData.formTitle}
								</p>
							</div>
							<div className="flex-1">
								{formsData.forms.map((form) => {
									if (!!form?.showWhenType && props.type) {
										if (!form?.showWhenType.includes(props.type)) {
											return <></>;
										}
									}

									return (
										<>
											<FormInput
												key={form.label}
												label={form.label}
												type={form.type}
												required={form.isRequired}
												value={
													formsData.type === 'patientProfile'
														? form.name === 'address'
															? doctorDocument?.meta?.[form.name]
															: patientProfile?.[form.name]
														: doctorDocument?.meta?.[form.name]
												}
												prefix={form?.prefix}
												onChange={(val) => {
													formsData.type === 'patientProfile'
														? form.name === 'address'
															? setDoctorDocument((old) => ({
																	...old,
																	meta: {
																		...old?.meta,
																		[form.name]: val,
																	},
															  }))
															: setPatientProfile((old) => ({
																	...old,
																	[form.name]: val,
															  }))
														: setDoctorDocument((old) => ({
																...old,
																meta: {
																	...old?.meta,
																	[form.name]: val,
																},
														  }));
													form.name === 'phone' && setSearchPhone(val);
												}}
												disabledDate={(d: any) => !d || d.isBefore(new Date())}
											/>
											{form.name === 'validUntil' &&
												(isLoading ? (
													<div className="py-4 mx-auto w-fit">
														<Spinner size={25} color={theme.colors.primary} />
													</div>
												) : (
													<div className="flex items-center py-2">
														<img
															src={
																props.emr.patient?.profilePic ||
																props.emr.patientInRelation
																	?.profilePictureUrl ||
																DEFAULT_USER
															}
															alt="Profile"
															className="rounded-full mr-3"
															style={{ width: 50, height: 50 }}
														/>
														<div className="flex flex-col">
															<p className="m-0 mb-0 font-medium text-4.5">
																{props.emr.patient?.name ||
																	getName(props.emr.patientInRelation) ||
																	'-'}
															</p>
															<div className="flex gap-x-2 items-center">
																<p className="m-0 text-3.5 text-charcoal-300">
																	{capitalizeWords(
																		props.emr.patient?.gender ||
																			props.emr.patientInRelation?.gender ||
																			'-',
																	)}
																</p>
																<div
																	style={{
																		width: 5,
																		height: 5,
																		borderRadius: 5,
																		backgroundColor: `${theme.colors.charcoal300}`,
																	}}
																/>
																<p className="m-0 text-3.5 text-charcoal-300">
																	{props.emr.patient?.birthdate ||
																	props.emr.patientInRelation?.birthdate
																		? calculateAge(
																				props.emr.patient?.birthdate ||
																					props.emr.patientInRelation
																						?.birthdate,
																		  ) + ' yo'
																		: '-'}
																</p>
															</div>
														</div>
													</div>
												))}
										</>
									);
								})}
							</div>
						</div>
						{index < forms.length - 1 && (
							<div className="my-1">
								<Separator />
							</div>
						)}
					</div>
				))}
				<div className="h-6" />
			</div>
			<Separator />
			<div className="py-3 px-4">
				<div className="flex justify-between w-full">
					<div className="w-44">
						<Button
							type="SOLIDASH"
							onClick={() => setIsModalConfirmationVisible(true)}
						>
							Clear Form
						</Button>
					</div>
					<div className="w-44">
						<Button
							type="SOLID"
							onClick={onNext}
							isLoading={isLoading}
							isDisable={!isFormValid || isLoading}
						>
							Save
						</Button>
					</div>
				</div>
			</div>
			<ModalInfoPatient
				isVisible={isModalInfoPatientVisible}
				setVisibility={setModalInfoPatientVisible}
				dataPatient={foundPatient}
				dataRelatives={foundRelatives}
				actionHandler={(data) => {
					setPatientProfile({ ...patientProfile, ...data });
					setIsPhoneDisable(true);
					setModalInfoPatientVisible(false);
				}}
			/>

			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={clearFormHandler}
				type={EModalConfirmationType.CLEAR_FORM}
			/>
		</form>
	);
}
