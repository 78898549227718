import { useEffect, useState } from 'react';
import { Button } from '../../../../Button/Button/Button';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import { Modal } from 'antd';

interface Props {
	isShow: boolean;
	onCloseModal: () => void;
	onSave: (data: {
		vaccineName: string;
		date: string;
		nextDose: string;
		adverseReaction: string;
	}) => void;
}

export default function ModalFormAddVaccine(props: Props) {
	const [vaccineName, setVaccineName] = useState('');
	const [date, setDate] = useState('');
	const [nextDose, setNextDose] = useState('');
	const [adverseReaction, setAdverseReaction] = useState('');
	const [error, setError] = useState<string | null>(null);

	const handleSave = () => {
		if (!vaccineName || !date || !nextDose || !adverseReaction) {
			Modal.error({ title: 'Please fill in all fields!' });
			return;
		}

		if (new Date(nextDose) <= new Date(date)) {
			Modal.error({ title: 'Next Dose must be after Data Vaccines' });
			return;
		} else {
			setError(null);
		}

		props.onSave({
			vaccineName,
			date,
			nextDose,
			adverseReaction,
		});

		props.onCloseModal();

		setVaccineName('');
		setDate('');
		setNextDose('');
		setAdverseReaction('');
	};

	return (
		<Modal
			open={props.isShow}
			onCancel={props.onCloseModal}
			title={<p className="m-0 text-center font-semibold">Add Vaccine</p>}
			bodyStyle={{ padding: 0 }}
			wrapClassName="modal-h-full"
			style={{ height: 700 }}
			footer={null}
		>
			<div className="h-full flex flex-col">
				<div className="px-4 py-1">
					<FormInput
						label="Vaccine Name"
						placeholder="Input vaccine name"
						type={EFormType.SINGLE_LINE}
						value={vaccineName}
						onChange={(value) => setVaccineName(value)}
					/>
					<div className="flex gap-x-5 justify-between">
						<div className="flex-1">
							<FormInput
								label="Date"
								type={EFormType.DATE_PICKER}
								value={date}
								onChange={(value) => setDate(value)}
							/>
						</div>
						<div className="flex-1">
							<FormInput
								label="Next Dose"
								type={EFormType.DATE_PICKER}
								value={nextDose}
								onChange={(value) => setNextDose(value)}
							/>
						</div>
					</div>
					<FormInput
						label="Adverse Reaction"
						type={EFormType.MULTI_LINE}
						value={adverseReaction}
						onChange={(value) => setAdverseReaction(value)}
					/>
				</div>
				<Separator />

				<div className="px-4 py-2 flex justify-end">
					<Button onClick={props.onCloseModal} className="mr-2" type="OUTLINE">
						Cancel
					</Button>
					<Button onClick={handleSave} type="OUTLINE">
						Add
					</Button>
				</div>
			</div>
		</Modal>
	);
}
