import { EmrProperties } from '../../../../../types/emr.type';
import EmptyList from '../../../../EmptyList';
import Separator from '../../../../Separator';
import { Info } from 'react-feather';
import { theme } from '../../../../../assets/theme';
import Spinner from '../../../../Spinner';
interface Props {
	patient: EmrProperties;
	isLoading: boolean;
}

export default function HPIDetail({ patient, isLoading }: Props) {
	return (
		<div className="flex-1 flex flex-col">
			<div className="py-6 px-3 flex-1">
				{isLoading ? (
					<div className="py-4 mx-auto w-fit">
						<Spinner size={25} color={theme.colors.primary} />
					</div>
				) : !patient?.hpi ? (
					<EmptyList
						icon={<Info color={theme.colors.black50} />}
						title="Data Empty"
						description="No history of present illness data available for this patient."
					/>
				) : (
					<>
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">Chief Complaint</p>
							</div>
							<div className="flex-1 flex gap-x-3">
								<div className="flex-1">
									<p className="m-0 text-3.5 font-medium text-charcoal-300">
										Notes
									</p>
									<p className="m-0 text-4">{patient.hpi.chiefComplaint}</p>
								</div>
							</div>
						</div>
						<div className="py-5">
							<Separator />
						</div>
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">
									History Of Present Illness
								</p>
							</div>
							<div className="flex-1 flex gap-x-3">
								<div className="flex-1">
									<p className="m-0 text-3.5 font-medium text-charcoal-300">
										Notes
									</p>
									<p className="m-0 mt-2">
										{patient.hpi.historyOfPresentIllness}
									</p>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
