import { ChevronRight, Info } from 'react-feather';
import { useEffect, useState } from 'react';
import { theme } from '../../../../../assets/theme';
import EmptyList from '../../../../EmptyList';
import Separator from '../../../../Separator';
import Spinner from '../../../../Spinner';
import { EmrProperties } from '../../../../../types/emr.type';
import { formatNumber } from '../../../../../utils/commons';

interface Props {
	setNextStep: () => void;
	similarPatients: EmrProperties[];
	isLoading: boolean;
	onItemClick: (emrId: string) => void;
}

export default function ListBilling(props: Props) {
	const { isLoading, similarPatients, setNextStep, onItemClick } = props;
	const [search, setSearch] = useState('');
	const [debouncedSearch, setDebouncedSearch] = useState(search);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedSearch(search);
		}, 500);
		return () => {
			clearTimeout(handler);
		};
	}, [search]);

	function onClickItem(item: EmrProperties) {
		setNextStep();
		onItemClick(item.emrId || '');
	}

	const calculateTotalPrice = (transactionEMRs: any[]) => {
		return transactionEMRs.reduce((total, transaction) => {
			const transactionTotal = transaction.transactionEMRItems?.reduce(
				(subTotal: any, item: any) => subTotal + (item.price || 0),
				0,
			);
			return total + transactionTotal;
		}, 0);
	};

	// const filteredPatients = patients.filter(
	// 	(patient) =>
	// 		patient.transactionEMRs &&
	// 		patient?.patient?.name
	// 			?.toLowerCase()
	// 			.includes(debouncedSearch.toLowerCase()),
	// );

	const formatDate = (dateString: string) => {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		};
		return new Date(dateString).toLocaleDateString('en-US', options);
	};

	return (
		<div className="flex-1 flex flex-col">
			<div className="flex-1 min-h-0">
				<div className="px-0">
					{isLoading ? (
						<div className="py-4 mx-auto w-fit">
							<Spinner size={25} color={theme.colors.primary} />
						</div>
					) : similarPatients.length > 0 ? (
						similarPatients.every(
							(patient) => patient.transactionEMRs?.length === 0,
						) ? (
							<EmptyList
								icon={<Info color={theme.colors.black50} />}
								title="Data Empty"
								description="You have not added this data yet"
							/>
						) : (
							similarPatients.map((patient, index) => {
								if (patient.transactionEMRs) {
									const total = calculateTotalPrice(
										patient.transactionEMRs || [],
									);
									return (
										<div
											className={`${
												index !== similarPatients.length - 1 ? 'mb-5' : ''
											}`}
											key={index}
										>
											<button
												onClick={() => onClickItem(patient)}
												className="bg-transparent border-none text-left w-full cursor-pointer flex justify-between"
											>
												<div className="flex-1 flex flex-col">
													<p className="m-0 text-3.5 font-semibold mb-4">
														{formatDate(patient.createdAt?.toString() ?? '')}
													</p>
													<div className="flex">
														<div className="flex-1">
															<p className="m-0 text-3.5 font-medium text-charcoal-300">
																Total
															</p>
															<p className="m-0">
																{total > 0 ? formatNumber(total, true) : '-'}
															</p>
														</div>

														<div className="flex-1">
															<p className="m-0 text-3.5 font-medium text-charcoal-300">
																Settled/Unsettled
															</p>
															<p className="m-0">
																{patient.isSettled === true
																	? 'Settled'
																	: patient.isSettled === false
																	? 'Unsettled'
																	: '-'}
															</p>
														</div>
													</div>
												</div>
												<ChevronRight
													width={20}
													height={20}
													color={theme.colors.black30}
												/>
											</button>
											<Separator style={{ height: '2px' }} />
										</div>
									);
								}
							})
						)
					) : (
						<EmptyList
							icon={<Info color={theme.colors.black50} />}
							title="Data Empty"
							description="You have not added this data yet"
						/>
					)}
				</div>
			</div>
		</div>
	);
}
