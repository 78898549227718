import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Radio, Select, message } from 'antd';
import FormInput, { EFormType } from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import { Button } from '../../../Button/Button/Button';
import { Trash2, ChevronRight } from 'react-feather';
import IconButton from '../../../Button/IconButton';
import { theme } from '../../../../assets/theme';
import { TransactionEMRProperties } from '../../../../types/transactionEmr.type';
import {
	apiGetEmr,
	apiGetTemplateBillingEmr,
	apiTemplateBillingEmr,
	apiTransactionEmr,
	apiUpdateTransactionEmr,
} from '../../../../api/emr';
import { useAuth } from '../../../../context/auth.context';
import { ETransactionStatusType } from '../../../../types/transactionConsultation.type';
import { TemplateEMRBillingProperties } from '../../../../types/templateEmrBilling.type';
import { Checkbox } from 'antd';
import { formatNumber } from '../../../../utils/commons';
import { EmrProperties } from '../../../../types/emr.type';
import ModalConfirmation, {
	EModalConfirmationType,
} from './Components/ModalConfirmation';
import ModalSaveDraft from './Components/ModalSaveDraft';
import styled from 'styled-components';
import COLORS from '../../../../assets/globalColors';

interface Props {
	step: number;
	emr: EmrProperties;
	billing?: TransactionEMRProperties;
	setBilling: React.Dispatch<
		React.SetStateAction<TransactionEMRProperties | undefined>
	>;
	setNextStep: () => void;
	isEditing: boolean;
	patientBilling: any;
	setPatientBilling: React.Dispatch<React.SetStateAction<any>>;
}

export default function FormCreateBilling(props: Props) {
	const { patientId, emrId } = props.emr;
	const { user } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [isTemplateChecked, setIsTemplateChecked] = useState(false);
	const [selectedTemplateOption, setSelectedTemplateOption] =
		useState('Create New');
	const [services, setServices] = useState([
		{ serviceName: '', price: '', isNoCharge: false },
	]);
	const [transactionEmrId, setTransactionEmrId] = useState<string | undefined>(
		undefined,
	);
	const history = useHistory();
	const [isModalVisible, setModalVisible] = useState(false);
	const [isModalSaveDraftVisible, setIsModalSaveDraftVisible] = useState(false);
	const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
	const [serviceIndexToRemove, setServiceIndexToRemove] = useState<
		number | null
	>(null);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	const [isPaid, setIsPaid] = useState(false);
	const [isProceedPayment, setIsProceedPayment] = useState(false);
	const [templateBillings, setTemplateBillings] = useState<
		TemplateEMRBillingProperties[]
	>([]);
	const [templateOptions, setTemplateOptions] = useState<any[]>([]);

	const handleConfirmChange = () => {
		props.setPatientBilling((old: any) => ({
			...old,
			paymentMethod: selectedPaymentMethod,
		}));
		setSelectedPaymentMethod(null);
		setModalVisible(false);
	};

	const addService = () => {
		if (isPaid) return;
		setServices((prev) => [
			...prev,
			{
				serviceName: '',
				price: '',
				isNoCharge: props.patientBilling?.paymentMethod === 'NO_CHARGE',
			},
		]);
	};

	const handleServiceChange = (index: number, field: string, value: string) => {
		setServices((prev) => {
			const newServices = [...prev];
			newServices[index] = { ...newServices[index], [field]: value };
			return newServices;
		});
	};

	const handleChargeChange = (index: number, value: string) => {
		const newIsNoCharge = value === 'noCharge';
		setServices((prev) => {
			const newServices = [...prev];
			newServices[index].isNoCharge = newIsNoCharge;
			newServices[index].price = newIsNoCharge ? '0' : '';
			return newServices;
		});
	};

	const calculateTotal = () => {
		return services.reduce((total, service) => {
			const price = parseFloat(service.price) || 0;
			return total + (service.isNoCharge ? 0 : price);
		}, 0);
	};

	useEffect(() => {
		if (!window.location.search) return;

		const parse = window.location.search.replace('?', '').split('&');

		if (parse.length === 0) return;

		for (const q of parse) {
			const query = q.split('=');
			if (query[0] === 'emrId' && !!query[1]) {
				(async () => {
					const res = await apiGetEmr(query[1]);
					const transactionEMR = res?.transactionEMRs?.find(
						(trx) => trx.emrId === query[1],
					);
					if (transactionEMR) {
						if (
							res?.isSettled ||
							transactionEMR.transactionStatus ===
								ETransactionStatusType.COMPLETED
						) {
							setIsPaid(true);
						}
						props.setBilling(transactionEMR);
						setTransactionEmrId(transactionEMR.transactionEMRId);

						const servicesList =
							transactionEMR.transactionEMRItems?.map((item) => ({
								serviceName: item.serviceName ?? '',
								price: item.price !== undefined ? item.price.toString() : '',
								isNoCharge: item.price === 0,
							})) ?? [];

						setServices(servicesList);

						props.setPatientBilling({
							...props.patientBilling,
							nam: 'create new',
							name: transactionEMR.title,
							paymentMethod: transactionEMR.paymentMethod,
							transactionEMRItems: transactionEMR.transactionEMRItems?.map(
								(item) => ({
									serviceName: item.serviceName,
									price: item.price,
								}),
							),
						});
					}
				})();
			}
		}
	}, [window.location.search]);

	useEffect(() => {
		const fetchBillingTemplate = async (doctorId: string) => {
			try {
				const res = await apiGetTemplateBillingEmr(doctorId);
				if (res) {
					setTemplateBillings(res);
					const options = res.map((item: TemplateEMRBillingProperties) => ({
						value: item.templateEmrBillingId,
						label: (
							<div className="flex items-center justify-between my-2">
								<span>{item.name || 'Unnamed Template'}</span>
								<ChevronRight
									style={{
										height: 16,
										width: 16,
										color: theme.colors.charcoal300,
									}}
								/>
							</div>
						),
						name: item.name,
					}));
					setTemplateOptions(options);
				}
			} catch (error) {
				console.error('Error fetch billing template:', error);
			}
		};

		if (user?.userId) {
			fetchBillingTemplate(user.userId);
		}
	}, []);

	const handleChange = (templateId: string) => {
		if (!isPaid) {
			const selectedTemplate = templateBillings.find(
				(item) => item.templateEmrBillingId === templateId,
			);

			if (selectedTemplate) {
				const { name, paymentMethod, metaItems } = selectedTemplate;
				props.setPatientBilling((old: any) => ({
					...old,
					template: templateId,
					name,
					paymentMethod,
					services: metaItems?.map((item) => ({
						serviceName: item.serviceName,
						isNoCharge: item.isNoCharge,
						price: item.price,
					})),
				}));

				setServices(
					(metaItems ?? []).map((item) => ({
						serviceName: item.serviceName || '',
						price: item.price || '',
						isNoCharge: item.isNoCharge || false,
					})),
				);
			}
		}
	};

	useEffect(() => {
		if (props.patientBilling?.paymentMethod === 'NO_CHARGE') {
			setServices((prev) =>
				prev.map((item) => ({
					...item,
					price: '',
					isNoCharge: true,
				})),
			);
		}
	}, [props.patientBilling?.paymentMethod]);

	const saveBilling = async () => {
		setIsLoading(true);

		const totalPrice = calculateTotal();

		if (
			totalPrice <= 0 &&
			props.patientBilling?.paymentMethod !== 'NO_CHARGE'
		) {
			message.error('Price cannot be 0 or empty for chargeable services.');
			setIsLoading(false);
			return;
		}

		const invalidService = services.find((service) => {
			if (!service.isNoCharge) {
				if (!service.price || parseFloat(service.price) <= 0) {
					message.error('Price cannot be 0 or empty for chargeable services.');
					return true;
				}
				if (!service.serviceName || service.serviceName.trim() === '') {
					message.error('Service name needs to be filled.');
					return true;
				}
			}
			return false;
		});

		if (invalidService) {
			setIsLoading(false);
			return;
		}

		let isSuccess = false;

		const { patientBilling, billing, setBilling, setNextStep } = props;

		const transactionStatus =
			totalPrice === 0
				? ETransactionStatusType.COMPLETED
				: ETransactionStatusType.WAITING_PAYMENT;

		const data: TransactionEMRProperties = {
			...billing,
			emrId: emrId,
			patientId: patientId || patientBilling?.patientId,
			title: props.patientBilling?.name,
			doctorId: user?.userId,
			paymentMethod: patientBilling?.paymentMethod,
			transactionStatus: transactionStatus,
			transactionEMRItems: services.map((service) => ({
				serviceName: service.serviceName,
				price: parseFloat(service.price) || 0,
				isNoCharge: service.isNoCharge,
			})),
		};

		try {
			const res = transactionEmrId
				? await apiUpdateTransactionEmr(transactionEmrId, data)
				: await apiTransactionEmr(data);

			if (res) {
				setBilling(res);
				if (isTemplateChecked) {
					saveTemplateBilling();
				}
				isSuccess = true;
			}

			if (isSuccess) {
				setNextStep();

				if (!props.isEditing) {
					message.success('Success create Electronic Medical Record');
				} else {
					message.success('Success update Electronic Medical Record');
				}
				history.replace({
					pathname: `/app/emr/detail`,
					search: `?emrId=${emrId}&tab=1`,
					state: { step: 1, selectedPatient: props.emr },
				});
			}
		} catch (error) {
			console.error('Error saving Billing:', error);
		} finally {
			setModalVisible(false);
			setIsLoading(false);
		}
	};

	const saveTemplateBilling = async () => {
		setIsLoading(true);
		let isSuccess = false;

		const { patientBilling, billing, setBilling, setNextStep } = props;

		const data: TemplateEMRBillingProperties = {
			...billing,
			doctorId: user?.userId,
			paymentMethod: patientBilling?.paymentMethod,
			name: patientBilling?.name,
			metaItems: services.map((service) => ({
				serviceName: service.serviceName,
				price: parseFloat(service.price) || 0,
				isNoCharge: service.isNoCharge,
			})),
		};

		try {
			const res = await apiTemplateBillingEmr(data);
			if (res) {
				setBilling(res);
				isSuccess = true;
			}

			if (isSuccess) {
				setNextStep();
			}
		} catch (error) {
			console.error('Error saving Billing Template:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleConfirmSaveDraft = () => {
		setIsModalSaveDraftVisible(false);
		history.replace({
			pathname: `/app/emr/detail`,
			search: `?emrId=${emrId}&tab=1`,
			state: { step: 1, selectedPatient: props.emr },
		});
		message.success('EMR saved as draft');
	};

	return (
		<>
			<form>
				<div className="py-6 px-3">
					<div>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Billing</p>
							</div>
							<div className="flex-1">
								<>
									<p className="m-0 text-4">Choose Service Template</p>
									<InputContainer>
										<Select
											value={
												props.patientBilling?.template
													? templateBillings.find(
															(item) =>
																item.templateEmrBillingId ===
																props.patientBilling?.template,
													  )?.name
													: undefined
											}
											filterOption={(input, option) =>
												option?.name
													?.toLowerCase()
													.includes(input.toLowerCase())
											}
											onChange={handleChange}
											placeholder="Select a service template"
											className={
												'w-full text-4 border-none outline-none bg-transparent'
											}
											style={{
												width: '100%',
												padding: 0,
											}}
											disabled={isPaid}
											options={templateOptions}
											showSearch
										/>
									</InputContainer>
								</>

								<div className="mt-3 mb-4">
									<p className="m-0">
										Mode Of Payment <span style={{ color: COLORS.red }}>*</span>
									</p>
									<Radio.Group
										value={props.patientBilling?.paymentMethod}
										onChange={(e) => {
											if (!isPaid) {
												const selectedValue = e.target.value;
												if (props.patientBilling?.paymentMethod) {
													setSelectedPaymentMethod(selectedValue);
													setModalVisible(true);
												} else {
													props.setPatientBilling((old: any) => ({
														...old,
														paymentMethod: selectedValue,
													}));
												}
											}
										}}
										style={{ display: 'flex', flexDirection: 'column' }}
									>
										<Radio value="IN_APP">Pay Through Medeasy</Radio>
										<Radio value="NO_CHARGE">No Charge</Radio>
										<Radio value="INSURANCE">HMO/Insurance</Radio>
										<Radio value="CASH">Direct Payment In Clinic</Radio>
									</Radio.Group>
								</div>

								<Separator className="mb-4" />

								{services.map((service, index) => (
									<div key={index}>
										<div className="mb-4 flex items-start justify-between">
											<div className="flex-1">
												<FormInput
													label="Service Name"
													name={`serviceName_${index}`}
													placeholder="Input Service Name"
													type={EFormType.SINGLE_LINE}
													required
													value={service.serviceName}
													onChange={(val) =>
														handleServiceChange(index, 'serviceName', val)
													}
													disabled={isPaid}
												/>

												<div className="flex items-center gap-x-1">
													<Radio.Group
														value={service.isNoCharge ? 'noCharge' : 'charge'}
														onChange={(e) => {
															if (
																props.patientBilling?.paymentMethod ===
																'NO_CHARGE'
															)
																return;
															if (!isPaid) {
																handleChargeChange(index, e.target.value);
															}
														}}
													>
														<Radio value="charge"></Radio>
													</Radio.Group>

													<FormInput
														label="Price"
														name={`price_${index}`}
														required
														placeholder={
															props.patientBilling?.paymentMethod ===
															'NO_CHARGE'
																? '0'
																: 'Enter Price'
														}
														type={EFormType.SINGLE_LINE}
														value={service.isNoCharge ? '0' : service.price}
														onChange={(val) => {
															if (!isPaid) {
																if (!service.isNoCharge) {
																	handleServiceChange(index, 'price', val);
																}
															}
														}}
														disabled={
															props.patientBilling?.paymentMethod ===
																'NO_CHARGE' ||
															isPaid ||
															service.isNoCharge
														}
													/>

													<Radio.Group
														value={service.isNoCharge ? 'noCharge' : 'charge'}
														onChange={(e) => {
															if (!isPaid) {
																handleChargeChange(index, e.target.value);
															}
														}}
													>
														<Radio value="noCharge">No Charge</Radio>
													</Radio.Group>
												</div>
											</div>

											<div style={{ marginLeft: 'auto' }}>
												<IconButton
													onClick={() => {
														setIsRemoveModalVisible(true);
														setServiceIndexToRemove(index);
													}}
												>
													<Trash2
														width={20}
														height={20}
														color={theme.colors.primary}
													/>
												</IconButton>
											</div>
										</div>
										{index < services.length - 1 && (
											<Separator className="my-4 mb-3" />
										)}
									</div>
								))}

								<Separator className="my-4 mb-4" />
								<Button type="OUTLINE" onClick={addService}>
									Add Service
								</Button>
							</div>
						</div>
					</div>
					<div className="h-6" />
				</div>
				{props.patientBilling?.nam !== 'my templates' && (
					<div className="flex items-center ml-4">
						<Checkbox
							checked={isTemplateChecked}
							onChange={(e) => setIsTemplateChecked(e.target.checked)}
							className="mr-2"
						/>
						<p className="mb-0">Save this service list as template</p>
					</div>
				)}
				{isTemplateChecked && (
					<div className="items-center ml-4">
						<FormInput
							label="Templates Names"
							name="name"
							placeholder="Input Service Title"
							required
							type={EFormType.SINGLE_LINE}
							value={props.patientBilling?.name}
							onChange={(val) => {
								props.setPatientBilling((old: any) => ({
									...old,
									name: val,
								}));
							}}
							disabled={isPaid}
						/>
					</div>
				)}

				<Separator />
				<div className="py-4 px-4">
					<div
						className="flex justify-between mb-3 p-2 h-10"
						style={{ backgroundColor: '#F1F3F5' }}
					>
						<p className="text-4 font-semibold">Total</p>
						<p className="text-4 font-semibold">
							{formatNumber(calculateTotal(), true)}
						</p>
					</div>
					<div className="flex">
						<div className="ml-auto">
							<div className="w-24">
								<Button
									type="OUTLINE"
									onClick={() => setIsModalSaveDraftVisible(true)}
									isLoading={isLoading}
									className="w-full"
								>
									Save
								</Button>
							</div>
						</div>
						<div className="w-44 ml-2">
							<Button
								type="SOLID"
								onClick={() => {
									setIsProceedPayment(true);
									setModalVisible(true);
								}}
								isLoading={isLoading}
								className="w-full"
								isDisable={
									!(
										props.patientBilling?.name &&
										props.patientBilling?.paymentMethod &&
										services.every((item) => item.serviceName)
									)
								}
							>
								For Payment
							</Button>
						</div>
					</div>
				</div>
			</form>
			<ModalConfirmation
				isVisible={isModalVisible}
				setVisibility={setModalVisible}
				actionHandler={isProceedPayment ? saveBilling : handleConfirmChange}
				title={isProceedPayment ? 'Confirmation' : 'Payment Method'}
				type={
					isProceedPayment
						? EModalConfirmationType.CUSTOM
						: EModalConfirmationType.CHANGE
				}
				message={
					isProceedPayment
						? 'Are you sure you want to proceed to payment?'
						: undefined
				}
			/>

			<ModalConfirmation
				isVisible={isRemoveModalVisible}
				setVisibility={setIsRemoveModalVisible}
				actionHandler={() => {
					if (serviceIndexToRemove !== null) {
						setServices((prev) =>
							prev.filter((_, i) => i !== serviceIndexToRemove),
						);
					}
					setIsRemoveModalVisible(false);
					setServiceIndexToRemove(null);
				}}
				type={EModalConfirmationType.REMOVE_ITEM}
				title="Service"
			/>

			<ModalSaveDraft
				isModalVisible={isModalSaveDraftVisible}
				setModalVisible={setIsModalSaveDraftVisible}
				handleConfirmChange={handleConfirmSaveDraft}
			/>
		</>
	);
}

const InputContainer = styled.div`
	.ant-select-selector,
	.ant-select-selection-item {
		border: none !important;
		box-shadow: none !important;
		padding: 0 !important;
	}

	.ant-select-arrow {
		top: 45%;
	}

	.ant-select-single .ant-select-selector .ant-select-selection-search {
		top: 0px;
		left: 0px;
	}

	padding: 10px 12px 5px 12px;
	display: flex;
	align-items: center;
	column-gap: 8px;
	border: 1px solid ${({ theme }) => theme.colors.ash800};
	border-radius: 16px;
	&:focus-within {
		border-color: ${({ theme }) => theme.colors.black100};
	}
`;
