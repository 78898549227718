import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ChevronRight, Clock, Info, Search } from 'react-feather';
import { ReactComponent as HPI } from '../../../../../assets/icons/hpi.svg';
import { EmrProperties } from '../../../../../types/emr.type';
import { theme } from '../../../../../assets/theme';
import EmptyList from '../../../../EmptyList';
import { EMetaType } from '../../../../../types/doctorDocument.type';
import { Button } from '../../../../Button/Button/Button';
import { Card } from 'antd';
import Spinner from '../../../../Spinner';

interface Props {
	patient?: EmrProperties;
	isLoading: boolean;
	nextStep: (newStep?: number) => void;
	setPreviewDocument: React.Dispatch<
		React.SetStateAction<{ [key: string]: any }>
	>;
}

export default function PlanDetail({
	isLoading,
	patient,
	nextStep,
	setPreviewDocument,
}: Props) {
	const history = useHistory();
	const [doctorDocument, setDoctorDocument] = useState<any>();

	useEffect(() => {
		if (
			patient?.doctorDocuments &&
			Array.isArray(patient?.doctorDocuments) &&
			patient?.doctorDocuments?.length > 0
		) {
			const medicalCertificate = patient?.doctorDocuments?.find(
				(doc) => doc.type === EMetaType.MEDICAL_CERTIFICATE,
			);
			const clearance = patient?.doctorDocuments?.find(
				(doc) => doc.type === EMetaType.CLEARANCE,
			);
			const fitToWork = patient?.doctorDocuments?.find(
				(doc) => doc.type === EMetaType.FIT_TO_WORK,
			);
			const referralNotes = patient?.doctorDocuments?.find(
				(doc) => doc.type === EMetaType.REFERRAL_NOTES,
			);
			const others = patient?.doctorDocuments?.find(
				(doc) => doc.type === EMetaType.OTHERS,
			);

			const updatedDoctorDocument = {
				...doctorDocument,
				...(medicalCertificate && { medicalCertificate }),
				...(clearance && { clearance }),
				...(fitToWork && { fitToWork }),
				...(referralNotes && { referralNotes }),
				...(others && { others }),
			};

			setDoctorDocument(updatedDoctorDocument);
		}
	}, [patient]);

	const handleSeeInSchedule = () => {
		history.push('/app/schedule');
	};

	const formatDate = (dateString: string) => {
		if (dateString === 'Invalid date' || !dateString) return null;
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		};
		return new Date(dateString).toLocaleDateString('en-US', options);
	};
	const formatTime = (dateString: string) => {
		if (dateString === 'Invalid date' || !dateString) return null;
		const date = new Date(dateString);
		return date.toLocaleTimeString('en-US', {
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
		});
	};

	const conditions = [
		{
			condition: patient?.prescription,
			label: 'Prescription',
			validUntil: patient?.prescription?.validUntil,
			documentId: patient?.prescription?.rxPrescriptionId,
			type: 'rx-prescription',
		},
		{
			condition: patient?.labRequest,
			label: 'Lab Request',
			validUntil: patient?.labRequest?.validUntil,
			documentId: patient?.labRequest?.labRequestId,
			type: 'lab-request',
		},
		{
			condition: doctorDocument?.clearance,
			label: 'Clearance',
			validUntil: doctorDocument?.clearance?.validUntil,
			documentId: doctorDocument?.clearance?.doctorDocumentId,
			type: 'doctor-document',
		},
		{
			condition: doctorDocument?.fitToWork,
			label: 'Fit To Work',
			validUntil: doctorDocument?.fitToWork?.validUntil,
			documentId: doctorDocument?.fitToWork?.doctorDocumentId,
			type: 'doctor-document',
		},
		{
			condition: doctorDocument?.medicalCertificate,
			label: 'Medical Certificate',
			validUntil: doctorDocument?.medicalCertificate?.validUntil,
			documentId: doctorDocument?.fitToWork?.doctorDocumentId,
			type: 'doctor-document',
		},
		{
			condition: doctorDocument?.others,
			label: 'Doctor Document',
			validUntil: doctorDocument?.others?.validUntil,
			documentId: doctorDocument?.others?.doctorDocumentId,
			type: 'doctor-document',
		},
		{
			condition: doctorDocument?.referralNotes,
			label: 'Referral Notes',
			validUntil: doctorDocument?.referralNotes?.validUntil,
			documentId: doctorDocument?.referralNotes?.doctorDocumentId,
			type: 'doctor-document',
		},
	];

	const handleDoctorDocumentClick = (documentId: string, type: string) => {
		if (patient?.emrId && patient?.patientId) {
			nextStep(10);
			setPreviewDocument({
				documentId,
				type,
			});
		} else return;
	};

	return (
		<div className="flex-1 flex flex-col">
			<div className="py-6 px-3 flex-1">
				{isLoading ? (
					<div className="py-4 mx-auto w-fit">
						<Spinner size={25} color={theme.colors.primary} />
					</div>
				) : !patient?.plan ? (
					<EmptyList
						icon={<Info color={theme.colors.black50} />}
						title="Data Empty"
						description="No history of present illness data available for this patient?."
					/>
				) : (
					<>
						<div className="flex gap-x-3 mb-8">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">
									Plan Next Schedule Appointment
								</p>
							</div>
							<div className="flex-1">
								{patient?.plan.specialInstruction ||
								patient?.plan.followUpNextDate ||
								patient?.plan.fromInTime ||
								patient?.plan.toInTime ||
								patient?.plan.place ? (
									<>
										<div className="mb-5">
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Special Intruction
											</p>
											<p>{patient?.plan.specialInstruction || '-'}</p>
										</div>

										<div className="mb-5 flex">
											<div className="flex-1">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Follow Up Next Date
												</p>
												<p className="m-0">
													{formatDate(patient?.plan.followUpNextDate) || '-'}
												</p>
											</div>
											<div className="flex-1">
												<p className="m-0 text-3.5 font-medium text-charcoal-300">
													Times
												</p>
												<p className="m-0">
													{' '}
													{patient?.plan.fromInTime && patient?.plan.toInTime
														? `${formatTime(
																patient?.plan.fromInTime,
														  )} - ${formatTime(patient?.plan.toInTime)}`
														: '-'}
												</p>
											</div>
										</div>

										<div>
											<p className="m-0 text-3.5 font-medium text-charcoal-300">
												Place
											</p>
											<p>{patient?.plan.place || '-'}</p>
										</div>
										{patient?.plan.followUpNextDate &&
											patient?.plan.fromInTime &&
											patient?.plan.toInTime && (
												<div className="flex" style={{ maxWidth: '184px' }}>
													<Button type="OUTLINE" onClick={handleSeeInSchedule}>
														See in Schedule
													</Button>
												</div>
											)}
									</>
								) : (
									<div className="w-full flex flex-col items-center">
										<div className="p-1">
											<Info color={theme.colors.black50} />
										</div>
										<p className="text-black-60 text-4 text-center">
											No upcoming schedule available for this patient?.
										</p>
									</div>
								)}
							</div>
						</div>

						<div className="flex gap-x-1 mt-4">
							{' '}
							{/* Flex container for title and menu */}
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">
									Attachment For Patient
								</p>
							</div>
							<div className="flex-1 flex flex-col gap-5">
								{' '}
								{!conditions.some((item) => item.condition) ? (
									<EmptyList
										icon={<Info color={theme.colors.black50} />}
										title="Data Empty"
										description="You have not added Doctor Document History."
									/>
								) : (
									conditions
										?.filter((item) => item.condition)
										.map((item, index) => (
											<React.Fragment key={index}>
												<Card
													className="menu-item cursor-pointer"
													style={{
														borderRadius: 16,
														borderColor: `${theme.colors.ash300}`,
													}}
													bodyStyle={{ padding: 12 }}
													onClick={() =>
														handleDoctorDocumentClick(
															item.documentId,
															item.type,
														)
													}
												>
													<div className="flex justify-between items-center">
														<div className="flex items-center">
															<HPI className="menu-icon mr-2" />
															<div>
																<p className="menu-title m-0 mb-0 font-semibold">
																	{item.label}
																</p>
																<p className="menu-description text-3 mb-0 text-charcoal-300">
																	<Clock
																		width={12}
																		height={12}
																		style={{ marginBottom: '-2px' }}
																		color={theme.colors.charcoal300}
																	/>{' '}
																	Valid until {formatDate(item.validUntil)}
																</p>
															</div>
														</div>
														<ChevronRight className="text-gray-400" />
													</div>
												</Card>
											</React.Fragment>
										))
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
