import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { theme } from '../../assets/theme';
import { Calendar, ChevronDown } from 'react-feather';
import { Button, DatePicker, Space, Typography } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const FilterRangeDate = ({
	valueFrom,
	valueTo,
	onChangeFrom,
	onChangeTo,
	maximumDate,
	minimumDate,
}: {
	valueFrom?: string;
	valueTo?: string;
	onChangeFrom: (text: string) => void;
	onChangeTo: (text: string) => void;
	maximumDate?: Date;
	minimumDate?: Date;
}) => {
	const [isPickerVisible, setIsPickerVisible] = useState(false);

	const handleRangeChange = (
		dates: [Moment | null, Moment | null] | null,
		_: [string, string],
	) => {
		if (dates && dates[0] && dates[1]) {
			onChangeFrom(dates[0].toISOString());
			onChangeTo(dates[1].toISOString());
		} else {
			onChangeFrom('');
			onChangeTo('');
		}
		togglePickerVisibility();
	};

	const togglePickerVisibility = () => {
		setIsPickerVisible((prev) => !prev);
	};

	return (
		<div>
			<Button
				className="p-0-important h-3"
				style={{
					display: 'flex',
					alignItems: 'center',
					columnGap: '5px',
					cursor: 'pointer',
					marginTop: 4,
				}}
				onClick={togglePickerVisibility}
			>
				<Calendar color={theme.colors.charcoal300} height={12} width={12} />
				{valueFrom && valueTo ? (
					<Text className="text-3 text-charcoal-300">
						{getDateText(valueFrom, valueTo)}
					</Text>
				) : (
					<Text className="text-3 text-charcoal-300">Select Range</Text>
				)}
				<ChevronDown color={theme.colors.charcoal300} height={12} width={12} />
			</Button>

			{isPickerVisible && (
				<Space direction="vertical" style={{ marginTop: '10px' }}>
					<RangePicker
						size="small"
						onChange={handleRangeChange}
						value={
							valueFrom && valueTo
								? [moment(valueFrom), moment(valueTo)]
								: undefined
						}
						disabledDate={(current) => {
							const maxDate = maximumDate ? moment(maximumDate) : undefined;
							const minDate = minimumDate ? moment(minimumDate) : undefined;

							if (!current) return false;

							return (
								(minDate ? current.isBefore(minDate, 'day') : false) ||
								(maxDate ? current.isAfter(maxDate, 'day') : false)
							);
						}}
					/>
				</Space>
			)}
		</div>
	);
};

export default FilterRangeDate;

function getDateText(dateFrom: string, dateTo: string) {
	const stringFromDate = moment(dateFrom).format('D MMM YYYY');
	const stringToDate = moment(dateTo).format('D MMM YYYY');
	return `${stringFromDate} - ${stringToDate}`;
}
