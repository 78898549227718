import React from 'react';
import { useState } from 'react';
import { CheckCircle, ChevronRight } from 'react-feather';
import Separator from '../../../Separator';
import { ReactComponent as VitalSign } from '../../../../assets/icons/vital-sign.svg';
import { ReactComponent as HPI } from '../../../../assets/icons/hpi.svg';
import { ReactComponent as MedicalHistory } from '../../../../assets/icons/medical-history.svg';
import { ReactComponent as Assesment } from '../../../../assets/icons/assesment.svg';
import { ReactComponent as Plan } from '../../../../assets/icons/plan.svg';
import { ReactComponent as RxLab } from '../../../../assets/icons/rx-labreq.svg';
import { Button } from '../../../Button/Button/Button';
import { EmrProperties } from '../../../../types/emr.type';
import { apiUpdateEmr } from '../../../../api/emr';
import { useAuth } from '../../../../context/auth.context';
import { useHistory } from 'react-router-dom';
import { Typography, message } from 'antd';
import ModalSaveDraft from './Components/ModalSaveDraft';
import { theme } from '../../../../assets/theme';

const { Text, Paragraph } = Typography;

interface Props {
	emr: EmrProperties;
	searchPhone?: string | undefined;
	emrId?: string;
	step: number;
	handleVitalSignClick: () => void;
	handleHPIClick: () => void;
	handlePatientHistoryClick: () => void;
	handleObjectiveFindingsClick: () => void;
	handleDiagnosisClick: () => void;
	handlePlanClick: () => void;
	setNextStep: () => void;
	setFormName: React.Dispatch<React.SetStateAction<string>>;
	patientProfile: any;
	setPatientProfile: React.Dispatch<React.SetStateAction<any>>;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
}

export default function FormEmr(props: Props) {
	const { user } = useAuth();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [isModalVisible, setModalVisible] = useState(false);

	const saveEmr = async () => {
		setIsLoading(true);
		let isSuccess = false;

		const { patientProfile, emr, setEmr, setNextStep, setFormName, emrId } =
			props;

		const data: EmrProperties = {
			...emr,
			patientId: patientProfile.patientId,
			doctorId: user?.userId,
			emrId: emrId,
			hpiId: emr.hpiId,
			objectiveFindingId: emr.objectiveFindingId,
			vitalSignId: emr.vitalSignId,
			medicalHistoryId: emr.medicalHistoryId,
			diagnosisId: emr.diagnosisId,
			planId: emr.planId,
			user: {
				name: patientProfile.patientName,
				age: patientProfile.age,
				gender: patientProfile.gender,
				phone: props.searchPhone,
				birthdate: patientProfile.birthdate,
				address: patientProfile.address,
				maritalStatus: patientProfile.maritalStatus,
			},
			status: patientProfile?.status || 'completed',
		};

		try {
			if (emr?.emrId) {
				const res = await apiUpdateEmr(emr.emrId, data);
				if (res) {
					setEmr({
						...emr,
						...res,
					});
					isSuccess = true;
				}
			}

			if (isSuccess) {
				setNextStep();
			}
		} catch (error) {
			console.error('Error saving emr:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleConfirmChange = () => {
		setModalVisible(false);
		history.replace({
			pathname: `/app/emr/detail`,
			search: `?emrId=${props.emr.emrId}&tab=1`,
			state: { step: 1, selectedPatient: props.emr },
		});
		message.success('EMR saved as draft');
	};

	return (
		<div className="patient-detail-container">
			<div className="menu-section space-y-4">
				<div
					className="menu-item flex justify-between items-center cursor-pointer"
					onClick={props.handleVitalSignClick}
				>
					<div className="flex items-center">
						<VitalSign className="menu-icon mr-2" />
						<div className="flex">
							<p className="menu-title m-0 mb-0 font-semibold">Vital Sign</p>
							{props.emr.vitalSignId && (
								<CheckCircle
									height={15}
									width={15}
									color={theme.colors.green}
									className="ml-2 mt-1"
								/>
							)}
						</div>
					</div>
					<ChevronRight className="text-gray-400" />
				</div>
				<Separator className="mt-3 mb-3" />
				<div
					className="menu-item flex justify-between items-center cursor-pointer"
					onClick={() => {
						props.handleHPIClick();
						props.setFormName('HPI');
					}}
				>
					<div className="flex items-center">
						<HPI className="menu-icon mr-2" />
						<div className="flex">
							<p className="menu-title m-0 mb-0 font-semibold">HPI</p>
							{props.emr.hpiId && (
								<CheckCircle
									height={15}
									width={15}
									color={theme.colors.green}
									className="ml-2 mt-1"
								/>
							)}
						</div>
					</div>
					<ChevronRight className="text-gray-400" />
				</div>

				<Separator className="mt-3 mb-3" />
				<div
					className="menu-item flex justify-between items-center cursor-pointer"
					onClick={props.handlePatientHistoryClick}
				>
					<div className="flex items-center">
						<MedicalHistory className="menu-icon mr-2" />{' '}
						<div>
							<div className="flex">
								<p className="menu-title m-0 mb-0 font-semibold">
									Patient History
								</p>
								{props.emr.medicalHistoryId && (
									<CheckCircle
										height={15}
										width={15}
										color={theme.colors.green}
										className="ml-2 mt-1"
									/>
								)}
							</div>
							<p className="menu-description text-3.5 text-gray-60 mb-0">
								Past medical, Social and Family history
							</p>
						</div>
					</div>
					<ChevronRight className="text-gray-400" />
				</div>
				<Separator className="mt-3 mb-3" />
				<div
					className="menu-item flex justify-between items-center cursor-pointer"
					onClick={props.handleObjectiveFindingsClick}
				>
					<div className="flex items-center">
						<Assesment className="menu-icon mr-2" />
						<div>
							<div className="flex">
								<p className="menu-title m-0 mb-0 font-semibold">
									Objective Findings
								</p>
								{props.emr.objectiveFindingId && (
									<CheckCircle
										height={15}
										width={15}
										color={theme.colors.green}
										className="ml-2 mt-1"
									/>
								)}
							</div>

							<p className="menu-description text-3.5 text-gray-60 mb-0">
								Physical examination and diagnostic test result
							</p>
						</div>
					</div>
					<ChevronRight className="text-gray-400" />
				</div>
				<Separator className="mt-3 mb-3" />
				<div
					className="menu-item flex justify-between items-center cursor-pointer"
					onClick={props.handleDiagnosisClick}
				>
					<div className="flex items-center">
						<Plan className="menu-icon mr-2" />
						<div className="flex">
							<p className="menu-title m-0 mb-0 font-semibold">Diagnosis</p>
							{props.emr.diagnosisId && (
								<CheckCircle
									height={15}
									width={15}
									color={theme.colors.green}
									className="ml-2 mt-1"
								/>
							)}
						</div>
					</div>
					<ChevronRight className="text-gray-400" />
				</div>
				<Separator className="mt-3 mb-3" />
				<div
					className="menu-item flex justify-between items-center cursor-pointer"
					onClick={props.handlePlanClick}
				>
					<div className="flex items-center">
						<RxLab className="menu-icon mr-2" />
						<div>
							<div className="flex">
								<p className="menu-title m-0 mb-0 font-semibold">Plan</p>
								{props.emr.planId && (
									<CheckCircle
										height={15}
										width={15}
										color={theme.colors.green}
										className="ml-2 mt-1"
									/>
								)}
							</div>
							<p className="menu-description text-3.5 text-gray-60 mb-0">
								Management, Prescription, Lab request and other documents
							</p>
						</div>
					</div>
					<ChevronRight className="text-gray-400" />
				</div>

				<Separator className="mt-3 mb-3" />
				<div className="py-3 px-4">
					<div className="flex">
						<div className="ml-auto">
							<div className="w-24">
								<Button
									type="OUTLINE"
									onClick={() => setModalVisible(true)}
									isLoading={isLoading}
									className="w-full"
								>
									Save
								</Button>
							</div>
						</div>
						<div className="w-44 ml-2">
							<Button
								type="SOLID"
								onClick={saveEmr}
								isLoading={isLoading}
								className="w-full"
							>
								Proceed To Billing
							</Button>
						</div>
					</div>
				</div>
			</div>
			<ModalSaveDraft
				isModalVisible={isModalVisible}
				setModalVisible={setModalVisible}
				handleConfirmChange={handleConfirmChange}
			/>
		</div>
	);
}
