import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { httpRequest } from '../../../../../../helpers/api';
import { ApiResponsePagination } from '../../../../../../types/apiResponse.type';
import ResultSectionScoreCategory from '../../../../../AppCalculator/Bmi/ResultSectionCategoryScore';
import { apiGetEmr } from '../../../../../../api/emr';
import { EmrProperties } from '../../../../../../types/emr.type';
import { calculateAge } from '../../../../../../helpers/datetime';

interface Props {
	nextStep: (newStep?: number) => void;
	pageType: 'detail-patient' | 'detail-emr' | 'trends';
	patientId?: string;
	emrDetail?: EmrProperties;
	setIsAvailable: (isAvailable: boolean) => void;
	onFetchComplete: () => void;
	data?: any;
}

export default function VitalSignBmi(props: Props) {
	const { emrDetail, pageType, patientId } = props;
	const [bmi, setBmi] = useState<any>();
	const [birthdate, setBirthdate] = useState<any>();
	const history = useHistory();
	let age: number | undefined = undefined;

	useEffect(() => {
		const fetchVitalSignBmi = async (url: string): Promise<void> => {
			try {
				const bmiResponse =
					await httpRequest.get<ApiResponsePagination<any>>(url);

				if (bmiResponse?.data?.payload) {
					setBmi(bmiResponse.data.payload);
					props.setIsAvailable(true);
				} else {
					props.setIsAvailable(false);
				}
			} catch (error) {
				console.error('Error fetching BMI:', error);
				props.setIsAvailable(false);
			}
		};

		const fetchAndSetPatientBirthdate = async (): Promise<void> => {
			if (!patientId) return;

			try {
				const response = await httpRequest.get<ApiResponsePagination<any>>(
					`/emr?patientId=${patientId}&isPatientUnique=true`,
				);

				const result = response?.data?.payload?.results?.[0];
				if (!result) {
					console.warn('No patient data found');
					return;
				}

				const birthdate =
					result.patient?.birthdate || result.patientInRelation?.birthdate;

				if (birthdate) {
					setBirthdate(birthdate);
				} else {
					console.warn('Birthdate not found for patient or patientInRelation');
				}
			} catch (error) {
				console.error('Error fetching patient details:', error);
			}
		};

		const handleFetch = async (): Promise<void> => {
			try {
				// from Patient Detail
				if (patientId && !emrDetail) {
					// if page is not from trends chart
					if (!props.data) {
						await Promise.all([
							fetchVitalSignBmi(`/emr/vital-sign-trends/${patientId}?type=bmi`),
							fetchAndSetPatientBirthdate(),
						]);
					} else {
						setBmi(props.data);
						fetchAndSetPatientBirthdate();
					}

					props.onFetchComplete();
				} else if (emrDetail && !patientId) {
					// if fetch vital-sign-trends with type all is failed
					if (!props.data) {
						fetchVitalSignBmi(
							`/emr/vital-sign-trends/${emrDetail.patientId}?type=bmi&emrId=${emrDetail.emrId}`,
						);
					} else {
						setBmi(props.data);
					}

					props.onFetchComplete();
				}
			} catch (error) {
				console.error('Error during fetch bmi:', error);
			}
		};

		handleFetch();
	}, [emrDetail, patientId]);

	if (!emrDetail && patientId) {
		age = calculateAge(birthdate);
	} else if (emrDetail && !patientId) {
		const birthdate =
			emrDetail?.patient?.birthdate || emrDetail?.patientInRelation?.birthdate;
		age = calculateAge(birthdate);
	}

	const getHeightandWeight = () => {
		if (emrDetail && !patientId) {
			return {
				height: emrDetail?.vitalSign?.height ?? 0,
				weight: emrDetail?.vitalSign?.weight ?? 0,
			};
		} else {
			return {
				height: bmi?.height.currentValue ?? 0,
				weight: bmi?.weight.currentValue ?? 0,
			};
		}
	};

	const { height, weight } = getHeightandWeight();

	let roundedBmiScore = 0;

	if (height && weight) {
		const bmiScore = weight / ((height / 100) * (height / 100));
		roundedBmiScore = Math.round(bmiScore * 100) / 100;
	}

	return (
		<>
			{bmi || emrDetail?.vitalSign ? (
				<ResultSectionScoreCategory
					pageType={pageType}
					bmiScore={roundedBmiScore}
					height={height}
					weight={weight}
					age={age}
					nextStep={() => props.nextStep(2)}
				/>
			) : undefined}
		</>
	);
}
