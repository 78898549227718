import React, { Key, useEffect, useState } from 'react';
import moment from 'moment';
import { EMRFindTrendProperties } from '../../../../../../types/emr.type';
import { Collapse, Typography, List, Divider } from 'antd';
import { ChevronUp, ChevronDown } from 'react-feather';
import { theme } from '../../../../../../assets/theme';
import styled from 'styled-components';

const { Text } = Typography;
const { Panel } = Collapse;

interface Props {
	data?: EMRFindTrendProperties;
	birthdate: Date | string;
}

export const HistoryDataPediaChart = ({ data, birthdate }: Props) => {
	const [activeKey, setActiveKey] = useState<string[]>(['1']);
	const [isCollapsed, setIsCollapsed] = useState(false);

	const formatDate = (date: string, format: string) =>
		moment(date).format(format);

	const handleCollapseChange = (key: string | string[]) => {
		setActiveKey(Array.isArray(key) ? key : [key]);
		setIsCollapsed(!(Array.isArray(key) && key.includes('1')));
	};

	const groupedData = data?.histories.reduce(
		(acc, item) => {
			const date = moment(item.date).format('YYYY-MM-DD');
			if (!acc[date]) {
				acc[date] = [];
			}
			acc[date].push(item);
			return acc;
		},
		{} as Record<string, EMRFindTrendProperties[]>,
	);

	return (
		<StyledCollapse>
			<Collapse
				bordered={false}
				onChange={handleCollapseChange}
				activeKey={activeKey}
				expandIconPosition="end"
				style={{ padding: 0 }}
			>
				<Panel
					header={
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								padding: '10px 20px',
								backgroundColor: '#f5f5f5',
								borderRadius: 10,
								width: '100%',
								margin: '0px',
							}}
						>
							<Text strong style={{ fontSize: 16 }}>
								History Data
							</Text>
							{isCollapsed ? (
								<ChevronDown onClick={() => setIsCollapsed(!isCollapsed)} />
							) : (
								<ChevronUp onClick={() => setIsCollapsed(!isCollapsed)} />
							)}
						</div>
					}
					key="1"
					showArrow={false}
					style={{
						display: 'flex',
						flexDirection: 'column',
						backgroundColor: 'white',
						padding: 0,
						paddingRight: 0,
					}}
				>
					{Object.keys(groupedData || {}).map((date, index) => (
						<div key={index}>
							<Text
								strong
								style={{
									fontSize: 18,
									marginTop: 10,
									marginBottom: 10,
									display: 'block',
								}}
							>
								{moment(date).isSame(moment(), 'day') ? 'Today, ' : ''}
								{formatDate(date, 'D MMM YYYY')}
							</Text>
							<List
								dataSource={groupedData[date]}
								renderItem={(item: any) =>
									item.headCircumference ? (
										<div>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													position: 'relative',
												}}
											>
												<div
													style={{
														position: 'absolute',
														top: '9%',
														left: 7,
														width: 10,
														height: 10,
														borderRadius: 5,
														backgroundColor: `${theme.colors.primary}`,
													}}
												/>
												<div
													style={{
														flex: 1,
														marginLeft: 10,
														rowGap: 5,
													}}
												>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															padding: '10px 0px 0px 16px',
															fontSize: 14,
															borderLeft: `4px solid ${theme.colors.ash400}`,
															height: '100%',
															margin: 0,
														}}
													>
														<Text style={{ color: '#888' }}>
															{formatDate(item.date, 'HH:mm')}
														</Text>

														<Divider
															style={{
																marginTop: 8,
																marginBottom: 8,
																borderColor: `${theme.colors.ash300}`,
															}}
														/>

														<DetailInformationContainer>
															<AgeInformation
																title="Age as y+m+d"
																birthdate={birthdate}
																currentDate={item.date}
															/>
															<AgeInformation
																title="Age as days"
																birthdate={birthdate}
																currentDate={item.date}
															/>
														</DetailInformationContainer>

														<Divider
															style={{
																marginTop: 8,
																marginBottom: 8,
																borderColor: `${theme.colors.ash300}`,
															}}
														/>
														<div className="flex justify-between">
															<div className="text-left flex-1">
																<VitalSignSummaryTitle>
																	Height
																</VitalSignSummaryTitle>

																<div style={DetailInformationValueStyles}>
																	<span style={DetailInformationNumberStyle}>
																		{item.vitalSign.height}{' '}
																	</span>{' '}
																	{data?.unit}
																</div>
															</div>
															<div className="text-left flex-1">
																<VitalSignSummaryTitle>
																	Weight
																</VitalSignSummaryTitle>

																<div style={DetailInformationValueStyles}>
																	<span style={DetailInformationNumberStyle}>
																		{item.vitalSign.weight}{' '}
																	</span>{' '}
																	{data?.unit}
																</div>
															</div>
															<div className="text-left flex-1">
																<VitalSignSummaryTitle>
																	Head Circumference
																</VitalSignSummaryTitle>

																<div style={DetailInformationValueStyles}>
																	<span style={DetailInformationNumberStyle}>
																		{item.headCircumference}{' '}
																	</span>{' '}
																	{data?.unit}
																</div>
															</div>
														</div>

														<Divider
															style={{
																marginTop: 10,
																marginBottom: 0,
																borderColor: `${theme.colors.ash300}`,
															}}
														/>
													</div>
												</div>
											</div>
										</div>
									) : null
								}
							/>
							<Divider
								style={{
									borderWidth: 3,
									marginTop: 10,
									marginBottom: 10,
									borderColor: `${theme.colors.ash300}`,
								}}
							/>
						</div>
					))}
				</Panel>
			</Collapse>
		</StyledCollapse>
	);
};

const StyledCollapse = styled.div`
	.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
		padding: 0px;
	}
`;

const DetailInformationContainer = styled.div`
	display: flex;
	width: 100%;
`;

const DetailInformationAgeStyle = styled.div`
	text-align: left;
	flex: 1;
`;

const DetailInformationUnit = styled.div`
	color: #748494;
	font-size: 14px;
`;

const DetailInformationTitle = styled.div`
	font-size: 12px;
	color: #748494;
	margin-bottom: 4px;
`;

const VitalSignSummaryTitle = styled.div`
	font-size: 12px;
	color: #748494;
	margin-bottom: 8px;
`;

export const DetailInformationValueStyles: React.CSSProperties = {
	fontSize: 12,
	color: '#748494',
	fontWeight: 400,
	lineHeight: '14px',
};

export const DetailInformationNumberStyle: React.CSSProperties = {
	fontSize: 14,
	fontWeight: 600,
	color: 'black',
	lineHeight: '14px',
};

const AgeInformation = ({ title, birthdate, currentDate }: any) => (
	<DetailInformationAgeStyle>
		<DetailInformationTitle>{title}</DetailInformationTitle>
		<div style={{ display: 'flex', gap: '5px' }}>
			{title !== 'Age as days' && (
				<DetailInformationUnit>
					<span className="font-medium" style={{ color: 'black' }}>
						{moment(currentDate).diff(moment(birthdate), 'years')}
					</span>{' '}
					years
				</DetailInformationUnit>
			)}

			{title !== 'Age as days' && (
				<DetailInformationUnit>
					<span className="font-medium" style={{ color: 'black' }}>
						{moment(currentDate).diff(moment(birthdate), 'months') % 12}
					</span>{' '}
					month
				</DetailInformationUnit>
			)}

			<DetailInformationUnit>
				<span className="font-medium" style={{ color: 'black' }}>
					{title !== 'Age as days'
						? moment(currentDate).diff(moment(birthdate), 'days') % 30
						: moment(currentDate).diff(moment(birthdate), 'days')}
				</span>{' '}
				days
			</DetailInformationUnit>
		</div>
	</DetailInformationAgeStyle>
);
