import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { EMRFindTrendProperties } from '../../../../../../types/emr.type';
import { Collapse, Typography, List, Divider } from 'antd';
import { ChevronUp, ChevronDown } from 'react-feather';
import { theme } from '../../../../../../assets/theme';
import styled from 'styled-components';

const { Text } = Typography;
const { Panel } = Collapse;

interface Props {
	data?: EMRFindTrendProperties;
	dataExtractor: (item: any) => {
		value: number;
		unit: string;
	};
}

export const HistoryData = ({ data, dataExtractor }: Props) => {
	const [activeKey, setActiveKey] = useState<string[]>(['1']);
	const [isCollapsed, setIsCollapsed] = useState(false);

	const formatDate = (date: string, format: string) =>
		moment(date).format(format);

	const handleCollapseChange = (key: string | string[]) => {
		setActiveKey(Array.isArray(key) ? key : [key]);
		setIsCollapsed(!(Array.isArray(key) && key.includes('1')));
	};

	const groupedData = data?.histories.reduce(
		(acc, item) => {
			const date = moment(item.date).format('YYYY-MM-DD');
			if (!acc[date]) {
				acc[date] = [];
			}
			acc[date].push(item);
			return acc;
		},
		{} as Record<string, EMRFindTrendProperties[]>,
	);

	return (
		<StyledCollapse>
			<Collapse
				bordered={false}
				onChange={handleCollapseChange}
				activeKey={activeKey}
				expandIconPosition="end"
				style={{ padding: 0 }}
			>
				<Panel
					header={
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								padding: '10px 20px',
								backgroundColor: '#f5f5f5',
								borderRadius: 10,
								width: '100%',
								margin: '0px',
							}}
						>
							<Text strong style={{ fontSize: 16 }}>
								History Data
							</Text>
							{isCollapsed ? (
								<ChevronDown onClick={() => setIsCollapsed(!isCollapsed)} />
							) : (
								<ChevronUp onClick={() => setIsCollapsed(!isCollapsed)} />
							)}
						</div>
					}
					key="1"
					showArrow={false}
					style={{
						display: 'flex',
						flexDirection: 'column',
						backgroundColor: 'white',
						padding: 0,
						paddingRight: 0,
					}}
				>
					{Object.keys(groupedData || {}).map((date, index) => (
						<div key={index}>
							<Text
								strong
								style={{
									fontSize: 18,
									marginTop: 10,
									marginBottom: 10,
									display: 'block',
								}}
							>
								{moment(date).isSame(moment(), 'day') ? 'Today, ' : ''}
								{formatDate(date, 'D MMM YYYY')}
							</Text>
							<List
								dataSource={groupedData[date]}
								renderItem={(item: any) => {
									const { value, unit } = dataExtractor(item);
									return (
										<div>
											{value && (
												<div>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															position: 'relative',
														}}
													>
														<div
															style={{
																position: 'absolute',
																top: '20%',
																left: 7,
																width: 10,
																height: 10,
																borderRadius: 5,
																backgroundColor: `${theme.colors.primary}`,
															}}
														/>
														<div
															style={{
																flex: 1,
																marginLeft: 10,
																rowGap: 5,
															}}
														>
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'column',
																	padding: '10px 0px 0px 16px',
																	fontSize: 14,
																	borderLeft: `4px solid ${theme.colors.ash400}`,
																	height: '100%',
																	margin: 0,
																}}
															>
																<Text style={{ color: '#888' }}>
																	{formatDate(item.date, 'HH:mm')}
																</Text>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: '5px',
																		marginTop: 5,
																	}}
																>
																	<Text strong style={{ fontSize: 16 }}>
																		{value}
																	</Text>
																	<Text style={{ color: '#888' }}>
																		{unit || data?.unit}
																	</Text>
																</div>

																<Divider
																	style={{
																		marginTop: 10,
																		marginBottom: 0,
																		borderColor: `${theme.colors.ash300}`,
																	}}
																/>
															</div>
														</div>
													</div>
												</div>
											)}
										</div>
									);
								}}
							/>
							<Divider
								style={{
									borderWidth: 3,
									marginTop: 10,
									marginBottom: 10,
									borderColor: `${theme.colors.ash300}`,
								}}
							/>
						</div>
					))}
				</Panel>
			</Collapse>
		</StyledCollapse>
	);
};

const StyledCollapse = styled.div`
	.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
		padding: 0px;
	}
`;
