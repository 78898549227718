import React, { useState, useEffect } from 'react';
import { Button } from '../../../../Button/Button/Button';
import { EmrProperties } from '../../../../../types/emr.type';
import EmptyList from '../../../../EmptyList';
import Separator from '../../../../Separator';
import { Info } from 'react-feather';
import { theme } from '../../../../../assets/theme';
import { calculateAge } from '../../../../../helpers/datetime';
import { getPaymentEmrMethod } from '../../../../../helpers/emr';
import { EPaymentMethodEMR } from '../../../../../types/templateEmrBilling.type';
import { formatNumber } from '../../../../../utils/commons';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import { apiUpdateEmr } from '../../../../../api/emr';
import { Divider, message } from 'antd';
import ModalConfirmation, {
	EModalConfirmationType,
} from '../../CreateEmr/Components/ModalConfirmation';
import { getName } from '../../../../../helpers/name';
import DEFAULT_USER from '../../../../../assets/default-user2.png';
import { capitalizeWords } from '../../../../../helpers/text';
import Spinner from '../../../../Spinner';

interface Props {
	patient?: EmrProperties;
	isLoading: boolean;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setPatient: React.Dispatch<React.SetStateAction<EmrProperties | undefined>>;
}

export default function BillingDetail({
	isLoading,
	patient,
	setIsLoading,
	setPatient,
}: Props) {
	const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<any>();
	const [isModalVisible, setModalVisible] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);

	const validateForm = () => {
		const isValid =
			(selectedPaymentStatus?.isSettled !== null &&
				patient?.notes !== selectedPaymentStatus?.notes) ||
			patient?.isSettled !== selectedPaymentStatus?.isSettled;

		setIsFormValid(isValid);
	};

	useEffect(() => {
		validateForm();
	}, [selectedPaymentStatus, patient]);

	useEffect(() => {
		setSelectedPaymentStatus({
			isSettled: patient?.isSettled,
			notes: patient?.notes,
		});
	}, [patient]);

	const confirmSaveRecord = async () => {
		setModalVisible(false);
		try {
			if (patient?.emrId) {
				setIsLoading(true);
				const res = await apiUpdateEmr(patient?.emrId, {
					...patient,
					isSettled: selectedPaymentStatus?.isSettled,
					notes: selectedPaymentStatus?.notes,
				});

				if (res) {
					setPatient((old: any) => ({
						...old,
						isSettled: selectedPaymentStatus?.isSettled,
						notes: selectedPaymentStatus?.notes,
					}));
					message.success('Success save record');
				}
			}
		} catch (error) {
			console.error('Error updating record:', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="flex-1 flex flex-col">
			<div className="py-6 px-3 flex-1">
				{isLoading ? (
					<div className="py-4 mx-auto w-fit">
						<Spinner size={25} color={theme.colors.primary} />
					</div>
				) : patient?.transactionEMRs?.length === 0 ? (
					<EmptyList
						icon={<Info color={theme.colors.black50} />}
						title="Data Empty"
						description="No billing information available for this patient."
					/>
				) : (
					<>
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">Doctor</p>
							</div>
							<div className="flex-1">
								<div className="flex items-center">
									<img
										src={patient?.doctor.profilePic || DEFAULT_USER}
										alt="Profile"
										className="w-16 h-16 rounded-full mr-3"
									/>
									<div className="flex flex-col">
										<p className="m-0 mb-0 font-bold text-4">
											{patient?.doctor.name}
										</p>
										<div className="flex gap-x-2">
											<p className="m-0 text-3.5 text-charcoal-300">
												{patient?.doctor?.doctor?.title || ''}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<Separator className="my-5" style={{ height: 2 }} />
						<div className="flex gap-x-3 mb-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">Patient Profile</p>
							</div>
							<div className="flex-1 flex gap-x-3">
								<div className="flex-1">
									<p className="m-0 text-3.5 text-charcoal-300">Name</p>
									<p className="m-0 mb-1 text-4">
										{patient?.patient?.name ||
											getName(patient?.patientInRelation) ||
											'-'}
									</p>

									<p className="m-0 text-3.5 text-charcoal-300">Age</p>
									<p className="m-0 text-4">
										{calculateAge(patient?.patient?.birthdate) ||
											calculateAge(patient?.patientInRelation?.birthdate) ||
											'-'}
									</p>
								</div>
								<div className="flex-1">
									<p className="m-0 text-3.5 text-charcoal-300">Gender</p>
									<p className="m-0 mb-1 text-4">
										{capitalizeWords(
											patient?.patient?.gender ||
												patient?.patientInRelation?.gender ||
												'-',
										)}
									</p>

									<p className="m-0 text-3.5 text-charcoal-300">Status</p>
									<p className="m-0 text-4">
										{capitalizeWords(
											patient?.patient?.maritalStatus ||
												patient?.patientInRelation?.maritalStatus ||
												'-',
										)}
									</p>
								</div>
							</div>
						</div>

						<Separator className="my-5" style={{ height: 2 }} />

						<Separator className="my-5" style={{ height: 2 }} />
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">Mode of Payment</p>
							</div>
							<div className="flex-1 flex gap-x-3">
								<div className="flex-1">
									<p className="m-0 text-3.5">
										{getPaymentEmrMethod(
											patient?.transactionEMRs?.[0]
												?.paymentMethod as EPaymentMethodEMR,
										)}
									</p>
								</div>
							</div>
						</div>

						<Separator className="my-5" style={{ height: 2 }} />
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">Payment Details</p>
							</div>
							<div className="flex-1 flex gap-x-3">
								<div className="flex-1">
									<div className="mb-4">
										<p className="m-0 mb-3 font-semibold text-4">
											Payment Details
										</p>
										<div className="flex flex-col gap-2">
											{patient?.transactionEMRs?.[0]?.transactionEMRItems &&
												patient?.transactionEMRs?.[0]?.transactionEMRItems?.map(
													(item: any) => (
														<div
															key={item.id}
															className="flex justify-between text-3.5"
														>
															<p className="m-0">{item.serviceName}</p>
															<p className="m-0">
																{formatNumber(item.price, true)}
															</p>
														</div>
													),
												)}
										</div>
									</div>
									<Divider
										dashed
										style={{
											margin: '12px 0px',
											borderColor: theme.colors.ash700,
										}}
									/>
									<div className="flex justify-between text-4">
										<p className="m-0 font-semibold">Total</p>
										<p className="m-0">
											{formatNumber(
												patient?.transactionEMRs?.[0]?.transactionEMRItems?.reduce(
													(total: any, item: any) => total + item.price,
													0,
												),
												true,
											)}
										</p>
									</div>
								</div>
							</div>
						</div>
						<Separator className="my-5" style={{ height: 2 }} />
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">Payment Status</p>
							</div>
							<div className="flex-1 flex gap-x-3">
								<div className="flex-1">
									<FormInput
										name="isSettled"
										type={EFormType.ITEM_PICKER_H}
										items={['Settled', 'Unsettled']}
										value={
											selectedPaymentStatus?.isSettled === true
												? 'settled'
												: selectedPaymentStatus?.isSettled === false
												? 'unsettled'
												: null
										}
										onChange={(val) => {
											setSelectedPaymentStatus((old: any) => ({
												...old,
												isSettled: val === 'settled' ? true : false,
											}));
										}}
									/>
									<FormInput
										name="notes"
										placeholder="Notes"
										type={EFormType.MULTI_LINE}
										value={selectedPaymentStatus?.notes}
										onChange={(val) => {
											setSelectedPaymentStatus((old: any) => ({
												...old,
												notes: val,
											}));
										}}
									/>
									<Button
										type="OUTLINE"
										onClick={() => setModalVisible(true)}
										isLoading={isLoading}
										isDisable={!isFormValid || isLoading}
									>
										Save Record
									</Button>
								</div>
							</div>
						</div>
						<ModalConfirmation
							isVisible={isModalVisible}
							setVisibility={setModalVisible}
							actionHandler={confirmSaveRecord}
							title="Patient Payment Status"
							type={EModalConfirmationType.CHANGE}
						/>
					</>
				)}
			</div>
		</div>
	);
}
