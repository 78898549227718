import { EmrProperties } from '../../../../../types/emr.type';
import EmptyList from '../../../../EmptyList';
import Separator from '../../../../Separator';
import { ICDProperties } from '../../../../../types/icd.type';
import { theme } from '../../../../../assets/theme';
import { Info } from 'react-feather';
import Spinner from '../../../../Spinner';

interface Props {
	patient: EmrProperties;
	isLoading: boolean;
}

export default function DiagnosisDetail({ isLoading, patient }: Props) {
	const icds = patient?.diagnosis?.icds || [];

	return (
		<div className="flex-1 flex flex-col">
			<div className="py-6 px-3 flex-1">
				{isLoading ? (
					<div className="py-4 mx-auto w-fit">
						<Spinner size={25} color={theme.colors.primary} />
					</div>
				) : !patient?.diagnosis ? (
					<EmptyList
						icon={<Info color={theme.colors.black50} />}
						title="Data Empty"
						description="No diagnosis data available for this patient."
					/>
				) : (
					<>
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">Diagnosis</p>
							</div>
							<div className="flex-1 flex gap-x-3">
								<div className="flex-1">
									<p className="m-0 text-3.5 font-medium text-charcoal-300">
										Diagnosis
									</p>
									<p className="m-0 text-4">{patient.diagnosis.diagnosis}</p>
								</div>
							</div>
						</div>
						<div className="py-5">
							<Separator />
						</div>
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">ICD 10</p>
							</div>
							<div className="flex-1 flex gap-x-3">
								<div className="flex-1">
									<p className="m-0 text-3.5 font-medium text-charcoal-300">
										ICD 10
									</p>
									{icds.length > 0 ? (
										icds.map((icd: ICDProperties, index: number) => (
											<div key={icd.id}>
												<p className="m-0 mt-2 text-4">{icd.value}</p>
												{index < icds.length - 1 && <Separator />}
											</div>
										))
									) : (
										<p className="m-0 mt-2 text-gray-500">No ICDs available</p>
									)}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
