import React, { Key, useEffect } from 'react';
import moment from 'moment';
import {
	BloodPressureTrendProperties,
	BmiTrendProperties,
	EMRFindTrendProperties,
	EMRGraphData,
} from '../../../../types/emr.type';
import { apiGetVitalSignTrend } from '../../../../api/emr';
import { generateScatterData } from '../../../../helpers/generateGraphData';
import Spinner from '../../../Spinner';
import { theme } from '../../../../assets/theme';
import { Card, Divider, Typography } from 'antd';
import VitalSignTypeSummary from '../DetailEmr/VitalSign/VitalSignTypeSummary';
import FilterRangeDate from '../../../Form/FilterRangeDate';
import { HistoryData } from '../DetailEmr/VitalSign/VitalSignHistoryData/HistoryData';
import { Chart } from 'react-google-charts';
import { VitalSignTrendProperties } from '../../../../types/vitalSign.type';
import { HistoryDataBloodPressure } from '../DetailEmr/VitalSign/VitalSignHistoryData/HistoryDataBloodPressure';
import VitalSignBmi from '../DetailEmr/VitalSign/VitalSignType/VitalSignBmi';
import { HistoryDataBmi } from '../DetailEmr/VitalSign/VitalSignHistoryData/HistoryDataBmi';

const { Text } = Typography;

const BMIGraph = ({ patientId }: { patientId: string }) => {
	const [data, setData] = React.useState<BmiTrendProperties>();
	const [isLoading, setIsLoading] = React.useState(false);
	const [weightChart, setWeightChart] = React.useState<EMRGraphData[]>([]);
	const [heightChart, setHeightChart] = React.useState<EMRGraphData[]>([]);
	const [valueWeight, setValueWeightFrom] = React.useState(
		moment().subtract(3, 'years').toISOString(),
	);
	const [valueWeightTo, setValueWeightTo] = React.useState(
		moment().endOf('month').toISOString(),
	);
	const [valueHeightFrom, setValueHeightFrom] = React.useState(
		moment().subtract(3, 'years').toISOString(),
	);
	const [valueHeightTo, setValueHeightTo] = React.useState(
		moment().endOf('month').toISOString(),
	);

	const fetchData = async (from?: string, to?: string) => {
		setIsLoading(true);
		try {
			const res = await apiGetVitalSignTrend(
				patientId,
				'bmi',
				undefined,
				from,
				to,
			);
			if (res) {
				setData(res as BmiTrendProperties);
			}
		} catch (e) {
			console.log(e);
			setIsLoading(false);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		fetchData(
			moment(valueWeight).format('YYYY-MM-DD'),
			moment(valueWeightTo).format('YYYY-MM-DD'),
		);
		fetchData(
			moment(valueHeightFrom).format('YYYY-MM-DD'),
			moment(valueHeightTo).format('YYYY-MM-DD'),
		);
	}, [valueWeightTo, valueWeight, valueHeightTo, valueHeightFrom]);

	const dateToDay = (date: Date): number => {
		try {
			const day = Number(
				moment(date).toISOString().split('T')[0].split('-')[2],
			);
			return day;
		} catch (error) {
			return 0;
		}
	};

	useEffect(() => {
		if (data) {
			const newGraph: any[] = [['date', 'value']];
			newGraph.push([1, data.weight.currentValue]);
			for (let i = 0; i < data.weight.graphData.length; i++) {
				const dayNumber = dateToDay(data.weight.graphData[i].date);
				newGraph.push([dayNumber, data.weight.graphData[i].value]);
			}
			newGraph.push([dateToDay(new Date()), data.weight.currentValue]);

			const sortedGraph = [
				newGraph[0],
				...newGraph.slice(1).sort((a, b) => a[0] - b[0]),
			];

			setWeightChart(sortedGraph);

			const newGraphHeight: any[] = [['date', 'value']];
			newGraphHeight.push([1, data.height.currentValue]);
			for (let i = 0; i < data.height.graphData.length; i++) {
				const dayNumber = dateToDay(data.height.graphData[i].date);
				newGraphHeight.push([dayNumber, data.height.graphData[i].value]);
			}
			newGraphHeight.push([dateToDay(new Date()), data.height.currentValue]);

			const sortedGraphHeight = [
				newGraphHeight[0],
				...newGraphHeight.slice(1).sort((a, b) => a[0] - b[0]),
			];

			setHeightChart(sortedGraphHeight);
		}
	}, [data]);

	return isLoading ? (
		<div className="py-5 mx-auto w-fit">
			<Spinner size={25} color={theme.colors.primary} />
		</div>
	) : (
		<div className="p-5 flex flex-col">
			<VitalSignBmi
				patientId={patientId}
				nextStep={() => {}}
				pageType="trends"
				setIsAvailable={() => {}}
				onFetchComplete={() => {}}
				data={data}
			/>
			<Divider style={{ borderWidth: 2, marginTop: 20, marginBottom: 20 }} />
			<TrendInfo
				label="Weight"
				value={data?.weight?.currentValue}
				unit={data?.weight?.unit}
			/>
			<FilterRangeDate
				valueFrom={valueWeight}
				valueTo={valueWeightTo}
				onChangeFrom={(val) => setValueWeightFrom(val)}
				onChangeTo={(val) => setValueWeightTo(val)}
			/>
			<Chart
				chartType="AreaChart"
				width="100%"
				height="300px"
				data={weightChart}
				options={{
					hAxis: {
						minValue: 1,
						maxValue: 30,
						ticks: [1, 7, 14, 21, 30],
					},
					vAxis: {
						minValue: 0,
						maxValue: 100,
						ticks: [0, 27, 50, 80, 100],
					},
					legend: 'none',
					backgroundColor: 'transparent',
					colors: [`green`],
					pointSize: 5,
					chartArea: {
						left: 30,
						top: 20,
						right: 10,
						bottom: 40,
					},
				}}
			/>
			<Card className="min-h-24 rounded-3 mt-2\.5" bodyStyle={{ padding: 16 }}>
				<p className="font-semibold text-4 mb-0">Summary Weight</p>
				<div className="flex justify-between items-center">
					{data?.weight.summary?.map((item: any, index: Key) => (
						<VitalSignTypeSummary
							key={index}
							title={item.title}
							value={item.value}
							unit={item.unit}
						/>
					))}
				</div>
			</Card>
			<Divider style={{ borderWidth: 2, marginTop: 20, marginBottom: 20 }} />
			<TrendInfo
				label="Height"
				value={data?.height?.currentValue}
				unit={data?.height?.unit}
			/>
			<FilterRangeDate
				valueFrom={valueHeightFrom}
				valueTo={valueHeightTo}
				onChangeFrom={(val) => setValueHeightFrom(val)}
				onChangeTo={(val) => setValueHeightTo(val)}
			/>
			<Chart
				chartType="AreaChart"
				width="100%"
				height="300px"
				data={heightChart}
				options={{
					hAxis: {
						minValue: 1,
						maxValue: 30,
						ticks: [1, 7, 14, 21, 30],
					},
					vAxis: {
						minValue: 0,
						maxValue: 200,
						ticks: [0, 50, 100, 150, 200],
					},
					legend: 'none',
					backgroundColor: 'transparent',
					colors: [`red`],
					pointSize: 5,
					chartArea: {
						left: 30,
						top: 20,
						right: 10,
						bottom: 40,
					},
				}}
			/>

			<Card className="min-h-24 rounded-3 mt-2\.5" bodyStyle={{ padding: 16 }}>
				<p className="font-semibold text-4 mb-0">Summary Height</p>
				<div className="flex justify-between items-center">
					{data?.height.summary?.map((item: any, index: Key) => (
						<VitalSignTypeSummary
							key={index}
							title={item.title}
							value={item.value}
							unit={item.unit}
						/>
					))}
				</div>
			</Card>
			<Divider
				style={{
					borderWidth: 2,
					marginTop: 20,
					marginBottom: 20,
					borderColor: `${theme.colors.ash300}`,
				}}
			/>
			<HistoryDataBmi
				heightData={data?.height}
				weightData={data?.weight}
				dataExtractor={(item: any) => ({
					heightValue: item.height,
					weightValue: item.weight,
				})}
			/>
		</div>
	);
};

export default BMIGraph;

const generateSummary = (
	summary: VitalSignTrendProperties[],
	title: string,
) => {
	const item = summary?.find((item) => item.title === title);
	return item ? item.value : '';
};

const TrendInfo = ({
	label,
	value,
	unit,
}: {
	label: string;
	value?: number;
	unit?: string;
}) => {
	return (
		<div
			className="text-3\.5 font-normal leading-7"
			style={{ color: '#748494' }}
		>
			<Text className="flex mb-2" style={{ color: '#748494' }}>
				{label}
			</Text>
			<span className="text-10 font-semibold text-black-100">{value}</span>{' '}
			{unit}
		</div>
	);
};
