import { ReactComponent as MedicalCertificate } from '../../../assets/icons/medical-certificate.svg';
import { ReactComponent as DoctorDocumentHistory } from '../../../assets/icons/doctor-document-history.svg';
import { ReactComponent as FitToWork } from '../../../assets/icons/fit-to-work.svg';
import { ChevronRight } from 'react-feather';
import Separator from '../../Separator';
import { EKycStatus } from '../../../types/doctor.type';
import { useAuth } from '../../../context/auth.context';

export enum EmrNav {
	Main = 'Main',
	CreateEMR = 'Create',
	EditEMR = 'Edit',
	Patient = 'Patient',
	Detail = 'Detail',
	DocumentPreview = 'Document-Preview',
}

const emr = [
	{
		icon: <MedicalCertificate />,
		title: 'Create EMR',
		description: 'Create Electronic Medical Records',
		nav: EmrNav.CreateEMR,
	},
	{
		icon: <FitToWork />,
		title: 'My Patient Record',
		description: 'Manage patient record',
		nav: EmrNav.Patient,
	},
];

interface Props {
	addNav: (nav: EmrNav) => void;
}

export default function MainEMR(props: Props) {
	const { doctorKYCStatus } = useAuth();
	const isDisabled = doctorKYCStatus !== EKycStatus.APPROVED;

	function Item(cprops: {
		icon: JSX.Element;
		title: string;
		description: string;
		nav: EmrNav;
		isDisabled?: boolean;
	}) {
		return (
			<button
				disabled={cprops.isDisabled}
				onClick={() => !cprops.isDisabled && props.addNav(cprops.nav)} // Hanya panggil addNav jika tidak dinonaktifkan
				className={`w-full text-left flex gap-x-2.5 items-center py-4 px-2 border border-solid rounded-6 border-black-10 cursor-pointer ${
					cprops.isDisabled ? 'bg-gray' : 'bg-transparent'
				}`}
			>
				{cprops.icon}
				<div className="flex-1">
					<p className="m-0 font-semibold text-4">{cprops.title}</p>
					<p className="m-0 text-3.5 text-black-60">{cprops.description}</p>
				</div>
				<div className="flex">
					<ChevronRight />
				</div>
			</button>
		);
	}

	return (
		<div>
			<div className="relative px-6 py-5.5 flex items-center">
				<h1 className="font-semibold text-6 m-0 w-full">
					Electronic Medical Record
				</h1>
			</div>
			<Separator style={{ height: 2 }} />
			<div className="py-6 px-3 space-y-3">
				{emr.map((menu) => (
					<Item
						key={menu.title}
						icon={menu.icon}
						title={menu.title}
						description={menu.description}
						nav={menu.nav}
						isDisabled={isDisabled}
					/>
				))}
			</div>
		</div>
	);
}
