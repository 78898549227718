import { Col, Modal, Row, Typography } from 'antd';
import styled from 'styled-components';
import { Button } from '../../../../Button/Button/Button';

export enum EModalConfirmationType {
	DELETE = 'DELETE',
	CANCEL = 'CANCEL',
	CHANGE = 'CHANGE',
	CLEAR_FORM = 'CLEAR_FORM',
	REMOVE_ITEM = 'REMOVE_ITEM',
	CUSTOM = 'CUSTOM',
}

type Props = {
	isVisible: boolean;
	setVisibility: (state: boolean) => void;
	actionHandler: () => void;
	title?: string;
	type: EModalConfirmationType;
	message?: string;
	buttonConfirm?: string;
};

const { Text, Paragraph } = Typography;

export default function ModalConfirmation(props: Props) {
	function cancelReject() {
		props.setVisibility(false);
	}

	let modalTitle = '';
	let modalMessage = '';
	let buttonText = '';

	switch (props.type) {
		case EModalConfirmationType.CHANGE:
			modalTitle = `Change ${props.title}`;
			modalMessage = `Are you sure you want to change ${props?.title?.toLowerCase()}?`;
			buttonText = 'Change';
			break;
		case EModalConfirmationType.DELETE:
			modalTitle = `Delete ${props.title}`;
			modalMessage = `Are you sure you want to delete this ${props?.title?.toLowerCase()}?`;
			buttonText = 'Delete';
			break;
		case EModalConfirmationType.CANCEL:
			modalTitle = `Cancel ${props.title}`;
			modalMessage = `Are you sure you want to cancel ${props?.title?.toLowerCase()}?`;
			buttonText = 'Confirm';
			break;
		case EModalConfirmationType.CLEAR_FORM:
			modalTitle = `Clear Form`;
			modalMessage = `All entered data will be removed. Do you want to proceed?`;
			buttonText = 'Confirm';
			break;
		case EModalConfirmationType.REMOVE_ITEM:
			modalTitle = `Remove ${props.title}`;
			modalMessage = `Are you sure you want to remove this ${props?.title?.toLowerCase()}?`;
			buttonText = 'Confirm';
			break;
		case EModalConfirmationType.CUSTOM:
			modalTitle = `${props.title}`;
			modalMessage = props.message || '';
			buttonText = props.buttonConfirm || 'Confirm';
			break;
		default:
			break;
	}

	return (
		<Modal open={props.isVisible} footer={null} closable={false} width={360}>
			<div className="flex flex-col items-center">
				<Text style={{ marginBottom: 16 }} className="text-5 font-semibold">
					{modalTitle}
				</Text>
				<Paragraph style={{ marginBottom: 0, textAlign: 'center' }}>
					{modalMessage}
				</Paragraph>
			</div>

			<Row gutter={24} className="mt-6">
				<Col span={12}>
					<Button type="TRANSPARENT" onClick={cancelReject}>
						{props.type === 'CANCEL' ? 'Close' : 'Cancel'}
					</Button>
				</Col>
				<Col span={12}>
					<Button
						type="SOLID"
						onClick={props.actionHandler}
						className="rounded-3 bg-primary w-full"
					>
						{buttonText}
					</Button>
				</Col>
			</Row>
		</Modal>
	);
}

const IconWrapper = styled.div`
	height: 64px;
	width: 64px;
	background-color: ${(props) => props.theme.colors.gray10};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 24px;
`;
