import moment from 'moment';
import { EMRGraphData } from '../types/emr.type';

export const generateScatterData = (inputData: EMRGraphData[]) => {
	const data: any[] = [];

	data.push(['date', 'value']);

	if (!inputData || !inputData.length) return data;

	inputData?.sort(
		(a, b) => moment(a.date).get('days') - moment(b.date).get('days'),
	);

	for (let i = 0; i < inputData.length; i++) {
		const currentData = inputData[i];

		if (
			i === 0 ||
			dateToDay(currentData.date) !== dateToDay(inputData[i - 1].date)
		) {
			data.push([dateToDay(currentData.date), currentData.value]);
		} else {
			const prevData = inputData[i - 1];
			const valueDiff = Math.abs(currentData.value - prevData.value);

			if (valueDiff <= 50) {
				const stepCount = Math.floor(valueDiff / 0.1);
				const dateDiffInMillis = moment(currentData.date).diff(
					prevData.date,
					'milliseconds',
				);

				for (let step = 1; step <= stepCount; step++) {
					const newValue =
						prevData.value +
						step * 0.1 * (currentData.value > prevData.value ? 1 : -1);
					const newDate = moment(prevData.date)
						.add((dateDiffInMillis / (stepCount + 1)) * step, 'milliseconds')
						.toDate();

					data.push([dateToDay(newDate), newValue]);
				}
			}

			// Setelah menambahkan interpolasi, tambahkan nilai yang asli
			data.push([dateToDay(currentData.date), currentData.value]);
		}
	}

	return data;
};

export const dateToDay = (date: Date): number => {
	try {
		const day = Number(moment(date).toISOString().split('T')[0].split('-')[2]);
		return day;
	} catch (error) {
		return 0;
	}
};
