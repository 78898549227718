import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import { Button } from '../../../../Button/Button/Button';
import { theme } from '../../../../../assets/theme';
import { CheckCircle, ChevronRight, Info } from 'react-feather';
import { PlanProperties } from '../../../../../types/plan.type';
import { apiCreatePlan, apiGetEmr } from '../../../../../api/emr';
import { useAuth } from '../../../../../context/auth.context';
import { message } from 'antd';
import { LabRequestProperties } from '../../../../../types/labRequest.type';
import { RxPrescriptionProperties } from '../../../../../types/rxPrescription.type';
import { EMetaType } from '../../../../../types/doctorDocument.type';
import { EmrProperties } from '../../../../../types/emr.type';

interface Props {
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	step: number;
	setNextStep: () => void;
	setFormName: (formname: string) => void;
	patientPlan: any;
	setPatientPlan: React.Dispatch<React.SetStateAction<any>>;
	doctorDocumentId: any;
	labRequest?: LabRequestProperties;
	prescription?: RxPrescriptionProperties;
	handleRxClick: () => void;
	handleLabReqClick: () => void;
	handleClearanceClick: () => void;
	handleMedicalCertificateClick: () => void;
	handleFitToWorkClick: () => void;
	handleOwnTemplateClick: () => void;
	handleReferralNotesClick: () => void;
	rxPrescriptionId: any;
	labRequestId: any;
}

export default function FormCreatePlan(props: Props) {
	const { user } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const isMountedRef = useRef(true);
	const [isFormValid, setIsFormValid] = useState(false);
	const [documentAvailability, setDocumentAvailability] = useState({
		medicalCertificate: false,
		clearance: false,
		fitToWork: false,
		ownTemplate: false,
		referralNotes: false,
		rxPrescription: false,
		labRequest: false,
	});

	const validateForm = () => {
		const { patientPlan } = props;
		const isValid =
			!!documentAvailability.medicalCertificate ||
			!!documentAvailability.clearance ||
			!!documentAvailability.fitToWork ||
			!!documentAvailability.ownTemplate ||
			!!documentAvailability.referralNotes ||
			!!documentAvailability.rxPrescription ||
			!!documentAvailability.labRequest ||
			!!patientPlan.specialInstruction ||
			!!patientPlan.place;

		setIsFormValid(isValid);
	};

	useEffect(() => {
		validateForm();
	}, [props.patientPlan, documentAvailability]);

	useEffect(() => {
		return () => {
			isMountedRef.current = false;
		};
	}, []);

	useEffect(() => {
		if (
			(!props.emr.doctorDocuments || props.emr.doctorDocuments.length < 1) &&
			!props.emr.prescription &&
			!props.emr.labRequest
		)
			return;

		const availability = {
			medicalCertificate: false,
			clearance: false,
			fitToWork: false,
			ownTemplate: false,
			referralNotes: false,
			rxPrescription: !!props.emr.prescription,
			labRequest: !!props.emr.labRequest,
		};

		if (props.emr.doctorDocuments) {
			props.emr.doctorDocuments.forEach((doc) => {
				switch (doc.type) {
					case EMetaType.MEDICAL_CERTIFICATE:
						availability.medicalCertificate = !!doc.doctorDocumentId;
						break;
					case EMetaType.CLEARANCE:
						availability.clearance = !!doc.doctorDocumentId;
						break;
					case EMetaType.FIT_TO_WORK:
						availability.fitToWork = !!doc.doctorDocumentId;
						break;
					case EMetaType.OTHERS:
						availability.ownTemplate = !!doc.doctorDocumentId;
						break;
					case EMetaType.REFERRAL_NOTES:
						availability.referralNotes = !!doc.doctorDocumentId;
						break;
					default:
						break;
				}
			});
		}

		setDocumentAvailability(availability);
	}, [props.emr]);

	// Initial Value for Edit Emr
	useEffect(() => {
		if (!props.emr.plan) return;

		props.setPatientPlan({
			...props.patientPlan,
			followUpNextDate: props.emr.plan.followUpNextDate,
			specialInstruction: props.emr.plan.specialInstruction,
			place: props.emr.plan.place,
			fromInTime: props.emr.plan.fromInTime,
			toInTime: props.emr.plan.toInTime,
		});
	}, []);

	const savePlan = async () => {
		if (!isFormValid) return;

		setIsLoading(true);

		const { patientPlan } = props;

		const fromTime = moment(patientPlan.fromInTime);
		const toTime = moment(patientPlan.toInTime);

		if (fromTime.isAfter(toTime)) {
			message.error('"From" time cannot be later than "To" time.');
			setIsLoading(false);
			return;
		}

		let isSuccess = false;

		const { emr, setEmr, setNextStep, setFormName } = props;

		const data: PlanProperties = {
			...emr.plan,
			emrId: emr.emrId,
			doctorId: user?.userId,
			followUpNextDate: patientPlan.followUpNextDate,
			specialInstruction: patientPlan.specialInstruction || '',
			place: patientPlan.place,
			fromInTime: patientPlan.fromInTime,
			toInTime: patientPlan.toInTime,
			patientId: patientPlan.patientId,
		};

		try {
			if (!props.emr.emrId) {
				throw new Error('EMR ID is required.');
			}

			const res = await apiCreatePlan(props.emr.emrId, 'plan', data);
			if (isMountedRef.current && res) {
				setEmr({
					...emr,
					...res,
				});
				isSuccess = true;
			}

			if (isMountedRef.current && isSuccess) {
				setNextStep();
				setFormName('EMR');
				message.success('PLAN Saved Successfully');
			}
		} catch (error) {
			if (isMountedRef.current) {
				console.error('Error saving Plan:', error);
			}
		} finally {
			if (isMountedRef.current) {
				setIsLoading(false);
			}
		}
	};

	return (
		<>
			<form>
				<div className="py-6 px-3">
					<div>
						<h3 className="mb-0">Plan</h3>
						<p className="text-3.5 text-gray-400 mt-0">
							Management, Prescription, Lab request and other documents
						</p>
					</div>
					<p
						className="text-3.5 mb-2 flex items-center"
						style={{
							backgroundColor: '#03CEA4',
							color: '#FFFFFF',
							padding: '10px',
							borderRadius: '6px',
						}}
					>
						<Info className="mr-1" width={16} height={15} />
						This can be viewed by the patient in the Medeasy app
					</p>
					<div>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">
									Plan Next Schedule Appointment
								</p>
							</div>
							<div className="flex-1">
								<FormInput
									label="Special Intructions"
									name="specialInstruction"
									type={EFormType.MULTI_LINE}
									value={props.patientPlan?.specialInstruction}
									onChange={(val) => {
										props.setPatientPlan((old: any) => ({
											...old,
											specialInstruction: val,
										}));
									}}
								/>
								<FormInput
									label="Follow Up Next Date"
									name="followUpNextDate"
									type={EFormType.DATE_PICKER}
									value={props.patientPlan?.followUpNextDate}
									onChange={(val) => {
										props.setPatientPlan((old: any) => ({
											...old,
											followUpNextDate: val,
										}));
									}}
									disabledDate={(d: any) => {
										const today = new Date();
										today.setHours(0, 0, 0, 0);
										return !d || d.isBefore(today, 'day');
									}}
								/>
								<div className="flex gap-x-3">
									<div className="flex-1 w-1/3">
										<FormInput
											label="From"
											name="fromInTime"
											type={EFormType.TIME_PICKER}
											value={props.patientPlan?.fromInTime}
											onChange={(val) => {
												props.setPatientPlan((old: any) => ({
													...old,
													fromInTime: val,
												}));
											}}
										/>
									</div>
									<div className="flex-1 w-1/3">
										<FormInput
											label="To"
											name="toInTimee"
											type={EFormType.TIME_PICKER}
											value={props.patientPlan?.toInTime}
											onChange={(val) => {
												props.setPatientPlan((old: any) => ({
													...old,
													toInTime: val,
												}));
											}}
										/>
									</div>
								</div>

								<FormInput
									label="Place"
									name="place"
									type={EFormType.MULTI_LINE}
									value={props.patientPlan?.place}
									onChange={(val) => {
										props.setPatientPlan((old: any) => ({
											...old,
											place: val,
										}));
									}}
								/>
							</div>
						</div>

						<Separator className="mt-3 mb-3" />
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Rx and Lab Request</p>
							</div>
							<div className="flex-1">
								<div
									className="flex justify-between items-center cursor-pointer"
									onClick={props.handleRxClick}
								>
									<p className=" m-0 mb-0">Prescription</p>
									<div className="flex items-center">
										{documentAvailability.rxPrescription && (
											<CheckCircle
												height={15}
												width={15}
												color={theme.colors.green}
												className="mr-5"
											/>
										)}
										<ChevronRight className="text-gray-400" />
									</div>
								</div>
								<Separator className="mt-3 mb-3" />
								<div
									className="flex justify-between items-center cursor-pointer"
									onClick={props.handleLabReqClick}
								>
									<p className=" m-0 mb-0">Lab Request</p>
									<div className="flex items-center">
										{documentAvailability.labRequest && (
											<CheckCircle
												height={15}
												width={15}
												color={theme.colors.green}
												className="mr-5"
											/>
										)}
										<ChevronRight className="text-gray-400" />
									</div>
								</div>
								<Separator className="mt-3 mb-3" />
							</div>
						</div>

						<Separator className="mt-3 mb-3" />
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Doctor Documents</p>
							</div>
							<div className="flex-1">
								<div
									className="flex justify-between items-center cursor-pointer"
									onClick={props.handleMedicalCertificateClick}
								>
									<p className=" m-0 mb-0">Medical Certificate</p>
									<div className="flex items-center">
										{documentAvailability.medicalCertificate && (
											<CheckCircle
												height={15}
												width={15}
												color={theme.colors.green}
												className="mr-5"
											/>
										)}
										<ChevronRight className="text-gray-400" />
									</div>
								</div>
								<Separator className="mt-3 mb-3" />
								<div
									className="flex justify-between items-center cursor-pointer"
									onClick={props.handleClearanceClick}
								>
									<p className=" m-0 mb-0">Clearance</p>
									<div className="flex items-center">
										{documentAvailability.clearance && (
											<CheckCircle
												height={15}
												width={15}
												color={theme.colors.green}
												className="mr-5"
											/>
										)}
										<ChevronRight className="text-gray-400" />
									</div>
								</div>
								<Separator className="mt-3 mb-3" />
								<div
									className="flex justify-between items-center cursor-pointer"
									onClick={props.handleFitToWorkClick}
								>
									<p className=" m-0 mb-0">Fit To Work</p>
									<div className="flex items-center">
										{documentAvailability.fitToWork && (
											<CheckCircle
												height={15}
												width={15}
												color={theme.colors.green}
												className="mr-5"
											/>
										)}
										<ChevronRight className="text-gray-400" />
									</div>
								</div>
								<Separator className="mt-3 mb-3" />
								<div
									className="flex justify-between items-start  cursor-pointer"
									onClick={props.handleOwnTemplateClick}
								>
									<div>
										<p className="m-0 mb-0">Own Template</p>
										<p className="m-0 text-3.5">
											Use this document if you want to create custom doctor
											documents
										</p>
									</div>
									<div className="flex items-center">
										{documentAvailability.ownTemplate && (
											<CheckCircle
												height={15}
												width={15}
												color={theme.colors.green}
												className="mr-5"
											/>
										)}
										<ChevronRight className="text-gray-400" />
									</div>
								</div>

								<Separator className="mt-3 mb-3" />
								<div
									className="flex justify-between items-center cursor-pointer"
									onClick={props.handleReferralNotesClick}
								>
									<p className=" m-0 mb-0">Referral Notes</p>
									<div className="flex items-center">
										{documentAvailability.referralNotes && (
											<CheckCircle
												height={15}
												width={15}
												color={theme.colors.green}
												className="mr-5"
											/>
										)}
										<ChevronRight className="text-gray-400" />
									</div>
								</div>
								<Separator className="mt-3 mb-3" />
							</div>
						</div>
						<Separator className="mt-3 mb-3" />
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Document Preview</p>
							</div>
							<div className="flex-1">
								<div>
									<p className="m-0 mb-0 text-3.5">
										See a preview of the document you created by clicking the
										button below.
									</p>
									<Button
										type="OUTLINE"
										onClick={() => {
											if (
												!props.emr.prescription &&
												!props.emr.labRequest &&
												!props.emr.doctorDocuments
											) {
												message.info('No Document Data is Available');
												return;
											}

											props.setFormName('DocumentPreview');
											// window.open(
											// 	'/app/emr/document-preview?emrId=' + props.emr.emrId,
											// 	'_blank',
											// );
										}}
										isLoading={isLoading}
									>
										See Document Preview
									</Button>
								</div>
							</div>
						</div>
					</div>
					<div className="h-6" />
				</div>
				<Separator />
				<div className="py-3 px-4">
					<div className="w-44 ml-auto">
						<Button
							type="SOLID"
							onClick={savePlan}
							isLoading={isLoading}
							isDisable={!isFormValid || isLoading}
						>
							Save
						</Button>
					</div>
				</div>
			</form>
		</>
	);
}
