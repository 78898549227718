// import {GeneralPatientProperties} from '../api/helpers/generalPatient.type';
import { ICDProperties } from './icd.type';
import { PaymentProperties } from './payment.type';

export enum EMetaType {
	MEDICAL_CERTIFICATE = 'MEDICAL_CERTIFICATE',
	CLEARANCE = 'CLEARANCE',
	FIT_TO_WORK = 'FIT_TO_WORK',
	REFERRAL_NOTES = 'REFERRAL_NOTES',
	OTHERS = 'OTHERS',
}

export type DoctorDocumentProperties = {
	doctorDocumentId?: string;
	emrId?: string;
	transactionConsultationId?: string;
	doctorId?: string;
	patientId?: string;
	patientInRelationId?: string;
	// metaPatient: GeneralPatientProperties;
	// metaPatientInRelation: GeneralPatientProperties;
	metaPatient?: any;
	metaPatientInRelation?: any;
	validUntil: string;
	saveAsTemplate?: boolean;
	isTemporary?: boolean;
	icds?: ICDProperties[];
	price?: number;
	type?: EMetaType;
	createdAt?: string;
	meta?: MetaMedicalCertificate | MetaClearence | MetaFitToWork | MetaOthers;
	payment?: PaymentProperties;
};

export type MetaMedicalCertificate = {
	address: string;
	clinicName: string;
	diagnosis: string;
	medicalAttentionInDays: number;
};

export type MetaClearence = {
	title: string;
	comorbidConditions: string;
	procedures: string;
	symptoms: string;
	functionalCapacity: string;
	chestXRay: string;
	lecg12: string;
	bloodTest: string;
	otherTest: string;
	patientStratified: string;
	recommendation: string;
};

export type MetaFitToWork = {
	title: string;
	comorbidConditions: string;
	chiefComplaint: string;
	peFindings: string;
	testResult: string;
	physicalCondition: 'FIT' | 'UNFIT';
	recommendation: string;
};

export type MetaOthers = {
	title: string;
	content: string;
};
