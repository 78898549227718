import { EPaymentMethodEMR } from '../types/templateEmrBilling.type';

export const getSettledStatus = (status?: boolean) => {
	if (status === true) {
		return 'Settled';
	} else if (status === false) {
		return 'Unsettled';
	} else {
		return '-';
	}
};

export const getPaymentEmrMethod = (method?: EPaymentMethodEMR) => {
	if (method === EPaymentMethodEMR.CASH) {
		return 'Direct Payment in Clinic';
	} else if (method === EPaymentMethodEMR.INSURANCE) {
		return 'HMO/Insurance';
	} else if (method === EPaymentMethodEMR.IN_APP) {
		return 'Pay Through Medeasy';
	} else if (method === EPaymentMethodEMR.NO_CHARGE) {
		return 'No Charge';
	} else {
		return '-';
	}
};
