import { Tag, Typography } from 'antd';
import { ETransactionStatusType } from '../../../types/transactionConsultation.type';

const { Text } = Typography;

export const renderStatusTag = (statusString: string) => {
	let temp = statusString;
	let status = '';
	let title = '';
	let color = '';

	if (temp.valueOf() === ETransactionStatusType.WAITING_PAYMENT) {
		title = 'Waiting for Payment';
		status = 'is waiting for payment';
		color = 'orange';
	} else if (temp.valueOf() === ETransactionStatusType.WAITING_APPROVAL) {
		title = 'Waiting for Approval';
		status = 'is waiting for approval';
		color = 'orange';
	} else if (temp.valueOf() === ETransactionStatusType.APPROVED) {
		title = 'Approved';
		status = 'has been approved';
		color = 'green';
	} else if (temp.valueOf() === ETransactionStatusType.REJECTED) {
		title = 'Rejected';
		status = 'has been rejected';
		color = 'red';
	} else if (temp.valueOf() === ETransactionStatusType.REJECTED_CHANGE_DOCTOR) {
		title = 'Rejected';
		status = 'has been rejected by Doctor';
		color = 'red';
	} else if (temp.valueOf() === ETransactionStatusType.REJECTED_REFUND) {
		title = 'Rejected';
		status = 'has been rejected (refund)';
		color = 'red';
	} else if (temp.valueOf() === ETransactionStatusType.CANCELED_BY_SYSTEM) {
		title = 'Expired';
		status = 'has expired';
		color = 'red';
	} else if (temp.valueOf() === ETransactionStatusType.CANCELED_BY_USER) {
		title = 'Cancelled';
		status = 'has been cancelled';
		color = 'red';
	} else if (temp.valueOf() === ETransactionStatusType.COMPLETED) {
		title = 'Paid';
		status = 'has been completed';
		color = 'green';
	} else if (
		temp.valueOf() === ETransactionStatusType.REJECTED_CHANGE_SCHEDULE
	) {
		title = 'Rescheduled';
		status = 'has been rescheduled';
		color = 'orange';
	} else {
		title = 'On Progress';
		status = 'is on progress';
		color = 'grey';
	}

	return <Tag color={color}>{title}</Tag>;
};

export const renderVitalSignStatus = (statusString: string) => {
	let fontColor = '';
	let badgeColor = '';
	let title = '';

	if (statusString === 'Low') {
		title = statusString;
		fontColor = '#1473E6';
		badgeColor = '#DAEFFA';
	} else if (statusString === 'Normal') {
		title = statusString;
		fontColor = '#268E6C';
		badgeColor = '#C8F1E9';
	} else if (statusString === 'Normal High') {
		title = statusString;
		fontColor = '#F26B29';
		badgeColor = '#FCDAC9';
	} else {
		title = statusString;
		fontColor = '#D7373F';
		badgeColor = '#FDDAE7';
	}
	return (
		<div
			style={{
				backgroundColor: badgeColor,
				borderRadius: 12,
				lineHeight: '10px',
				padding: '4px 7px',
				display: 'inline-block',
			}}
		>
			<Text style={{ fontSize: 10, color: fontColor }}>{title}</Text>
		</div>
	);
};
