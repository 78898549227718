import wNumb from 'wnumb';

export const previewText = (value: string, maxChar: number) => {
	if (!value) {
		return '-';
	}
	if (value?.length > maxChar) {
		return value.substring(0, maxChar) + '...';
	}
	return value;
};

export const formatNumber = (value: any, isCurrency: boolean = false) => {
	if (!value) {
		if (isCurrency) {
			return wNumb({
				mark: '.',
				thousand: ',',
				prefix: '₱ ',
				suffix: '',
			}).to(Number('0'));
		} else {
			return 0;
		}
	}
	if (isCurrency) {
		return wNumb({
			mark: '.',
			thousand: ',',
			prefix: '₱ ',
			suffix: '.00',
		}).to(Number(value));
	}
	console.info(
		'wNumb',
		wNumb({
			mark: '.',
			thousand: ',',
			prefix: '',
			suffix: '',
		}),
	);
	return wNumb({
		mark: '.',
		thousand: ',',
		prefix: '',
		suffix: '',
	}).to(Number(value));
};

export const deformatNumber = (value: any) => {
	if (!value) {
		return 0;
	}
	return wNumb({
		mark: '.',
		thousand: ',',
		prefix: '',
		suffix: '',
	}).from(value);
};
