import { Card, Typography } from 'antd';
import moment from 'moment';
import { ChevronDown, Calendar, Info } from 'react-feather';
import styled from 'styled-components';
import VitalSignTypeHeader from './VitalSignTypeHeader';
import PatientDetailVitalSignSummary from './PatientDetailVitalSignSummary';
import EmrDetailVitalSignSummary from './EmrDetailVitalSignSummary';
import { useEffect, useState } from 'react';
import FilterRangeDate from '../../../../Form/FilterRangeDate';
import { theme } from '../../../../../assets/theme';

interface Props {
	pageType: 'detail-patient' | 'detail-emr';
	nextStep: (newStep?: number) => void;
	data: any;
	birthdate?: Date | string;
	setDate: (date: string | undefined) => void;
	setFromDate: (date: string | undefined) => void;
	setToDate: (date: string | undefined) => void;
	date: string | undefined;
	fromDate: string | undefined;
	toDate: string | undefined;
	typeTitle:
		| 'Pedia Growth Chart'
		| 'Blood Pressure'
		| 'Blood Sugar'
		| 'Heart Rate'
		| 'Temperature'
		| 'Respiratory Rate'
		| 'SpO2';
}

const { Text } = Typography;

export default function VitalSignTypeCard(props: Props) {
	const { pageType, data, typeTitle, birthdate } = props;
	const [age, setAge] = useState<any>({});

	const convertFromAgeDate = (
		birthdate: Date,
		currentDate: Date | undefined,
	) => {
		const fromDate = moment(currentDate);
		const inputDate = moment(birthdate);

		const years = fromDate.diff(inputDate, 'years');
		inputDate.add(years, 'years');

		const months = fromDate.diff(inputDate, 'months');
		inputDate.add(months, 'months');

		const days = fromDate.diff(inputDate, 'days');

		return { year: years, month: months, days: days };
	};

	const calculateDaysDifference = (
		birthdate: Date,
		currentDate: Date | undefined,
	) => {
		if (!currentDate) {
			throw new Error('currentDate is undefined');
		}

		const fromDate = moment(currentDate);
		const inputDate = moment(birthdate);

		const daysDifference = fromDate.diff(inputDate, 'days');

		return daysDifference;
	};

	function getLatestHistory(histories: any) {
		if (!Array.isArray(histories) || histories.length === 0) {
			return null;
		}

		return histories.reduce((latest, current) =>
			new Date(current.createdAt) > new Date(latest.createdAt)
				? current
				: latest,
		);
	}

	// function getOldestHistory(histories: any) {
	// 	if (!Array.isArray(histories) || histories.length === 0) {
	// 		return null;
	// 	}

	// 	return histories.reduce((oldest, current) => {
	// 		return new Date(current.date) < new Date(oldest.date) ? current : oldest;
	// 	});
	// }

	// const getVitalSignFromDate = () => {
	// 	if (typeTitle !== 'Blood Pressure') {
	// 		const oldestHistory = getOldestHistory(data?.histories);
	// 		return oldestHistory?.date || null;
	// 	} else {
	// 		const oldestHistory =
	// 			getOldestHistory(data?.systolic?.histories) ||
	// 			getOldestHistory(data?.diastolic?.histories);
	// 		return oldestHistory?.date || null;
	// 	}
	// };

	const getVitalSignCurrentDate = () => {
		if (typeTitle === 'Blood Pressure') {
			return (
				data?.diastolic?.currentDate || data?.systolic?.currentDate || null
			);
		} else {
			return data?.currentDate || null;
		}
	};

	useEffect(() => {
		if (pageType === 'detail-emr') {
			props.setDate(getVitalSignCurrentDate());
		} else if (
			pageType === 'detail-patient' &&
			typeTitle === 'Pedia Growth Chart'
		) {
			props.setDate(getVitalSignCurrentDate());
		}
	}, [data, pageType, typeTitle]);

	return (
		<Card
			style={{ minHeight: 100, borderRadius: 12, marginTop: 20 }}
			bodyStyle={{ padding: 16 }}
		>
			<VitalSignTypeHeader
				typeTitle={typeTitle}
				pageType={pageType}
				nextStep={props.nextStep || (() => {})}
			/>
			<VitalSignTypeDate>
				{pageType === 'detail-emr' ? (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '5px',
						}}
					>
						<Calendar width={12} height={12} color={theme.colors.charcoal300} />
						<Text className="text-3 text-charcoal-300">
							{props.date
								? moment(props.date).format('D MMM YYYY HH:mm')
								: 'No Date Available'}
						</Text>
					</div>
				) : (
					<>
						{typeTitle === 'Pedia Growth Chart' ? (
							<>
								<Calendar width={12} height={12} style={{ color: '#748494' }} />
								<Text style={DateStyle}>
									{props.date
										? moment(props.date).format('D MMM YYYY')
										: 'No Date Available'}
								</Text>
								{/* <ChevronDown width={12} style={{ color: '#748494' }} /> */}
							</>
						) : (
							<FilterRangeDate
								valueFrom={props.fromDate}
								valueTo={props.toDate}
								onChangeFrom={(val) => props.setFromDate(val)}
								onChangeTo={(val) => props.setToDate(val)}
							/>
						)}
					</>
				)}
			</VitalSignTypeDate>
			{pageType === 'detail-patient' ? (
				<PatientDetailVitalSignSummary
					data={data}
					typeTitle={typeTitle}
					age={age}
					setAge={setAge}
					convertFromAgeDate={convertFromAgeDate}
					calculateDaysDifference={calculateDaysDifference}
					birthdate={birthdate}
				/>
			) : (
				<EmrDetailVitalSignSummary
					data={data}
					typeTitle={typeTitle}
					age={age}
					setAge={setAge}
					convertFromAgeDate={convertFromAgeDate}
					calculateDaysDifference={calculateDaysDifference}
					birthdate={birthdate}
				/>
			)}
		</Card>
	);
}

const VitalSignTypeDate = styled.div`
	display: flex;
	gap: 3px;
	align-items: center;
`;

const DateStyle: React.CSSProperties = {
	fontSize: 12,
	color: '#748494',
};
