import { SetStateAction, useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import Separator from '../../../Separator';
import Steps from '../../../Steps';
import { getName } from '../../../../helpers/name';
import { RxPrescriptionProperties } from '../../../../types/rxPrescription.type';
import moment from 'moment';
import { ICDProperties } from '../../../../types/icd.type';
import ModalInfoPatient from '../../RxAndLabRequest/CreateRx/Components/Modal/ModalInfoPatient';
import FormCreateEmr from './FormCreateEmr';
import FormEmr from './FormEmr';
import FormCreatePatientHistory from './Components/FormPatientHistory';
import FormCreateObjectiveFindings from './Components/FormObjectiveFindings';
import FormCreateDiagnosis from './Components/FormDiagnois';
import { LabRequestProperties } from '../../../../types/labRequest.type';
import FormCreatePlan from './Components/FormPlan';
import FormCreateBilling from './FormCreateBilling';
import FormCreateRxPre from './Components/FormPrescription';
import FormCreateLabRequest from './Components/FormLabReq';
import FormCreateClearance from './Components/FormClearance';
import FormCreateMedicalCertificate from './Components/FormMedicalCertificate';
import FormCreateFitToWork from './Components/FormFitToWork';
import FormCreateOwnTemplate from './Components/FormOwnTemplate';
import FormCreateHPI from './Components/FormHPI';
import FormCreateReferralNotes from './Components/FormReferralNotes';
import { EmrProperties } from '../../../../types/emr.type';
import FormCreateVitalSign from './Components/VitalSign';
import { TransactionEMRProperties } from '../../../../types/transactionEmr.type';
import ModalConfirmation, {
	EModalConfirmationType,
} from './Components/ModalConfirmation';
import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { apiFindUserByPhone } from '../../../../api/auth';
import { theme } from '../../../../assets/theme';
import MonitoringLabResultsTrend from '../DetailEmr/ObjecttiveFindings/MonitoringLabResultsTrend';
import DocumentPreview from './Components/DocumentPreview';

const steps = ['Patient Info', 'Fill up EMR', 'Billing'];

export default function MainCreateEmr() {
	const history = useHistory();
	let { search } = useLocation();
	const [title, setTitle] = useState('Create EMR');
	const [step, setStep] = useState(0);
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [isChoosedByPatientRecords, setIsChoosedByPatientRecords] =
		useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isEditingPhone, setIsEditingPhone] = useState(false);
	const [emr, setEmr] = useState<EmrProperties>({
		patientId: '',
	});
	const [emrId, setEmrId] = useState<string | undefined>(undefined);
	const [billing, setBilling] = useState<TransactionEMRProperties>();
	const [emrHistory, setEmrHistory] = useState<EmrProperties>();
	const [icds, setIcds] = useState<ICDProperties[]>();
	const [patientProfile, setPatientProfile] = useState<{
		[key: string]: any;
	}>();
	const [isModalInfoPatientVisible, setModalInfoPatientVisible] =
		useState(false);
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);
	const [foundPatient, setFoundPatient] = useState<any>();
	const [foundRelatives, setFoundRelatives] = useState<any[]>([]);
	const [searchPhone, setSearchPhone] = useState<any>();
	const [isPhoneDisable, setIsPhoneDisable] = useState(false);
	const [formName, setFormName] = useState<string>('EMR');
	const [prescription, setPrescription] = useState<RxPrescriptionProperties>();
	const [labRequest, setLabRequest] = useState<LabRequestProperties>();
	const [doctorDocumentId, setDoctorDocumentId] = useState<any>(undefined);
	const [rxPrescriptionId, setRxPrescriptionId] = useState<string | undefined>(
		undefined,
	);
	const [labRequestId, setLabRequestId] = useState<string | undefined>(
		undefined,
	);

	const handleMenuClick = (info: { key: string }) => {
		if (info.key === 'view-history') {
			history.push({
				pathname: '/app/emr/patient',
				state: {
					step: 1,
					patientId: patientProfile?.patientId,
					isFromCreateEmr: true,
				},
			});
		}
	};

	const items = [{ label: 'View History', key: 'view-history' }];

	useEffect(() => {
		setSearchPhone(patientProfile?.phone);
	}, [patientProfile?.phone]);

	useEffect(() => {
		if (
			searchPhone &&
			searchPhone.length >= 9 &&
			!isEditingPhone &&
			!isPhoneDisable
		) {
			const timeout = setTimeout(() => {
				apiFindUserByPhone('+63' + searchPhone.trim())
					.then((res) => {
						const foundData = {
							patientId: res.userId,
							patientName: getName(res),
							birthdate: res.birthdate,
							gender: res.gender,
							address: res.userAddress?.addressDetail,
							maritalStatus: res.maritalStatus,
						};

						if (!isChoosedByPatientRecords) {
							if (res.patient?.relatives && res.patient.relatives.length > 0) {
								const relativesData: any[] = [];
								res.patient.relatives.map((item: any) => {
									relativesData.push({
										patientId: item.patientInRelationId,
										patientInRelationId: item.patientInRelationId,
										patientName: getName(item),
										birthdate: item.birthdate,
										gender: item.gender,
										maritalStatus: item.maritalStatus,
										address: res.userAddress?.addressDetail,
									});
								});
								setFoundRelatives(relativesData);
								setFoundPatient(foundData);
								setModalInfoPatientVisible(true);

								setPatientProfile({
									...patientProfile,
									...foundData,
									phone: searchPhone,
								});
							} else {
								setPatientProfile({
									...patientProfile,
									...foundData,
									patientId: res.userId,
									phone: searchPhone,
								});
							}
						} else {
							if (res.userId === patientProfile?.patientId) {
								setPatientProfile({
									...patientProfile,
									...foundData,
									patientId: res.userId,
									phone: searchPhone,
								});
							} else {
								const relativeMatch = res.patient?.relatives.find(
									(relative: any) =>
										relative.patientInRelationId === patientProfile?.patientId,
								);

								if (relativeMatch) {
									setPatientProfile({
										...patientProfile,
										...foundData,
										patientId: patientProfile?.patientId,
										phone: searchPhone,
										patientName: getName(relativeMatch),
										birthdate: relativeMatch.birthdate,
										gender: relativeMatch.gender,
										maritalStatus: relativeMatch.maritalStatus,
									});
								}
							}
						}

						setIsPhoneDisable(true);
					})
					.catch((err) => {
						setPatientProfile((prev) => ({
							...prev,
							patientId: undefined,
							phone: prev?.phone,
						}));
					});
			}, 1000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [searchPhone]);

	useEffect(() => {
		if (!isEditing) return;

		let _title = title;
		if (_title.includes('Create')) {
			_title = _title.replace('Create', 'Edit');
		}
		setTitle(_title);
	}, [isEditing]);

	function setNextStep(newStep: number) {
		const _step = newStep || step + 1;
		setStep(_step);
	}

	useEffect(() => {
		if (formName === 'Plan') {
			setTitle('Plan');
		} else if (formName === 'DocumentPreview') {
			setTitle('Document Overview');
		} else if (formName === 'HPI') {
			setTitle('HPI');
		} else if (formName === 'PatientHistory') {
			setTitle('Patient History');
		} else if (formName === 'ObjectiveFinding') {
			setTitle('Objective Findings');
		} else if (formName === 'MonitoringLabResults') {
			setTitle('Monitoring Lab Results History');
		} else if (formName === 'Diagnosis') {
			setTitle('Diagnosis');
		} else if (formName === 'VitalSigns') {
			setTitle('Vital Signs');
		} else if (step === 2) {
			setTitle('Billing');
		} else if (formName === 'EMR' && step === 0) {
			setTitle(`${isEditing ? 'Edit' : 'Create'} EMR`);
		} else if (formName === 'EMR' && step === 1) {
			setTitle(`${isEditing ? 'Edit' : 'Fill Up'} EMR`);
		} else if (formName === 'Prescription') {
			setTitle('Prescription');
		} else if (formName === 'LabRequest') {
			setTitle('Lab request');
		} else if (formName === 'MedicalCertificate') {
			setTitle('Medical Certificate');
		} else if (formName === 'Clearance') {
			setTitle('Clearance');
		} else if (formName === 'FitToWork') {
			setTitle('Fit to Work');
		} else if (formName === 'OwnTemplate') {
			setTitle('Own Template');
		} else if (formName === 'Referral') {
			setTitle('Referral Notes');
		}
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [step, formName]);

	function handleConfirmCancelCreateEmr() {
		if (!isEditing) {
			history.replace('/app/emr');
		} else {
			history.goBack();
		}
		setIsModalConfirmationVisible(false);
	}

	useEffect(() => {
		console.log('emr =>', emr);
	}, [emr]);

	function onBack() {
		const _step = step - 1;
		setFormName('EMR');
		if (
			formName === 'HPI' ||
			formName === 'Diagnosis' ||
			formName === 'PatientHistory' ||
			formName === 'Plan' ||
			formName === 'ObjectiveFinding' ||
			formName === 'VitalSigns'
		) {
			setStep(1);
			setTitle(`${isEditing ? 'Edit' : 'Fill up'} EMR`);
		} else if (step === 0) {
			setIsModalConfirmationVisible(true);
		} else if (
			formName === 'Prescription' ||
			formName === 'LabRequest' ||
			formName === 'MedicalCertificate' ||
			formName === 'Clearance' ||
			formName === 'FitToWork' ||
			formName === 'OwnTemplate' ||
			formName === 'Referral' ||
			formName === 'DocumentPreview'
		) {
			setFormName('Plan');
			setTitle('Plan');
		} else if (formName === 'MonitoringLabResults') {
			setFormName('ObjectiveFinding');
			setTitle('Objective Findings');
		} else if (step === 1) {
			setStep(0);
			setTitle(`${isEditing ? 'Edit' : 'Create'} EMR`);
		} else if (step === 2) {
			setStep(1);
			setTitle(`${isEditing ? 'Edit' : 'Fill up'} EMR`);
		}
	}

	return (
		<div>
			<div className="relative px-6 py-5.5 flex justify-between items-center">
				{!isConfirmed && (
					<button
						onClick={onBack}
						className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
					>
						<ArrowLeft width={20} height={20} />
						<p className="m-0 font-bold text-3.5">Back</p>
					</button>
				)}
				<h1 className="font-semibold text-6 m-0 w-full text-center">{title}</h1>

				<Dropdown
					menu={{ items, onClick: handleMenuClick }}
					trigger={['click']}
					disabled={!patientProfile?.patientId}
				>
					<Button
						icon={<MoreOutlined />}
						className="w-auto"
						style={
							!patientProfile?.patientId
								? {
										color: `${theme.colors.charcoal200}`,
										backgroundColor: 'white',
										cursor: 'default',
										pointerEvents: 'none',
										border: 'none',
										boxShadow: 'none',
								  }
								: {
										color: 'black',
										backgroundColor: 'white',
										border: 'none',
										boxShadow: 'none',
								  }
						}
					/>
				</Dropdown>
			</div>
			{!isConfirmed && formName === 'EMR' && (
				<>
					<Separator style={{ height: 2 }} />
					<div className="px-13 py-3.5">
						<Steps steps={steps} activeStepIndex={step} />
					</div>
				</>
			)}
			<Separator />
			{step === 0 ? (
				<FormCreateEmr
					emr={emr}
					setEmr={setEmr}
					step={step}
					setNextStep={() => setNextStep(1)}
					setIsEditing={() => setIsEditing(true)}
					isEditing={isEditing}
					setIsEditingPhone={() => setIsEditingPhone(true)}
					searchPhone={searchPhone}
					setSearchPhone={setSearchPhone}
					patientProfile={patientProfile}
					setPatientProfile={setPatientProfile}
					isPhoneDisable={isPhoneDisable}
					setIsPhoneDisable={setIsPhoneDisable}
					setIsChoosedByPatientRecords={() =>
						setIsChoosedByPatientRecords(true)
					}
				/>
			) : formName === 'HPI' ? (
				<FormCreateHPI
					emr={emr}
					setEmr={setEmr}
					goBack={() => onBack()}
					setNextStep={() => setNextStep(1)}
					setFormName={() => setFormName('EMR')}
					step={step}
					patientHpi={patientProfile}
					setPatientHpi={setPatientProfile}
				/>
			) : formName === 'PatientHistory' ? (
				<FormCreatePatientHistory
					emr={emr}
					setEmr={setEmr}
					step={step}
					setNextStep={() => setNextStep(1)}
					setFormName={() => setFormName('EMR')}
					patientMedicalHistory={patientProfile}
					setPatientMedicalHistory={setPatientProfile}
				/>
			) : formName === 'ObjectiveFinding' ? (
				<FormCreateObjectiveFindings
					emr={emr}
					setEmr={setEmr}
					step={step}
					setNextStep={() => setNextStep(1)}
					goBack={() => onBack()}
					setFormName={setFormName}
					patientObjectiveFinding={patientProfile}
					setPatientObjectiveFinding={setPatientProfile}
				/>
			) : formName === 'MonitoringLabResults' ? (
				<MonitoringLabResultsTrend patientId={emr.patientId} />
			) : formName === 'Diagnosis' ? (
				<FormCreateDiagnosis
					emr={emr}
					setEmr={setEmr}
					setNextStep={() => setNextStep(1)}
					setFormName={() => setFormName('EMR')}
					icds={icds}
					setIcds={setIcds}
					goBack={() => onBack()}
					patientDiagnosis={patientProfile}
					setPatientDiagnosis={setPatientProfile}
				/>
			) : formName === 'Plan' ? (
				<FormCreatePlan
					emr={emr}
					setEmr={setEmr}
					step={step}
					setNextStep={() => setNextStep(1)}
					setFormName={setFormName}
					patientPlan={patientProfile}
					setPatientPlan={setPatientProfile}
					labRequest={labRequest}
					prescription={prescription}
					doctorDocumentId={doctorDocumentId}
					handleRxClick={() => setFormName('Prescription')}
					handleLabReqClick={() => setFormName('LabRequest')}
					handleMedicalCertificateClick={() =>
						setFormName('MedicalCertificate')
					}
					handleClearanceClick={() => setFormName('Clearance')}
					handleFitToWorkClick={() => setFormName('FitToWork')}
					handleOwnTemplateClick={() => setFormName('OwnTemplate')}
					handleReferralNotesClick={() => setFormName('Referral')}
					rxPrescriptionId={rxPrescriptionId}
					labRequestId={labRequestId}
				/>
			) : formName === 'VitalSigns' ? (
				<FormCreateVitalSign
					emr={emr}
					setEmr={setEmr}
					step={step}
					setNextStep={() => setNextStep(1)}
					setFormName={() => setFormName('EMR')}
					patientVitalSign={patientProfile}
					setPatientVitalSign={setPatientProfile}
				/>
			) : formName === 'Prescription' ? (
				<FormCreateRxPre
					prescription={prescription}
					emr={emr}
					setEmr={setEmr}
					setPrescription={setPrescription}
					setPatientRx={setPatientProfile}
					patientRx={patientProfile}
					setNextStep={() => setNextStep(1)}
					setFormName={() => setFormName('Plan')}
					setRxPrescriptionId={setRxPrescriptionId}
				/>
			) : formName === 'LabRequest' ? (
				<FormCreateLabRequest
					step={step}
					emr={emr}
					setEmr={setEmr}
					labRequest={labRequest}
					setLabRequest={setLabRequest}
					patientLabReq={patientProfile}
					setPatientLabReq={setPatientProfile}
					setNextStep={() => setNextStep(1)}
					setFormName={() => setFormName('Plan')}
					setLabRequestId={setLabRequestId}
				/>
			) : formName === 'MedicalCertificate' ? (
				<FormCreateMedicalCertificate
					step={step}
					emr={emr}
					setEmr={setEmr}
					setNextStep={() => setNextStep(1)}
					setFormName={() => setFormName('Plan')}
				/>
			) : formName === 'Clearance' ? (
				<FormCreateClearance
					emr={emr}
					setEmr={setEmr}
					step={step}
					setNextStep={() => setNextStep(1)}
					setFormName={() => setFormName('Plan')}
				/>
			) : formName === 'FitToWork' ? (
				<FormCreateFitToWork
					emr={emr}
					setEmr={setEmr}
					step={step}
					setNextStep={() => setNextStep(1)}
					setFormName={() => setFormName('Plan')}
				/>
			) : formName === 'OwnTemplate' ? (
				<FormCreateOwnTemplate
					emr={emr}
					setEmr={setEmr}
					onBack={onBack}
					setNextStep={() => setNextStep(1)}
					setFormName={() => setFormName('Plan')}
				/>
			) : formName === 'Referral' ? (
				<FormCreateReferralNotes
					emr={emr}
					setEmr={setEmr}
					onBack={onBack}
					setNextStep={() => setNextStep(1)}
					setFormName={() => setFormName('Plan')}
				/>
			) : formName === 'DocumentPreview' ? (
				<DocumentPreview emrId={emr.emrId} />
			) : step === 1 && formName === 'EMR' ? (
				<FormEmr
					emr={emr}
					searchPhone={searchPhone}
					setEmr={setEmr}
					setFormName={setFormName}
					patientProfile={patientProfile}
					setPatientProfile={setPatientProfile}
					step={step}
					setNextStep={() => setNextStep(2)}
					handleVitalSignClick={() => {
						setFormName('VitalSigns');
					}}
					handleHPIClick={() => {
						setFormName('HPI');
					}}
					handlePatientHistoryClick={() => {
						setFormName('PatientHistory');
					}}
					handleObjectiveFindingsClick={() => {
						setFormName('ObjectiveFinding');
					}}
					handleDiagnosisClick={() => {
						setFormName('Diagnosis');
					}}
					handlePlanClick={() => {
						setFormName('Plan');
					}}
					emrId={emrId}
				/>
			) : step === 2 ? (
				<FormCreateBilling
					billing={billing}
					setBilling={setBilling}
					step={step}
					setNextStep={() => setNextStep(2)}
					isEditing={isEditing}
					patientBilling={patientProfile}
					setPatientBilling={setPatientProfile}
					emr={emr}
				/>
			) : undefined}

			<ModalInfoPatient
				isVisible={isModalInfoPatientVisible}
				setVisibility={setModalInfoPatientVisible}
				dataPatient={foundPatient}
				dataRelatives={foundRelatives}
				actionHandler={(data) => {
					setPatientProfile({ ...patientProfile, ...data });
					setIsPhoneDisable(true);
					setModalInfoPatientVisible(false);
				}}
			/>

			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={handleConfirmCancelCreateEmr}
				title={`${isEditing ? 'Edit' : 'Create'} EMR`}
				type={EModalConfirmationType.CANCEL}
			/>
		</div>
	);
}
