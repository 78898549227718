import { useEffect, useState } from 'react';
import { Plus, Trash2, Edit } from 'react-feather';
import {
	apiCreateRxPrescription,
	apiGetRxPrescription,
	apiUpdateRxPrescription,
} from '../../../../../api/rxPrescription';
import COLORS from '../../../../../assets/globalColors';
import { ReactComponent as Medicines } from '../../../../../assets/icons/medicines.svg';
import { theme } from '../../../../../assets/theme';
import { useAuth } from '../../../../../context/auth.context';
import { RxPrescriptionProperties } from '../../../../../types/rxPrescription.type';
import { Button } from '../../../../Button/Button/Button';
import EmptyList from '../../../../EmptyList';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import ModalEditMedicine from '../../../RxAndLabRequest/CreateRx/Components/Modal/ModalEditMedicine';
import Separator from '../../../../Separator';
import ModalAddMedicine from '../../../RxAndLabRequest/CreateRx/Components/Modal/ModalAddMedicine';
import { ReactComponent as Logo } from '../../../../../assets/icons/logo.svg';
import IconButton from '../../../../Button/IconButton';
import { EditDrugMedicineProperties } from '../../../../../types/drugMedicine';
import { Checkbox, message } from 'antd';
import { apiCreateTemplateRxPrescription } from '../../../../../api/templateRxPrescription';
import { TemplateRxPrescriptionProperties } from '../../../../../types/templateRxPrescription.type';
import ModalConfirmation, { EModalConfirmationType } from './ModalConfirmation';
import { EmrProperties } from '../../../../../types/emr.type';

const forms = [
	{
		label: 'Valid Until',
		name: 'validUntil',
		type: EFormType.DATE_PICKER,
		isRequired: true,
	},
];

interface Props {
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	prescription?: RxPrescriptionProperties;
	setPrescription: React.Dispatch<
		React.SetStateAction<RxPrescriptionProperties | undefined>
	>;
	setPatientRx: React.Dispatch<React.SetStateAction<any>>;
	patientRx: any;
	setNextStep: (args: { rxPrescriptionId?: string }) => void;
	setFormName: () => void;
	setRxPrescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function FormCreateRxPre(props: Props) {
	const { user } = useAuth();
	const [showModalAddMedicine, setShowModalAddMedicine] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showModalEditMedicine, setShowModalEditMedicine] = useState(false);
	const [isTemplateChecked, setIsTemplateChecked] = useState(false);
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);
	const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
	const [itemToDeleteIndex, setItemToDeleteIndex] = useState<number | null>(
		null,
	);
	const [selectedMedicine, setSelectedMedicine] =
		useState<EditDrugMedicineProperties>();
	const todayAfter3Month = new Date();
	todayAfter3Month.setMonth(new Date().getMonth() + 3);

	useEffect(() => {
		async function fetchPrescription() {
			if (props.emr.prescription?.rxPrescriptionId) {
				try {
					setIsLoading(true);
					const fetchedPrescription = await apiGetRxPrescription(
						props.emr.prescription.rxPrescriptionId,
					);

					if (fetchedPrescription) {
						props.setPrescription(fetchedPrescription);
						props.setPatientRx((old: any) => ({
							...old,
							prescription: fetchedPrescription,
							rxPrescriptionId: fetchedPrescription.rxPrescriptionId,
						}));
					}
				} catch (error) {
					console.error('Failed to fetch prescription:', error);
				} finally {
					setIsLoading(false);
				}
			}
		}

		fetchPrescription();

		if (!props.emr.prescription) {
			props.setPrescription((old) => ({
				...old,
				validUntil: todayAfter3Month.toString(),
			}));
		}
	}, [props.emr.prescription]);

	useEffect(() => {
		if (selectedMedicine) {
			setShowModalEditMedicine(true);
		}
	}, [selectedMedicine]);

	const handleTemplateCheckboxChange = (e: any) => {
		setIsTemplateChecked(e.target.checked);
		if (e.target.checked) {
			props.setPrescription({
				...props.prescription,
				saveAsTemplate: true,
			});
		} else {
			props.setPrescription({
				...props.prescription,
				saveAsTemplate: false,
				templateName: '',
			});
		}
	};

	const savePrescriptionTemplate = async (rxPrescriptionId?: string) => {
		let data: TemplateRxPrescriptionProperties = {
			doctorId: user?.userId || '',
			templateName: props.prescription?.templateName,
			saveFromId: rxPrescriptionId,
			templatePrescriptionItems: props.prescription?.prescriptionItems || [],
		};

		try {
			await apiCreateTemplateRxPrescription(data);
		} catch (e) {
			console.log(e);
		}
	};

	async function savePrescription() {
		setIsLoading(true);

		const { emr, setEmr, setPrescription } = props;
		let isSuccess = false;
		let rxPrescriptionId: string | undefined;

		let data: RxPrescriptionProperties = {
			...props.prescription,
			isTemporary: true,
			patientId: emr.patientId,
			doctorId: user?.userId,
			emrId: emr.emrId,
			metaPatient: emr.patient || emr.patientInRelation || {},
			prescriptionItems: props.prescription?.prescriptionItems?.map((item) => ({
				...item,
				drugMedicineId: item?.drugMedicineDetail?.drugMedicineId || '',
				drugMedicineVariantId: item?.drugMedicineVariantId || '',
			})),
		};
		delete data?.saveAsTemplate;
		delete data?.templateName;

		console.log('data =>', data);

		if (props.prescription?.rxPrescriptionId) {
			const res = await apiUpdateRxPrescription(
				props.prescription.rxPrescriptionId,
				data,
			);
			if (res) {
				setEmr({
					...emr,
					prescription: res,
				});

				props.setPrescription(res);

				props.setRxPrescriptionId(res.rxPrescriptionId);

				if (props.prescription?.saveAsTemplate) {
					savePrescriptionTemplate(props.prescription.rxPrescriptionId);
				}

				if (res.rxPrescriptionId) {
					props.setPatientRx({
						...props.patientRx,
						rxPrescriptionId: res.rxPrescriptionId,
					});
					rxPrescriptionId = res.rxPrescriptionId;
				}

				isSuccess = true;
			}
		} else {
			const res = await apiCreateRxPrescription(data);
			if (res) {
				setEmr({
					...emr,
					prescription: res,
				});
				props.setPrescription(res);
				if (props.prescription?.saveAsTemplate) {
					savePrescriptionTemplate(res.rxPrescriptionId);
				}

				if (res.rxPrescriptionId) {
					props.setPatientRx({
						...props.patientRx,
						rxPrescriptionId: res.rxPrescriptionId,
					});
					rxPrescriptionId = res.rxPrescriptionId;
				}

				isSuccess = true;
			}
		}

		if (isSuccess) {
			props.setNextStep({ rxPrescriptionId });
			props.setFormName();
			message.success('Rx Prescription Saved Successfully');
		}

		setIsLoading(false);
	}

	function deleteItem(index: number | null) {
		if (index !== null) {
			const newPrescription = [...props.prescription!.prescriptionItems!];
			newPrescription.splice(index, 1);
			props.setPrescription((old) => ({
				...old,
				prescriptionItems: newPrescription,
			}));
		}
	}

	function showDeleteConfirmation(index: number) {
		setItemToDeleteIndex(index);
		setIsModalDeleteVisible(true);
	}

	const clearFormHandler = () => {
		props.setPrescription((old) => ({
			...old,
			prescriptionItems: [],
		}));
		setIsModalConfirmationVisible(false);
	};

	function editItem(drugMedicineProps: EditDrugMedicineProperties) {
		setSelectedMedicine(drugMedicineProps);
	}
	return (
		<>
			<form>
				<div className="py-6 px-3">
					<div className="flex gap-x-3">
						<div className="pt-2 w-1/4">
							<p className="m-0 font-semibold text-4">Prescription </p>
						</div>
						<div className="flex-1">
							{forms.map((form) => (
								<FormInput
									key={form.label}
									label={form.label}
									name={form.name}
									type={form.type}
									required={form.isRequired}
									value={props.prescription?.validUntil}
									onChange={(val) => {
										props.setPrescription((old) => ({
											...old,
											validUntil: val,
										}));
									}}
									disabledDate={(d: any) => !d || d.isBefore(new Date())}
								/>
							))}
						</div>
					</div>
					<div className="py-4">
						<Separator />
					</div>
					<div className="flex gap-x-3">
						<div className="w-1/4"></div>
						<div className="flex-1">
							<div className="flex justify-between">
								<p className="m-0 text-4 text-charcoal-300">
									Medicines <span style={{ color: COLORS.red }}>*</span>
								</p>
								<button
									onClick={() => setShowModalAddMedicine(true)}
									type="button"
									className="border-none bg-transparent p-0 flex items-center gap-x-1 text-primary text-4 cursor-pointer"
								>
									<Plus width={24} height={24} />
									Add Medicine
								</button>
							</div>
							<div className="mt-2">
								{!!props.prescription?.prescriptionItems &&
								props.prescription.prescriptionItems.length > 0 ? (
									props.prescription.prescriptionItems.map((item, index) => (
										<div key={item.drugMedicineDetail?.drugMedicineId}>
											<div className="py-3">
												<PrescriptionItem
													name={item.drugMedicineDetail.drugMedicineName}
													dosage={item.drugMedicineDetail.dose}
													qty={item.quantity}
													sig={item.sig}
													onEditItem={() => {
														editItem({
															...item.drugMedicineDetail,
															sig: item.sig,
															quantity: item.quantity,
														});
													}}
													onDeleteItem={() => showDeleteConfirmation(index)}
													doesHavePoint={
														!!item.drugMedicineDetail.points &&
														!!item.drugMedicineDetail.partner
													}
												/>
											</div>
											{index <
												props.prescription!.prescriptionItems!.length - 1 && (
												<Separator />
											)}
										</div>
									))
								) : (
									<EmptyList
										icon={<Medicines color={theme.colors.black50} />}
										title="Prescription empty"
										description="You can add prescriptions from template or add medication manually"
									/>
								)}
							</div>
						</div>
					</div>
				</div>
				<Separator />
				<div className="py-3 px-4 flex flex-col">
					<div className="flex items-center mb-2">
						<Checkbox
							checked={isTemplateChecked}
							onChange={handleTemplateCheckboxChange}
							className="mr-2"
						/>
						<p className="m-0">Save this perscription as template</p>
					</div>
					{props.prescription?.saveAsTemplate ? (
						<div className="mb-3">
							<FormInput
								label="Template name"
								name="templateName"
								type={EFormType.SINGLE_LINE}
								placeholder="Write here"
								value={props.prescription?.templateName}
								onChange={(val) => {
									props.setPrescription({
										...props.prescription,
										templateName: val,
									});
								}}
							/>
						</div>
					) : undefined}
					<div className="flex justify-between w-full">
						<div className="ml-0">
							<Button
								type="SOLIDASH"
								onClick={() => setIsModalConfirmationVisible(true)}
							>
								Clear Form
							</Button>
						</div>
						<div className="mr-0">
							<Button
								type="SOLID"
								onClick={savePrescription}
								isLoading={isLoading}
								isDisable={
									!props.prescription?.validUntil ||
									!props.prescription?.prescriptionItems?.length
								}
							>
								Save prescription
							</Button>
						</div>
					</div>
				</div>
			</form>
			<ModalAddMedicine
				isShow={showModalAddMedicine}
				onCloseModal={() => setShowModalAddMedicine(false)}
				setPrescription={props.setPrescription}
			/>
			<ModalEditMedicine
				medicineProps={selectedMedicine}
				isShow={showModalEditMedicine}
				onCloseModal={() => setShowModalEditMedicine(false)}
				setPrescription={props.setPrescription}
			/>

			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={clearFormHandler}
				type={EModalConfirmationType.CLEAR_FORM}
			/>

			<ModalConfirmation
				isVisible={isModalDeleteVisible}
				setVisibility={setIsModalDeleteVisible}
				actionHandler={() => {
					deleteItem(itemToDeleteIndex!);
					setIsModalDeleteVisible(false);
					setItemToDeleteIndex(null);
				}}
				type={EModalConfirmationType.REMOVE_ITEM}
				title="Medicine Item"
			/>
		</>
	);
}
function PrescriptionItem(props: {
	name?: string;
	dosage?: string;
	qty: number;
	sig?: string;
	onEditItem: () => void;
	onDeleteItem: () => void;
	doesHavePoint?: boolean;
}) {
	return (
		<div className="flex gap-x-2 items-center">
			<div className="flex-1">
				<div className="flex gap-x-1 items-center">
					<p className="m-0 text-4.5 font-medium">
						{props.name}{' '}
						<span>
							{props.doesHavePoint && <Logo width={20} height={20} />}
						</span>
					</p>
				</div>
				<div className="mt-2 flex gap-x-2.5">
					<p className="flex-1 m-0 text-3.5 text-black-70">{props.dosage}</p>
					<p className="flex-1 m-0 text-3.5 text-black-70">
						{props.qty ? `qty. ${props.qty}` : ''}
					</p>
				</div>
				<div>
					<p className="m-0 mt-2 text-3.5 text-black-70">{props.sig}</p>
				</div>
			</div>
			<IconButton onClick={props.onEditItem}>
				<Edit width={24} height={24} color={theme.colors.primary} />
			</IconButton>
			<IconButton onClick={props.onDeleteItem}>
				<Trash2 width={24} height={24} color={theme.colors.primary} />
			</IconButton>
		</div>
	);
}
