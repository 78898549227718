import { message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
	apiCreateDoctorDocument,
	apiGetDoctorDocument,
	apiUpdateDoctorDocument,
} from '../../../../../api/doctorDocument';
import { getName } from '../../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../../helpers/profile';
import {
	DoctorDocumentProperties,
	EMetaType,
	MetaClearence,
	MetaFitToWork,
	MetaMedicalCertificate,
	MetaOthers,
} from '../../../../../types/doctorDocument.type';
import { IUser } from '../../../../../types/user.type';
import { Button } from '../../../../Button/Button/Button';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import ModalInfoPatient from '../../../RxAndLabRequest/CreateRx/Components/Modal/ModalInfoPatient';
import { useAuth } from '../../../../../context/auth.context';
import { ICDProperties } from '../../../../../types/icd.type';
import ModalConfirmation, { EModalConfirmationType } from './ModalConfirmation';
import { EmrProperties } from '../../../../../types/emr.type';

const forms = [
	{
		formTitle: 'Fit To Work',
		type: 'DoctorDocument',
		forms: [
			{
				label: 'Valid Until',
				name: 'validUntil',
				type: EFormType.DATE_PICKER,
				prefix: '',
				isRequired: true,
			},
			{
				label: 'Comorbid Conditions',
				name: 'comorbidConditions',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.FIT_TO_WORK],
			},
			{
				label: 'Chief Complaint',
				name: 'chiefComplaint',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.FIT_TO_WORK],
			},
			{
				label: 'PE Findings',
				name: 'peFindings',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.FIT_TO_WORK],
			},
			{
				label: 'Test Results',
				name: 'testResult',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.FIT_TO_WORK],
			},
			{
				label: 'Physical Condition',
				name: 'physicalCondition',
				type: EFormType.ITEM_PICKER_H,
				showWhenType: [EMetaType.FIT_TO_WORK],
				items: ['FIT', 'UNFIT'],
			},
			{
				label: 'Recommendations',
				name: 'recommendations',
				type: EFormType.MULTI_LINE,
				maxLength: 100,
				maxLine: 6,
				showWhenType: [EMetaType.FIT_TO_WORK],
			},
		],
	},
];

interface Props {
	type?: EMetaType;
	step: number;
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	setNextStep: () => void;
	setFormName: () => void;
}

export default function FormCreateFitToWork(props: Props) {
	const { user } = useAuth();
	const [isLoading, setIsLoading] = useState(false);

	const [patientProfile, setPatientProfile] = useState<{
		[key: string]: any;
	}>();
	const [doctorDocument, setDoctorDocument] = useState<{
		[key: string]: any;
	}>();
	const [isModalInfoPatientVisible, setModalInfoPatientVisible] =
		useState(false);
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);
	const [foundPatient, setFoundPatient] = useState<any>();
	const [foundRelatives, setFoundRelatives] = useState<any[]>([]);
	const [searchPhone, setSearchPhone] = useState<string | undefined>('');
	const [isPhoneDisable, setIsPhoneDisable] = useState(false);
	const [showModalChangePhone, setShowModalChangePhone] = useState(false);
	const [showModalAddIcd, setShowModalAddIcd] = useState(false);
	const [icds, setIcds] = useState<ICDProperties[]>();
	const [isFormValid, setIsFormValid] = useState(false);
	const todayAfter3Month = new Date();
	todayAfter3Month.setMonth(new Date().getMonth() + 3);

	const validateForm = () => {
		// const hasOtherValues =
		// 	!!doctorDocument?.chiefComplaint ||
		// 	!!doctorDocument?.physicalCondition ||
		// 	!!doctorDocument?.recommendations ||
		// 	!!doctorDocument?.comorbidConditions ||
		// 	!!doctorDocument?.peFindings ||
		// 	!!doctorDocument?.testResult ||
		// 	!!doctorDocument?.recommendations;

		// const isValid = !!doctorDocument?.validUntil && hasOtherValues;
		const isValid = !!doctorDocument?.meta?.validUntil;

		setIsFormValid(isValid);
	};

	useEffect(() => {
		validateForm();
	}, [doctorDocument]);

	// useEffect(() => {
	// 	setDoctorDocument((old) => ({
	// 		...old,
	// 		validUntil: todayAfter3Month.toString(),
	// 	}));
	// }, []);

	useEffect(() => {
		setShowModalAddIcd(false);
	}, [icds]);

	useEffect(() => {
		(async () => {
			const fitToWork = props.emr.doctorDocuments?.find(
				(doc) => doc.type === EMetaType.FIT_TO_WORK,
			);

			if (!fitToWork) {
				setDoctorDocument((old) => ({
					...old,
					meta: {
						...old?.meta,
						validUntil: todayAfter3Month.toString(),
					},
				}));
				return;
			}

			setIsLoading(true);

			const res = await apiGetDoctorDocument(fitToWork.doctorDocumentId || '');
			if (res) {
				const metaPatient = res.metaPatient as IUser;
				setPatientProfile({
					patientName: getName(metaPatient),
					gender: getGender(metaPatient)?.toLowerCase(),
					age: moment().diff(getBirthdate(metaPatient), 'years', false),
					phone: metaPatient?.phone,
				});
				setSearchPhone(metaPatient?.phone);
				setDoctorDocument(res);
				setIcds(res.icds);
			}

			setIsLoading(false);
		})();
	}, [props.emr.doctorDocuments]);

	async function saveDoctorDocument() {
		setIsLoading(true);

		const { emr, setEmr } = props;

		const patientName = patientProfile?.patientName
			? patientProfile?.patientName.split(' ')
			: [];
		const birthdate = new Date();
		birthdate.setUTCFullYear(
			birthdate.getUTCFullYear() - (patientProfile?.age ?? 0),
		);

		let isSuccess = false;
		let data: DoctorDocumentProperties = {
			transactionConsultationId: doctorDocument?.transactionConsultationId,
			doctorId: user?.userId,
			type: EMetaType.FIT_TO_WORK,
			patientId: emr.patientId,
			// metaPatient: {
			// 	firstName: patientName[0],
			// 	middleName:
			// 		patientName.length > 2 ? patientName.slice(1, -1).join(' ') : '',
			// 	lastName:
			// 		patientName.length > 1 ? patientName[patientName.length - 1] : '',
			// 	birthdate,
			// 	gender: patientProfile?.gender?.toLowerCase(),
			// 	phone: searchPhone,
			// },
			metaPatient: emr.patient || emr.patientInRelation || {},
			validUntil: doctorDocument?.validUntil ?? new Date().toISOString(),
			price: doctorDocument?.price ?? 0,
			meta: doctorDocument?.meta,
			isTemporary: true,
			emrId: emr.emrId,
		};

		if (doctorDocument?.doctorDocumentId) {
			data = {
				...data,
				meta: doctorDocument?.meta as
					| MetaMedicalCertificate
					| MetaClearence
					| MetaFitToWork
					| MetaOthers,
				emrId: emr.emrId,
			};

			const res = await apiUpdateDoctorDocument(
				doctorDocument.doctorDocumentId,
				data,
			);
			if (res) {
				setEmr({
					...emr,
					doctorDocuments: (emr.doctorDocuments || []).map((doc) =>
						doc.doctorDocumentId === res.doctorDocumentId ? res : doc,
					),
				});
				setDoctorDocument(res);
				isSuccess = true;
			}
		} else {
			data = {
				...data,
				transactionConsultationId: doctorDocument?.transactionConsultationId,
				doctorId: user?.userId,
				type: EMetaType.FIT_TO_WORK,
				meta: doctorDocument?.meta as
					| MetaMedicalCertificate
					| MetaClearence
					| MetaFitToWork
					| MetaOthers,
				emrId: emr.emrId,
			};

			const res = await apiCreateDoctorDocument(data);
			if (res) {
				setEmr({
					...emr,
					doctorDocuments: [...(emr.doctorDocuments || []), res],
				});
				setDoctorDocument(res);
				isSuccess = true;
			}
		}

		if (isSuccess) {
			props.setNextStep();
			props.setFormName();
			message.success('Fit To Work Saved Sucessfully');
		}

		setIsLoading(false);
	}

	const defaultDoctorDocument = {
		validUntil: todayAfter3Month.toString(),
		comorbidConditions: '',
		chiefComplaint: '',
		peFindings: '',
		testResult: '',
		physicalCondition: '',
		recommendations: '',
	};

	const clearFormHandler = () => {
		setDoctorDocument((old) => ({
			...old,
			meta: defaultDoctorDocument,
		}));
		setIsModalConfirmationVisible(false);
	};

	return (
		<form>
			<div className="py-6 px-3">
				{forms.map((formsData, index) => (
					<div key={index}>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">
									{formsData.formTitle}
								</p>
							</div>
							<div className="flex-1">
								{formsData.forms.map((form) => {
									if (!!form?.showWhenType && props.type) {
										if (!form?.showWhenType.includes(props.type)) {
											return <></>;
										}
									}
									return (
										<FormInput
											key={form.label}
											label={form.label}
											type={form.type}
											items={form?.items}
											required={form.isRequired}
											withoutHorizontalPadding={!!form.items}
											withoutVerticalPadding={!!form.items}
											value={
												formsData.type === 'patientProfile'
													? form.name === 'address'
														? doctorDocument?.meta?.[form.name]
														: patientProfile?.[form.name]
													: doctorDocument?.meta?.[form.name] || ''
											}
											prefix={form?.prefix}
											onChange={(val) => {
												formsData.type === 'patientProfile'
													? form.name === 'address'
														? setDoctorDocument((old) => ({
																...old,
																meta: {
																	...old?.meta,
																	[form.name]: val,
																},
														  }))
														: setPatientProfile((old) => ({
																...old,
																[form.name]: val,
														  }))
													: setDoctorDocument((old) => ({
															...old,
															meta: {
																...old?.meta,
																[form.name]: val,
															},
													  }));
												form.name === 'phone' && setSearchPhone(val);
											}}
											disabledDate={(d: any) => !d || d.isBefore(new Date())}
											buttonRightLabel={
												form.name === 'phone' &&
												isPhoneDisable && (
													<p
														onClick={() => setShowModalChangePhone(true)}
														className="m-0 text-4 cursor-pointer text-primary"
													>
														Change Phone
													</p>
												)
											}
											disabled={form.name === 'phone' && isPhoneDisable}
										/>
									);
								})}
							</div>
						</div>
						{index < forms.length - 1 && (
							<div className="my-1">
								<Separator />
							</div>
						)}
					</div>
				))}
				<div className="h-6" />
			</div>
			<Separator />
			<div className="py-3 px-4 flex justify-between">
				<div className="w-44">
					<Button
						type="SOLIDASH"
						onClick={() => setIsModalConfirmationVisible(true)}
					>
						Clear Form
					</Button>
				</div>
				<div className="w-44">
					<Button
						type="SOLID"
						onClick={saveDoctorDocument}
						isLoading={isLoading}
						isDisable={!isFormValid || isLoading}
					>
						Save
					</Button>
				</div>
			</div>

			<ModalInfoPatient
				isVisible={isModalInfoPatientVisible}
				setVisibility={setModalInfoPatientVisible}
				dataPatient={foundPatient}
				dataRelatives={foundRelatives}
				actionHandler={(data) => {
					setPatientProfile({ ...patientProfile, ...data });
					setIsPhoneDisable(true);
					setModalInfoPatientVisible(false);
				}}
			/>

			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={clearFormHandler}
				type={EModalConfirmationType.CLEAR_FORM}
			/>
		</form>
	);
}
