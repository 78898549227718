import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import { Button } from '../../../../Button/Button/Button';
import {
	EConditionBloodSugar,
	VitalSignProperties,
} from '../../../../../types/vitalSign.type';
import { apiCreateVitalSign, apiGetEmr } from '../../../../../api/emr';
import { Select, message } from 'antd';
import { Plus, Trash2, Edit } from 'react-feather';
import { theme } from '../../../../../assets/theme';
import ModalConfirmation, { EModalConfirmationType } from './ModalConfirmation';
import { EmrProperties } from '../../../../../types/emr.type';
import { useHistory } from 'react-router-dom';
interface Props {
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	step: number;
	setNextStep: () => void;
	setFormName: () => void;
	patientVitalSign: any;
	setPatientVitalSign: React.Dispatch<React.SetStateAction<any>>;
}

export default function FormCreateVitalSign(props: Props) {
	const [isLoading, setIsLoading] = useState(false);
	const [isHistory, setIsHistory] = useState(false);
	const [pediaGrowthFields, setPediaGrowthFields] = useState(false);
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);
	const [initialPediaChart, setInitialPediaChart] = useState<any>({});
	const [isFormValid, setIsFormValid] = useState(false);
	const isMountedRef = useRef(true);
	const history = useHistory();

	useEffect(() => {
		return () => {
			isMountedRef.current = false;
		};
	}, []);

	const validateForm = () => {
		const { patientVitalSign } = props;
		const isValid =
			!!patientVitalSign.heightUnit &&
			!!patientVitalSign.height &&
			!!patientVitalSign.weight &&
			!!patientVitalSign.vitalSignHeartRate?.date &&
			!!patientVitalSign.vitalSignHeartRate?.heartRate &&
			!!patientVitalSign.vitalSignTemperature?.date &&
			!!patientVitalSign.vitalSignTemperature?.temperature &&
			!!patientVitalSign.vitalSignBloodPressure?.date &&
			!!patientVitalSign.vitalSignBloodPressure?.systolic &&
			!!patientVitalSign.vitalSignBloodPressure?.diastolic;

		setIsFormValid(isValid);
	};

	useEffect(() => {
		validateForm();
	}, [props.patientVitalSign]);

	useEffect(() => {
		if (pediaGrowthFields) {
			const birthdate = new Date(props.patientVitalSign?.birthdate || '');
			const { year, month, days } = convertFromAgeDate(birthdate, new Date());
			setInitialPediaChart(() => ({
				year: year,
				month: month,
				days: days,
			}));
			props.setPatientVitalSign((old: any) => ({
				...old,
				vitalSignPediaChart: {
					...old.vitalSignPediaChart,
					year: initialPediaChart.year,
					month: initialPediaChart.month,
					days: initialPediaChart.days,
				},
			}));
		}
	}, [pediaGrowthFields]);

	useEffect(() => {
		const birthdate = new Date(props.patientVitalSign?.birthdate || '');
		const { year, month, days } = convertFromAgeDate(birthdate, new Date());
		setInitialPediaChart(() => ({
			year: year,
			month: month,
			days: days,
		}));
	}, [props.patientVitalSign?.birthdate]);

	// Initial Value for Edit Emr
	useEffect(() => {
		if (!props.emr.vitalSignId) return;

		const updatedPatientVitalSign = { ...props.patientVitalSign };

		if (props.emr.vitalSign?.vitalSignPediaChart) {
			setPediaGrowthFields(true);
			const result = convertFromAgeDate(
				new Date(
					props.emr.patient?.birthdate ||
						props.emr.patientInRelation?.birthdate ||
						'',
				),
				props.emr.vitalSign?.vitalSignPediaChart?.date,
			);
			const { year, month, days } = result;

			updatedPatientVitalSign.vitalSignPediaChart = {
				...props.emr.vitalSign?.vitalSignPediaChart,
				year,
				month,
				days,
				headCircumference:
					props.emr.vitalSign?.vitalSignPediaChart?.headCircumference,
				notes: props.emr.vitalSign?.vitalSignPediaChart?.notes,
			};
		}

		updatedPatientVitalSign.heightUnit = props.emr.vitalSign?.heightUnit;
		updatedPatientVitalSign.height = props.emr.vitalSign?.height;
		updatedPatientVitalSign.weight = props.emr.vitalSign?.weight;
		updatedPatientVitalSign.vitalSignHeartRate = {
			...props.emr.vitalSign?.vitalSignHeartRate,
			date: props.emr.vitalSign?.vitalSignHeartRate?.date,
			heartRate: props.emr.vitalSign?.vitalSignHeartRate?.heartRate,
			notes: props.emr.vitalSign?.vitalSignHeartRate?.notes,
		};
		updatedPatientVitalSign.vitalSignRespiratoryRate = {
			...props.emr.vitalSign?.vitalSignRespiratoryRate,
			date: props.emr.vitalSign?.vitalSignRespiratoryRate?.date,
			respiratoryRate:
				props.emr.vitalSign?.vitalSignRespiratoryRate?.respiratoryRate,
			notes: props.emr.vitalSign?.vitalSignRespiratoryRate?.notes,
		};
		updatedPatientVitalSign.vitalSignSpO2 = {
			...props.emr.vitalSign?.vitalSignSpO2,
			date: props.emr.vitalSign?.vitalSignSpO2?.date,
			spO2: props.emr.vitalSign?.vitalSignSpO2?.spO2,
			notes: props.emr.vitalSign?.vitalSignSpO2?.notes,
		};
		updatedPatientVitalSign.vitalSignTemperature = {
			...props.emr.vitalSign?.vitalSignTemperature,
			date: props.emr.vitalSign?.vitalSignTemperature?.date,
			temperature: props.emr.vitalSign?.vitalSignTemperature?.temperature,
			notes: props.emr.vitalSign?.vitalSignTemperature?.notes,
		};
		updatedPatientVitalSign.vitalSignBloodPressure = {
			...props.emr.vitalSign?.vitalSignBloodPressure,
			date: props.emr.vitalSign?.vitalSignBloodPressure?.date,
			systolic: props.emr.vitalSign?.vitalSignBloodPressure?.systolic,
			diastolic: props.emr.vitalSign?.vitalSignBloodPressure?.diastolic,
			notes: props.emr.vitalSign?.vitalSignBloodPressure?.notes,
		};
		updatedPatientVitalSign.vitalSignBloodSugar = {
			...props.emr.vitalSign?.vitalSignBloodSugar,
			date: props.emr.vitalSign?.vitalSignBloodSugar?.date,
			bloodSugar: props.emr.vitalSign?.vitalSignBloodSugar?.bloodSugar,
			bloodSugarUnit:
				props.emr.vitalSign?.vitalSignBloodSugar?.bloodSugarUnit || 'mg/dL',
			notes: props.emr.vitalSign?.vitalSignBloodSugar?.notes,
			condition: props.emr.vitalSign?.vitalSignBloodSugar?.condition,
		};

		props.setPatientVitalSign(updatedPatientVitalSign);
	}, []);

	const convertToAgeDate = () => {
		const { year, month, days } =
			props.patientVitalSign?.vitalSignPediaChart || {};
		if (year || month || days) {
			return moment()
				.subtract(year || 0, 'years')
				.subtract(month || 0, 'months')
				.subtract(days || 0, 'days')
				.toDate();
		}
		return undefined;
	};

	const convertFromAgeDate = (date: Date, createdAt: Date | undefined) => {
		const fromDate = moment(createdAt);
		const inputDate = moment(date);

		const years = fromDate.diff(inputDate, 'years');
		inputDate.add(years, 'years');

		const months = fromDate.diff(inputDate, 'months');
		inputDate.add(months, 'months');

		const days = fromDate.diff(inputDate, 'days');

		return { year: years, month: months, days: days };
	};

	const saveVitalSign = async () => {
		setIsLoading(true);
		let isSuccess = false;
		const { emr, setEmr, patientVitalSign, setNextStep, setFormName } = props;

		const data: VitalSignProperties = {
			...emr.vitalSign,
			emrId: emr.emrId,
			height: patientVitalSign.height,
			heightUnit: patientVitalSign.heightUnit,
			weight: patientVitalSign.weight,
			weightUnit: patientVitalSign.weightUnit || 'kg',
			vitalSignHeartRate: {
				date: patientVitalSign?.vitalSignHeartRate?.date,
				heartRate: patientVitalSign?.vitalSignHeartRate?.heartRate,
				notes: patientVitalSign?.vitalSignHeartRate?.notes,
			},
			vitalSignRespiratoryRate: {
				date: patientVitalSign?.vitalSignRespiratoryRate?.date,
				respiratoryRate:
					patientVitalSign?.vitalSignRespiratoryRate?.respiratoryRate,
				notes: patientVitalSign?.vitalSignRespiratoryRate?.notes,
			},
			vitalSignSpO2: {
				date: patientVitalSign?.vitalSignSpO2?.date,
				spO2: patientVitalSign?.vitalSignSpO2?.spO2,
				notes: patientVitalSign?.vitalSignSpO2?.notes,
			},
			vitalSignTemperature: {
				date: patientVitalSign?.vitalSignTemperature?.date,
				temperature: patientVitalSign?.vitalSignTemperature?.temperature,
				notes: patientVitalSign?.vitalSignTemperature?.notes,
			},
			vitalSignBloodPressure: {
				date: patientVitalSign?.vitalSignBloodPressure?.date,
				systolic: patientVitalSign?.vitalSignBloodPressure?.systolic,
				diastolic: patientVitalSign?.vitalSignBloodPressure?.diastolic,
				notes: patientVitalSign?.vitalSignBloodPressure?.notes,
			},
			vitalSignBloodSugar: {
				date: patientVitalSign?.vitalSignBloodSugar?.date,
				bloodSugar: patientVitalSign?.vitalSignBloodSugar?.bloodSugar,
				bloodSugarUnit:
					patientVitalSign?.vitalSignBloodSugar?.bloodSugarUnit || 'mg/dL',
				notes: patientVitalSign?.vitalSignBloodSugar?.notes,
				condition: patientVitalSign?.vitalSignBloodSugar?.condition,
			},
			vitalSignPediaChart: {
				date: new Date(),
				notes: patientVitalSign?.vitalSignPediaChart?.notes,
				headCircumference:
					patientVitalSign?.vitalSignPediaChart?.headCircumference,
			},
		};

		if (!pediaGrowthFields) {
			data.vitalSignPediaChart = {};
		}

		try {
			if (!emr.emrId) {
				throw new Error('EMR ID is required.');
			}

			const res = await apiCreateVitalSign(emr.emrId, 'vital_sign', data);
			if (isMountedRef.current && res) {
				setEmr({
					...emr,
					...res,
				});
				isSuccess = true;
			}

			if (isMountedRef.current && isSuccess) {
				setNextStep();
				setFormName();
				message.success('Vital Sign Saved Successfully');
			}
		} catch (error) {
			if (isMountedRef.current) {
				console.error('Error saving Vital Sign:', error);
			}
		} finally {
			if (isMountedRef.current) {
				setIsLoading(false);
			}
		}
	};

	const handleRemovePediaGrowthChart = () => {
		setPediaGrowthFields(false);
		props.setPatientVitalSign((old: any) => ({
			...old,
			vitalSignPediaChart: {},
		}));
		setIsModalConfirmationVisible(false);
	};

	const showModal = () => {
		setIsModalConfirmationVisible(true);
	};

	const handleViewTrend = (nextStep: number) => {
		history.push({
			pathname: '/app/emr/patient',
			state: {
				step: nextStep,
				selectedPatient: props.emr,
			},
		});
	};

	return (
		<>
			<form>
				<div className="py-6 px-3">
					<div>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">BMI</p>
								<p
									className="m-0 text-3.5 text-charcoal-300 cursor-pointer hover:text-white"
									onClick={() => handleViewTrend(2)}
								>
									View Trend
								</p>
							</div>
							<div className="flex-1">
								<div className="flex-1">
									<FormInput
										label="Height"
										name="heightUnit"
										required
										type={EFormType.ITEM_PICKER_H}
										items={['Cm', 'Inch']}
										value={props.patientVitalSign?.heightUnit}
										onChange={(val) => {
											props.setPatientVitalSign((old: any) => ({
												...old,
												heightUnit: val,
											}));
										}}
									/>
									<FormInput
										label=""
										name="height"
										required
										type={EFormType.SINGLE_LINE}
										value={props.patientVitalSign?.height}
										suffix={
											<p className="m-0 text-black-60 whitespace-nowrap">
												{props.patientVitalSign?.heightUnit}
											</p>
										}
										onChange={(val) => {
											props.setPatientVitalSign((old: any) => ({
												...old,
												height: val,
											}));
										}}
									/>
								</div>
								<FormInput
									label="Weight"
									required
									name="weight"
									type={EFormType.SINGLE_LINE}
									formType="number"
									value={props.patientVitalSign?.weight}
									suffix={
										<p className="m-0 text-black-60 whitespace-nowrap">kg</p>
									}
									onChange={(val) => {
										props.setPatientVitalSign((old: any) => ({
											...old,
											weight: val,
										}));
									}}
								/>
							</div>
						</div>
						{!pediaGrowthFields ? (
							<div className="flex gap-x-3">
								<div className="pt-2 w-1/4">
									<p className="m-0 font-semibold text-4"></p>
								</div>
								<div className="flex-1">
									<div className="mt-4 mb-4">
										<Button
											type="OUTLINE"
											onClick={() => setPediaGrowthFields(true)}
										>
											Add Pedia Growth Chart
										</Button>
									</div>
								</div>
							</div>
						) : (
							<>
								<Separator />
								<div className="flex gap-x-3">
									<div className="pt-2 w-1/4">
										<p className="m-0 font-semibold text-4">
											Pedia Growth Chart
										</p>
										<p
											className="m-0 text-3.5 text-charcoal-300 cursor-pointer hover:text-white"
											onClick={() => handleViewTrend(3)}
										>
											View Trend
										</p>
										<div className="cursor-pointer mt-1 " onClick={showModal}>
											<Trash2 size={18} color={theme.colors.primary} />
										</div>
									</div>
									<div className="flex-1">
										<div className="mt-2">
											<label className="block text-4 text-gray-700 mb-0">
												Ages as year+month+days
											</label>
										</div>
										<div className="flex">
											<div className="mr-2">
												<FormInput
													label=""
													name="year"
													type={EFormType.SINGLE_LINE}
													value={
														props.patientVitalSign?.vitalSignPediaChart?.year
													}
													suffix={
														<p className="m-0 text-black-60 whitespace-nowrap">
															year
														</p>
													}
													onChange={(val) => {
														props.setPatientVitalSign((old: any) => ({
															...old,
															vitalSignPediaChart: {
																...old.vitalSignPediaChart,
																year: val,
															},
														}));
													}}
												/>
											</div>
											<div className="mr-2">
												<FormInput
													label=""
													name="month"
													type={EFormType.SINGLE_LINE}
													value={
														props.patientVitalSign?.vitalSignPediaChart?.month
													}
													suffix={
														<p className="m-0 text-black-60 whitespace-nowrap">
															mo
														</p>
													}
													onChange={(val) => {
														props.setPatientVitalSign((old: any) => ({
															...old,
															vitalSignPediaChart: {
																...old.vitalSignPediaChart,
																month: val,
															},
														}));
													}}
												/>
											</div>
											<div>
												<FormInput
													label=""
													name="days"
													type={EFormType.SINGLE_LINE}
													value={
														props.patientVitalSign?.vitalSignPediaChart?.days
													}
													suffix={
														<p className="m-0 text-black-60 whitespace-nowrap">
															days
														</p>
													}
													onChange={(val) => {
														props.setPatientVitalSign((old: any) => ({
															...old,
															vitalSignPediaChart: {
																...old.vitalSignPediaChart,
																days: val,
															},
														}));
													}}
												/>
											</div>
										</div>

										<FormInput
											label="Head Circumference"
											name="headCircumference"
											type={EFormType.SINGLE_LINE}
											value={
												props.patientVitalSign?.vitalSignPediaChart
													?.headCircumference
											}
											suffix={
												<p className="m-0 text-black-60 whitespace-nowrap">
													cm
												</p>
											}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignPediaChart: {
														...old.vitalSignPediaChart,
														headCircumference: val,
													},
												}));
											}}
										/>

										<FormInput
											label="Notes"
											name="notes"
											type={EFormType.MULTI_LINE}
											value={props.patientVitalSign?.vitalSignPediaChart?.notes}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignPediaChart: {
														...old.vitalSignPediaChart,
														notes: val,
													},
												}));
											}}
										/>
									</div>
								</div>
							</>
						)}
						<Separator />
						<Separator />
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Heart Rate</p>
								<p
									className="m-0 text-3.5 text-charcoal-300 cursor-pointer hover:text-white"
									onClick={() => handleViewTrend(4)}
								>
									View Trend
								</p>
							</div>
							<div className="flex-1">
								<div className="flex gap-x-3">
									<div className="flex-1 w-1/3">
										<FormInput
											label="Date"
											name="date"
											type={EFormType.DATE_PICKER}
											required
											value={props.patientVitalSign?.vitalSignHeartRate?.date}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignHeartRate: {
														...old.vitalSignHeartRate,
														date: val,
													},
												}));
											}}
										/>
									</div>
									<div className="flex-1 w-1/3">
										<FormInput
											label="Time"
											name="time"
											required
											type={EFormType.TIME_PICKER}
											value={props.patientVitalSign?.vitalSignHeartRate?.date}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignHeartRate: {
														...old.vitalSignHeartRate,
														date: val,
													},
												}));
											}}
										/>
									</div>
								</div>

								<FormInput
									label="Heart Rate"
									name="heartRate"
									required
									formType="number"
									type={EFormType.SINGLE_LINE}
									value={props.patientVitalSign?.vitalSignHeartRate?.heartRate}
									suffix={
										<p className="m-0 text-black-60 whitespace-nowrap">BPM</p>
									}
									onChange={(val) => {
										props.setPatientVitalSign((old: any) => ({
											...old,
											vitalSignHeartRate: {
												...old.vitalSignHeartRate,
												heartRate: val,
											},
										}));
									}}
								/>
								<FormInput
									label="Notes"
									name="notes"
									type={EFormType.MULTI_LINE}
									value={props.patientVitalSign?.vitalSignHeartRate?.notes}
									onChange={(val) => {
										props.setPatientVitalSign((old: any) => ({
											...old,
											vitalSignHeartRate: {
												...old.vitalSignHeartRate,
												notes: val,
											},
										}));
									}}
								/>
							</div>
						</div>
						<Separator />
						<Separator />
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Respiratory Rate</p>
								<p
									className="m-0 text-3.5 text-charcoal-300 cursor-pointer hover:text-white"
									onClick={() => handleViewTrend(5)}
								>
									View Trend
								</p>
							</div>
							<div className="flex-1">
								<div className="flex gap-x-3">
									<div className="flex-1 w-1/3">
										<FormInput
											label="Date"
											name="date"
											type={EFormType.DATE_PICKER}
											value={
												props.patientVitalSign?.vitalSignRespiratoryRate?.date
											}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignRespiratoryRate: {
														...old.vitalSignRespiratoryRate,
														date: val,
													},
												}));
											}}
										/>
									</div>
									<div className="flex-1 w-1/3">
										<FormInput
											label="Time"
											name="time"
											type={EFormType.TIME_PICKER}
											value={
												props.patientVitalSign?.vitalSignRespiratoryRate?.date
											}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignRespiratoryRate: {
														...old.vitalSignRespiratoryRate,
														date: val,
													},
												}));
											}}
										/>
									</div>
								</div>

								<FormInput
									label="Respiratory Rate"
									name="respiratoryRate"
									formType="number"
									type={EFormType.SINGLE_LINE}
									value={
										props.patientVitalSign?.vitalSignRespiratoryRate
											?.respiratoryRate
									}
									suffix={
										<p className="m-0 text-black-60 whitespace-nowrap">CPM</p>
									}
									onChange={(val) => {
										props.setPatientVitalSign((old: any) => ({
											...old,
											vitalSignRespiratoryRate: {
												...old.vitalSignRespiratoryRate,
												respiratoryRate: Number(val),
											},
										}));
									}}
								/>
								<FormInput
									label="Notes"
									name="notes"
									type={EFormType.MULTI_LINE}
									value={
										props.patientVitalSign?.vitalSignRespiratoryRate?.notes
									}
									onChange={(val) => {
										props.setPatientVitalSign((old: any) => ({
											...old,
											vitalSignRespiratoryRate: {
												...old.vitalSignRespiratoryRate,
												notes: val,
											},
										}));
									}}
								/>
							</div>
						</div>
						<Separator />
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">SpO2</p>
								<p
									className="m-0 text-3.5 text-charcoal-300 cursor-pointer hover:text-white"
									onClick={() => handleViewTrend(6)}
								>
									View Trend
								</p>
							</div>
							<div className="flex-1">
								<div className="flex gap-x-3">
									<div className="flex-1 w-1/3">
										<FormInput
											label="Date"
											name="date"
											type={EFormType.DATE_PICKER}
											value={props.patientVitalSign?.vitalSignSpO2?.date}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignSpO2: {
														...old.vitalSignSpO2,
														date: val,
													},
												}));
											}}
										/>
									</div>
									<div className="flex-1 w-1/3">
										<FormInput
											label="Time"
											name="time"
											type={EFormType.TIME_PICKER}
											value={props.patientVitalSign?.vitalSignSpO2?.date}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignSpO2: {
														...old.vitalSignSpO2,
														date: val,
													},
												}));
											}}
										/>
									</div>
								</div>

								<FormInput
									label="SpO2"
									name="spo2"
									formType="number"
									type={EFormType.SINGLE_LINE}
									value={props.patientVitalSign?.vitalSignSpO2?.spO2}
									suffix={
										<p className="m-0 text-black-60 whitespace-nowrap">%</p>
									}
									onChange={(val) => {
										props.setPatientVitalSign((old: any) => ({
											...old,
											vitalSignSpO2: {
												...old.vitalSignSpO2,
												spO2: Number(val),
											},
										}));
									}}
								/>
								<FormInput
									label="Notes"
									name="notes"
									type={EFormType.MULTI_LINE}
									value={props.patientVitalSign?.vitalSignSpO2?.notes}
									onChange={(val) => {
										props.setPatientVitalSign((old: any) => ({
											...old,
											vitalSignSpO2: {
												...old.vitalSignSpO2,
												notes: val,
											},
										}));
									}}
								/>
							</div>
						</div>
						<Separator />

						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Temperature</p>
								<p
									className="m-0 text-3.5 text-charcoal-300 cursor-pointer hover:text-white"
									onClick={() => handleViewTrend(7)}
								>
									View Trend
								</p>
							</div>
							<div className="flex-1">
								<div className="flex gap-x-3">
									<div className="flex-1 w-1/3">
										<FormInput
											label="Date"
											name="date"
											required
											type={EFormType.DATE_PICKER}
											value={props.patientVitalSign?.vitalSignTemperature?.date}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignTemperature: {
														...old.vitalSignTemperature,
														date: val,
													},
												}));
											}}
										/>
									</div>
									<div className="flex-1 w-1/3">
										<FormInput
											label="Time"
											name="time"
											required
											type={EFormType.TIME_PICKER}
											value={props.patientVitalSign?.vitalSignTemperature?.date}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignTemperature: {
														...old.vitalSignTemperature,
														date: val,
													},
												}));
											}}
										/>
									</div>
								</div>

								<FormInput
									label="Temperature"
									name="temperature"
									required
									formType="number"
									type={EFormType.SINGLE_LINE}
									value={
										props.patientVitalSign?.vitalSignTemperature?.temperature
									}
									suffix={
										<p className="m-0 text-black-60 whitespace-nowrap">°C</p>
									}
									onChange={(val) => {
										props.setPatientVitalSign((old: any) => ({
											...old,
											vitalSignTemperature: {
												...old.vitalSignTemperature,
												temperature: val,
											},
										}));
									}}
								/>
								<FormInput
									label="Notes"
									name="notes"
									type={EFormType.MULTI_LINE}
									value={props.patientVitalSign?.vitalSignTemperature?.notes}
									onChange={(val) => {
										props.setPatientVitalSign((old: any) => ({
											...old,
											vitalSignTemperature: {
												...old.vitalSignTemperature,
												notes: val,
											},
										}));
									}}
								/>
							</div>
						</div>
						<Separator />
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Blood Pressure</p>
								<p
									className="m-0 text-3.5 text-charcoal-300 cursor-pointer hover:text-white"
									onClick={() => handleViewTrend(8)}
								>
									View Trend
								</p>
							</div>
							<div className="flex-1">
								<div className="flex gap-x-3">
									<div className="flex-1 w-1/3">
										<FormInput
											label="Date"
											name="date"
											required
											type={EFormType.DATE_PICKER}
											value={
												props.patientVitalSign?.vitalSignBloodPressure?.date
											}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignBloodPressure: {
														...old.vitalSignBloodPressure,
														date: val,
													},
												}));
											}}
										/>
									</div>
									<div className="flex-1 w-1/3">
										<FormInput
											label="Time"
											name="time"
											required
											type={EFormType.TIME_PICKER}
											value={
												props.patientVitalSign?.vitalSignBloodPressure?.date
											}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignBloodPressure: {
														...old.vitalSignBloodPressure,
														date: val,
													},
												}));
											}}
										/>
									</div>
								</div>

								<div className="flex gap-x-3">
									<div className="flex-1 w-1/3">
										<FormInput
											label="Systolic"
											name="systolic"
											required
											type={EFormType.SINGLE_LINE}
											value={
												props.patientVitalSign?.vitalSignBloodPressure?.systolic
											}
											suffix={
												<p className="m-0 text-black-60 whitespace-nowrap">
													mmHg
												</p>
											}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignBloodPressure: {
														...old.vitalSignBloodPressure,
														systolic: val,
													},
												}));
											}}
										/>
									</div>
									<div className="flex-1 w-1/3">
										<FormInput
											label="Diastolic"
											name="diastolic"
											required
											type={EFormType.SINGLE_LINE}
											value={
												props.patientVitalSign?.vitalSignBloodPressure
													?.diastolic
											}
											suffix={
												<p className="m-0 text-black-60 whitespace-nowrap">
													mmHg
												</p>
											}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignBloodPressure: {
														...old.vitalSignBloodPressure,
														diastolic: val,
													},
												}));
											}}
										/>
									</div>
								</div>

								<FormInput
									label="Notes"
									name="notes"
									type={EFormType.MULTI_LINE}
									value={props.patientVitalSign?.vitalSignBloodPressure?.notes}
									onChange={(val) => {
										props.setPatientVitalSign((old: any) => ({
											...old,
											vitalSignBloodPressure: {
												...old.vitalSignBloodPressure,
												notes: val,
											},
										}));
									}}
								/>
							</div>
						</div>
						<Separator />
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Blood Sugar</p>
								<p
									className="m-0 text-3.5 text-charcoal-300 cursor-pointer hover:text-white"
									onClick={() => handleViewTrend(9)}
								>
									View Trend
								</p>
							</div>
							<div className="flex-1">
								<div className="flex gap-x-3">
									<div className="flex-1 w-1/3">
										<FormInput
											label="Date"
											name="date"
											type={EFormType.DATE_PICKER}
											value={props.patientVitalSign?.vitalSignBloodSugar?.date}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignBloodSugar: {
														...old.vitalSignBloodSugar,
														date: val,
													},
												}));
											}}
										/>
									</div>
									<div className="flex-1 w-1/3">
										<FormInput
											label="Time"
											name="time"
											type={EFormType.TIME_PICKER}
											value={props.patientVitalSign?.vitalSignBloodSugar?.date}
											onChange={(val) => {
												props.setPatientVitalSign((old: any) => ({
													...old,
													vitalSignBloodSugar: {
														...old.vitalSignBloodSugar,
														date: val,
													},
												}));
											}}
										/>
									</div>
								</div>

								<div className="flex-1">
									<FormInput
										label="Blood Sugar"
										name="bloodSugarUnit"
										type={EFormType.ITEM_PICKER_H}
										items={['mg/dL', 'mmol/L']}
										value={
											props.patientVitalSign?.vitalSignBloodSugar
												?.bloodSugarUnit
										}
										onChange={(val) => {
											props.setPatientVitalSign((old: any) => ({
												...old,
												vitalSignBloodSugar: {
													...old.vitalSignBloodSugar,
													bloodSugarUnit: val,
												},
											}));
										}}
									/>
									<FormInput
										label=""
										name="bloodSugar"
										type={EFormType.SINGLE_LINE}
										value={
											props.patientVitalSign?.vitalSignBloodSugar?.bloodSugar
										}
										suffix={
											<p className="m-0 text-black-60 whitespace-nowrap">
												{
													props.patientVitalSign?.vitalSignBloodSugar
														?.bloodSugarUnit
												}
											</p>
										}
										onChange={(val) => {
											props.setPatientVitalSign((old: any) => ({
												...old,
												vitalSignBloodSugar: {
													...old.vitalSignBloodSugar,
													bloodSugar: val,
												},
											}));
										}}
									/>
								</div>

								<label
									htmlFor="condition"
									className="block mb-0 text-sm  text-gray-60"
								>
									Condition
								</label>
								<Select
									id="condition"
									size="large"
									value={props.patientVitalSign?.vitalSignBloodSugar?.condition}
									onChange={(val) => {
										props.setPatientVitalSign((old: any) => ({
											...old,
											vitalSignBloodSugar: {
												...old.vitalSignBloodSugar,
												condition: val,
											},
										}));
									}}
									options={Object.values(EConditionBloodSugar).map((value) => ({
										label: value,
										value,
									}))}
									style={{ width: '100%' }}
								/>
								<FormInput
									label="Notes"
									name="notes"
									type={EFormType.MULTI_LINE}
									value={props.patientVitalSign?.vitalSignBloodSugar?.notes}
									onChange={(val) => {
										props.setPatientVitalSign((old: any) => ({
											...old,
											vitalSignBloodSugar: {
												...old.vitalSignBloodSugar,
												notes: val,
											},
										}));
									}}
								/>
							</div>
						</div>
					</div>
					<div className="h-6" />
				</div>
				<Separator />
				<div className="py-3 px-4">
					<div className="w-44 ml-auto">
						<Button
							type="SOLID"
							onClick={saveVitalSign}
							isLoading={isLoading}
							isDisable={!isFormValid || isLoading}
						>
							Save
						</Button>
					</div>
				</div>
			</form>
			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={handleRemovePediaGrowthChart}
				title="Pedia Growth Chart Field"
				type={EModalConfirmationType.REMOVE_ITEM}
			/>
		</>
	);
}
