export function capitalizeFirstLetter(text: string) {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

export function convertHtmlToText(htmlText: string) {
	return htmlText.replace(/<[^>]+>/g, ' ');
}

export function convertToTitleCase(input: string): string {
	const words = input.split('_');
	const capitalizedWords = words.map((word) => {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	});
	return capitalizedWords.join(' ');
}

export function capitalizeWords(input: string) : string {
	const words = input.split(' ');
	const capitalizedWords = words.map((word) => {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	});
	return capitalizedWords.join(' ');
  };