import styled from 'styled-components';
import { renderVitalSignStatus } from '../../StatusTag';

interface Props {
	bloodPressureParams?: string;
	title: string;
	value: number;
	unit: string;
	status?: string;
}

export default function VitalSignTypeSummary(props: Props) {
	const { bloodPressureParams, title, value, unit, status } = props;
	return (
		<div style={ItemDetailInformationStyles}>
			<VitalSignSummaryTitle>
				{title} {bloodPressureParams && bloodPressureParams}
			</VitalSignSummaryTitle>
			<div style={DetailInformationValueStyles}>
				<span style={DetailInformationNumberStyle}>{value}</span> {unit}
			</div>
			{status && renderVitalSignStatus(status)}
		</div>
	);
}

const VitalSignSummaryTitle = styled.div`
	font-size: 12px;
	color: #748494;
	margin-bottom: 8px;
`;

const ItemDetailInformationStyles: React.CSSProperties = {
	textAlign: 'left',
	flex: 1,
};

export const DetailInformationValueStyles: React.CSSProperties = {
	fontSize: 14,
	color: '#748494',
	fontWeight: 400,
	lineHeight: '14px',
};

export const DetailInformationNumberStyle: React.CSSProperties = {
	fontSize: 24,
	fontWeight: 600,
	color: 'black',
	lineHeight: '14px',
};
