import { useState, useEffect } from 'react';
import { apiGetEmr } from '../../../../../api/emr';
import { ArrowLeft } from 'react-feather';
import { Tabs, message } from 'antd';
import PreviewDocument from '../../../Components/PreviewDocument';
import {
	capitalizeFirstLetter,
	convertToTitleCase,
} from '../../../../../helpers/text';
import { RxPrescriptionProperties } from '../../../../../types/rxPrescription.type';
import styled from 'styled-components';
import { theme } from '../../../../../assets/theme';

type Props = {
	emrId?: string;
};

const DocumentPreview = ({ emrId }: Props) => {
	// const querySearch = new URLSearchParams(window.location.search);
	// const emrId = querySearch.get('emrId');
	const [activeTab, setActiveTab] = useState<string>();
	const [tabItems, setTabItems] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	const fetchEmr = async () => {
		try {
			setIsLoading(true);
			const data = await apiGetEmr(emrId || '');

			if (!data) {
				message.error('Failed to fetch EMR data. Please try again.');
				return;
			}

			const newTabItems = [];

			if (data?.prescription?.rxPrescriptionId) {
				newTabItems.push({
					label: 'Rx Prescription',
					key: `Rx Prescrpition`,
					children: (
						<PreviewDocument
							docId={data.prescription.rxPrescriptionId}
							type="rx-prescription"
							buttonType="none"
						/>
					),
				});
			}

			if (data?.labRequest?.labRequestId) {
				newTabItems.push({
					label: 'Lab Request',
					key: 'Lab Request',
					children: (
						<PreviewDocument
							docId={data.labRequest.labRequestId}
							type="lab-request"
							buttonType="none"
						/>
					),
				});
			}

			if (data?.doctorDocuments?.length) {
				data.doctorDocuments.forEach((doc) => {
					if (doc.doctorDocumentId) {
						const docTitle = convertToTitleCase(doc.type as string);
						newTabItems.push({
							label: docTitle,
							key: docTitle,
							children: (
								<PreviewDocument
									docId={doc.doctorDocumentId}
									type="doctor-document"
									buttonType="none"
								/>
							),
						});
					}
				});
			}

			setTabItems(newTabItems);
		} catch (err) {
			console.error(err);
			message.error('Failed to load EMR data. Please check your connection.');
		} finally {
			setIsLoading(false);
		}
	};

	// const onBack = () => {
	// 	window.history.back();
	// };

	useEffect(() => {
		fetchEmr();
	}, []);

	return isLoading ? (
		<div>Loading...</div>
	) : (
		<TabStyle>
			{/* <div className="relative px-6 py-5.5 flex items-center">
				<button
					onClick={onBack}
					className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
				>
					<ArrowLeft width={20} height={20} />
					<p className="m-0 font-bold text-3.5">Back</p>
				</button>

				<h1 className="font-semibold text-6 m-0 w-full text-center">
					Document Overview
				</h1>
			</div> */}
			<Tabs
				defaultActiveKey={activeTab}
				centered
				items={tabItems}
				onChange={(e) => {
					setActiveTab(e);
				}}
			/>
		</TabStyle>
	);
};

export default DocumentPreview;

const TabStyle = styled.div`
	padding: 0 12px;

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: black !important;
		font-weight: 500 !important;
	}

	.ant-tabs-tab {
		color: ${theme.colors.charcoal300} !important;
		font-weight: 500 !important;
	}

	.ant-tabs-ink-bar {
		height: 4px !important;
	}
`;
