import React, { useState, useEffect } from 'react';
import {
	Layout,
	Space,
	Button,
	Drawer,
	Dropdown,
	Menu,
	Modal,
	Row,
	Col,
	Typography,
	MenuProps,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import {
	CloseOutlined,
	MenuOutlined,
	QuestionCircleOutlined,
	SmallDashOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { EAuthState } from '../types/auth.type';
import styled from 'styled-components';
import { getName } from '../helpers/name';
import Title from 'antd/lib/typography/Title';
import {
	//  DEFAULT_IMAGE,
	DEFAULT_IMAGE_USER,
} from '../helpers/constants';
import useModal from '../hooks/useModal';
import useWindowSize from '../hooks/useWindowSize';
import SignInModalWithFunction from '../components/login/SignInModalWithFunction';
import { useGlobal } from '../context/global.context';
import { useAuth } from '../context/auth.context';

const { Header } = Layout;

const MENU_LIST = [
	{
		title: 'Home',
		to: '/',
		hrefLogin: '/app',
	},
	{
		title: 'Features',
		to: '/#features',
	},
	// {
	//   title: 'Perks and Benefit',
	//   to: '/#perks-and-benefit',
	// },
	{
		title: 'Promo and Deals',
		to: '/#promo-deals',
		hrefLogin: '/app/promo-deals',
	},
	{
		title: 'Articles',
		to: '/articles',
		hrefLogin: '/app/articles',
	},
	{
		title: 'Store',
		to: '/#store',
	},
	{
		title: 'Lend a Hand',
		to: '/#lend-a-hand',
	},
	{
		title: 'Application',
		to: '/our-apps',
	},
];

type Props = {
	activePath?: string;
	isInsideWebapp?: boolean;
};

const AppHeader: React.FC<Props> = ({ activePath, isInsideWebapp }) => {
	const location = useLocation();
	const history = useHistory();
	const { isMobile, width } = useWindowSize();
	const { setIsShowModalChooseRole } = useGlobal();
	const [showMenu, setShowMenu] = useState(true);
	const { user, token: authToken, signout, init } = useAuth();
	const {
		isShowModalLendAHand,
		isShowModalStore,
		setIsShowModalLendAHand,
		setIsShowModalStore,
	} = useModal();

	useEffect(() => {
		init();
	}, []);

	const dropdownContents: MenuProps['items'] = [
		{
			key: 'signout',
			label: (
				<Link
					to={'#'}
					onClick={async () => {
						Modal.confirm({
							title: 'Confirmation',
							content: 'Are you sure want to signout?',
							onOk: () => {
								signout();
								history.replace('/');
							},
						});
					}}
				>
					Logout
				</Link>
			),
		},
	];

	const LIMIT_NAV = 7;

	const dropdownContentsNav = (
		<Menu
			selectedKeys={[`${location.pathname}`]}
			items={MENU_LIST.slice(LIMIT_NAV).map((list) => ({
				key: list.to,
				label: <Link to={list.to}>{list.title}</Link>,
			}))}
		/>
	);

	useEffect(() => {
		setShowMenu(false);
	}, [location]);

	const type: EAuthState = location.pathname.startsWith('/register')
		? EAuthState.ON_REGISTRATION
		: authToken
		? EAuthState.LOGGED_IN
		: EAuthState.NOT_LOGGED_IN;

	const clickModal = ['/#store', '/#lend-a-hand'];
	const handleClickModal = (link: string) => {
		if (link === '/#store') {
			setIsShowModalStore(true);
		} else if (link === '/#lend-a-hand') {
			setIsShowModalLendAHand(true);
		}
	};

	return (
		<React.Fragment>
			<Header id="header">
				<div className={isInsideWebapp ? 'container' : 'container'}>
					<Link to="/" className="logo">
						<img src="/logo-text.svg" alt="Medeasy Logo" />
					</Link>
					<nav className="nav-menu">
						{MENU_LIST.slice(0, LIMIT_NAV).map((item) => {
							return (
								<Link
									className={
										activePath === item.to || activePath === item.hrefLogin
											? 'active'
											: ''
									}
									key={item.to}
									to={clickModal.includes(item.to) ? '#' : item.to}
									onClick={() => handleClickModal(item.to)}
								>
									{item.title}
								</Link>
							);
						})}
						{MENU_LIST.length > LIMIT_NAV && (
							<Dropdown overlay={dropdownContentsNav}>
								<Button type="text">
									<SmallDashOutlined />
								</Button>
							</Dropdown>
						)}
					</nav>
					{type === EAuthState.LOGGED_IN ||
					type === EAuthState.ON_REGISTRATION ? (
						<Space className="right-header">
							<Button className="flex items-center">
								<QuestionIconContainer>
									<QuestionCircleOutlined />
								</QuestionIconContainer>
							</Button>
							<Button
								type="text"
								onClick={() => {
									if (user?.doctor) {
										history.push('/app');
									}
								}}
								className="flex items-center"
							>
								{type === EAuthState.LOGGED_IN ? (
									<img
										src={
											user?.profilePic ? user?.profilePic : DEFAULT_IMAGE_USER
										}
										className="rounded-5 w-10 h-10 object-cover"
										alt={user?.name}
									/>
								) : (
									<UserIconContainer>
										<UserOutlined />
									</UserIconContainer>
								)}
								{(type === EAuthState.LOGGED_IN ||
									type === EAuthState.ON_REGISTRATION) && (
									<>
										<Dropdown menu={{ items: dropdownContents }}>
											<DoctorTextName
												style={{
													maxWidth:
														width < 1200
															? 400
															: width < 1240
															? 30
															: width < 1250
															? 50
															: width < 1380
															? 80
															: width < 1400
															? 200
															: 200,
												}}
											>
												Hi, {getName(user) || 'New User'}
											</DoctorTextName>
										</Dropdown>
									</>
								)}
							</Button>
						</Space>
					) : (
						<Space className="right-header">
							{/* <Button
                type="link"
                size="large"
                onClick={() => {
                  setRole('Doctor');
                  setShowSignInModal(true);
                }}
              >
                Join as Doctor
              </Button> */}
							<Button
								type="primary"
								size="large"
								className="btn-text-bold"
								onClick={() => setIsShowModalChooseRole(true)}
							>
								Sign In
							</Button>
						</Space>
					)}
					<div className="toggle-menu">
						<Button type="default" onClick={() => setShowMenu(true)}>
							<MenuOutlined className="text-black-100" />
						</Button>
					</div>
				</div>
			</Header>
			<Drawer
				className="menu-mobile-drawer"
				title={<img src="/Logo.svg" alt="Medeasy Logo" />}
				placement="left"
				open={showMenu}
				onClose={() => setShowMenu(false)}
				destroyOnClose={true}
				closable={false}
				extra={
					<Button type="default" onClick={() => setShowMenu(false)}>
						<CloseOutlined />
					</Button>
				}
				footer={
					<Space direction="horizontal" className="additional-menu-mobile">
						<Link
							to="#coming-soon-modal"
							onClick={() =>
								window.open(
									'https://play.google.com/store/apps/details?id=com.medeasyapp&hl=en&gl=US',
									'_blank',
									'noreferrer',
								)
							}
						>
							<img
								src="/images/google-play-download-icon.png"
								alt="Get it on Google Play"
							/>
						</Link>
						<Link
							to="#coming-soon-modal"
							onClick={() =>
								window.open(
									'https://apps.apple.com/id/app/medeasy-ph/id6443653585?l=id',
									'_blank',
									'noreferrer',
								)
							}
						>
							<img
								src="/images/app-store-download-icon.png"
								alt="Get it on App Store"
							/>
						</Link>
					</Space>
				}
			>
				<nav className="mobile-menu">
					{MENU_LIST.map((item) => (
						<Link
							className={activePath === item.to ? 'active' : ''}
							key={item.to}
							to={item.to}
						>
							{item.title}
						</Link>
					))}
				</nav>
			</Drawer>

			{/* Modal - Choose Role */}
			<SignInModalWithFunction />

			<CustomModal
				open={isShowModalStore}
				onCancel={() => {
					setIsShowModalStore(false);
				}}
				footer={null}
				width="40%"
			>
				<Row gutter={[20, 20]} className="card-grid" align="middle">
					<Col flex="auto">
						<span className="tag-primary mb-4 inline-block">COMING SOON</span>
						<Title level={4} style={{ marginBottom: 4 }}>
							Medeasy Store
						</Title>
						<Typography.Text>
							Our store shall offer health and wellness products at the best
							prices which you can purchase with cash or points.
						</Typography.Text>
					</Col>
					<Col flex="150px">
						<img src="/images/store-icon.png" alt="Medeasy Store" />
					</Col>
				</Row>
			</CustomModal>
			<CustomModal
				open={isShowModalLendAHand}
				onCancel={() => {
					setIsShowModalLendAHand(false);
				}}
				footer={null}
				width="40%"
			>
				<Row gutter={[20, 20]} className="card-grid" align="middle">
					<Col flex="auto">
						<span className="tag-primary mb-4 inline-block">COMING SOON</span>
						<Title level={4} style={{ marginBottom: 4 }}>
							Lend a Hand
						</Title>
						<Typography.Text>
							Join us as we help less fortunate patients in need of financial
							assistance.
						</Typography.Text>
					</Col>
					<Col flex="150px">
						<img src="/images/lend-hand-icon.png" alt="Lend a Hand" />
					</Col>
				</Row>
			</CustomModal>
		</React.Fragment>
	);
};
export default AppHeader;

const QuestionIconContainer = styled.div`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.black} !important;
`;

const UserIconContainer = styled.div`
	width: 34px;
	height: 34px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ theme }) => theme.colors.black} !important;
	background-color: ${({ theme }) => theme.colors.gray20};
	border-radius: 36px;

	&:hover {
		background-color: ${({ theme }) => theme.colors.gray20} !important;
	}
`;

const DoctorTextName = styled.p`
	margin: 0;
	margin-left: 12px;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.black};
	overflow: hidden;
	text-overflow: ellipsis;
`;

const CustomModal = styled(Modal)`
	.ant-modal-body {
		padding: 32px;
	}
`;
