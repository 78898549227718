import React, { useState, useEffect, useRef } from 'react';
import { Modal, Card, Typography, Image as ImageAntd, Spin, Empty } from 'antd';
import { theme } from '../../../../../assets/theme';
import { Button } from '../../../../Button/Button/Button';
import Separator from './../../../../Separator';
import { ReactSketchCanvas, ReactSketchCanvasRef } from 'react-sketch-canvas';
import useFetchList from '../../../../../hooks/useFetchList';
import { Search } from 'react-feather';
import FormInput from '../../../../Form/FormInput2';
import { useDebounce } from 'use-debounce';
import { useAuth } from '../../../../../context/auth.context';

const { Text } = Typography;

interface ImageModalProps {
	visible: boolean;
	onClose: () => void;
	uploadedImages: File[];
	setUploadedImages: React.Dispatch<React.SetStateAction<File[]>>;
	setFinalImage: React.Dispatch<React.SetStateAction<string | null>>;
	finalImage: string | null;
	doctorId: string;
}

interface AnatomyImage {
	anatomyPictureTemplateId: string;
	title: string;
	doctorId: string | null;
	fileUrl: string;
	createdByUserId: string;
	createdAt: string;
	updatedAt: string;
}

const ImageModal: React.FC<ImageModalProps> = ({
	visible,
	onClose,
	uploadedImages,
	setUploadedImages,
	setFinalImage,
	finalImage,
	doctorId,
}) => {
	const [selectedImage, setSelectedImage] = useState<string | null>(finalImage);
	const [drawingColor, setDrawingColor] = useState<string>('black');
	const [search, setSearch] = useState<string>('');
	const [searchAnatomyDebounce] = useDebounce(search, 500);
	const {userId} = useAuth();

	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const sketchCanvasRef = useRef<ReactSketchCanvasRef | null>(null);

	const {
		data: anatomyImages,
		fetchList: fetchAnatomy,
		setSearch: setSearchAnatomy,
		isLoading,
	} = useFetchList<AnatomyImage>({
		endpoint: '/anatomy-picture-templates',
		initialQuery: {
			doctorId: doctorId || userId,
		},
	});

	useEffect(() => {
		fetchAnatomy();
	}, []);

	useEffect(() => {
		setSearchAnatomy(searchAnatomyDebounce);
	}, [searchAnatomyDebounce]);

	useEffect(() => {
		if (finalImage) {
			setSelectedImage(finalImage);
		}
	}, [finalImage]);

	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files) {
			const newFiles = Array.from(files);
			setUploadedImages((prevImages) => [...prevImages, ...newFiles]);
		}
	};

	const handleUploadClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const clearDrawing = () => {
		sketchCanvasRef.current?.clearCanvas();
	};

	const undoDrawing = () => {
		sketchCanvasRef.current?.undo();
	};

	const handleImageClick = (src: string) => {
		setSelectedImage(src);
	};

	const handleSave = async () => {
		if (sketchCanvasRef.current && selectedImage) {
			try {
				const imageDataUrl = await sketchCanvasRef.current.exportImage('png');
				const combinedImage = new Image();
				combinedImage.src = imageDataUrl;

				await new Promise((resolve, reject) => {
					combinedImage.onload = resolve;
					combinedImage.onerror = reject;
				});

				const canvasElement = document.createElement('canvas');
				canvasElement.width = 400;
				canvasElement.height = 400;
				const ctx = canvasElement.getContext('2d');

				if (ctx) {
					const selectedImg = new Image();
					selectedImg.crossOrigin = 'anonymous';
					selectedImg.src = selectedImage;

					await new Promise((resolve, reject) => {
						selectedImg.onload = resolve;
						selectedImg.onerror = reject;
					});

					const imgWidth = selectedImg.width;
					const imgHeight = selectedImg.height;
					const canvasWidth = canvasElement.width;
					const canvasHeight = canvasElement.height;

					const imgAspectRatio = imgWidth / imgHeight;
					const canvasAspectRatio = canvasWidth / canvasHeight;

					let drawWidth, drawHeight;

					if (imgAspectRatio > canvasAspectRatio) {
						drawWidth = canvasWidth;
						drawHeight = canvasWidth / imgAspectRatio;
					} else {
						drawHeight = canvasHeight;
						drawWidth = canvasHeight * imgAspectRatio;
					}

					const offsetX = (canvasWidth - drawWidth) / 2;
					const offsetY = (canvasHeight - drawHeight) / 2;

					ctx.drawImage(selectedImg, offsetX, offsetY, drawWidth, drawHeight);
					ctx.drawImage(combinedImage, 0, 0);

					const finalImageDataUrl = canvasElement.toDataURL('image/png');
					setFinalImage(finalImageDataUrl);
					onClose();
				}
			} catch (error) {
				console.error('Error while saving the image:', error);
			}
		}
	};

	React.useEffect(() => {
		if (selectedImage && sketchCanvasRef.current) {
			sketchCanvasRef.current.clearCanvas();
			sketchCanvasRef.current.loadPaths([]);
		}
	}, [selectedImage]);

	return (
		<Modal
			title={
				<div className="text-center font-bold text-lg">
					{selectedImage ? 'Draw Picture' : 'Anatomy Picture'}
				</div>
			}
			open={visible}
			onCancel={() => (selectedImage ? setSelectedImage(null) : onClose())}
			footer={null}
			style={{ minWidth: '700px' }}
		>
			{!selectedImage && (
				<div className="flex items-center mb-4">
					<div className="flex-1">
						<FormInput
							prefix={
								<div className="flex">
									<Search
										width={20}
										height={20}
										color={theme.colors.charcoal200}
									/>
								</div>
							}
							placeholder="Search Anatomy"
							value={search}
							onChange={setSearch}
						/>
					</div>
				</div>
			)}
			{isLoading ? (
				<Spin style={{ width: '100%' }} />
			) : (
				<div className="flex flex-col items-center justify-center">
					{!selectedImage ? (
						<div>
							<div
								style={{
									display: 'grid',
									gridTemplateColumns: 'repeat(5, 1fr)',
									columnGap: 10,
									rowGap: 10,
									width: '100%',
								}}
							>
								{Array.isArray(anatomyImages) && anatomyImages.length > 0
									? anatomyImages.map((img, index) => (
											<Card
												key={index}
												style={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
													textAlign: 'center',
													cursor: 'pointer',
												}}
												onClick={() => handleImageClick(img.fileUrl)}
											>
												<div
													style={{
														display: 'flex',
														justifyContent: 'center',
														marginBottom: '10px',
													}}
												>
													<ImageAntd
														src={img.fileUrl}
														width={60}
														height={100}
														preview={false}
														style={{
															objectFit: 'contain',
														}}
														alt={`Anatomy ${img.title || index}`}
													/>
												</div>
												<div
													style={{ display: 'flex', justifyContent: 'center' }}
												>
													<Text style={{ textAlign: 'center', fontSize: 14 }}>
														{img.title}
													</Text>
												</div>
											</Card>
									  ))
									: null}
								{uploadedImages.map((image, index) => (
									<Card
										key={index}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											textAlign: 'center',
											cursor: 'pointer',
										}}
										onClick={() => handleImageClick(URL.createObjectURL(image))}
									>
										<ImageAntd
											src={URL.createObjectURL(image)}
											alt={`Uploaded ${index}`}
											width={60}
											height={100}
											preview={false}
											style={{
												objectFit: 'contain',
											}}
										/>
										<div style={{ display: 'flex', justifyContent: 'center' }}>
											<Text style={{ textAlign: 'center', fontSize: 14 }}>
												{`Uploaded Image ${index + 1}`}
											</Text>
										</div>
									</Card>
								))}
								{!uploadedImages.length && !anatomyImages.length && (
									<div
										style={{
											display: 'flex',
											flex: 1,
											width: '100vw',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<Empty description="Data Not Found" />
									</div>
								)}
							</div>
						</div>
					) : (
						<>
							<div className="flex items-center mb-4 justify-between w-full">
								<div className="flex flex-1" style={{ columnGap: 10 }}>
									<div
										style={{
											height: 30,
											border: '2px solid',
											borderColor: theme.colors.primary,
											color: theme.colors.primary,
											fontWeight: 'bold',
											fontSize: 14,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											borderRadius: 5,
											paddingInline: 10,
											cursor: 'pointer',
										}}
										onClick={clearDrawing}
									>
										Clear
									</div>
									<div
										style={{
											height: 30,
											border: '2px solid',
											borderColor: theme.colors.primary,
											color: theme.colors.primary,
											fontWeight: 'bold',
											fontSize: 14,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											borderRadius: 5,
											paddingInline: 10,
											cursor: 'pointer',
										}}
										onClick={undoDrawing}
									>
										Undo
									</div>
								</div>
								<div className="flex flex-1 flex-end" style={{ columnGap: 5 }}>
									{['#FFFFFF', '#000000', '#268E6C', '#1473E6', '#D7373F'].map(
										(color) => (
											<button
												key={color}
												style={{
													backgroundColor: color,
													width: 30,
													height: 30,
													border: '2px solid gray',
													borderColor:
														color === drawingColor ? 'white' : 'gray',
													cursor: 'pointer',
													borderRadius: '18px',
												}}
												onClick={() => setDrawingColor(color)}
											/>
										),
									)}
								</div>
							</div>

							{selectedImage && (
								<img
									src={selectedImage}
									alt="Selected Anatomy"
									className="mb-4"
									style={{
										width: '400px',
										height: '400px',
										objectFit: 'contain',
										position: 'absolute',
										zIndex: 0,
									}}
								/>
							)}

							<ReactSketchCanvas
								ref={sketchCanvasRef}
								width="400px"
								height="400px"
								strokeColor={drawingColor}
								strokeWidth={5}
								className="mt-4 border"
								style={{
									position: 'relative',
									zIndex: 1,
								}}
								canvasColor="transparent"
							/>
						</>
					)}

					<Separator className="mt-4" />
					<input
						type="file"
						ref={fileInputRef}
						onChange={handleImageUpload}
						style={{ display: 'none' }}
					/>
					<div className="flex justify-between mt-4 w-full">
						<div style={{ display: 'flex', flex: 1 }}>
							<Button
								onClick={() =>
									selectedImage ? setSelectedImage(null) : onClose()
								}
								type="SOLIDASH"
							>
								Close
							</Button>
						</div>
						<div style={{ display: 'flex', flex: 1 }}></div>
						<div style={{ display: 'flex', flex: 1 }}>
							{!selectedImage ? (
								<Button onClick={handleUploadClick} type="OUTLINE">
									Upload Other Picture
								</Button>
							) : (
								<Button onClick={handleSave} type="SOLID">
									Save
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
		</Modal>
	);
};

export default ImageModal;
