import { ChevronRight, Info, Search } from 'react-feather';
import { theme } from '../../../../assets/theme';
import useFetchList from '../../../../hooks/useFetchList';
import { EmrProperties } from '../../../../types/emr.type';
import EmptyList from '../../../EmptyList';
import FormInput from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import Spinner from '../../../Spinner';
import { Button } from '../../../Button/Button/Button';
import React, { useEffect, useState } from 'react';
import { getUser } from '../../../../helpers/auth';
import { useHistory } from 'react-router-dom';
import { calculateAge } from '../../../../helpers/datetime';
import { getName } from '../../../../helpers/name';

interface Props {
	setSelectedPatient: React.Dispatch<
		React.SetStateAction<EmrProperties | undefined>
	>;
	setNextStep: () => void;
	search?: string;
	isFromCreateEmr?: boolean;
}

export default function ListPatient(props: Props) {
	const user = getUser();
	const history = useHistory();
	const {
		data: patients,
		isLoading,
		setSearch,
		search,
		fetchList,
	} = useFetchList<EmrProperties>({
		endpoint: 'emr',
		initialQuery: {
			doctorId: user?.userId,
			isPatientUnique: 'true',
		},
		limit: 1000000,
		primaryKey: 'emrId',
	});

	const [sortOrder, setSortOrder] = useState<'nameAsc' | 'nameDesc' | 'latest'>(
		'nameAsc',
	);

	const handleSearchChange = async (val: string) => {
		setSearch(val);
		console.log(val);
		await fetchList();
	};

	const onClickItem = (item: EmrProperties) => {
		if (props.isFromCreateEmr) {
			history.push({
				pathname: '/app/emr/create',
				state: { selectedPatient: item },
			});
		} else {
			props.setSelectedPatient(item);
			props.setNextStep();
		}
	};

	const uniquePatients = patients.reduce<EmrProperties[]>((acc, current) => {
		if (!current.patient && !current.patientInRelation) {
			return acc;
		}

		const duplicate = acc.find(
			(patient) => patient.patientId === current.patientId,
		);

		if (!duplicate) {
			acc.push(current);
		}
		return acc;
	}, []);

	const sortedPatients = uniquePatients.sort((a, b) => {
		const getPatientName = (patient: EmrProperties) =>
			patient.patient?.name || getName(patient.patientInRelation);

		switch (sortOrder) {
			case 'nameAsc':
				return getPatientName(a).localeCompare(getPatientName(b));
			case 'nameDesc':
				return getPatientName(b).localeCompare(getPatientName(a));
			case 'latest':
				return (
					new Date(b.updatedAt || 0).getTime() -
					new Date(a.updatedAt || 0).getTime()
				);
			default:
				return 0;
		}
	});

	const handleSortChange = (order: 'nameAsc' | 'nameDesc' | 'latest') => {
		setSortOrder(order);
	};

	return (
		<div className="flex-1 flex flex-col">
			<div className="px-3 py-1">
				<FormInput
					prefix={
						<Search width={20} height={20} color={theme.colors.charcoal200} />
					}
					placeholder="Search Patient"
					value={search}
					onChange={handleSearchChange}
				/>
			</div>

			<div className="px-3 py-2">
				<div className="flex gap-x-2">
					<div className="ml-4">
						<Button
							type={sortOrder === 'nameAsc' ? 'OUTLINE' : 'SOLIDASH'}
							onClick={() => handleSortChange('nameAsc')}
						>
							A-Z
						</Button>
					</div>

					<div className="ml-4">
						<Button
							type={sortOrder === 'nameDesc' ? 'OUTLINE' : 'SOLIDASH'}
							onClick={() => handleSortChange('nameDesc')}
						>
							Z-A
						</Button>
					</div>

					<div className="ml-4">
						<Button
							type={sortOrder === 'latest' ? 'OUTLINE' : 'SOLIDASH'}
							onClick={() => handleSortChange('latest')}
						>
							Latest
						</Button>
					</div>
				</div>
			</div>

			<div className="flex-1 min-h-0">
				<div className="px-3">
					{!isLoading ? (
						sortedPatients.length > 0 ? (
							sortedPatients.map((patient) => (
								<div key={patient.patientId}>
									<div className="py-4">
										<button
											onClick={() => onClickItem(patient)}
											className="bg-transparent border-none text-left w-full cursor-pointer flex items-center gap-x-2"
										>
											<div className="flex justify-between items-center w-full">
												<div className="flex flex-col">
													<p
														className="m-0 flex-1 min-w-0"
														style={{ fontWeight: 600, fontSize: 16 }}
													>
														{patient?.patient?.name ||
															getName(patient?.patientInRelation)}
													</p>
													<div
														className="flex gap-3"
														style={{
															fontWeight: 400,
															fontSize: 14,
														}}
													>
														{patient?.patient?.birthdate ? (
															<div>
																{calculateAge(patient?.patient?.birthdate)} y.o.
															</div>
														) : patient?.patientInRelation?.birthdate ? (
															<div>
																{calculateAge(
																	patient?.patientInRelation?.birthdate,
																)}{' '}
																y.o.
															</div>
														) : (
															''
														)}

														<div
															className="m-0 flex-1 min-w-0"
															style={{ color: theme.colors.black60 }}
														>
															{patient?.patient?.phone
																? `+63 ${patient?.patient?.phone?.substring(2)}`
																: patient.patientInRelation?.patient?.user
																		?.phone
																? `+63 ${patient.patientInRelation?.patient?.user?.phone?.substring(
																		2,
																  )}`
																: ''}
														</div>
													</div>
												</div>
												<ChevronRight
													width={20}
													height={20}
													color={theme.colors.black30}
												/>
											</div>
										</button>
									</div>
									<Separator />
								</div>
							))
						) : (
							<EmptyList
								icon={<Info color={theme.colors.black50} />}
								title="No patients found"
							/>
						)
					) : (
						<div className="py-4 mx-auto w-fit">
							<Spinner size={25} color={theme.colors.primary} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
