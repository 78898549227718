import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../../../assets/theme';
import { httpRequest } from '../../../helpers/api';
import { getErrorMessage } from '../../../helpers/errorHandler';
import { ApiResponseDefault } from '../../../types/apiResponse.type';
import { BalanceProperties } from '../../../types/balance.type';
import Card from '../../Card';
import { ReactComponent as Spinner } from '../../../assets/icons/spinner.svg';
import { Button, message } from 'antd';
import { useAuth } from '../../../context/auth.context';
import { formatNumber } from '../../../utils/commons';

export default function CardBalance() {
	const history = useHistory();
	const { user } = useAuth();
	const [balance, setBalance] = useState<number>();

	useEffect(() => {
		if (!user?.userId) return;

		(async () => {
			try {
				const res = await httpRequest.get<
					ApiResponseDefault<BalanceProperties>
				>(`/balances/${user.userId}`);
				setBalance(res.data.payload.currentAmount);
			} catch (err) {
				const error = getErrorMessage(err);
				console.error(error);
				message.error(error);
			}
		})();
	}, [user?.userId]);

	return (
		<Card className="w-full p-4">
			<div className="relative">
				<CustomButton onClick={() => history.push('app/balance', { tab: '0' })}>
					View Detail
				</CustomButton>
				<IconSquareRounded>
					<img src="/icons/walled-red.svg" alt="Wallet" />
				</IconSquareRounded>
				<div className="mt-3">
					{balance !== undefined ? (
						<CardTitleBig>{formatNumber(balance, true)}</CardTitleBig>
					) : (
						<Spinner width={27.5} height={27.5} color={theme.colors.primary} />
					)}
					<TextGray70>Professional fee</TextGray70>
				</div>
			</div>
		</Card>
	);
}
const CustomButton = styled.button`
	background-color: transparent;
	border: none;
	padding: 12px;
	cursor: pointer;
	color: #748494;
	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}
	position: absolute;
	top: 0;
	right: 0;
`;

const IconSquareRounded = styled.div`
	width: fit-content;
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.colors.primary10};
	border-radius: 12px;
`;

const CardTitleBig = styled.p`
	margin: 0;
	font-weight: 600;
	font-size: 24px;
	color: ${({ theme }) => theme.colors.gray100};
`;

const TextGray70 = styled.p`
	margin: 0;
	color: ${({ theme }) => theme.colors.gray70};
`;
