import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import './styles.less';
import VitalSignDetail from '../DetailEmr/VitalSign/MainVitalSignDetail';
import { EmrProperties } from '../../../../types/emr.type';
import HPIDetail from './../DetailEmr/HPI/HPI';
import { useLocation } from 'react-router-dom';
import MedicalHistoryDetail from '../DetailEmr/PatientHistory/MedicalHistory';
import AssesmentDetail from '../DetailEmr/ObjecttiveFindings/Assesment';
import DiagnosisDetail from '../DetailEmr/Diagnosis/Diagnosis';
import PlanDetail from '../DetailEmr/Plan/PLAN';
import HistoryDetail from '../DetailEmr/History/DetailHistory';
import BillingDetail from '../DetailEmr/Billing/Billing';
import styled from 'styled-components';
import { theme } from '../../../../assets/theme';

const { TabPane } = Tabs;

interface Props {
	patient?: EmrProperties;
	isLoading: boolean;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	nextStep: (newStep?: number) => void;
	setPreviewDocument: React.Dispatch<
		React.SetStateAction<{ [key: string]: any }>
	>;
	setPatient: React.Dispatch<React.SetStateAction<EmrProperties | undefined>>;
}

export default function DetailEmrs({
	patient,
	isLoading,
	nextStep,
	setIsLoading,
	setPreviewDocument,
	setPatient,
}: Props) {
	const [activeTab, setActiveTab] = useState('1');
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const tab = queryParams.get('tab');
		if (tab) {
			setActiveTab(tab);
		}
	}, [location.search]);

	return (
		<div className="patient-detail-container">
			<TabStyle>
				<Tabs activeKey={activeTab} onChange={setActiveTab} centered>
					<TabPane tab="Overview" key="1">
						<HistoryDetail patient={patient} emrId={patient?.emrId} />
					</TabPane>
					<TabPane tab="Vital Signs" key="2">
						<VitalSignDetail
							labRequest={{ labRequestId: undefined }}
							pageType="detail-emr"
							emrId={patient?.emrId}
							patient={patient}
							nextStep={nextStep}
						/>
					</TabPane>
					<TabPane tab="HPI" key="3">
						<HPIDetail patient={patient!} isLoading={isLoading} />
					</TabPane>
					<TabPane tab="Patient History" key="4">
						<MedicalHistoryDetail patient={patient!} isLoading={isLoading} />
					</TabPane>
					<TabPane tab="Objective Findings" key="5">
						<AssesmentDetail
							patient={patient!}
							isLoading={isLoading}
							nextStep={nextStep}
						/>
					</TabPane>
					<TabPane tab="Diagnosis" key="6">
						<DiagnosisDetail patient={patient!} isLoading={isLoading} />
					</TabPane>
					<TabPane tab="Plan" key="7">
						<PlanDetail
							patient={patient}
							isLoading={isLoading}
							nextStep={nextStep}
							setPreviewDocument={setPreviewDocument}
						/>
					</TabPane>
					<TabPane tab="Billing" key="8">
						<BillingDetail
							patient={patient}
							isLoading={isLoading}
							setIsLoading={setIsLoading}
							setPatient={setPatient}
						/>
					</TabPane>
				</Tabs>
			</TabStyle>
		</div>
	);
}

const TabStyle = styled.div`
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: black !important;
		font-weight: 500 !important;
	}

	.ant-tabs-tab {
		color: ${theme.colors.charcoal300} !important;
		font-weight: 500 !important;
	}

	.ant-tabs-ink-bar {
		height: 4px !important;
	}
`;
