export type TemplateEMRBillingProperties = {
    templateEmrBillingId?: string;
    name?: string;
		title?: string;
    paymentMethod?: EPaymentMethodEMR;
    metaItems?: any[];
    doctorId?: string;
    saveFromId?: string;
    updatedAt?: Date;
    createdAt?: Date;
};

export enum EPaymentMethodEMR {
    CASH = 'CASH',
    INSURANCE = 'INSURANCE',
    IN_APP = 'IN_APP',
    NO_CHARGE = 'NO_CHARGE',
}
