import styled from 'styled-components';
import { Button } from '../../../../Button/Button/Button';

type Props = {
	nextStep: (newStep?: number) => void;
	typeTitle: string;
	pageType: 'detail-patient' | 'detail-emr' | 'trends';
};

export default function VitalSignTypeHeader(props: Props) {
	const { typeTitle, pageType } = props;
	return (
		<>
			<HeaderContainer>
				<div className="text-4\.5 font-semibold">{typeTitle}</div>
				{pageType !== 'trends' && (
					<div>
						<Button
							type="TRANSPARENT"
							onClick={() => {
								props.nextStep();
							}}
							className="p-0-important"
						>
							See Trends
						</Button>
					</div>
				)}
			</HeaderContainer>
		</>
	);
}

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: end;
`;
