import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EmrProperties } from '../../../../../types/emr.type';
import EmptyList from '../../../../EmptyList';
import Separator from '../../../../Separator';
import { httpRequest } from '../../../../../helpers/api';
import { ApiResponseDefault } from '../../../../../types/apiResponse.type';
import { ChevronRight, Info, Search } from 'react-feather';
import { theme } from '../../../../../assets/theme';
import { calculateAge } from '../../../../../helpers/datetime';
import { Tabs, Avatar, Tag } from 'antd';
import { renderStatusTag } from '../../StatusTag';
import { getName } from '../../../../../helpers/name';
import styled from 'styled-components';
import DEFAULT_USER from '../../../../../assets/default-user2.png';
import {
	capitalizeWords,
	convertToTitleCase,
} from '../../../../../helpers/text';
import Spinner from '../../../../Spinner';

interface Props {
	patient?: EmrProperties;
	emrId?: any;
}

export default function HistoryDetail({ emrId, patient }: Props) {
	const [similarPatients, setSimilarPatients] = useState<EmrProperties | null>(
		null,
	);
	const [isLoading, setIsLoading] = useState(true);
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (patient?.patientId) {
			const fetchSimilarPatients = async () => {
				try {
					setIsLoading(true);
					const response = await httpRequest.get<ApiResponseDefault<any>>(
						`/emr?patientId=${patient.patientId}`,
					);
					const foundData = response.data.payload.results.find(
						(patient: any) => patient.emrId === emrId,
					);
					setSimilarPatients(foundData);
				} catch (error) {
					console.error('Error fetching similar patients:', error);
				} finally {
					setIsLoading(false);
					setInitialized(true);
				}
			};

			fetchSimilarPatients();
		} else {
			setIsLoading(false);
			setInitialized(false);
		}
	}, [emrId, patient]);

	const formatDate = (dateString: any) => {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		};
		return new Date(dateString).toLocaleDateString('en-US', options);
	};

	return (
		<div className="flex-1 flex flex-col">
			<div className="py-6 px-3 flex-1">
				{isLoading || !initialized ? (
					<div className="py-4 mx-auto w-fit">
						<Spinner size={25} color={theme.colors.primary} />
					</div>
				) : !similarPatients?.patient && !similarPatients?.patientInRelation ? (
					<EmptyList
						icon={<Info color={theme.colors.black50} />}
						title="Data Empty"
						description="No history of history data for this patient."
					/>
				) : (
					<>
						<div>
							<div className="flex justify-between items-center mb-2">
								<p className="m-0 text-3.5 text-charcoal-300">Status</p>
								<TagStyle className="m-0 text-4">
									{renderStatusTag(
										(similarPatients?.transactionEMRs?.[0]
											?.transactionStatus as string) || '',
									)}
								</TagStyle>
							</div>
							<div className="flex justify-between items-center mb-2">
								<p className="m-0 text-3.5 text-charcoal-300">Created At</p>
								<p className="m-0 text-4">
									{formatDate(similarPatients.createdAt) || '-'}
								</p>
							</div>
							<div className="flex justify-between items-center mb-2">
								<p className="m-0 text-3.5 text-charcoal-300">By</p>
								<p className="m-0 text-4">
									{similarPatients.doctor.name || '-'}
								</p>
							</div>
							<div className="flex justify-between items-center mb-2">
								<p className="m-0 text-3.5 text-charcoal-300">
									Settled/Unsettled
								</p>
								<p className="m-0 text-3.5">
									{similarPatients.isSettled === true
										? 'Settled'
										: similarPatients.isSettled === false
										? 'Unsettled'
										: '-'}
								</p>
							</div>
						</div>

						<Separator className="mb-4 mt-4" />
						<div className="flex justify-center mb-4">
							<Avatar
								size={187}
								src={
									similarPatients?.patientPictureUrl ||
									similarPatients?.patientInRelation?.profilePictureUrl ||
									DEFAULT_USER
								}
								alt={
									similarPatients?.patient?.name ||
									getName(similarPatients?.patientInRelation)
								}
								style={{ borderRadius: '10px' }}
							/>
						</div>
						<div className="mb-3">
							<p className="m-0 text-3.5 font-medium text-charcoal-200">Name</p>
							<p className="m-0 text-4\.5">
								{similarPatients?.patient?.name ||
									getName(similarPatients?.patientInRelation) ||
									'-'}
							</p>
						</div>
						<div className="mb-3">
							<p className="m-0 text-3.5 font-medium text-charcoal-200">
								Gender
							</p>
							<p className="m-0 text-4\.5">
								{capitalizeWords(
									similarPatients?.patient?.gender ||
										similarPatients?.patientInRelation?.gender ||
										'-',
								)}
							</p>
						</div>
						<div className="mb-3">
							<p className="m-0 text-3.5 font-medium text-charcoal-200">Age</p>
							<p className="m-0 text-4\.5">
								{calculateAge(similarPatients?.patient?.birthdate) !== undefined
									? calculateAge(similarPatients?.patient?.birthdate)
									: calculateAge(
											similarPatients?.patientInRelation?.birthdate,
									  )}{' '}
								years
							</p>
						</div>
						<div className="mb-3">
							<p className="m-0 text-3.5 font-medium text-charcoal-200">
								Status
							</p>
							<p className="m-0 text-4\.5">
								{capitalizeWords(
									similarPatients?.patient?.maritalStatus ||
										similarPatients?.patientInRelation?.maritalStatus ||
										'-',
								)}
							</p>
						</div>
						<div className="mb-3">
							<p className="m-0 text-3.5 font-medium text-charcoal-200">
								Phone Number
							</p>
							<p className={`m-0 text-4\.5 text-blue`}>
								{similarPatients?.patient?.phone
									? `+63 ${similarPatients?.patient?.phone?.substring(2)}`
									: similarPatients?.patientInRelation?.patient?.user?.phone
									? `+63 ${similarPatients?.patientInRelation?.patient?.user?.phone?.substring(
											2,
									  )}`
									: '-'}
							</p>
						</div>
						<div className="mb-3">
							<p className="m-0 text-3.5 font-medium text-charcoal-200">
								Address
							</p>
							<p className="m-0 text-4\.5">
								{capitalizeWords(
									similarPatients?.patient?.userAddress?.addressDetail ||
										similarPatients?.patientInRelation?.patient?.user
											?.userAddress?.addressDetail ||
										'-',
								)}
							</p>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

const TagStyle = styled.p`
	.ant-tag {
		margin: 0px !important;
	}
`;
