import { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { Plus, Trash2, Edit } from 'react-feather';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import { Button } from '../../../../Button/Button/Button';
import { MedicalHistoryProperties } from '../../../../../types/medicalHistory.type';
import { apiCreateMedicalHistory } from '../../../../../api/emr';
import ModalFormAddVaccine from './ModalVaccine';
import { theme } from '../../../../../assets/theme';
import DetailPatient from '../../PatientList/DetailPatient';
import { apiGetEmr } from '../../../../../api/emr';
import { EmrProperties } from '../../../../../types/emr.type';
import { message } from 'antd';
import EmptyList from '../../../../EmptyList';
import moment from 'moment';
import { iconEmptySecretary as IconEmpty } from '../../../../../assets/icons';
import ModalConfirmation, { EModalConfirmationType } from './ModalConfirmation';
import styled from 'styled-components';

const { TabPane } = Tabs;

interface Props {
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	step: number;
	setNextStep: () => void;
	setFormName: () => void;
	patientMedicalHistory: any;
	setPatientMedicalHistory: React.Dispatch<React.SetStateAction<any>>;
}

export default function FormCreatePatientHistory(props: Props) {
	const [isLoading, setIsLoading] = useState(false);
	const [showModalAddMedicine, setShowModalAddMedicine] = useState(false);
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);
	const [vaccineToDeleteIndex, setVaccineToDeleteIndex] = useState<
		number | null
	>(null);
	const [vaccines, setVaccines] = useState<
		{
			vaccineName: string;
			date: string;
			nextDose: string;
			adverseReaction: string;
		}[]
	>([]);
	const [gender, setGender] = useState<string | undefined>(undefined);
	const [activeKey, setActiveKey] = useState('1');

	useEffect(() => {
		const fetchEmrData = async () => {
			if (props.emr.emrId) {
				try {
					const data = await apiGetEmr(props.emr.emrId);
					setGender(data?.patient?.gender || data?.patientInRelation?.gender);
					const vaccineData =
						data?.medicalHistory?.postMedicalHistory?.vaccines || [];
					setVaccines(vaccineData);
					console.log('vaccine data', vaccineData);
				} catch (error) {
					console.error('Error fetching EMR data:', error);
				}
			}
		};
		fetchEmrData();
	}, [props.emr.emrId]);

	const handleSaveVaccine = (data: {
		vaccineName: string;
		date: string;
		nextDose: string;
		adverseReaction: string;
	}) => {
		setVaccines((prevVaccines) => [...prevVaccines, data]);
		setShowModalAddMedicine(false);
		message.success('Vaccine saved successfully');
	};

	// const handleDeleteVaccine = (index: number) => {
	// 	setVaccines((prevVaccines) => prevVaccines.filter((_, i) => i !== index));
	// };
	const handleDeleteVaccine = () => {
		if (vaccineToDeleteIndex !== null) {
			setVaccines((prevVaccines) =>
				prevVaccines.filter((_, i) => i !== vaccineToDeleteIndex),
			);
		}
		setIsModalConfirmationVisible(false);
	};

	const showDeleteModal = (index: number) => {
		setVaccineToDeleteIndex(index);
		setIsModalConfirmationVisible(true);
	};

	const handleFieldChange = (fieldName: any, change: number) => {
		props.setPatientMedicalHistory((old: any) => {
			const newValue = (old?.obgyn?.[fieldName] || 0) + change;
			return {
				...old,
				obgyn: {
					...old.obgyn,
					[fieldName]: newValue >= 0 ? newValue : 0,
				},
			};
		});
	};

	// Initial Value for Edit Emr
	useEffect(() => {
		if (!props.emr.medicalHistoryId) return;

		props.setPatientMedicalHistory({
			...props.patientMedicalHistory,
			postMedicalHistory: {
				...props.emr.medicalHistory?.postMedicalHistory,
				isHypertension:
					props.emr.medicalHistory?.postMedicalHistory?.isHypertension,
				noteHypertension:
					props.emr.medicalHistory?.postMedicalHistory?.noteHypertension,
				isDiabetes: props.emr.medicalHistory?.postMedicalHistory?.isDiabetes,
				noteDiabetes:
					props.emr.medicalHistory?.postMedicalHistory?.noteDiabetes,
				isAsthma: props.emr.medicalHistory?.postMedicalHistory?.isAsthma,
				isCkd: props.emr.medicalHistory?.postMedicalHistory?.isCkd,
				noteCkd: props.emr.medicalHistory?.postMedicalHistory?.noteCkd,
				isCad: props.emr.medicalHistory?.postMedicalHistory?.isCad,
				noteCad: props.emr.medicalHistory?.postMedicalHistory?.noteCad,
				isAllergies: props.emr.medicalHistory?.postMedicalHistory?.isAllergies,
				noteAllergies:
					props.emr.medicalHistory?.postMedicalHistory?.noteAllergies,
				isSurgicalProcedure:
					props.emr.medicalHistory?.postMedicalHistory?.isSurgicalProcedure,
				noteSurgicalProcedure:
					props.emr.medicalHistory?.postMedicalHistory?.noteSurgicalProcedure,
				isTraumaAccident:
					props.emr.medicalHistory?.postMedicalHistory?.isTraumaAccident,
				noteTraumaAccident:
					props.emr.medicalHistory?.postMedicalHistory?.noteTraumaAccident,
				isImmunization:
					props.emr.medicalHistory?.postMedicalHistory?.isImmunization,
				others: props.emr.medicalHistory?.postMedicalHistory?.others,
			},
			obgyn: {
				...props.emr.medicalHistory?.obgyn,
				menarche: props.emr.medicalHistory?.obgyn?.menarche,
				cycle: props.emr.medicalHistory?.obgyn?.cycle,
				duration: props.emr.medicalHistory?.obgyn?.duration,
				menstrualSymptoms: props.emr.medicalHistory?.obgyn?.menstrualSymptoms,
				lmp: props.emr.medicalHistory?.obgyn?.lmp,
				pnm: props.emr.medicalHistory?.obgyn?.pnm,
				ageOfMenopause: props.emr.medicalHistory?.obgyn?.ageOfMenopause,
				isContraceptiveHistory:
					props.emr.medicalHistory?.obgyn?.isContraceptiveHistory,
				noteContraceptiveHistory:
					props.emr.medicalHistory?.obgyn?.noteContraceptiveHistory,
				gravida: props.emr.medicalHistory?.obgyn?.gravida,
				para: props.emr.medicalHistory?.obgyn?.para,
				fullTerm: props.emr.medicalHistory?.obgyn?.fullTerm,
				preterm: props.emr.medicalHistory?.obgyn?.preterm,
				abortion: props.emr.medicalHistory?.obgyn?.abortion,
				livingBabies: props.emr.medicalHistory?.obgyn?.livingBabies,
			},
			socialHistory: {
				...props.emr.medicalHistory?.socialHistory,
				isAlcohol: props.emr.medicalHistory?.socialHistory?.isAlcohol,
				alcohol: props.emr.medicalHistory?.socialHistory?.alcohol,
				isSmoking: props.emr.medicalHistory?.socialHistory?.isSmoking,
				smoking: props.emr.medicalHistory?.socialHistory?.smoking,
				isDrugUse: props.emr.medicalHistory?.socialHistory?.isDrugUse,
				drugUse: props.emr.medicalHistory?.socialHistory?.drugUse,
				others: props.emr.medicalHistory?.socialHistory?.others,
			},
			familyHistory: {
				...props.emr.medicalHistory?.familyHistory,
				isBronchialAsthma:
					props.emr.medicalHistory?.familyHistory?.isBronchialAsthma,
				bronchialAsthma:
					props.emr.medicalHistory?.familyHistory?.bronchialAsthma,
				isDiabetes: props.emr.medicalHistory?.familyHistory?.isDiabetes,
				diabetes: props.emr.medicalHistory?.familyHistory?.diabetes,
				isHypertension: props.emr.medicalHistory?.familyHistory?.isHypertension,
				hypertension: props.emr.medicalHistory?.familyHistory?.hypertension,
				isIhd: props.emr.medicalHistory?.familyHistory?.isIhd,
				ihd: props.emr.medicalHistory?.familyHistory?.ihd,
				isCancer: props.emr.medicalHistory?.familyHistory?.isCancer,
				cancer: props.emr.medicalHistory?.familyHistory?.cancer,
				isKidneyDisease:
					props.emr.medicalHistory?.familyHistory?.isKidneyDisease,
				kidneyDisease: props.emr.medicalHistory?.familyHistory?.kidneyDisease,
				isThyroidProblems:
					props.emr.medicalHistory?.familyHistory?.isThyroidProblems,
				thyroidProblems:
					props.emr.medicalHistory?.familyHistory?.thyroidProblems,
				isBloodDyscrasia:
					props.emr.medicalHistory?.familyHistory?.isBloodDyscrasia,
				bloodDyscrasia: props.emr.medicalHistory?.familyHistory?.bloodDyscrasia,
				others: props.emr.medicalHistory?.familyHistory?.others,
			},
		});
	}, []);

	const saveMedicalHistory = async () => {
		setIsLoading(true);
		let isSuccess = false;

		const { emr, setEmr, patientMedicalHistory, setNextStep, setFormName } =
			props;

		const data: MedicalHistoryProperties = {
			...emr.medicalHistory,
			emrId: emr.emrId,
			postMedicalHistory: {
				isHypertension:
					patientMedicalHistory?.postMedicalHistory?.isHypertension,
				noteHypertension:
					patientMedicalHistory?.postMedicalHistory?.noteHypertension,
				isDiabetes: patientMedicalHistory?.postMedicalHistory?.isDiabetes,
				noteDiabetes: patientMedicalHistory?.postMedicalHistory?.noteDiabetes,
				isAsthma: patientMedicalHistory?.postMedicalHistory?.isAsthma,
				noteAsthma: patientMedicalHistory?.postMedicalHistory?.noteAsthma,
				isCkd: patientMedicalHistory?.postMedicalHistory?.isCkd,
				noteCkd: patientMedicalHistory?.postMedicalHistory?.noteCkd,
				isCad: patientMedicalHistory?.postMedicalHistory?.isCad,
				noteCad: patientMedicalHistory?.postMedicalHistory?.noteCad,
				isAllergies: patientMedicalHistory?.postMedicalHistory?.isAllergies,
				noteAllergies: patientMedicalHistory?.postMedicalHistory?.noteAllergies,
				otherCommorbideIllness:
					patientMedicalHistory?.postMedicalHistory?.otherCommorbideIllness,
				isSurgicalProcedure:
					patientMedicalHistory?.postMedicalHistory?.isSurgicalProcedure,
				noteSurgicalProcedure:
					patientMedicalHistory?.postMedicalHistory?.noteSurgicalProcedure,
				isTraumaAccident:
					patientMedicalHistory?.postMedicalHistory?.isTraumaAccident,
				noteTraumaAccident:
					patientMedicalHistory?.postMedicalHistory?.noteTraumaAccident,
				isImmunization:
					patientMedicalHistory?.postMedicalHistory?.isImmunization,
				others: patientMedicalHistory?.postMedicalHistory?.others,
				vaccines: vaccines,
			},
			obgyn: {
				menarche: patientMedicalHistory?.obgyn?.menarche,
				cycle: patientMedicalHistory?.obgyn?.cycle,
				duration: patientMedicalHistory?.obgyn?.duration,
				menstrualSymptoms: patientMedicalHistory?.obgyn?.menstrualSymptoms,
				lmp: patientMedicalHistory?.obgyn?.lmp,
				pnm: patientMedicalHistory?.obgyn?.pnm,
				ageOfMenopause: patientMedicalHistory?.obgyn?.ageOfMenopause,
				isContraceptiveHistory:
					patientMedicalHistory?.obgyn?.isContraceptiveHistory,
				noteContraceptiveHistory:
					patientMedicalHistory?.obgyn?.noteContraceptiveHistory,
				gravida: patientMedicalHistory?.obgyn?.gravida,
				para: patientMedicalHistory?.obgyn?.para,
				fullTerm: patientMedicalHistory?.obgyn?.fullTerm,
				preTerm: patientMedicalHistory?.obgyn?.preTerm,
				abortion: patientMedicalHistory?.obgyn?.abortion,
				livingBabies: patientMedicalHistory?.obgyn?.livingBabies,
			},
			socialHistory: {
				isAlcohol: patientMedicalHistory?.socialHistory?.isAlcohol,
				alcohol: patientMedicalHistory?.socialHistory?.alcohol,
				smoking: patientMedicalHistory?.socialHistory?.smoking,
				isSmoking: patientMedicalHistory?.socialHistory?.isSmoking,
				drugUse: patientMedicalHistory?.socialHistory?.drugUse,
				isDrugUse: patientMedicalHistory?.socialHistory?.isDrugUse,
				others: patientMedicalHistory?.socialHistory?.others,
			},
			familyHistory: {
				isBronchialAsthma:
					patientMedicalHistory?.familyHistory?.isBronchialAsthma,
				bronchialAsthma: patientMedicalHistory?.familyHistory?.bronchialAsthma,
				diabetes: patientMedicalHistory?.familyHistory?.diabetes,
				isDiabetes: patientMedicalHistory?.familyHistory?.isDiabetes,
				hypertension: patientMedicalHistory?.familyHistory?.hypertension,
				isHypertension: patientMedicalHistory?.familyHistory?.isHypertension,
				ihd: patientMedicalHistory?.familyHistory?.ihd,
				isIhd: patientMedicalHistory?.familyHistory?.isIhd,
				cancer: patientMedicalHistory?.familyHistory?.cancer,
				isCancer: patientMedicalHistory?.familyHistory?.isCancer,
				kidneyDisease: patientMedicalHistory?.familyHistory?.kidneyDisease,
				isKidneyDisease: patientMedicalHistory?.familyHistory?.isKidneyDisease,
				isThyroidProblems:
					patientMedicalHistory?.familyHistory?.isThyroidProblems,
				thyroidProblems: patientMedicalHistory?.familyHistory?.thyroidProblems,
				bloodDyscrasia: patientMedicalHistory?.familyHistory?.bloodDyscrasia,
				isBloodDyscrasia:
					patientMedicalHistory?.familyHistory?.isBloodDyscrasia,
				others: patientMedicalHistory?.familyHistory?.others,
			},
		};

		try {
			if (!emr.emrId) {
				throw new Error('EMR ID is required.');
			}

			const res = await apiCreateMedicalHistory(
				emr.emrId,
				'medical_history',
				data,
			);
			if (res) {
				setEmr({
					...emr,
					...res,
				});
				isSuccess = true;
			}

			if (isSuccess) {
				setNextStep();
				setFormName();
				message.success('Medical History Saved Successfully');
			}
		} catch (error) {
			console.error('Error saving medical History:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const tabMap: Record<string, string> = {
		'Past Medical History': '1',
		'OB/GYN': '2',
		'Social History': '3',
		'Family History': '4',
	};

	const handleButtonClick = () => {
		const nextKey = (parseInt(activeKey, 10) + 1).toString();
		if (nextKey <= '4') {
			setActiveKey(nextKey);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		} else {
			saveMedicalHistory();
		}
	};

	const handleBackClick = () => {
		const prevKey = (parseInt(activeKey, 10) - 1).toString();
		if (prevKey >= '1') {
			setActiveKey(prevKey);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const formatDate = (dateString: string) => {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		};
		return new Date(dateString).toLocaleDateString('en-US', options);
	};

	const onTabChange = (key: string) => {
		setActiveKey(key);
	};

	return (
		<div className="py-6 px-3">
			<div className="pt-2 w-1/2 ml-4">
				<p className="m-0 font-semibold text-5">Patient History</p>
				<p className="m-0 text-4">Past medical, Social and Family history</p>
			</div>
			<div className="flex justify-center mt-4">
				<TabStyle>
					<Tabs
						activeKey={activeKey}
						onChange={(key) => setActiveKey(key)}
						centered
					>
						<TabPane tab="Past Medical History" key="1">
							<div className="py-2 px-3">
								<div className="flex items-start justify-between">
									<div className="pt-2 w-1/4">
										<p className="m-0 font-semibold text-4">
											Past Medical History
										</p>
									</div>
									<div className="flex-1 flex flex-col">
										<div className="flex flex-col">
											<FormInput
												label="Hypertension"
												name="isHypertension"
												type={EFormType.ITEM_PICKER_H}
												items={['Yes', 'No']}
												value={
													props.patientMedicalHistory.postMedicalHistory
														?.isHypertension != null
														? props.patientMedicalHistory.postMedicalHistory
																?.isHypertension
															? 'yes'
															: 'no'
														: ''
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedHistory = {
															...old.postMedicalHistory,
															isHypertension: val === 'yes',
														};
														if (val === 'no') {
															updatedHistory.noteHypertension = '';
														}
														return {
															...old,
															postMedicalHistory: updatedHistory,
														};
													});
												}}
											/>

											{props.patientMedicalHistory?.postMedicalHistory
												?.isHypertension && (
												<FormInput
													label="Hypertension Note"
													name="noteHypertension"
													type={EFormType.MULTI_LINE}
													value={
														props.patientMedicalHistory?.postMedicalHistory
															?.noteHypertension
													}
													onChange={(val) => {
														props.setPatientMedicalHistory((old: any) => {
															const updatedHistory = {
																...old.postMedicalHistory,
																noteHypertension: val,
															};

															return {
																...old,
																postMedicalHistory: updatedHistory,
															};
														});
													}}
												/>
											)}
										</div>
										<div className="flex flex-col">
											<FormInput
												label="Diabetes"
												name="isDiabetes"
												type={EFormType.ITEM_PICKER_H}
												items={['Yes', 'No']}
												value={
													props.patientMedicalHistory.postMedicalHistory
														?.isDiabetes != null
														? props.patientMedicalHistory?.postMedicalHistory
																?.isDiabetes
															? 'yes'
															: 'no'
														: ''
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedHistory = {
															...old.postMedicalHistory,
															isDiabetes: val === 'yes',
														};
														if (val === 'no') {
															updatedHistory.noteDiabetes = '';
														}
														return {
															...old,
															postMedicalHistory: updatedHistory,
														};
													});
												}}
											/>

											{props.patientMedicalHistory?.postMedicalHistory
												?.isDiabetes && (
												<FormInput
													label="Diabetes Note"
													name="noteDiabetes"
													type={EFormType.MULTI_LINE}
													value={
														props.patientMedicalHistory?.postMedicalHistory
															?.noteDiabetes
													}
													onChange={(val) => {
														props.setPatientMedicalHistory((old: any) => {
															const updatedHistory = {
																...old.postMedicalHistory,
																noteDiabetes: val,
															};

															return {
																...old,
																postMedicalHistory: updatedHistory,
															};
														});
													}}
												/>
											)}
										</div>

										<div className="flex flex-col">
											<FormInput
												label="Asthma"
												name="isAsthma"
												type={EFormType.ITEM_PICKER_H}
												items={['Yes', 'No']}
												value={
													props.patientMedicalHistory.postMedicalHistory
														?.isAsthma != null
														? props.patientMedicalHistory?.postMedicalHistory
																?.isAsthma
															? 'yes'
															: 'no'
														: ''
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedHistory = {
															...old.postMedicalHistory,
															isAsthma: val === 'yes',
														};
														if (val === 'no') {
															updatedHistory.noteAsthma = '';
														}
														return {
															...old,
															postMedicalHistory: updatedHistory,
														};
													});
												}}
											/>

											{props.patientMedicalHistory?.postMedicalHistory
												?.isAsthma && (
												<FormInput
													label="Asthma Note"
													name="noteAsthma"
													type={EFormType.MULTI_LINE}
													value={
														props.patientMedicalHistory?.postMedicalHistory
															?.noteAsthma
													}
													onChange={(val) => {
														props.setPatientMedicalHistory((old: any) => {
															const updatedHistory = {
																...old.postMedicalHistory,
																noteAsthma: val,
															};

															return {
																...old,
																postMedicalHistory: updatedHistory,
															};
														});
													}}
												/>
											)}
										</div>

										<div className="flex flex-col">
											<FormInput
												label="Chronic Kidney Disease (CKD)"
												name="isCkd"
												type={EFormType.ITEM_PICKER_H}
												items={['Yes', 'No']}
												value={
													props.patientMedicalHistory.postMedicalHistory
														?.isCkd != null
														? props.patientMedicalHistory?.postMedicalHistory
																?.isCkd
															? 'yes'
															: 'no'
														: ''
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedHistory = {
															...old.postMedicalHistory,
															isCkd: val === 'yes',
														};
														if (val === 'no') {
															updatedHistory.noteCkd = '';
														}
														return {
															...old,
															postMedicalHistory: updatedHistory,
														};
													});
												}}
											/>

											{props.patientMedicalHistory?.postMedicalHistory
												?.isCkd && (
												<FormInput
													label="Chronic Kidney Disease Note"
													name="noteCkd"
													type={EFormType.MULTI_LINE}
													value={
														props.patientMedicalHistory?.postMedicalHistory
															?.noteCkd
													}
													onChange={(val) => {
														props.setPatientMedicalHistory((old: any) => {
															const updatedHistory = {
																...old.postMedicalHistory,
																noteCkd: val,
															};

															return {
																...old,
																postMedicalHistory: updatedHistory,
															};
														});
													}}
												/>
											)}
										</div>

										<div className="flex flex-col">
											<FormInput
												label="Coronary Artery Disease (CAD)"
												name="isCad"
												type={EFormType.ITEM_PICKER_H}
												items={['Yes', 'No']}
												value={
													props.patientMedicalHistory.postMedicalHistory
														?.isCad != null
														? props.patientMedicalHistory?.postMedicalHistory
																?.isCad
															? 'yes'
															: 'no'
														: ''
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedHistory = {
															...old.postMedicalHistory,
															isCad: val === 'yes',
														};
														if (val === 'no') {
															updatedHistory.noteCad = '';
														}
														return {
															...old,
															postMedicalHistory: updatedHistory,
														};
													});
												}}
											/>

											{props.patientMedicalHistory?.postMedicalHistory
												?.isCad && (
												<FormInput
													label="Coronary Artery Disease Note"
													name="noteCad"
													type={EFormType.MULTI_LINE}
													value={
														props.patientMedicalHistory?.postMedicalHistory
															?.noteCad
													}
													onChange={(val) => {
														props.setPatientMedicalHistory((old: any) => {
															const updatedHistory = {
																...old.postMedicalHistory,
																noteCad: val,
															};

															return {
																...old,
																postMedicalHistory: updatedHistory,
															};
														});
													}}
												/>
											)}
										</div>

										<div className="flex flex-col">
											<FormInput
												label="Allergies"
												name="isAllergies"
												type={EFormType.ITEM_PICKER_H}
												items={['Yes', 'No']}
												value={
													props.patientMedicalHistory.postMedicalHistory
														?.isAllergies != null
														? props.patientMedicalHistory?.postMedicalHistory
																?.isAllergies
															? 'yes'
															: 'no'
														: ''
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedHistory = {
															...old.postMedicalHistory,
															isAllergies: val === 'yes',
														};
														if (val === 'no') {
															updatedHistory.noteAllergies = '';
														}
														return {
															...old,
															postMedicalHistory: updatedHistory,
														};
													});
												}}
											/>

											{props.patientMedicalHistory?.postMedicalHistory
												?.isAllergies && (
												<FormInput
													label="Allergies Note"
													name="noteAllergies"
													type={EFormType.MULTI_LINE}
													value={
														props.patientMedicalHistory?.postMedicalHistory
															?.noteAllergies
													}
													onChange={(val) => {
														props.setPatientMedicalHistory((old: any) => {
															const updatedHistory = {
																...old.postMedicalHistory,
																noteAllergies: val,
															};

															return {
																...old,
																postMedicalHistory: updatedHistory,
															};
														});
													}}
												/>
											)}
										</div>
									</div>
								</div>
								<Separator className="my-3" />
								<div className="flex items-start justify-between px-2">
									<div className="pt-2 w-1/4">
										<p className="m-0 font-semibold text-4">
											Surgical Procedures
										</p>
									</div>
									<div className="flex-1 flex flex-col">
										<FormInput
											label="Surgical Procedure"
											name="isSurgicalProcedure"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.postMedicalHistory
													?.isSurgicalProcedure != null
													? props.patientMedicalHistory?.postMedicalHistory
															?.isSurgicalProcedure
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedHistory = {
														...old.postMedicalHistory,
														isSurgicalProcedure: val === 'yes',
													};
													if (val === 'no') {
														updatedHistory.noteSurgicalProcedure = '';
													}
													return { ...old, postMedicalHistory: updatedHistory };
												});
											}}
										/>

										{props.patientMedicalHistory?.postMedicalHistory
											?.isSurgicalProcedure && (
											<FormInput
												label="Surgical Procedure Note"
												name="noteSurgicalProcedure"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.postMedicalHistory
														?.noteSurgicalProcedure
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedHistory = {
															...old.postMedicalHistory,
															noteSurgicalProcedure: val,
														};
														return {
															...old,
															postMedicalHistory: updatedHistory,
														};
													});
												}}
											/>
										)}
									</div>
								</div>

								<Separator className="my-3" />
								<div className="flex items-start justify-between px-2">
									<div className="pt-2 w-1/4">
										<p className="m-0 font-semibold text-4">
											Trauma / Accident
										</p>
									</div>
									<div className="flex-1 flex flex-col">
										<FormInput
											label="Trauma/Accident"
											name="isTraumaAccident"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.postMedicalHistory
													?.isTraumaAccident != null
													? props.patientMedicalHistory?.postMedicalHistory
															?.isTraumaAccident
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedHistory = {
														...old.postMedicalHistory,
														isTraumaAccident: val === 'yes',
													};
													if (val === 'no') {
														updatedHistory.noteTrauma = '';
													}
													return { ...old, postMedicalHistory: updatedHistory };
												});
											}}
										/>

										{props.patientMedicalHistory?.postMedicalHistory
											?.isTraumaAccident && (
											<FormInput
												label="Trauma/Accident Note"
												name="noteTrauma"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.postMedicalHistory
														?.noteTraumaAccident
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedHistory = {
															...old.postMedicalHistory,
															noteTraumaAccident: val,
														};
														return {
															...old,
															postMedicalHistory: updatedHistory,
														};
													});
												}}
											/>
										)}
									</div>
								</div>

								<Separator className="my-3" />
								<div className="flex items-start justify-between px-2">
									<div className="pt-2 w-1/4">
										<p className="m-0 font-semibold text-4">Immunization</p>
									</div>
									<div className="flex-1 flex flex-col">
										<FormInput
											label="Immunization"
											name="isImmunization"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.postMedicalHistory
													?.isImmunization != null
													? props.patientMedicalHistory?.postMedicalHistory
															?.isImmunization
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedHistory = {
														...old.postMedicalHistory,
														isImmunization: val === 'yes',
													};
													return { ...old, postMedicalHistory: updatedHistory };
												});
											}}
										/>

										<div className="flex justify-between mb-5">
											<p className="m-0 text-4">Vaccine List</p>
											<button
												onClick={() => setShowModalAddMedicine(true)}
												type="button"
												className="border-none bg-transparent p-0 flex items-center gap-x-1 text-primary text-4 cursor-pointer"
											>
												<Plus width={24} height={24} />
												Add vaccine
											</button>
										</div>
										<div>
											{vaccines.map((vaccine, index) => (
												<div key={index}>
													<div className="flex justify-between">
														<p className="m-0 font-semibold text-4.5 mb-2">
															{vaccine.vaccineName}
														</p>
														<Trash2
															onClick={() => showDeleteModal(index)}
															className="cursor-pointer "
															color={theme.colors.primary}
															size={18}
														/>
													</div>
													<div className="flex items-center mb-1">
														<p className="m-0 text-charcoal-300 text-3.5 mr-2">
															Date Vaccines
														</p>
														<p className="m-0 text-3.5">
															{formatDate(vaccine.date)}
														</p>
													</div>

													<div className="flex items-center mb-1">
														<p className="m-0 text-charcoal-300 text-3.5 mr-2">
															Next Dose
														</p>
														<p className="m-0 text-3.5">
															{formatDate(vaccine.nextDose)}
														</p>
													</div>
													<p className="m-0 text-charcoal-300 text-3.5">
														Adverse Reaction
													</p>
													<p className="m-0 text-3.5">
														{vaccine.adverseReaction}
													</p>
													<Separator />
												</div>
											))}
										</div>
									</div>
								</div>

								<Separator className="my-3" />
								<div className="flex items-start justify-between px-2">
									<div className="pt-2 w-1/4"></div>

									<div className="flex-1 flex flex-col">
										<FormInput
											label="Others"
											name="others"
											type={EFormType.MULTI_LINE}
											value={
												props.patientMedicalHistory?.postMedicalHistory?.others
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => ({
													...old,
													postMedicalHistory: {
														...old.postMedicalHistory,
														others: val,
													},
												}));
											}}
										/>
									</div>
								</div>
							</div>
						</TabPane>

						<TabPane tab="OB/GYN" key="2">
							{gender !== 'male' && (
								<div className="py-2 px-3">
									<div className="flex items-start justify-between pt-0 px-2">
										<div className="pt-2 w-1/4">
											<p className="m-0 font-semibold text-4">OB/GYN</p>
										</div>
										<div className="flex-1 flex flex-col">
											<FormInput
												label="Menarche"
												name="menarche"
												type={EFormType.SINGLE_LINE}
												value={props.patientMedicalHistory?.obgyn?.menarche}
												suffix={
													<p className="m-0 text-black-60 whitespace-nowrap">
														y.o
													</p>
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => ({
														...old,
														obgyn: {
															...old.obgyn,
															menarche: val,
														},
													}));
												}}
											/>

											<FormInput
												label="Cycle"
												name="cycle"
												type={EFormType.ITEM_PICKER_H}
												items={['regular', 'irregular', 'unknown']}
												value={props.patientMedicalHistory?.obgyn?.cycle}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => ({
														...old,
														obgyn: {
															...old.obgyn,
															cycle: val,
														},
													}));
												}}
											/>
											<FormInput
												label="Duration"
												name="duration"
												type={EFormType.SINGLE_LINE}
												value={props.patientMedicalHistory?.obgyn?.duration}
												suffix={
													<p className="m-0 text-black-60 whitespace-nowrap">
														Days
													</p>
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => ({
														...old,
														obgyn: {
															...old.obgyn,
															duration: val,
														},
													}));
												}}
											/>
											<FormInput
												label="Menstrual Symptoms"
												name="menstrualSymptoms"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.obgyn?.menstrualSymptoms
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => ({
														...old,
														obgyn: {
															...old.obgyn,
															menstrualSymptoms: val,
														},
													}));
												}}
											/>
											<FormInput
												label="Last Menstrual Period (LMP)"
												name="lmp"
												type={EFormType.DATE_PICKER}
												value={props.patientMedicalHistory?.obgyn?.lmp}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => ({
														...old,
														obgyn: {
															...old.obgyn,
															lmp: val,
														},
													}));
												}}
											/>
											<FormInput
												label="Previous Normal Menstruation Period"
												name="pnm"
												type={EFormType.DATE_PICKER}
												value={props.patientMedicalHistory?.obgyn?.pnm}
												disabledDate={(currentDate: moment.Moment | null) => {
													return (
														currentDate != null &&
														currentDate.isAfter(moment().endOf('day'))
													);
												}}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => ({
														...old,
														obgyn: {
															...old.obgyn,
															pnm: val,
														},
													}));
												}}
											/>
											<FormInput
												label="Age of menopause"
												name="ageOfMenopause"
												type={EFormType.SINGLE_LINE}
												value={
													props.patientMedicalHistory?.obgyn?.ageOfMenopause
												}
												suffix={
													<p className="m-0 text-black-60 whitespace-nowrap">
														y.o
													</p>
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => ({
														...old,
														obgyn: {
															...old.obgyn,
															ageOfMenopause: val,
														},
													}));
												}}
											/>
											<FormInput
												label="Contraceptive History"
												name="isContraceptiveHistory"
												type={EFormType.ITEM_PICKER_H}
												items={['Yes', 'No']}
												value={
													props.patientMedicalHistory?.obgyn
														?.isContraceptiveHistory
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => ({
														...old,
														obgyn: {
															...old.obgyn,
															isContraceptiveHistory: val,
														},
													}));
												}}
											/>
											<FormInput
												label=""
												name="noteContraceptiveHistory"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.obgyn
														?.noteContraceptiveHistory
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => ({
														...old,
														obgyn: {
															...old.obgyn,
															noteContraceptiveHistory: val,
														},
													}));
												}}
											/>

											<Separator className="mt-2 mb-4" />
											<div className="flex items-center">
												<div className="w-full">
													<label className="block text-4 text-gray-700 mb-0">
														G (Gravida)
													</label>
													<p className="text-black-60 text-3.5 mb-0">
														Total number of pregnancies, including the current
														one
													</p>
													<div className="flex items-center">
														<div className="flex-4">
															<FormInput
																name="gravida"
																type={EFormType.SINGLE_LINE}
																value={
																	props.patientMedicalHistory?.obgyn?.gravida ||
																	0
																}
																suffix={
																	<p className="m-0 text-black-60 whitespace-nowrap">
																		Pregnancies
																	</p>
																}
																onChange={(val) => {
																	props.setPatientMedicalHistory(
																		(old: any) => ({
																			...old,
																			obgyn: {
																				...old.obgyn,
																				gravida: val,
																			},
																		}),
																	);
																}}
															/>
														</div>
														<div className="flex-1 flex space-x-4 ml-2">
															<div
																className="mr-2"
																style={{ height: 50, width: 50 }}
															>
																<Button
																	type="OUTLINE"
																	onClick={() =>
																		handleFieldChange('gravida', -1)
																	}
																	className="w-8 border-charcoal-200 h-full"
																>
																	<p
																		className="m-0 text-black-100 text-6"
																		style={{ lineHeight: 24 }}
																	>
																		-
																	</p>
																</Button>
															</div>
															<div style={{ height: 50, width: 50 }}>
																<Button
																	type="OUTLINE"
																	onClick={() =>
																		handleFieldChange('gravida', 1)
																	}
																	className="w-8 border-charcoal-200 h-full"
																>
																	<p
																		className="m-0 text-black-100 text-6"
																		style={{ lineHeight: 24 }}
																	>
																		+
																	</p>
																</Button>
															</div>
														</div>
													</div>
												</div>
											</div>

											{/* Para Section */}
											<div className="mt-2 flex items-center">
												<div className="w-full">
													<label className="block text-4 text-gray-700 mb-0">
														P (Para)
													</label>
													<p className="text-black-60 text-3.5 mb-0">
														Number of live births after 20 weeks gestation
													</p>
													<div className="flex items-center">
														<div className="flex-4">
															<FormInput
																name="para"
																type={EFormType.SINGLE_LINE}
																value={
																	props.patientMedicalHistory?.obgyn?.para || 0
																}
																suffix={
																	<p className="m-0 text-black-60 whitespace-nowrap">
																		live births
																	</p>
																}
																onChange={(val) => {
																	props.setPatientMedicalHistory(
																		(old: any) => ({
																			...old,
																			obgyn: {
																				...old.obgyn,
																				para: val,
																			},
																		}),
																	);
																}}
															/>
														</div>
														<div className="flex-1 flex space-x-4 ml-2">
															<div
																className="mr-2"
																style={{ height: 50, width: 50 }}
															>
																<Button
																	type="OUTLINE"
																	onClick={() => handleFieldChange('para', -1)}
																	className="w-8 border-charcoal-200 h-full"
																>
																	<p
																		className="m-0 text-black-100 text-6"
																		style={{ lineHeight: 24 }}
																	>
																		-
																	</p>
																</Button>
															</div>
															<div style={{ height: 50, width: 50 }}>
																<Button
																	type="OUTLINE"
																	onClick={() => handleFieldChange('para', 1)}
																	className="w-8 border-charcoal-200 h-full"
																>
																	<p
																		className="m-0 text-black-100 text-6"
																		style={{ lineHeight: 24 }}
																	>
																		+
																	</p>
																</Button>
															</div>
														</div>
													</div>
												</div>
											</div>

											{/* Full Term Section */}
											<div className="mt-2 flex items-center">
												<div className="w-full">
													<label className="block text-4 text-gray-700 mb-0">
														Ft (Full Term)
													</label>
													<p className="text-black-60 text-3.5 mb-0">
														Number of babies born at or after 37 weeks gestation
													</p>
													<div className="flex items-center">
														<div className="flex-4">
															<FormInput
																name="fullTerm"
																type={EFormType.SINGLE_LINE}
																value={
																	props.patientMedicalHistory?.obgyn
																		?.fullTerm || 0
																}
																suffix={
																	<p className="m-0 text-black-60 whitespace-nowrap">
																		baby
																	</p>
																}
																onChange={(val) => {
																	props.setPatientMedicalHistory(
																		(old: any) => ({
																			...old,
																			obgyn: {
																				...old.obgyn,
																				fullTerm: val,
																			},
																		}),
																	);
																}}
															/>
														</div>
														<div className="flex-1 flex space-x-4 ml-2">
															<div
																className="mr-2"
																style={{ height: 50, width: 50 }}
															>
																<Button
																	type="OUTLINE"
																	onClick={() =>
																		handleFieldChange('fullTerm', -1)
																	}
																	className="w-8 border-charcoal-200 h-full"
																>
																	<p
																		className="m-0 text-black-100 text-6"
																		style={{ lineHeight: 24 }}
																	>
																		-
																	</p>
																</Button>
															</div>
															<div style={{ height: 50, width: 50 }}>
																<Button
																	type="OUTLINE"
																	onClick={() =>
																		handleFieldChange('fullTerm', 1)
																	}
																	className="w-8 border-charcoal-200 h-full"
																>
																	<p
																		className="m-0 text-black-100 text-6"
																		style={{ lineHeight: 24 }}
																	>
																		+
																	</p>
																</Button>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="mt-2 flex items-center">
												<div className="w-full">
													<label className="block text-4 text-gray-700 mb-0">
														Pt (Preterm)
													</label>
													<p className="text-black-60 text-3.5 mb-0">
														Number of babies born before 37 weeks gestation
													</p>
													<div className="flex items-center">
														<div className="flex-4">
															<FormInput
																name="preterm"
																type={EFormType.SINGLE_LINE}
																value={
																	props.patientMedicalHistory?.obgyn?.preterm ||
																	0
																}
																suffix={
																	<p className="m-0 text-black-60 whitespace-nowrap">
																		baby
																	</p>
																}
																onChange={(val) => {
																	props.setPatientMedicalHistory(
																		(old: any) => ({
																			...old,
																			obgyn: {
																				...old.obgyn,
																				preterm: val,
																			},
																		}),
																	);
																}}
															/>
														</div>
														<div className="flex-1 flex space-x-4 ml-2">
															<div
																className="mr-2"
																style={{ height: 50, width: 50 }}
															>
																<Button
																	type="OUTLINE"
																	onClick={() =>
																		handleFieldChange('preterm', -1)
																	}
																	className="w-8 border-charcoal-200 h-full"
																>
																	<p
																		className="m-0 text-black-100 text-6"
																		style={{ lineHeight: 24 }}
																	>
																		-
																	</p>
																</Button>
															</div>
															<div style={{ height: 50, width: 50 }}>
																<Button
																	type="OUTLINE"
																	onClick={() =>
																		handleFieldChange('preterm', 1)
																	}
																	className="w-8 border-charcoal-200 h-full"
																>
																	<p
																		className="m-0 text-black-100 text-6"
																		style={{ lineHeight: 24 }}
																	>
																		+
																	</p>
																</Button>
															</div>
														</div>
													</div>
												</div>
											</div>

											{/* Abortion Section */}
											<div className="mt-2 flex items-center">
												<div className="w-full">
													<label className="block text-4 text-gray-700 mb-0">
														Ab (Abortion)
													</label>
													<p className="text-black-60 text-3.5 mb-0">
														Number of pregnancies ending before 20 weeks
														(miscarriages or abortions)
													</p>
													<div className="flex items-center">
														<div className="flex-4">
															<FormInput
																name="abortion"
																type={EFormType.SINGLE_LINE}
																value={
																	props.patientMedicalHistory?.obgyn
																		?.abortion || 0
																}
																suffix={
																	<p className="m-0 text-black-60 whitespace-nowrap">
																		Pregnancies
																	</p>
																}
																onChange={(val) => {
																	props.setPatientMedicalHistory(
																		(old: any) => ({
																			...old,
																			obgyn: {
																				...old.obgyn,
																				abortion: val,
																			},
																		}),
																	);
																}}
															/>
														</div>
														<div className="flex-1 flex space-x-4 ml-2">
															<div
																className="mr-2"
																style={{ height: 50, width: 50 }}
															>
																<Button
																	type="OUTLINE"
																	onClick={() =>
																		handleFieldChange('abortion', -1)
																	}
																	className="w-8 border-charcoal-200 h-full"
																>
																	<p
																		className="m-0 text-black-100 text-6"
																		style={{ lineHeight: 24 }}
																	>
																		-
																	</p>
																</Button>
															</div>
															<div style={{ height: 50, width: 50 }}>
																<Button
																	type="OUTLINE"
																	onClick={() =>
																		handleFieldChange('abortion', 1)
																	}
																	className="w-8 border-charcoal-200 h-full"
																>
																	<p
																		className="m-0 text-black-100 text-6"
																		style={{ lineHeight: 24 }}
																	>
																		+
																	</p>
																</Button>
															</div>
														</div>
													</div>
												</div>
											</div>

											{/* Living Babies Section */}
											<div className="mt-2 flex items-center">
												<div className="w-full">
													<label className="block text-4 text-gray-700 mb-0">
														Lb (Living Babies)
													</label>
													<p className="text-black-60 text-3.5 mb-0">
														Number of currently living children
													</p>
													<div className="flex items-center">
														<div className="flex-4">
															<FormInput
																name="livingBabies"
																type={EFormType.SINGLE_LINE}
																value={
																	props.patientMedicalHistory?.obgyn
																		?.livingBabies || 0
																}
																suffix={
																	<p className="m-0 text-black-60 whitespace-nowrap">
																		children
																	</p>
																}
																onChange={(val) => {
																	props.setPatientMedicalHistory(
																		(old: any) => ({
																			...old,
																			obgyn: {
																				...old.obgyn,
																				livingBabies: val,
																			},
																		}),
																	);
																}}
															/>
														</div>
														<div className="flex-1 flex space-x-4 ml-2">
															{/* Button for decrementing the living babies value */}
															<div
																className="mr-2"
																style={{ height: 50, width: 50 }}
															>
																<Button
																	type="OUTLINE"
																	onClick={() => {
																		const newLivingBabies =
																			(props.patientMedicalHistory?.obgyn
																				?.livingBabies || 0) - 1;
																		if (newLivingBabies >= 0) {
																			props.setPatientMedicalHistory(
																				(old: any) => ({
																					...old,
																					obgyn: {
																						...old.obgyn,
																						livingBabies: newLivingBabies,
																					},
																				}),
																			);
																		}
																	}}
																	className="w-8 border-charcoal-200 h-full border-charcoal-200 h-full"
																>
																	<p
																		className="m-0 text-black-100 text-6"
																		style={{ lineHeight: 24 }}
																	>
																		-
																	</p>
																</Button>
															</div>

															<div style={{ height: 50, width: 50 }}>
																<Button
																	type="OUTLINE"
																	onClick={() => {
																		const newLivingBabies =
																			(props.patientMedicalHistory?.obgyn
																				?.livingBabies || 0) + 1;
																		props.setPatientMedicalHistory(
																			(old: any) => ({
																				...old,
																				obgyn: {
																					...old.obgyn,
																					livingBabies: newLivingBabies,
																				},
																			}),
																		);
																	}}
																	className="w-8 border-charcoal-200 h-full"
																>
																	<p
																		className="m-0 text-black-100 text-6"
																		style={{ lineHeight: 24 }}
																	>
																		+
																	</p>
																</Button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							<div className="py-6 px-3">
								<EmptyList
									icon={<IconEmpty />}
									title="A male doesn’t need to complete the OB/GYN section"
								/>
							</div>
						</TabPane>

						<TabPane tab="Social History" key="3">
							<div className="py-2 px-3 m-0">
								<div className="flex items-start justify-between pt-0 px-2">
									<div className="pt-2 w-1/4">
										<p className="m-0 font-semibold text-4">Social History</p>
									</div>
									<div className="flex-1 flex flex-col">
										<FormInput
											label="Alcohol"
											name="isAlcohol"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.socialHistory?.isAlcohol !=
												null
													? props.patientMedicalHistory?.socialHistory
															?.isAlcohol
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedSocialHistory = {
														...old.socialHistory,
														isAlcohol: val === 'yes',
													};
													if (val === 'no') {
														updatedSocialHistory.alcohol = '';
													}
													return {
														...old,
														socialHistory: updatedSocialHistory,
													};
												});
											}}
										/>

										{props.patientMedicalHistory?.socialHistory?.isAlcohol && (
											<FormInput
												label="Alcohol Note"
												name="alcohol"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.socialHistory?.alcohol
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedSocialHistory = {
															...old.socialHistory,
															alcohol: val,
														};
														return {
															...old,
															socialHistory: updatedSocialHistory,
														};
													});
												}}
											/>
										)}

										<FormInput
											label="Smoking"
											name="isSmoking"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.socialHistory?.isSmoking !=
												null
													? props.patientMedicalHistory?.socialHistory
															?.isSmoking
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedSocialHistory = {
														...old.socialHistory,
														isSmoking: val === 'yes',
													};
													if (val === 'no') {
														updatedSocialHistory.smoking = '';
													}
													return {
														...old,
														socialHistory: updatedSocialHistory,
													};
												});
											}}
										/>

										{props.patientMedicalHistory?.socialHistory?.isSmoking && (
											<FormInput
												label="Smoking Note"
												name="smoking"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.socialHistory?.smoking
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedSocialHistory = {
															...old.socialHistory,
															smoking: val,
														};
														return {
															...old,
															socialHistory: updatedSocialHistory,
														};
													});
												}}
											/>
										)}

										<FormInput
											label="Illicit Drug use"
											name="isDrugUse"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.socialHistory?.isDrugUse !=
												null
													? props.patientMedicalHistory?.socialHistory
															?.isDrugUse
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedSocialHistory = {
														...old.socialHistory,
														isDrugUse: val === 'yes',
													};
													if (val === 'no') {
														updatedSocialHistory.drugUse = '';
													}
													return {
														...old,
														socialHistory: updatedSocialHistory,
													};
												});
											}}
										/>

										{props.patientMedicalHistory?.socialHistory?.isDrugUse && (
											<FormInput
												label="Drug Use Notes"
												name="drugUse"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.socialHistory?.drugUse
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedSocialHistory = {
															...old.socialHistory,
															drugUse: val,
														};
														return {
															...old,
															socialHistory: updatedSocialHistory,
														};
													});
												}}
											/>
										)}

										<FormInput
											label="Others"
											name="others"
											type={EFormType.MULTI_LINE}
											value={props.patientMedicalHistory?.socialHistory?.others}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => ({
													...old,
													socialHistory: {
														...old.socialHistory,
														others: val,
													},
												}));
											}}
										/>
									</div>
								</div>
							</div>
						</TabPane>

						<TabPane tab="Family History" key="4">
							<div className="py-2 px-2 m-0">
								<div className="flex items-start justify-between pt-0 px-2">
									<div className="pt-2 w-1/4">
										<p className="m-0 font-semibold text-4">Family History</p>
									</div>
									<div className="flex-1 flex flex-col">
										<FormInput
											label="Bronchial Asthma"
											name="isBronchialAsthma"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.familyHistory
													?.isBronchialAsthma != null
													? props.patientMedicalHistory?.familyHistory
															?.isBronchialAsthma
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedFamilyHistory = {
														...old.familyHistory,
														isBronchialAsthma: val === 'yes',
													};
													if (val === 'no') {
														updatedFamilyHistory.bronchialAsthma = '';
													}
													return {
														...old,
														familyHistory: updatedFamilyHistory,
													};
												});
											}}
										/>

										{props.patientMedicalHistory?.familyHistory
											?.isBronchialAsthma && (
											<FormInput
												label="Bronchial Asthma Notes"
												name="bronchialAsthma"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.familyHistory
														?.bronchialAsthma
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedFamilyHistory = {
															...old.familyHistory,
															bronchialAsthma: val,
														};
														return {
															...old,
															familyHistory: updatedFamilyHistory,
														};
													});
												}}
											/>
										)}

										<FormInput
											label="Diabetes"
											name="isDiabetes"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.familyHistory?.isDiabetes !=
												null
													? props.patientMedicalHistory?.familyHistory
															?.isDiabetes
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedFamilyHistory = {
														...old.familyHistory,
														isDiabetes: val === 'yes',
													};
													if (val === 'no') {
														updatedFamilyHistory.diabetes = '';
													}
													return {
														...old,
														familyHistory: updatedFamilyHistory,
													};
												});
											}}
										/>

										{props.patientMedicalHistory?.familyHistory?.isDiabetes && (
											<FormInput
												label="Diabetes Notes"
												name="diabetes"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.familyHistory?.diabetes
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedFamilyHistory = {
															...old.familyHistory,
															diabetes: val,
														};
														return {
															...old,
															familyHistory: updatedFamilyHistory,
														};
													});
												}}
											/>
										)}
										<FormInput
											label="Hypertension"
											name="isHypertension"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.familyHistory
													?.isHypertension != null
													? props.patientMedicalHistory?.familyHistory
															?.isHypertension
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedFamilyHistory = {
														...old.familyHistory,
														isHypertension: val === 'yes',
													};
													if (val === 'no') {
														updatedFamilyHistory.hypertension = '';
													}
													return {
														...old,
														familyHistory: updatedFamilyHistory,
													};
												});
											}}
										/>

										{props.patientMedicalHistory?.familyHistory
											?.isHypertension && (
											<FormInput
												label="Hypertension Notes"
												name="hypertension"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.familyHistory
														?.hypertension
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedFamilyHistory = {
															...old.familyHistory,
															hypertension: val,
														};
														return {
															...old,
															familyHistory: updatedFamilyHistory,
														};
													});
												}}
											/>
										)}

										<FormInput
											label="Ischemic Heart Disease (IHD)"
											name="isIhd"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.familyHistory?.isIhd != null
													? props.patientMedicalHistory?.familyHistory?.isIhd
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedFamilyHistory = {
														...old.familyHistory,
														isIhd: val === 'yes',
													};
													if (val === 'no') {
														updatedFamilyHistory.ihd = '';
													}
													return {
														...old,
														familyHistory: updatedFamilyHistory,
													};
												});
											}}
										/>

										{props.patientMedicalHistory?.familyHistory?.isIhd && (
											<FormInput
												label="IHD Notes"
												name="ihd"
												type={EFormType.MULTI_LINE}
												value={props.patientMedicalHistory?.familyHistory?.ihd}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedFamilyHistory = {
															...old.familyHistory,
															ihd: val,
														};
														return {
															...old,
															familyHistory: updatedFamilyHistory,
														};
													});
												}}
											/>
										)}

										<FormInput
											label="Cancer"
											name="isCancer"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.familyHistory?.isCancer !=
												null
													? props.patientMedicalHistory?.familyHistory?.isCancer
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedFamilyHistory = {
														...old.familyHistory,
														isCancer: val === 'yes',
													};
													if (val === 'no') {
														updatedFamilyHistory.cancer = '';
													}
													return {
														...old,
														familyHistory: updatedFamilyHistory,
													};
												});
											}}
										/>

										{props.patientMedicalHistory?.familyHistory?.isCancer && (
											<FormInput
												label="Cancer Notes"
												name="cancer"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.familyHistory?.cancer
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedFamilyHistory = {
															...old.familyHistory,
															cancer: val,
														};
														return {
															...old,
															familyHistory: updatedFamilyHistory,
														};
													});
												}}
											/>
										)}

										<FormInput
											label="Kidney Disease"
											name="isKidneyDisease"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.familyHistory
													?.isKidneyDisease != null
													? props.patientMedicalHistory?.familyHistory
															?.isKidneyDisease
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedFamilyHistory = {
														...old.familyHistory,
														isKidneyDisease: val === 'yes',
													};
													if (val === 'no') {
														updatedFamilyHistory.kidneyDisease = '';
													}
													return {
														...old,
														familyHistory: updatedFamilyHistory,
													};
												});
											}}
										/>

										{props.patientMedicalHistory?.familyHistory
											?.isKidneyDisease && (
											<FormInput
												label="Kidney Disease Notes"
												name="kidneyDisease"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.familyHistory
														?.kidneyDisease
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedFamilyHistory = {
															...old.familyHistory,
															kidneyDisease: val,
														};
														return {
															...old,
															familyHistory: updatedFamilyHistory,
														};
													});
												}}
											/>
										)}

										<FormInput
											label="Thyroid Problems"
											name="isThyroidProblems"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.familyHistory
													?.isThyroidProblems != null
													? props.patientMedicalHistory?.familyHistory
															?.isThyroidProblems
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedFamilyHistory = {
														...old.familyHistory,
														isThyroidProblems: val === 'yes',
													};
													if (val === 'no') {
														updatedFamilyHistory.thyroidProblems = '';
													}
													return {
														...old,
														familyHistory: updatedFamilyHistory,
													};
												});
											}}
										/>

										{props.patientMedicalHistory?.familyHistory
											?.isThyroidProblems && (
											<FormInput
												label="Thyroid Problems Notes"
												name="thyroidProblems"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.familyHistory
														?.thyroidProblems
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedFamilyHistory = {
															...old.familyHistory,
															thyroidProblems: val,
														};
														return {
															...old,
															familyHistory: updatedFamilyHistory,
														};
													});
												}}
											/>
										)}

										<FormInput
											label="Blood Dyscrasia"
											name="isBloodDyscrasia"
											type={EFormType.ITEM_PICKER_H}
											items={['Yes', 'No']}
											value={
												props.patientMedicalHistory.familyHistory
													?.isBloodDyscrasia != null
													? props.patientMedicalHistory?.familyHistory
															?.isBloodDyscrasia
														? 'yes'
														: 'no'
													: ''
											}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => {
													const updatedFamilyHistory = {
														...old.familyHistory,
														isBloodDyscrasia: val === 'yes',
													};
													if (val === 'no') {
														updatedFamilyHistory.bloodDyscrasia = '';
													}
													return {
														...old,
														familyHistory: updatedFamilyHistory,
													};
												});
											}}
										/>

										{props.patientMedicalHistory?.familyHistory
											?.isBloodDyscrasia && (
											<FormInput
												label="Blood Dyscrasia Notes"
												name="bloodDyscrasia"
												type={EFormType.MULTI_LINE}
												value={
													props.patientMedicalHistory?.familyHistory
														?.bloodDyscrasia
												}
												onChange={(val) => {
													props.setPatientMedicalHistory((old: any) => {
														const updatedFamilyHistory = {
															...old.familyHistory,
															bloodDyscrasia: val,
														};
														return {
															...old,
															familyHistory: updatedFamilyHistory,
														};
													});
												}}
											/>
										)}

										<FormInput
											label="Others"
											name="others"
											type={EFormType.MULTI_LINE}
											value={props.patientMedicalHistory?.familyHistory?.others}
											onChange={(val) => {
												props.setPatientMedicalHistory((old: any) => ({
													...old,
													familyHistory: {
														...old.familyHistory,
														others: val,
													},
												}));
											}}
										/>
									</div>
								</div>
							</div>
						</TabPane>
					</Tabs>
				</TabStyle>
			</div>
			<Separator />
			<div
				className={`py-3 px-4 flex space-x-6 ${
					['2', '3', '4'].includes(activeKey)
						? 'justify-between'
						: 'justify-end'
				}`}
			>
				{['2', '3', '4'].includes(activeKey) && (
					<div className="w-44">
						<Button
							type="OUTLINE"
							onClick={handleBackClick}
							isLoading={false}
							isDisable={isLoading}
						>
							Back
						</Button>
					</div>
				)}
				<div className="w-44">
					<Button
						type="SOLID"
						onClick={handleButtonClick}
						isLoading={isLoading}
					>
						{['1', '2', '3'].includes(activeKey) ? 'Next' : 'Save'}
					</Button>
				</div>
			</div>

			<ModalFormAddVaccine
				onSave={handleSaveVaccine}
				isShow={showModalAddMedicine}
				onCloseModal={() => setShowModalAddMedicine(false)}
			/>

			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={handleDeleteVaccine}
				title="Vaccine Item"
				type={EModalConfirmationType.REMOVE_ITEM}
			/>
		</div>
	);
}

const TabStyle = styled.div`
	width: 100%;
	max-width: 900px;

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: black !important;
		font-weight: 500 !important;
	}

	.ant-tabs-tab {
		color: ${theme.colors.charcoal300} !important;
		font-weight: 500 !important;
	}

	.ant-tabs-ink-bar {
		height: 4px !important;
	}

	.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
	.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
		all: revert;
	}

	.ant-tabs-nav-operations {
		display: none !important;
	}

	.ant-tabs-top > .ant-tabs-nav::before,
	.ant-tabs-bottom > .ant-tabs-nav::before,
	.ant-tabs-top > div > .ant-tabs-nav::before,
	.ant-tabs-bottom > div > .ant-tabs-nav::before {
		border-bottom: 0px !important;
	}
`;
