import { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import {
	apiGetRxPrescription,
	apiUpdateRxPrescription,
} from '../../../../api/rxPrescription';
import { apiCreateTemplateRxPrescription } from '../../../../api/templateRxPrescription';
import { useAuth } from '../../../../context/auth.context';
import {
	RxPrescriptionItem,
	RxPrescriptionProperties,
} from '../../../../types/rxPrescription.type';
import Separator from '../../../Separator';
import Steps from '../../../Steps';
import PreviewDocument from '../../Components/PreviewDocument';
import FormCreateRx from './FormCreateRx';
import { getName } from '../../../../helpers/name';
import { calculateAge } from '../../../../helpers/datetime';
import { httpRequest } from '../../../../helpers/api';
import { ApiResponseDefault } from '../../../../types/apiResponse.type';
import ModalInfoPatient from './Components/Modal/ModalInfoPatient';
import { TemplateRxPrescriptionProperties } from '../../../../types/templateRxPrescription.type';
import moment from 'moment';
import { apiGetDrugMedicine } from '../../../../api/drugMedicine';
import { ICDProperties } from '../../../../types/icd.type';

const steps = ['Fill Form', 'Preview'];

export default function MainCreateRx() {
	const history = useHistory();
	const { user } = useAuth();
	let { search } = useLocation();

	const query = new URLSearchParams(search);
	const paramField = query.get('templateRxPrescriptionId');
	const paramDocTmpId = query.get('docTmpId');

	const [title, setTitle] = useState('Create Rx Prescription');
	const [step, setStep] = useState(0);
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isEditingPhone, setIsEditingPhone] = useState(false);

	const [prescription, setPrescription] = useState<RxPrescriptionProperties>();
	const [prescriptionHistory, setPrescriptionHistory] =
		useState<RxPrescriptionProperties>();
	const [templateName, setTemplateName] = useState<string>();
	const [icds, setIcds] = useState<ICDProperties[]>();

	const [patientProfile, setPatientProfile] = useState<{
		[key: string]: any;
	}>();
	const [isModalInfoPatientVisible, setModalInfoPatientVisible] =
		useState(false);
	const [foundPatient, setFoundPatient] = useState<any>();
	const [foundRelatives, setFoundRelatives] = useState<any[]>([]);
	const [searchPhone, setSearchPhone] = useState<any>();
	const [isPhoneDisable, setIsPhoneDisable] = useState(false);

	async function getTemplate() {
		const response = await httpRequest.get<
			ApiResponseDefault<TemplateRxPrescriptionProperties>
		>(`template-rx-prescriptions/${paramField}`);
		setPrescription((prevState) => ({
			...prevState,
			prescriptionItems: response.data.payload
				.templatePrescriptionItems as RxPrescriptionItem[],
		}));
	}

	async function getPrescription(rxId: string) {
		try {
			const prescriptionTmp = await apiGetRxPrescription(rxId);

			const prescriptionItemsTmp = await Promise.all(
				prescriptionTmp?.prescriptionItems?.map(async (item) => {
					const newDrugMedicine = await apiGetDrugMedicine(item.drugMedicineId);
					return {
						...item,
						isDoctorGetPoint: undefined,
						drugMedicineDetail: {
							...item.drugMedicineDetail,
							points: newDrugMedicine?.points,
						},
					};
				}) || [],
			);

			const todayAfter3Months = new Date();
			todayAfter3Months.setMonth(todayAfter3Months.getMonth() + 3);

			setPrescription({
				...prescription,
				prescriptionItems: prescriptionItemsTmp,
				validUntil: todayAfter3Months.toISOString(),
				diagnosis: prescriptionTmp?.diagnosis,
			});

			setIcds(prescriptionTmp?.icds);

			const metaPatient = prescriptionTmp?.metaPatient;

			const name = metaPatient?.name;
			const firstName = metaPatient?.firstName;
			const middleName = metaPatient?.middleName;
			const lastName = metaPatient?.lastName;

			const patientName =
				name ??
				`${firstName ? firstName : ''}${middleName ? ` ${middleName}` : ''}${
					lastName ? ` ${lastName}` : ''
				}`;

			const age = metaPatient?.birthdate
				? moment().diff(metaPatient.birthdate, 'years', false)
				: undefined;
			const phone = metaPatient?.phone;
			setSearchPhone(phone);

			setPatientProfile({
				patientName,
				age,
				gender: metaPatient?.gender,
				phone,
			});
		} catch (error) {
			console.error('Error fetching prescription:', error);
		}
	}

	useEffect(() => {
		if (paramField) {
			getTemplate();
		} else if (paramDocTmpId) {
			getPrescription(paramDocTmpId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function apiFindUserByPhone(phone: string) {
		const res = await httpRequest.post<ApiResponseDefault<any>>(
			'/user/find-by-phone',
			{ phone },
		);

		if (res && res.data && res.data.payload && !res.data.payload.doctor) {
			return res.data.payload;
		} else {
			return Promise.reject({
				code: '',
			});
		}
	}

	useEffect(() => {
		setSearchPhone(patientProfile?.phone?.trim());
	}, [patientProfile?.phone]);

	useEffect(() => {
		if (searchPhone && searchPhone.length >= 9 && !isPhoneDisable) {
			const timeout = setTimeout(() => {
				apiFindUserByPhone('+63' + searchPhone)
					.then((res) => {
						const foundData = {
							patientId: res.userId,
							patientName: getName(res),
							age: calculateAge(res.birthdate),
							gender: res.gender,
						};

						if (res.patient?.relatives && res.patient.relatives.length > 0) {
							const relativesData: any[] = [];
							res.patient.relatives.map((item: any) =>
								relativesData.push({
									patientId: res.userId,
									patientName: getName(item),
									age: calculateAge(item.birthdate),
									gender: item.gender,
								}),
							);
							setFoundRelatives(relativesData);
							setFoundPatient(foundData);

							setPatientProfile({
								...patientProfile,
								...foundData,
								phone: searchPhone,
							});
							setModalInfoPatientVisible(true);
						} else {
							setPatientProfile({
								...patientProfile,
								...foundData,
								patientId: res.userId,
								phone: searchPhone,
							});

							setIsPhoneDisable(true);
						}
					})
					.catch((err) => {
						setPatientProfile((prev) => ({
							...prev,
							patientId: undefined,
							phone: prev?.phone,
						}));
					});
			}, 1000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [searchPhone]);

	useEffect(() => {
		if (!isEditing) return;

		let _title = title;
		if (_title.includes('Create')) {
			_title = _title.replace('Create', 'Edit');
		}
		setTitle(_title);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing]);

	function setNextStep() {
		const _step = step + 1;
		if (_step === 1) {
			setTitle('Preview Rx Prescription');
			setStep(_step);
		}
	}

	function onBack() {
		const _step = step - 1;
		if (_step >= 0) {
			if (_step === 0) {
				setTitle(`${isEditing ? 'Edit' : 'Create'} Rx Prescription`);
			}
			setStep(_step);
		} else {
			const backToRxTemp = () => {
				if (prescriptionHistory?.rxPrescriptionId) {
					return apiUpdateRxPrescription(prescriptionHistory.rxPrescriptionId, {
						...prescriptionHistory,
						isTemporary: false,
					}).then((resTemp) => {
						if (resTemp) {
							setPrescription(resTemp);
						}
					});
				} else {
					return Promise.resolve();
				}
			};
			if (isEditing) {
				backToRxTemp().then(() => {
					history.goBack();
				});
			} else {
				history.goBack();
			}
		}
	}

	async function confirmPrescription() {
		if (!prescription?.rxPrescriptionId) return;

		let isSuccess = false;

		const res = await apiUpdateRxPrescription(prescription.rxPrescriptionId, {
			isTemporary: false,
		});
		if (res) {
			isSuccess = true;
		}

		if (
			templateName &&
			prescription.rxPrescriptionId &&
			prescription.prescriptionItems
		) {
			isSuccess = false;
			const res = await apiCreateTemplateRxPrescription({
				doctorId: user?.userId || '',
				templateName,
				saveFromId: prescription.rxPrescriptionId,
				// templatePrescriptionItems: prescription.prescriptionItems,
				templatePrescriptionItems: prescription?.prescriptionItems?.map(
					(item) => ({
						...item,
						drugMedicineId: item?.drugMedicineDetail?.drugMedicineId || '',
						drugMedicineVariantId: item?.drugMedicineVariantId || '',
					}),
				),
			});
			if (res) {
				isSuccess = true;
			}
		}

		if (isSuccess) {
			setTitle('Result');
			setIsConfirmed(true);
		}
	}

	return (
		<div>
			<div className="relative px-6 py-5.5 flex items-center">
				{!isConfirmed && (
					<button
						onClick={onBack}
						className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
					>
						<ArrowLeft width={20} height={20} />
						<p className="m-0 font-bold text-3.5">Back</p>
					</button>
				)}
				<h1 className="font-semibold text-6 m-0 w-full text-center">{title}</h1>
			</div>
			{!isConfirmed && (
				<>
					<Separator style={{ height: 2 }} />
					<div className="px-13 py-3.5">
						<Steps steps={steps} activeStepIndex={step} />
					</div>
				</>
			)}
			<Separator />
			{step === 0 ? (
				<FormCreateRx
					prescription={prescription}
					setPrescription={setPrescription}
					prescriptionHistory={prescriptionHistory}
					setPrescriptionHistory={setPrescriptionHistory}
					step={step}
					setNextStep={setNextStep}
					setIsEditing={() => setIsEditing(true)}
					setIsEditingPhone={() => setIsEditingPhone(true)}
					searchPhone={searchPhone}
					setSearchPhone={setSearchPhone}
					patientProfile={patientProfile}
					setPatientProfile={setPatientProfile}
					isPhoneDisable={isPhoneDisable}
					setIsPhoneDisable={setIsPhoneDisable}
					icds={icds}
					setIcds={setIcds}
				/>
			) : step === 1 ? (
    <>
        <PreviewDocument
            docId={prescription?.rxPrescriptionId!}
            type="rx-prescription"
            btnSuffix={!isConfirmed ? 'Prescription' : 'Rx and Lab Request'}
            buttonType={!isConfirmed ? 'confirm' : 'back'}
            onClickBtn={!isConfirmed ? confirmPrescription : history.goBack}
            isShowSaveAsTemplate={!isConfirmed}
            templateName={templateName}
            setTemplateName={setTemplateName}
        />
    </>
) : undefined}

			<ModalInfoPatient
				isVisible={isModalInfoPatientVisible}
				setVisibility={setModalInfoPatientVisible}
				dataPatient={foundPatient}
				dataRelatives={foundRelatives}
				actionHandler={(data) => {
					setPatientProfile({ ...patientProfile, ...data });
					setIsPhoneDisable(true);
					setModalInfoPatientVisible(false);
				}}
			/>
		</div>
	);
}
