import { Card } from 'antd';
import VitalSignTypeSummary, {
	DetailInformationNumberStyle,
} from './VitalSignTypeSummary';
import styled from 'styled-components';
import { Key, useEffect } from 'react';

interface Props {
	data: any;
	typeTitle: string;
	age: any;
	setAge: React.Dispatch<React.SetStateAction<any>>;
	birthdate?: Date | string;
	convertFromAgeDate: (
		date: Date,
		createdAt: Date | undefined,
	) => { year: number; month: number; days: number };
	calculateDaysDifference: (date: Date, createdAt: Date | undefined) => number;
}

export default function EmrDetailVitalSignSummary(props: Props) {
	const {
		data,
		typeTitle,
		age,
		setAge,
		convertFromAgeDate,
		calculateDaysDifference,
		birthdate,
	} = props;

	useEffect(() => {
		if (typeTitle === 'Pedia Growth Chart') {
			const { year, month, days } = convertFromAgeDate(
				new Date(birthdate || ''),
				new Date(data?.currentDate),
			);

			const dayDifference = calculateDaysDifference(
				new Date(birthdate || ''),
				new Date(data?.currentDate),
			);
			setAge({
				year: year,
				month: month,
				days: days,
				ageAsDays: dayDifference,
			});
		}
	}, [data, birthdate]);

	const getVitalSignProperties = () => {
		if (typeTitle !== 'Blood Pressure') {
			return {
				value: data?.currentValue,
				unit: data?.unit,
				notes: data?.currentNote,
				status: data?.currentStatus,
			};
		}
		return { value: null, unit: '', notes: '', status: '' };
	};

	const { value, unit, notes, status } = getVitalSignProperties();

	return (
		<Card style={DetailInformationCardStyles} bodyStyle={{ padding: 0 }}>
			{typeTitle === 'Blood Pressure' ? (
				<DetailInformationContainer>
					<VitalSignSummarySection>
						<div className="flex flex-col gap-3">
							<VitalSignTypeSummary
								bloodPressureParams="Systolic"
								title={typeTitle}
								value={data?.systolic?.currentValue}
								unit={data?.systolic?.unit}
								status={data?.systolic?.currentStatus}
							/>
							<VitalSignTypeSummary
								bloodPressureParams="Diastolic"
								title={typeTitle}
								value={data?.diastolic?.currentValue}
								unit={data?.diastolic?.unit}
								status={data?.diastolic?.currentStatus}
							/>
						</div>
					</VitalSignSummarySection>
					<VitalSignNotesSection
						notes={data?.diastolic?.currentNote || data?.systolic?.currentNote}
					/>
				</DetailInformationContainer>
			) : typeTitle !== 'Pedia Growth Chart' ? (
				<DetailInformationContainer>
					<VitalSignSummarySection>
						<VitalSignSummary
							typeTitle={typeTitle}
							value={value}
							unit={unit}
							status={status}
						/>
					</VitalSignSummarySection>
					<VitalSignNotesSection notes={notes} />
				</DetailInformationContainer>
			) : (
				<PediaGrowthChartSummary
					age={age}
					data={data}
					notes={notes}
					typeTitle={typeTitle}
				/>
			)}
		</Card>
	);
}

const PediaGrowthChartSummary = ({ age, data, notes, typeTitle }: any) => (
	<>
		<AgeSummaryCard age={age} />
		<SummaryList data={data?.summary} />
		<NotesSection notes={notes} />
	</>
);

const AgeSummaryCard = ({ age }: any) => (
	<Card style={SummaryCardStyle} bodyStyle={{ padding: 12, marginBottom: 12 }}>
		<DetailInformationContainer>
			<AgeInformation title="Age as y+m+d" age={age} />
			<AgeInformation title="Age as days" age={{ days: age?.ageAsDays }} />
		</DetailInformationContainer>
	</Card>
);

const SummaryList = ({ data }: any) => (
	<div className="flex">
		{data?.map((item: any, index: Key) => (
			<VitalSignTypeSummary
				key={index}
				title={item.title}
				value={item.value}
				unit={item.unit}
				status={item.status}
			/>
		))}
	</div>
);

const NotesSection = ({ notes }: any) => (
	<div className="my-3">
		<VitalSignNotesTitle>Notes</VitalSignNotesTitle>
		<NoteContent>{notes || 'No description'}</NoteContent>
	</div>
);

const VitalSignSummary = ({ typeTitle, value, unit, status }: any) => (
	<VitalSignTypeSummary
		title={typeTitle}
		value={value}
		unit={unit}
		status={status}
	/>
);

const AgeInformation = ({ title, age }: any) => (
	<DetailInformationAgeStyle>
		<DetailInformationTitle>{title}</DetailInformationTitle>
		<div style={{ display: 'flex', gap: '5px' }}>
			{age?.year !== null && age?.year !== undefined && (
				<DetailInformationUnit>
					<span style={DetailInformationNumberStyle}>{age?.year}</span> years
				</DetailInformationUnit>
			)}
			{age?.month !== null && age?.month !== undefined && (
				<DetailInformationUnit>
					<span style={DetailInformationNumberStyle}>{age?.month}</span> month
				</DetailInformationUnit>
			)}
			<DetailInformationUnit>
				<span style={DetailInformationNumberStyle}>{age?.days}</span> days
			</DetailInformationUnit>
		</div>
	</DetailInformationAgeStyle>
);

const VitalSignNotesSection = ({ notes }: any) => (
	<div style={{ maxWidth: '65%', width: '65%' }}>
		<VitalSignNotesTitle>Notes</VitalSignNotesTitle>
		<NoteContent>{notes || 'No description'}</NoteContent>
	</div>
);

const DetailInformationCardStyles: React.CSSProperties = {
	marginTop: 12,
	border: 0,
};

const SummaryCardStyle: React.CSSProperties = {
	borderRadius: 8,
	border: 0,
	backgroundColor: '#F1F3F5',
};

const NoteContent = styled.div`
	font-size: 14px;
	font-weight: 400;
	color: black;
	line-height: 14px;
`;

const DetailInformationContainer = styled.div`
	display: flex;
	width: 100%;
`;

const DetailInformationAgeStyle = styled.div`
	text-align: left;
	flex: 1;
`;

const DetailInformationUnit = styled.div`
	color: #748494;
	font-size: 14px;
`;

const DetailInformationTitle = styled.div`
	font-size: 12px;
	color: #748494;
	margin-bottom: 4px;
`;

const VitalSignNotesTitle = styled.div`
	font-size: 12px;
	color: #748494;
	margin-bottom: 4px;
`;

const VitalSignSummarySection = styled.div`
	width: 35%;
	max-width: 35%;
`;
