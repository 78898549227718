import { message } from 'antd';
import { useState } from 'react';
import { Button } from '../../../../Button/Button/Button';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import { useEffect } from 'react';
import { EmrProperties } from '../../../../../types/emr.type';
import ModalConfirmation, { EModalConfirmationType } from './ModalConfirmation';
import {
	DoctorDocumentProperties,
	EMetaType,
	MetaClearence,
	MetaFitToWork,
	MetaMedicalCertificate,
	MetaOthers,
} from '../../../../../types/doctorDocument.type';
import {
	apiCreateDoctorDocument,
	apiGetDoctorDocument,
	apiUpdateDoctorDocument,
} from '../../../../../api/doctorDocument';
import { IUser } from '../../../../../types/user.type';
import { getName } from '../../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../../helpers/profile';
import moment from 'moment';
import { useAuth } from '../../../../../context/auth.context';

interface Props {
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	onBack: () => void;
	setNextStep: () => void;
	setFormName: () => void;
}

export default function FormCreateReferralNotes(props: Props) {
	const { user } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [referralNotes, setReferralNotes] = useState<{
		[key: string]: any;
	}>();
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);
	const [isFormValid, setIsFormValid] = useState(false);
	const [patientProfile, setPatientProfile] = useState<{
		[key: string]: any;
	}>();
	const todayAfter3Month = new Date();
	todayAfter3Month.setMonth(new Date().getMonth() + 3);

	const validateForm = () => {
		const isValid =
			!!referralNotes?.meta?.title && !!referralNotes?.meta?.content;

		setIsFormValid(isValid);
	};

	useEffect(() => {
		validateForm();
	}, [referralNotes]);

	useEffect(() => {
		(async () => {
			const referralNotes = props.emr.doctorDocuments?.find(
				(doc) => doc.type === EMetaType.REFERRAL_NOTES,
			);

			if (!referralNotes) return;

			setIsLoading(true);

			const res = await apiGetDoctorDocument(
				referralNotes.doctorDocumentId || '',
			);
			if (res) {
				const metaPatient = res.metaPatient as IUser;
				setPatientProfile({
					patientName: getName(metaPatient),
					gender: getGender(metaPatient)?.toLowerCase(),
					age: moment().diff(getBirthdate(metaPatient), 'years', false),
					phone: metaPatient?.phone,
				});
				setReferralNotes(res);
			}

			setIsLoading(false);
		})();
	}, [props.emr.doctorDocuments]);

	async function saveEmrAndReferralNotes() {
		setIsLoading(true);

		const { emr, setEmr } = props;

		if (!emr.emrId || !emr.patientId) {
			message.error(
				'EMR ID and Patient ID are required. Please create a medical record for the patient first.',
			);
			setIsLoading(false);
			return;
		}

		const patientName = patientProfile?.patientName
			? patientProfile?.patientName.split(' ')
			: [];
		const birthdate = new Date();
		birthdate.setUTCFullYear(
			birthdate.getUTCFullYear() - (patientProfile?.age ?? 0),
		);

		// const referralTitle = refferalNotes?.title || '';
		// const referralContent = refferalNotes?.content || '';

		let isSuccess = false;
		let data: DoctorDocumentProperties = {
			transactionConsultationId: referralNotes?.transactionConsultationId,
			doctorId: user?.userId,
			type: EMetaType.REFERRAL_NOTES,
			patientId: emr.patientId,
			// metaPatient: {
			// 	firstName: patientName[0],
			// 	middleName:
			// 		patientName.length > 2 ? patientName.slice(1, -1).join(' ') : '',
			// 	lastName:
			// 		patientName.length > 1 ? patientName[patientName.length - 1] : '',
			// 	birthdate,
			// 	gender: patientProfile?.gender?.toLowerCase(),
			// },
			metaPatient: emr.patient || emr.patientInRelation || {},
			validUntil: referralNotes?.validUntil ?? todayAfter3Month.toString(),
			price: referralNotes?.price ?? 0,
			meta: referralNotes?.meta,
			isTemporary: true,
			emrId: emr.emrId,
		};

		if (referralNotes?.doctorDocumentId) {
			data = {
				...data,
				meta: referralNotes?.meta as
					| MetaMedicalCertificate
					| MetaClearence
					| MetaFitToWork
					| MetaOthers,
				emrId: emr.emrId,
			};

			const res = await apiUpdateDoctorDocument(
				referralNotes.doctorDocumentId,
				data,
			);
			if (res) {
				setEmr({
					...emr,
					doctorDocuments: (emr.doctorDocuments || []).map((doc) =>
						doc.doctorDocumentId === res.doctorDocumentId ? res : doc,
					),
				});
				setReferralNotes(res);
				isSuccess = true;
			}
		} else {
			data = {
				...data,
				transactionConsultationId: referralNotes?.transactionConsultationId,
				doctorId: user?.userId,
				type: EMetaType.REFERRAL_NOTES,
				meta: referralNotes?.meta as
					| MetaMedicalCertificate
					| MetaClearence
					| MetaFitToWork
					| MetaOthers,
				emrId: emr.emrId,
			};

			const res = await apiCreateDoctorDocument(data);
			if (res) {
				setEmr({
					...emr,
					doctorDocuments: [...(emr.doctorDocuments || []), res],
				});
				setReferralNotes(res);
				isSuccess = true;
			}
		}

		if (isSuccess) {
			props.setNextStep();
			props.setFormName();
			message.success('Referral Notes Saved Sucessfully');
		}

		setIsLoading(false);
	}

	const clearFormHandler = () => {
		setReferralNotes({
			meta: {
				title: '',
				content: '',
			},
		});
		setIsModalConfirmationVisible(false);
	};

	return (
		<div>
			<form>
				<div className="py-6 px-3">
					<div className="flex gap-x-3">
						<div className="pt-2 w-1/4">
							<p className="m-0 font-semibold text-4">Referral Notes</p>
						</div>
						<div className="flex-1">
							<FormInput
								label="Referral title"
								name="title"
								type={EFormType.MULTI_LINE}
								required={true}
								value={referralNotes?.meta?.title}
								onChange={(val) =>
									setReferralNotes((old) => ({
										...old,
										meta: { ...old?.meta, title: val },
									}))
								}
							/>
							<FormInput
								label="Referral Notes"
								name="content"
								type={EFormType.MULTI_LINE}
								rows={12}
								required={true}
								value={referralNotes?.meta?.content}
								onChange={(val) =>
									setReferralNotes((old) => ({
										...old,
										meta: { ...old?.meta, content: val },
									}))
								}
							/>
						</div>
					</div>
				</div>
				<Separator />
				<div className="py-3 px-4 flex justify-between">
					<div className="w-44">
						<Button
							type="SOLIDASH"
							onClick={() => setIsModalConfirmationVisible(true)}
						>
							Clear Form
						</Button>
					</div>

					<div className="w-44">
						<Button
							type="SOLID"
							onClick={saveEmrAndReferralNotes}
							isLoading={isLoading}
							isDisable={!isFormValid || isLoading}
						>
							Save
						</Button>
					</div>
				</div>
			</form>
			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={clearFormHandler}
				type={EModalConfirmationType.CLEAR_FORM}
			/>
		</div>
	);
}
