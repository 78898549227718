import { Button, Col, Row, Spin, Typography } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import COLORS from '../../../assets/globalColors';
import {
	iconDoctorsTeam as IconDoctorTeam,
	iconHospital as IconHospital,
	iconChevron as IconChevron,
} from '../../../assets/icons';
import { ArticleProps } from '../../../types/article.type';
import { BannerProps } from '../../../types/banner.type';
import ArticleItem from './ArticleItem';
import PromoDealItem from './PromoDealItem';
import { useAuth } from '../../../context/auth.context';

export default function SidebarArticle({
	banners,
	articles,
	isLoading,
}: {
	banners?: BannerProps[];
	articles?: ArticleProps[];
	isLoading?: boolean;
}) {
	const location = useLocation();
	const history = useHistory();
	const { isLoggedIn } = useAuth();

	return (
		<Wrapper>
			{!location.pathname.startsWith('/app') && (
				<div
					className="p-4 bg-white"
					style={{
						border: `1px solid ${COLORS.gray_20}`,
						borderRadius: 12,
						boxShadow: `0px 10px 15px -3px rgba(152, 166, 192, 0.1), 0px 4px 6px -2px rgba(43, 54, 75, 0.05)`,
					}}
				>
					<div style={{ marginBottom: 40 }}>
						<Typography.Text className="text-primary text-6 font-bold block mb-1.5">
							Consult your health
						</Typography.Text>
						<Typography.Text className="text-black text-4 block ">
							Have a question regarding your health?
						</Typography.Text>
					</div>
					<div
						className="flex items-center py-4 px-3 mb-3"
						style={{
							border: `1px solid ${COLORS.gray_20}`,
							borderRadius: 12,
							gap: 12,
						}}
					>
						<IconDoctorTeam />
						<div className="flex flex-1 items-center justify-between">
							<div className="">
								<Typography.Text className="text-primary text-5 font-semibold block">
									Doctor Specialist
								</Typography.Text>
								<Typography.Text
									className="text-primary text-4 block"
									style={{ color: COLORS.black_70 }}
								>
									Get advice about your health
								</Typography.Text>
							</div>
							<IconChevron />
						</div>
					</div>
					<div
						className="flex items-center py-4 px-3"
						style={{
							border: `1px solid ${COLORS.gray_20}`,
							borderRadius: 12,
							gap: 12,
						}}
					>
						<IconHospital />
						<div className="flex flex-1 items-center justify-between">
							<div className="">
								<Typography.Text className="text-primary text-5 font-semibold block">
									Medical Check
								</Typography.Text>
								<Typography.Text
									className="text-primary text-4 block"
									style={{ color: COLORS.black_70 }}
								>
									Check your health with Labolatory Test
								</Typography.Text>
							</div>
							<IconChevron />
						</div>
					</div>
				</div>
			)}

			{banners?.length! > 0 && (
				<div style={{ marginTop: isLoggedIn ? 0 : 70 }}>
					<Typography.Title
						level={4}
						style={{ color: COLORS.gray_100, marginBottom: 24 }}
						className="font-bold block"
					>
						Promo and Deal
					</Typography.Title>
					{isLoading && <Spin spinning />}

					{!isLoading && (
						<Row gutter={[24, 24]}>
							{banners?.map((banner) => (
								<Col span={24}>
									<PromoDealItem banner={banner!} />
								</Col>
							))}
							<Col span={24}>
								<Button
									style={{
										background: COLORS.ash_300,
										color: COLORS.black,
										height: 42,
										width: '100%',
									}}
									block
									onClick={() => history.push('/promo-deals')}
								>
									<span className="font-bold">Show More</span>
								</Button>
							</Col>
						</Row>
					)}
				</div>
			)}

			{articles?.length! > 0 && !location.pathname.startsWith('/articles') && (
				<div style={{ marginTop: isLoggedIn ? 32 : 70 }}>
					<Typography.Title
						level={4}
						style={{ color: COLORS.gray_100, marginBottom: 12 }}
						className="font-bold block"
					>
						Related Articles
					</Typography.Title>
					{isLoading && <Spin spinning />}
					{!isLoading && (
						<Row gutter={[24, 24]}>
							{articles?.map((item) => (
								<Col span={24} key={item?.articleId}>
									<ArticleItem article={item} isDetail />
								</Col>
							))}
							<Col span={24}>
								<Button
									style={{
										background: COLORS.ash_300,
										color: COLORS.black,
										height: 42,
										width: '100%',
									}}
									block
									onClick={() => history.push('/articles')}
								>
									<span className="font-bold">Show More</span>
								</Button>
							</Col>
						</Row>
					)}
				</div>
			)}
		</Wrapper>
	);
}

const Wrapper = styled.div`
	/* position: -webkit-sticky;
  position: sticky;
  top: 100px; */
	background-color: #ffffff;
	padding: 20px;
	border-radius: 10px;
`;
