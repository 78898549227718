import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { httpRequest } from '../../../../../../helpers/api';
import { ApiResponsePagination } from '../../../../../../types/apiResponse.type';
import VitalSignTypeCard from '../VitalSignTypeCard';
import { EmrProperties } from '../../../../../../types/emr.type';
import moment from 'moment';
import { apiGetVitalSignTrend } from '../../../../../../api/emr';

interface Props {
	nextStep: (newStep?: number) => void;
	pageType: 'detail-patient' | 'detail-emr';
	patientId?: string;
	emrDetail?: EmrProperties;
	setIsAvailable: (isAvailable: boolean) => void;
	onFetchComplete: () => void;
	data?: any;
}

export default function VitalSignTemperature(props: Props) {
	const { emrDetail, pageType, patientId } = props;
	const [temperature, setTemperature] = useState<any>();
	const [date, setDate] = useState<string | undefined>();
	const [fromDate, setFromDate] = useState<string | undefined>(
		moment().subtract(3, 'years').toISOString(),
	);
	const [toDate, setToDate] = useState<string | undefined>(
		moment().endOf('month').toISOString(),
	);
	const history = useHistory();

	useEffect(() => {
		const fetchVitalSignTemperature = async () => {
			try {
				const response = await apiGetVitalSignTrend(
					patientId || emrDetail?.patientId || '',
					'temperature',
					emrDetail?.emrId || undefined,
					fromDate || undefined,
					toDate || undefined,
				);

				if (response) {
					setTemperature(response);
					props.setIsAvailable(true);
				} else {
					props.setIsAvailable(false);
				}
			} catch (error) {
				console.error('Error fetching  Temperature:', error);
				props.setIsAvailable(false);
			} finally {
				props.onFetchComplete();
			}
		};

		if ((patientId || emrDetail) && (fromDate || toDate)) {
			if (emrDetail && props.data) {
				setTemperature(props.data);
			} else {
				fetchVitalSignTemperature();
			}
		}
	}, [emrDetail, patientId, props.data, fromDate, toDate]);

	return (
		<>
			{temperature || emrDetail?.vitalSign?.vitalSignTemperature ? (
				<VitalSignTypeCard
					pageType={pageType}
					data={temperature}
					typeTitle="Temperature"
					nextStep={() => props.nextStep(7)}
					setDate={setDate}
					setFromDate={setFromDate}
					setToDate={setToDate}
					date={date}
					fromDate={fromDate}
					toDate={toDate}
				/>
			) : undefined}
		</>
	);
}
