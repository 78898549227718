import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { theme } from '../../../../assets/theme';
import { Button } from '../../../Button/Button/Button';
import EmptyList from '../../../EmptyList';
import FormInput, { EFormType } from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import { ReactComponent as LabRequest } from '../../../../assets/icons/picture.svg';
import { validatePhoneNumber } from '../../../../helpers/validation';
import { ReactComponent as Upload } from '../../../../assets/icons/icon-upload.svg';
import ModalConfirmationChangePhone from '../../RxAndLabRequest/CreateRx/Components/Modal/ModalConfirmationChangePhone';
import { EmrProperties } from '../../../../types/emr.type';
import {
	apiCreateEmr,
	apiGetEmr,
	apiUpdateEmr,
	apiUpdatePatientPicture,
} from '../../../../api/emr';
import { useAuth } from '../../../../context/auth.context';
import { useHistory, useLocation } from 'react-router-dom';
import { Search, Trash2 } from 'react-feather';
import { getName } from '../../../../helpers/name';
import ModalConfirmation, {
	EModalConfirmationType,
} from './Components/ModalConfirmation';

const forms = [
	{
		label: 'Patient Name',
		name: 'patientName',
		type: EFormType.SINGLE_LINE,
		isRequired: true,
		rows: undefined,
	},
	{
		label: 'Gender',
		name: 'gender',
		type: EFormType.ITEM_PICKER_H,
		items: ['MALE', 'FEMALE'],
		isRequired: true,
	},
	{
		label: 'Birthdate',
		name: 'birthdate',
		type: EFormType.DATE_PICKER,
		isRequired: true,
	},
];

interface Props {
	step: number;
	emr: EmrProperties;
	setEmr: React.Dispatch<React.SetStateAction<EmrProperties>>;
	setNextStep: () => void;
	setIsEditing: () => void;
	isEditing: boolean;
	setIsEditingPhone: () => void;

	searchPhone?: string | undefined;
	setSearchPhone: React.Dispatch<React.SetStateAction<any>>;
	patientProfile: any;
	setPatientProfile: React.Dispatch<React.SetStateAction<any>>;
	isPhoneDisable?: boolean;
	setIsPhoneDisable?: React.Dispatch<React.SetStateAction<any>>;
	setIsChoosedByPatientRecords: () => void;
}

type LocationState = {
	selectedPatient?: EmrProperties;
};

export default function FormCreateEmr(props: Props) {
	const { user } = useAuth();

	const [showModalChangePhone, setShowModalChangePhone] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [isExpired, setIsExpired] = useState(false);
	const [imageFile, setImageFile] = useState<File | null>(null);
	const [imagePreviewUrl, setImagePreviewUrl] = useState<string | undefined>(
		undefined,
	);
	const [isModalConfirmationVisible, setIsModalConfirmationVisible] =
		useState(false);
	const [isFormValid, setIsFormValid] = useState(false);
	const location = useLocation<LocationState>();
	const history = useHistory();

	const todayAfter3Month = new Date();
	todayAfter3Month.setMonth(new Date().getMonth() + 3);

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const newImageUrl = URL.createObjectURL(file);
			setImageFile(file);
			setImagePreviewUrl(newImageUrl);

			props.setPatientProfile((old: any) => ({
				...old,
				patientPictureUrl: newImageUrl,
			}));

			e.target.value = '';
		}
	};

	useEffect(() => {
		const birthdate = props.patientProfile?.birthdate;
		if (birthdate) {
			const now = moment();
			const dob = moment(birthdate);
			const years = now.diff(dob, 'years');
			const months = now.diff(dob.add(years, 'years'), 'months');

			props.setPatientProfile((old: any) => ({
				...old,
				age: `${years} years ${months} months`,
			}));
		}
	}, [props.patientProfile?.birthdate]);

	// Navigate from Choose Patient by Records List
	useEffect(() => {
		if (props.isEditing) return;
		const _selectedPatient = location.state?.selectedPatient;
		if (_selectedPatient) {
			props.setIsChoosedByPatientRecords();

			let phone = '';

			if (_selectedPatient.patient?.phone) {
				phone = _selectedPatient.patient.phone.startsWith('63')
					? _selectedPatient.patient.phone.slice(2)
					: _selectedPatient.patient.phone;
			} else if (_selectedPatient.patientInRelation?.patient?.user?.phone) {
				phone =
					_selectedPatient.patientInRelation.patient?.user.phone.startsWith(
						'63',
					)
						? _selectedPatient.patientInRelation.patient?.user.phone.slice(2)
						: _selectedPatient.patientInRelation.patient?.user.phone;
			}

			props.setPatientProfile((old: any) => ({
				...old,
				patientId: _selectedPatient.patientId,
				phone: phone,
			}));
		}
		// setIsReady(true);
	}, [location.state]);

	// Fetch EMR data for edit EMR
	useEffect(() => {
		if (!window.location.search) return;

		const parse = window.location.search.replace('?', '').split('&');
		if (parse.length === 0) return;

		for (const q of parse) {
			const query = q.split('=');
			if (query[0] === 'emrId' && !!query[1]) {
				(async () => {
					const res = await apiGetEmr(query[1]);
					if (res) {
						props.setIsChoosedByPatientRecords();
						props.setEmr(res);
						setImagePreviewUrl(res.patientPictureUrl);

						const name = res?.patient?.name || getName(res?.patientInRelation);
						const firstName =
							res?.patient?.firstName || res?.patientInRelation?.firstName;
						const middleName =
							res?.patient?.middleName || res?.patientInRelation?.middleName;
						const lastName =
							res?.patient?.lastName || res?.patientInRelation?.lastName;
						const patientName =
							name ??
							`${firstName || ''}${middleName ? ` ${middleName}` : ''}${
								lastName ? ` ${lastName}` : ''
							}`;

						let phone = '';

						if (res.patient?.phone) {
							phone = res.patient.phone.startsWith('63')
								? res.patient.phone.slice(2)
								: res.patient.phone;
						} else if (res.patientInRelation?.patient?.user?.phone) {
							phone = res.patientInRelation.patient?.user.phone.startsWith('63')
								? res.patientInRelation.patient?.user.phone.slice(2)
								: res.patientInRelation.patient?.user.phone;
						}
						const address = res?.patient?.userAddress?.addressDetail || '';
						const status = res.status || '';

						props.setPatientProfile({
							patientName,
							patientId: res.patientId,
							birthdate: res?.patient?.birthdate,
							gender: res?.patient?.gender,
							phone,
							patientPictureUrl: res.patientPictureUrl,
							maritalStatus: res?.patient?.maritalStatus,
							address,
							status,
						});
					}
				})();
				props.setIsEditing();
			}
		}
	}, [window.location.search]);

	useEffect(() => {
		const isValid = forms.every((form) => {
			if (form.isRequired) {
				const value = props.patientProfile?.[form.name];
				if (typeof value === 'string') {
					return value.trim() !== '';
				} else if (typeof value === 'number') {
					return !isNaN(value);
				}
				return false;
			}
			return true;
		});
		setIsFormValid(isValid);
	}, [props.patientProfile]);

	const handleDeleteImage = () => {
		{
			if (imagePreviewUrl) {
				URL.revokeObjectURL(imagePreviewUrl);
			}
			setImageFile(null);
			setImagePreviewUrl(undefined);
			props.setPatientProfile((old: any) => ({
				...old,
				patientPictureUrl: null,
			}));
		}
	};

	const handleChooseMyPatient = () => {
		history.push({
			pathname: '/app/emr/patient',
			state: { isFromCreateEmr: true },
		});
	};

	const saveEmr = async () => {
		setIsLoading(true);
		let isSuccess = false;

		const { patientProfile, emr, setEmr, setNextStep, setPatientProfile } =
			props;

		const fullName = patientProfile.patientName || emr?.user?.name || '';
		const nameParts = fullName.trim().split(' ');

		let firstName = '';
		let middleName = '';
		let lastName = '';

		if (nameParts.length === 1) {
			firstName = nameParts[0];
		} else if (nameParts.length > 1) {
			firstName = nameParts[0];
			lastName = nameParts[nameParts.length - 1];
			middleName = nameParts.slice(1, nameParts.length - 1).join(' ');
		}

		const data: EmrProperties = {
			...emr,
			patientId: patientProfile.patientId || emr?.patientId,
			doctorId: user?.userId,
			user: {
				...emr?.user,
				firstName,
				middleName,
				lastName,
				age: patientProfile.age || emr?.user?.age,
				gender: patientProfile.gender || emr?.user?.gender,
				phone: `63${props.searchPhone || emr?.user?.phone}`,
				birthdate: patientProfile.birthdate,
				address: patientProfile.address || emr?.user?.address,
				maritalStatus: patientProfile.maritalStatus || emr?.user?.maritalStatus,
			},
			status: patientProfile.status || emr?.status || 'draft',
		};

		try {
			if (emr?.emrId) {
				const res = await apiUpdateEmr(emr.emrId, data);
				if (res) {
					setEmr({
						...emr,
						...res,
					});
					isSuccess = true;

					if (imageFile && res.emrId) {
						const result = await apiUpdatePatientPicture(res.emrId, imageFile);
						setEmr({
							...res,
							patientPictureUrl: result.patientPictureUrl,
						});
					}
				}
			} else {
				const res = await apiCreateEmr(data);
				if (res) {
					setEmr({
						...emr,
						...res,
					});
					setPatientProfile({
						...patientProfile,
						patientId: res.patientId,
					});
					isSuccess = true;

					if (imageFile && res.emrId) {
						await apiUpdatePatientPicture(res.emrId, imageFile);
					}
				}
			}

			if (isSuccess) {
				setNextStep();
			}
		} catch (error) {
			console.error('Error saving emr:', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<form>
				<div className="py-6 px-3">
					<div>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Patient Information</p>
							</div>

							<div className="flex-1">
								<Button
									type="OUTLINE"
									className="w-full max-w-full mt-4 mb-10 flex items-center justify-center gap-1"
									onClick={handleChooseMyPatient}
								>
									<Search width={20} height={20} />
									Choose From My Patient Records
								</Button>

								<FormInput
									label="Phone Number"
									name="phone"
									value={props.patientProfile?.phone}
									type={EFormType.SINGLE_LINE}
									required
									prefix={
										<p
											className="m-0 text-black whitespace-nowrap"
											style={{
												borderRight: '1px solid gray',
												paddingRight: 10,
											}}
										>
											+63
										</p>
									}
									onChange={(val) => {
										props.setPatientProfile((old: any) => ({
											...old,
											phone: val,
										}));
									}}
									disabledDate={(d: any) => !d || d.isBefore(new Date())}
									buttonRightLabel={
										props.isPhoneDisable && (
											<p
												onClick={() => setShowModalChangePhone(true)}
												className="m-0 text-4 cursor-pointer text-primary"
											>
												Change Phone
											</p>
										)
									}
									disabled={!!props.isPhoneDisable}
								/>

								<div className="flex gap-x-1">
									<div className="flex-1">
										<p className="text-4.2">Patient's Picture</p>
										{!imagePreviewUrl ? (
											<div className="flex flex-col items-center">
												<EmptyList
													icon={
														<LabRequest
															width={48}
															height={48}
															color={theme.colors.black50}
														/>
													}
													title="Picture empty"
													description="Your Picture will appear here"
												/>
												<div style={{ width: '100%' }}>
													<Button
														type="OUTLINE"
														className="w-full max-w-full mt-4 mb-10 flex items-center justify-center"
														onClick={() =>
															document
																.getElementById('patientPictureUrl')
																?.click()
														}
													>
														<Upload
															width={17}
															height={17}
															color={theme.colors.black50}
															className="mr-1"
														/>
														Upload Image
													</Button>
												</div>

												<input
													type="file"
													id="patientPictureUrl"
													accept="image/*"
													onChange={handleImageChange}
													className="hidden"
												/>
											</div>
										) : (
											<div className="mb-4">
												{imagePreviewUrl && (
													<div
														className="relative"
														style={{
															border: '1px solid #a5b2bd',
															borderRadius: '8px',
															padding: '16px',
															marginTop: '16px',
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}
													>
														<img
															src={imagePreviewUrl}
															alt="Uploaded Image"
															style={{
																width: '200px',
																height: '200px',
																objectFit: 'cover',
																borderRadius: '8px',
															}}
														/>
														<div
															onClick={() =>
																setIsModalConfirmationVisible(true)
															}
															style={{
																borderRadius: '50%',
																backgroundColor: theme.colors.primary,
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																padding: 5,
																position: 'absolute',
																top: 0,
																right: 0,
																margin: 5,
																cursor: 'pointer',
															}}
														>
															<Trash2 size={20} color={theme.colors.white} />
														</div>
													</div>
												)}
												<div style={{ width: '100%' }}>
													<Button
														type="OUTLINE"
														className="w-full mt-4 flex items-center justify-center"
														onClick={() =>
															document
																.getElementById('patientPictureUrl')
																?.click()
														}
													>
														<Upload
															width={17}
															height={17}
															color={theme.colors.black50}
															className="mr-1"
														/>
														Upload Image
													</Button>
												</div>
												<input
													type="file"
													id="patientPictureUrl"
													accept="image/*"
													onChange={handleImageChange}
													className="hidden"
												/>
											</div>
										)}
									</div>
								</div>

								{forms.map((form) => (
									<FormInput
										key={form.label}
										label={form.label}
										name={form.name}
										type={form.type}
										items={form?.items}
										required={form.isRequired}
										withoutHorizontalPadding={!!form.items}
										withoutVerticalPadding={!!form.items}
										value={props.patientProfile?.[form.name]}
										rows={form.rows}
										onChange={(val) => {
											props.setPatientProfile((old: any) => ({
												...old,
												[form.name]: val,
											}));

											if (form.name === 'phone') {
												if (val.trim() === '') {
													return;
												}
												props.setSearchPhone(val);
												props.setIsEditingPhone();
											}
										}}
										disabledDate={(d: any) => !d || d.isAfter(new Date())}
										buttonRightLabel={
											form.name === 'phone' &&
											props.isPhoneDisable && (
												<p
													onClick={() => setShowModalChangePhone(true)}
													className="m-0 text-4 cursor-pointer text-primary"
												>
													Change Phone
												</p>
											)
										}
										disabled={form.name === 'phone' && props.isPhoneDisable}
									/>
								))}
								{isExpired && (
									<p style={{ color: 'red', marginTop: '7px' }}>
										Your Emr has expired
									</p>
								)}
							</div>
						</div>

						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Address</p>
							</div>
							<div className="flex-1">
								<FormInput
									key="address"
									label="Address"
									name="address"
									type={EFormType.MULTI_LINE}
									formType="text"
									rows={6}
									maxLine={6}
									value={props.patientProfile?.address}
									onChange={(val) => {
										props.setPatientProfile((old: any) => ({
											...old,
											address: val,
										}));
									}}
								/>
							</div>
						</div>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Status</p>
							</div>
							<div className="flex-1">
								<FormInput
									label="Status"
									name="maritalStatus"
									type={EFormType.ITEM_PICKER_H}
									items={['single', 'married', 'widowed']}
									value={props.patientProfile?.maritalStatus}
									onChange={(val) => {
										props.setPatientProfile((old: any) => ({
											...old,
											maritalStatus: val,
										}));
									}}
								/>
							</div>
						</div>
					</div>
					<div className="h-6" />
				</div>
				<Separator />
				<div className="py-3 px-4">
					<div className="w-44 ml-auto">
						<Button
							type="SOLID"
							onClick={saveEmr}
							isLoading={isLoading}
							isDisable={!isFormValid || isLoading}
						>
							Next
						</Button>
					</div>
				</div>
			</form>

			<ModalConfirmationChangePhone
				isVisible={showModalChangePhone}
				setVisibility={setShowModalChangePhone}
				actionHandler={() => {
					props.setPatientProfile({
						phone: undefined,
						patient: undefined,
						patientName: undefined,
						age: undefined,
						gender: undefined,
					});
					props.setSearchPhone(undefined);
					props.setIsPhoneDisable?.(false);
					setShowModalChangePhone(false);
				}}
			/>

			<ModalConfirmation
				isVisible={isModalConfirmationVisible}
				setVisibility={setIsModalConfirmationVisible}
				actionHandler={() => {
					handleDeleteImage();
					setIsModalConfirmationVisible(false);
				}}
				title={'Patient Picture'}
				type={EModalConfirmationType.DELETE}
			/>
		</>
	);
}
