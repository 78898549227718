import { message } from 'antd';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import {
	ApiResponseDefault,
	ApiResponsePagination,
} from '../types/apiResponse.type';
import { generateQueryString } from '../helpers/generateQueryString';
import { ApiResponseSuccess, ApiPaginationResults } from '../types/api.type';
import {
	BloodPressureTrendProperties,
	BmiTrendProperties,
	EMRFindAllTrendProperties,
	EMRFindBloodPressureProperties,
	EMRFindBMIProperties,
	EMRFindTrendProperties,
	EmrProperties,
	VitalSignTrendType,
} from '../types/emr.type';
import { HPIProperties } from '../types/hpi.type';
import { DiagnosisProperties } from '../types/diagnosis.type';
import { ObjectiveFindingProperties } from '../types/objectiveFindings.type';
import { VitalSignProperties } from '../types/vitalSign.type';
import { MedicalHistoryProperties } from '../types/medicalHistory.type';
import { PlanProperties, DoctorDocumentEMR } from '../types/plan.type';
import { TransactionEMRProperties } from '../types/transactionEmr.type';
import { TemplateEMRBillingProperties } from '../types/templateEmrBilling.type';
import axios from 'axios';

export async function apiCreateEmr(data: EmrProperties) {
	try {
		const res = await httpRequest.post<ApiResponseDefault<EmrProperties>>(
			`emr`,
			data,
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiGetEmr(emrId: string) {
	try {
		const res = await httpRequest.get<ApiResponseDefault<EmrProperties>>(
			`emr/${emrId}`,
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiUpdatePatientPicture(
	emrId: string,
	pictureFile: File,
) {
	const formData = new FormData();
	formData.append('file', pictureFile);

	try {
		const res = await httpRequest.put<ApiResponseDefault<any>>(
			`/emr/${emrId}/patient-picture`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}
export async function apiUpdateLabPicture(emrId: string, pictureFile: File) {
	const formData = new FormData();
	formData.append('file', pictureFile);

	try {
		const res = await httpRequest.put<ApiResponseDefault<any>>(
			`/emr/${emrId}/lab-test-picture`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiUpdateAnatomyPicture(
	emrId: string,
	pictureFile: File,
) {
	const formData = new FormData();
	formData.append('file', pictureFile);

	try {
		const res = await httpRequest.put<ApiResponseDefault<any>>(
			`/emr/${emrId}/anatomy-marking-picture`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiUpdateEmr(emrId: string, data: EmrProperties) {
	try {
		const res = await httpRequest.patch<ApiResponseDefault<EmrProperties>>(
			`emr/${emrId}`,
			data,
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiCreateHpi(
	emrId: string,
	serviceType: string,
	data: HPIProperties,
) {
	try {
		const res = await httpRequest.post<ApiResponseDefault<EmrProperties>>(
			`emr/${emrId}/${serviceType}`,
			data,
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiDeleteHpiByEMRId(emrId: string): Promise<any> {
	try {
		const res = await httpRequest.delete<ApiResponseDefault<HPIProperties>>(
			`/emr/hpi/${emrId}`,
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiCreateDiagnosis(
	emrId: string,
	serviceType: string,
	data: DiagnosisProperties,
) {
	try {
		const res = await httpRequest.post<ApiResponseDefault<EmrProperties>>(
			`emr/${emrId}/${serviceType}`,
			data,
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiDeleteDiagnosisByEMRId(emrId: string): Promise<any> {
	try {
		const res = await httpRequest.delete<
			ApiResponseDefault<DiagnosisProperties>
		>(`/emr/diagnosis/${emrId}`);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiCreateObjectiveFinding(
	emrId: string,
	serviceType: string,
	data: ObjectiveFindingProperties,
) {
	try {
		const res = await httpRequest.post<ApiResponseDefault<EmrProperties>>(
			`emr/${emrId}/${serviceType}`,
			data,
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiDeleteObjectiveFindingByEMRId(
	emrId: string,
): Promise<any> {
	try {
		const res = await httpRequest.delete<
			ApiResponseDefault<ObjectiveFindingProperties>
		>(`/emr/objective-finding/${emrId}`);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiCreateVitalSign(
	emrId: string,
	serviceType: string,
	data: VitalSignProperties,
) {
	try {
		const res = await httpRequest.post<ApiResponseDefault<VitalSignProperties>>(
			`emr/${emrId}/${serviceType}`,
			data,
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiGetVitalSignTrend(
	patientId: string,
	type?: VitalSignTrendType,
	emrId?: string,
	from?: string,
	to?: string,
	date?: string,
) {
	const url = `/emr/vital-sign-trends/${patientId}?type=${type}${
		emrId ? `&emrId=${emrId}` : ''
	}${from ? `&from=${from}` : ''}${to ? `&to=${to}` : ''}${
		date ? `&date=${date}` : ''
	}`;
	const res =
		type === 'blood_pressure'
			? await httpRequest.get<ApiResponseDefault<BloodPressureTrendProperties>>(
					url,
			  )
			: type === 'bmi'
			? await httpRequest.get<ApiResponseDefault<BmiTrendProperties>>(url)
			: await httpRequest.get<ApiResponseDefault<EMRFindTrendProperties>>(url);

	if (res && res.data && res.data.payload) {
		return res.data.payload;
	} else {
		return null;
	}
}

export async function apiCreateMedicalHistory(
	emrId: string,
	serviceType: string,
	data: MedicalHistoryProperties,
) {
	try {
		const res = await httpRequest.post<
			ApiResponseDefault<MedicalHistoryProperties>
		>(`emr/${emrId}/${serviceType}`, data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiCreatePlan(
	emrId: string,
	serviceType: string,
	data: PlanProperties,
) {
	try {
		const res = await httpRequest.post<ApiResponseDefault<PlanProperties>>(
			`emr/${emrId}/${serviceType}`,
			data,
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiTransactionEmr(data: TransactionEMRProperties) {
	try {
		const res = await httpRequest.post<
			ApiResponseDefault<TransactionEMRProperties>
		>(`transaction-emrs`, data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiUpdateTransactionEmr(
	transactionEmrId: string,
	data: TransactionEMRProperties,
) {
	try {
		const res = await httpRequest.patch<
			ApiResponseDefault<TransactionEMRProperties>
		>(`transaction-emrs/${transactionEmrId}`, data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiTemplateBillingEmr(
	data: TemplateEMRBillingProperties,
) {
	try {
		const res = await httpRequest.post<
			ApiResponseDefault<TemplateEMRBillingProperties>
		>(`template-emr-billings`, data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiGetTemplateBillingEmr(doctorId: string) {
	try {
		const res = await httpRequest.get<
			ApiResponsePagination<TemplateEMRBillingProperties>
		>(`template-emr-billings?doctorId=${doctorId}`);
		return res.data.payload.results;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}
