import { DatePicker, InputNumber, TimePicker } from 'antd';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { CheckCircle, Circle, MinusCircle } from 'react-feather';
import styled from 'styled-components';
import { theme } from '../../assets/theme';
import { validatePhoneNumber } from '../../helpers/validation';
import Separator from '../Separator';
import COLORS from '../../assets/globalColors';

export enum EFormType {
	SINGLE_LINE = 'SINGLE_LINE',
	MULTI_LINE = 'MULTI_LINE',
	TIME_PICKER = 'TIME_PICKER',
	DATE_PICKER = 'DATE_PICKER',
	ITEM_PICKER_H = 'ITEM_PICKER_H',
	INPUT_PHONE = 'INPUT_PHONE',
}

interface Props {
	label?: string;
	type?: EFormType;
	topSeparator?: boolean;
	bottomSeparator?: boolean;
	rows?: number;
	autoGrowRows?: boolean;
	value?: any;
	placeholder?: string;
	prefix?: any;
	suffix?: any;
	autoComplete?: string;
	disabled?: boolean;
	inputClassName?: string;
	withoutBorder?: boolean;
	withoutHorizontalPadding?: boolean;
	withoutVerticalPadding?: boolean;
	name?: string;
	items?: string[];
	required?: boolean;
	formType?: React.HTMLInputTypeAttribute;
	min?: string | number;
	maxLength?: number;
	maxLine?: number;
	maxValue?: number;
	onChange?: (val: string) => void;
	onKeyDown?: (e: any) => void;
	disabledDate?: any;
	error?: boolean;
	optional?: boolean;
	buttonRightLabel?: React.ReactNode;
}

export default function FormInput(props: Props) {
	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (props.value || !textAreaRef?.current) return;

		if (props.type === EFormType.MULTI_LINE && props.autoGrowRows) {
			autoGrowRows(textAreaRef.current);
		}
	}, [props.value]);

	function onChange(
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) {
		if (props.disabled) return;

		if (props.onChange) {
			props.onChange(event.currentTarget.value);
		}
		if (props.type === EFormType.MULTI_LINE && props.autoGrowRows) {
			autoGrowRows(event.target as HTMLTextAreaElement);
		}
	}

	function onChangeDate(value: moment.Moment | null, dateString: string) {
		if (props.disabled) return;

		if (value && props.onChange) {
			props.onChange(value.toISOString());
		}
	}

	function onChangeTime(time: moment.Moment | null, timeString: string) {
		if (props.disabled) return;

		if (time && props.onChange) {
			props.onChange(time.toISOString());
		}
	}

	function onChangeVal(val: string) {
		if (props.onChange) {
			props.onChange(val);
		}
	}

	function autoGrowRows(element: HTMLTextAreaElement) {
		element.style.height = 'inherit';
		element.style.height = `${element.scrollHeight}px`;
	}

	const withoutBorder =
		props.withoutBorder || props.type === EFormType.ITEM_PICKER_H;
	const withoutHorizontalPadding =
		props.withoutHorizontalPadding || props.type === EFormType.ITEM_PICKER_H;
	const withoutVerticalPadding =
		props.withoutVerticalPadding || props.type === EFormType.ITEM_PICKER_H;

	return (
		<>
			{props.topSeparator && <Separator style={{ margin: 0 }} />}
			<div className="py-2">
				<div className="flex flex-row justify-between">
					{props.label && (
						<p className="m-0 text-4">
							{props.label}{' '}
							{props.required && <span style={{ color: COLORS.red }}>*</span>}{' '}
							{props.optional && (
								<span style={{ color: COLORS.charcoal_200 }}>(optional)</span>
							)}{' '}
						</p>
					)}
					{props.buttonRightLabel && props.buttonRightLabel}
				</div>
				<InputContainer
					style={{
						borderColor: withoutBorder ? 'transparent' : undefined,
						paddingLeft: withoutHorizontalPadding ? 0 : undefined,
						paddingRight: withoutHorizontalPadding ? 0 : undefined,
						paddingTop: withoutVerticalPadding ? 0 : undefined,
						paddingBottom: withoutVerticalPadding ? 0 : undefined,
					}}
				>
					{props.prefix ? props.prefix : undefined}
					{props.onChange ? (
						!props.type || props.type === EFormType.SINGLE_LINE ? (
							<input
								value={props.value ?? ''}
								onChange={onChange}
								placeholder={props.placeholder}
								autoComplete={props.autoComplete}
								name={props.name}
								disabled={props.disabled}
								required={props.required}
								type={props.formType}
								min={props.min}
								className={
									'm-0 w-full text-4 border-none outline-none bg-transparent' +
									(props.inputClassName ? ' ' + props.inputClassName : '')
								}
							/>
						) : props.type === EFormType.MULTI_LINE ? (
							<textarea
								onKeyDown={props.onKeyDown}
								ref={props.autoGrowRows ? textAreaRef : undefined}
								rows={props.rows}
								value={props.value}
								onChange={onChange}
								disabled={props.disabled}
								placeholder={props.placeholder}
								autoComplete={props.autoComplete}
								name={props.name}
								required={props.required}
								className={
									'block m-0 w-full text-4 border-none outline-none bg-transparent' +
									(props.inputClassName ? ' ' + props.inputClassName : '')
								}
							/>
						) : props.type === EFormType.DATE_PICKER ? (
							<DatePicker
								value={props.value ? moment(props.value) : undefined}
								onChange={onChangeDate}
								placeholder={props.placeholder ?? ''}
								autoComplete={props.autoComplete}
								name={props.name}
								disabledDate={props.disabledDate}
								className={
									'w-full p-0 border-none shadow-none bg-transparent' +
									(props.inputClassName ? ' ' + props.inputClassName : '')
								}
							/>
						) : props.type === EFormType.TIME_PICKER ? (
							<TimePicker
								value={props.value ? moment(props.value) : undefined}
								onChange={onChangeTime}
								placeholder={props.placeholder ?? ''}
								autoComplete={props.autoComplete}
								name={props.name}
								className={
									'w-full p-0 border-none shadow-none bg-transparent' +
									(props.inputClassName ? ' ' + props.inputClassName : '')
								}
							/>
						) : props.type === EFormType.ITEM_PICKER_H ? (
							<div className="flex gap-x-3 w-full">
								{props.items?.map((item) => (
									<button
										key={item}
										type="button"
										onClick={() => onChangeVal(item.toLowerCase())}
										className={`flex-1 py-2 px-3.5 flex items-center gap-x-2.5 bg-transparent border rounded-4 border-solid cursor-pointer ${
											props.value === item.toLowerCase()
												? 'border-green'
												: 'border-ash-800'
										}`}
									>
										{props.value === item.toLowerCase() ? (
											<CheckCircle
												width={20}
												height={20}
												color={theme.colors.green}
											/>
										) : (
											<MinusCircle
												width={20}
												height={20}
												color={theme.colors.black40}
											/>
										)}
										<p className="m-0">
											{item[0].toUpperCase() + item.slice(1).toLowerCase()}
										</p>
									</button>
								))}
							</div>
						) : undefined
					) : (
						<div className={props.inputClassName}>{props.value}</div>
					)}
					{props.suffix ? props.suffix : undefined}
				</InputContainer>
				{props.name === 'phone' && validatePhoneNumber(props.value).length > 0
					? validatePhoneNumber(props.value).map((errorMessage, key) => (
							<p
								className="mt-2 mb-0 whitespace-nowrap text-3.5"
								style={{ color: 'red' }}
								key={key}
							>
								{errorMessage}
							</p>
					  ))
					: null}
			</div>
			{props.bottomSeparator && <Separator style={{ margin: 0 }} />}
			{props.error && (
				<p style={{ color: 'red', fontSize: '13px', marginTop: '-5px' }}>
					{props.label} is required*
				</p>
			)}
			{props.maxValue && Number(props.value) > props.maxValue && (
				<p style={{ color: 'red', fontSize: '13px', marginTop: '-5px' }}>
					{props.label} cannot more than {props.maxValue}
				</p>
			)}
			{props.maxLength && props.value?.toString().length > props.maxLength && (
				<p style={{ color: 'red', fontSize: '13px', marginTop: '-5px' }}>
					{props.label} cannot more than {props.maxLength} characters
				</p>
			)}
			{props.maxLine && props.value?.split('\n').length > props.maxLine && (
				<p style={{ color: 'red', fontSize: '13px', marginTop: '-5px' }}>
					{props.label} cannot more than {props.maxLine} lines
				</p>
			)}
		</>
	);
}

const InputContainer = styled.div`
	padding: 10px 12px;
	display: flex;
	align-items: center;
	column-gap: 8px;
	border: 1px solid ${({ theme }) => theme.colors.ash800};
	border-radius: 16px;
	&:focus-within {
		border-color: ${({ theme }) => theme.colors.black100};
	}
`;

const CustomInputNumber = styled(InputNumber)`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px;
	gap: 8px;
	width: 100%;
	background: #ffffff;
`;
