import React, { Key, useEffect } from 'react';
import moment from 'moment';
import { EMRFindTrendProperties } from '../../../../types/emr.type';
import { apiGetVitalSignTrend } from '../../../../api/emr';
import Spinner from '../../../Spinner';
import { theme } from '../../../../assets/theme';
import { Card, Divider, Typography } from 'antd';
import { Chart } from 'react-google-charts';
import { HistoryDataPediaChart } from '../DetailEmr/VitalSign/VitalSignHistoryData/HistoryDataPediaChart';

const { Text } = Typography;

const PediaChart = (props: { patientId: string; birthdate: Date }) => {
	const birthdate = props.birthdate || new Date();
	const [data, setData] = React.useState<EMRFindTrendProperties>();
	const [isLoading, setIsLoading] = React.useState(true);
	const [scatterData, setScatterData] = React.useState<any[][]>([
		['X', '3%', '15%', '50%', '85%', '97%', ''],
		[0, 2.4, 2.8, 3.2, 3.7, 4, NaN],
		[30, 3.2, 3.6, 4.2, 4.8, 5, NaN],
		[60, 4, 4.5, 5.1, 5.9, 6, NaN],
		[90, 4.6, 5.1, 5.8, 6.7, 7.2, NaN],
		[120, 5.1, 5.6, 6.4, 7.3, 7.5, NaN],
		[150, 5.5, 6.1, 6.9, 7.8, 8.1, NaN],
		[180, 5.8, 6.2, 7.3, 8.3, 8.9, NaN],
		[210, 6.1, 6.5, 7.6, 8.7, 9.4, NaN],
		[240, 6.3, 6.8, 7.9, 9, 9.7, NaN],
		[270, 6.6, 7, 8.2, 9.3, 10.1, NaN],
		[300, 6.8, 7.3, 8.5, 9.6, 10.4, NaN],
		[330, 7, 7.5, 8.7, 9.9, 10.7, NaN],
		[360, 7.1, 7.7, 8.9, 10.2, 11, NaN],
		[390, 7.3, 7.9, 9.2, 10.4, 10.8, NaN],
		[420, 7.5, 8, 9.4, 10.7, 11.3, NaN],
		[450, 7.7, 8.2, 9.6, 11, 11.5, NaN],
		[480, 7.9, 8.4, 9.8, 11.3, 11.9, NaN],
		[510, 8.1, 8.6, 10, 11.5, 12.1, NaN],
		[540, 8.2, 8.7, 10.2, 11.8, 12.3, NaN],
		[570, 8.4, 8.9, 10.4, 12, 12.6, NaN],
		[600, 8.6, 9, 10.6, 12.3, 12.9, NaN],
		[630, 8.8, 9.2, 10.9, 12.6, 13.3, NaN],
		[660, 9, 9.5, 11.1, 12.9, 13.5, NaN],
		[690, 9.2, 9.7, 11.3, 13.2, 13.8, NaN],
		[720, 9.3, 9.8, 11.5, 13.5, 14.1, NaN],
	]);

	const [heightData, setHeightData] = React.useState<any[][]>([
		['X', '3%', '15%', '50%', '85%', '97%', ''],
		[0, 46.3, 47.9, 49.9, 51.8, 53, NaN],
		[30, 51.1, 52.7, 54.7, 56.7, 57.9, NaN],
		[60, 54.7, 56.4, 58.4, 60.5, 61.7, NaN],
		[90, 57.6, 59.3, 61.4, 63.5, 64.8, NaN],
		[120, 60, 61.7, 63.9, 66, 67.3, NaN],
		[150, 61.9, 63.7, 65.9, 68.1, 69.4, NaN],
		[180, 63.6, 65.4, 67.6, 69.8, 71.1, NaN],
		[210, 65.1, 66.9, 69.2, 71.4, 72.7, NaN],
		[240, 66.5, 68.3, 70.6, 72.9, 74.2, NaN],
		[270, 67.7, 69.6, 72, 74.3, 75.7, NaN],
		[300, 69, 70.9, 73.3, 75.6, 77, NaN],
		[330, 70.2, 72.1, 74.5, 77, 78.4, NaN],
		[360, 71.3, 73.3, 75.7, 78.2, 79.7, NaN],
		[390, 72.4, 74.4, 76.9, 79.4, 80.9, NaN],
		[420, 73.4, 75.5, 78, 80.6, 82.1, NaN],
		[450, 74.4, 76.5, 79.1, 81.8, 83.3, NaN],
		[480, 75.4, 77.5, 80.2, 82.9, 84.5, NaN],
		[510, 76.3, 78.5, 81.2, 84, 85.6, NaN],
		[540, 77.2, 79.5, 82.3, 85.1, 86.7, NaN],
		[570, 78.1, 80.4, 83.2, 86.1, 87.8, NaN],
		[600, 78.9, 81.3, 84.2, 87.1, 88.8, NaN],
		[630, 79.7, 82.2, 85.1, 88.1, 89.8, NaN],
		[660, 80.5, 83, 86, 89.1, 90.9, NaN],
		[690, 81.3, 83.8, 86.9, 90, 91.9, NaN],
		[720, 82.1, 84.6, 87.8, 90.8, 92.8, NaN],
	]);

	const [headCircumferenceData, setHeadCircumferenceData] = React.useState<
		any[][]
	>([
		['X', '3%', '15%', '50%', '85%', '97%', ''],
		[0, 32.1, 33.1, 34.5, 35.8, 36.9, NaN],
		[30, 35.1, 36.1, 37.3, 38.5, 39.5, NaN],
		[60, 36.9, 37.9, 39.1, 40.3, 41.3, NaN],
		[90, 38.3, 39.3, 40.5, 41.7, 42.7, NaN],
		[120, 39.4, 40.4, 41.6, 42.9, 43.9, NaN],
		[150, 40.3, 41.3, 42.6, 43.8, 44.8, NaN],
		[180, 41, 42.1, 43.3, 44.6, 45.6, NaN],
		[210, 41.7, 42.7, 44, 45.3, 46.3, NaN],
		[240, 42.2, 43.2, 44.5, 45.8, 46.9, NaN],
		[270, 42.6, 43.7, 45, 46.3, 47.4, NaN],
		[300, 43, 44.1, 45.4, 46.7, 47.8, NaN],
		[330, 43.4, 44.4, 45.8, 47, 48.2, NaN],
		[360, 43.6, 44.7, 45.8, 47.1, 48.2, NaN],
		[390, 43.9, 45, 46.1, 47.4, 48.5, NaN],
		[420, 44.1, 45.2, 46.3, 47.6, 48.7, NaN],
		[450, 44.3, 45.3, 46.4, 47.8, 48.9, NaN],
		[480, 44.5, 45.5, 46.6, 47.9, 49, NaN],
		[510, 44.7, 45.7, 46.8, 48.1, 49.2, NaN],
		[540, 44.9, 45.9, 47, 48.3, 49.4, NaN],
		[570, 45.1, 46.1, 47.2, 48.5, 49.6, NaN],
		[600, 45.3, 46.3, 47.5, 48.7, 49.8, NaN],
		[630, 45.5, 46.5, 47.7, 48.9, 50, NaN],
		[660, 45.7, 46.7, 47.8, 49, 50.1, NaN],
		[690, 45.9, 46.9, 48, 49.2, 50.2, NaN],
		[720, 46.1, 47.1, 48.2, 49.3, 50.3, NaN],
	]);

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const resHeadCircumference = await apiGetVitalSignTrend(
				props.patientId,
				'pedia_chart',
			);
			if (resHeadCircumference) {
				setData(resHeadCircumference as EMRFindTrendProperties);
			}
		} catch (e) {
			console.log(e);
			setIsLoading(false);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		console.log('data: ', data);
	}, [data]);

	useEffect(() => {
		if (data) {
			const newScatterData = [...scatterData];
			const newHeightData = [...heightData];
			const newHeadCircumferenceData = [...headCircumferenceData];
			for (let i = 0; i < data.histories.length; i++) {
				const newDate = moment(data.histories[i].date).diff(birthdate, 'days');

				if (newDate > 720) {
					return;
				}

				newScatterData.push([
					newDate,
					NaN,
					NaN,
					NaN,
					NaN,
					NaN,
					data.histories[i].vitalSign?.weight,
				]);

				newHeightData.push([
					newDate,
					NaN,
					NaN,
					NaN,
					NaN,
					NaN,
					data.histories[i].vitalSign?.height,
				]);

				if (data.histories[i].headCircumference) {
					newHeadCircumferenceData.push([
						newDate,
						NaN,
						NaN,
						NaN,
						NaN,
						NaN,
						data.histories[i].headCircumference,
					]);
				}
			}

			setScatterData(newScatterData);
			setHeightData(newHeightData);
			setHeadCircumferenceData(newHeadCircumferenceData);
		}
	}, [data]);

	return isLoading ? (
		<div className="py-5 mx-auto w-fit">
			<Spinner size={25} color={theme.colors.primary} />
		</div>
	) : (
		<div className="p-5 flex flex-col">
			<TrendInfo
				label="Weight"
				value={data?.summary?.find((item) => item.title === 'Weight')?.value}
				unit={data?.summary?.find((item) => item.title === 'Weight')?.unit}
				days={moment(data?.currentDate).diff(birthdate, 'days')}
			/>
			<Chart
				chartType="LineChart"
				width="100%"
				height="400px"
				data={scatterData}
				options={{
					curveType: 'function',
					hAxis: {
						title: 'Days',
						minValue: 0,
						maxValue: 720,
						ticks: [
							0, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720,
						],
					},
					vAxis: {
						minValue: 2,
						maxValue: 16,
						ticks: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
					},
					legend: 'none',
					trendlines: {
						0: { type: 'polynomial', degree: 3 },
						1: { type: 'polynomial', degree: 3 },
						2: { type: 'polynomial', degree: 3 },
						3: { type: 'polynomial', degree: 3 },
						4: { type: 'polynomial', degree: 3 },
					},
					backgroundColor: 'transparent',
					colors: [
						'#03CEA4',
						'#03CEA4',
						'#03CEA4',
						'#03CEA4',
						'#03CEA4',
						`${theme.colors.primary}`,
					],
					series: {
						0: { lineWidth: 0, pointSize: 0 },
						1: { lineWidth: 0, pointSize: 0 },
						2: { lineWidth: 0, pointSize: 0 },
						3: { lineWidth: 0, pointSize: 0 },
						4: { lineWidth: 0, pointSize: 0 },
						5: { pointSize: 10 },
					},
					chartArea: {
						left: 20,
						top: 20,
						right: 20,
						bottom: 60,
					},
				}}
			/>
			<TrendInfo
				label="Height"
				value={data?.summary?.find((item) => item.title === 'Height')?.value}
				unit={data?.summary?.find((item) => item.title === 'Height')?.unit}
				days={moment(data?.currentDate).diff(birthdate, 'days')}
			/>
			<Chart
				chartType="LineChart"
				width="100%"
				height="400px"
				data={heightData}
				options={{
					curveType: 'function',
					hAxis: {
						title: 'Days',
						minValue: 0,
						maxValue: 720,
						ticks: [
							0, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720,
						],
					},
					vAxis: {
						minValue: 45,
						maxValue: 95,
						ticks: [45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95],
					},
					legend: 'none',
					trendlines: {
						0: { type: 'polynomial', degree: 3 },
						1: { type: 'polynomial', degree: 3 },
						2: { type: 'polynomial', degree: 3 },
						3: { type: 'polynomial', degree: 3 },
						4: { type: 'polynomial', degree: 3 },
					},
					backgroundColor: 'transparent',
					colors: [
						'#2B9CDC',
						'#2B9CDC',
						'#2B9CDC',
						'#2B9CDC',
						'#2B9CDC',
						`${theme.colors.primary}`,
					],
					series: {
						0: { lineWidth: 0, pointSize: 0 },
						1: { lineWidth: 0, pointSize: 0 },
						2: { lineWidth: 0, pointSize: 0 },
						3: { lineWidth: 0, pointSize: 0 },
						4: { lineWidth: 0, pointSize: 0 },
						5: { pointSize: 10 },
					},
					chartArea: {
						left: 20,
						top: 20,
						right: 20,
						bottom: 60,
					},
				}}
			/>
			<TrendInfo
				label="Head Circumference"
				value={data?.currentValue}
				unit={data?.unit}
				days={moment(data?.currentDate).diff(birthdate, 'days')}
			/>
			<Chart
				chartType="LineChart"
				width="100%"
				height="400px"
				data={headCircumferenceData}
				options={{
					curveType: 'function',
					hAxis: {
						title: 'Days',
						minValue: 0,
						maxValue: 720,
						ticks: [
							0, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720,
						],
					},
					vAxis: {
						minValue: 31,
						maxValue: 55,
						ticks: [
							31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46,
							47, 48, 49, 50, 51, 52, 53, 54, 55,
						],
					},
					legend: 'none',
					trendlines: {
						0: { type: 'polynomial', degree: 3 },
						1: { type: 'polynomial', degree: 3 },
						2: { type: 'polynomial', degree: 3 },
						3: { type: 'polynomial', degree: 3 },
						4: { type: 'polynomial', degree: 3 },
					},
					backgroundColor: 'transparent',
					colors: [
						'#825DD1',
						'#825DD1',
						'#825DD1',
						'#825DD1',
						'#825DD1',
						`${theme.colors.primary}`,
					],
					series: {
						0: { lineWidth: 0, pointSize: 0 },
						1: { lineWidth: 0, pointSize: 0 },
						2: { lineWidth: 0, pointSize: 0 },
						3: { lineWidth: 0, pointSize: 0 },
						4: { lineWidth: 0, pointSize: 0 },
						5: { pointSize: 10 },
					},
					chartArea: {
						left: 20,
						top: 20,
						right: 20,
						bottom: 60,
					},
				}}
			/>
			<HistoryDataPediaChart data={data} birthdate={birthdate} />
		</div>
	);
};

export default PediaChart;

const TrendInfo = ({
	label,
	value,
	unit,
	days,
}: {
	label: string;
	value?: number;
	unit?: string;
	days: number;
}) => {
	return (
		<div
			className="text-3\.5 font-normal leading-7"
			style={{ color: '#748494' }}
		>
			<Text className="flex mb-2 font-medium" style={{ color: '#748494' }}>
				{label}
			</Text>
			<span className="text-10 font-semibold text-black-100">{value}</span>{' '}
			{unit}
			<span className="text-10 font-semibold text-black-100 ml-4">
				{days}
			</span>{' '}
			days
		</div>
	);
};
