export enum EPaymentType {
  PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',
  DISCOUNT_VOUCHER = 'DISCOUNT_VOUCHER',
  POINT = 'POINT',
  BASE_PRICE = 'BASE_PRICE',
  PATIENT_PWD_DISCOUNT = 'PATIENT_PWD_DISCOUNT',
  PATIENT_TRANSACTION_FEE = 'PATIENT_TRANSACTION_FEE',
  PATIENT_HOME_SERVICE_FEE = 'PATIENT_HOME_SERVICE_FEE',
  DOCTOR_COMMISSION_FEE = 'DOCTOR_COMMISSION_FEE',
  DOCTOR_TRANSACTION_FEE = 'DOCTOR_TRANSACTION_FEE',
  DOCTOR_DOCTOR_DOCUMENT_FEE = 'DOCTOR_DOCTOR_DOCUMENT_FEE',
  DOCTOR_INCOME = 'DOCTOR_INCOME',
  MEDICALSERVICE_LAB_COMMISSION_FEE = 'MEDICALSERVICE_LAB_COMMISSION_FEE',
  MEDICALSERVICE_LAB_TRANSACTION_FEE = 'MEDICALSERVICE_LAB_TRANSACTION_FEE',
  TELEMEDICINE_EXTEND = 'TELEMEDICINE_EXTEND',
  FINAL_PAYMENT = 'FINAL_PAYMENT',
  PATIENT_REFUND_RECEIVED = 'PATIENT_REFUND_RECEIVED',
  DOCTOR_REFUND_RECEIVED = 'DOCTOR_REFUND_RECEIVED',
  LAB_REFUND_RECEIVED = 'LAB_REFUND_RECEIVED',
}

export enum EPaymentMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  EWALLET = 'EWALLET',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum EPaymentTypeVendor {
  PAYMONGO = 'PAYMONGO',
}

export enum EPaymentVendorId {
  CARD = 'CARD',
  GCASH = 'GCASH',
  GRABPAY = 'GRABPAY',
  MAYA = 'MAYA',
  BPI = 'BPI',
}

export enum EPaymentTransactionType {
  TELEMEDICINE = 'TELEMEDICINE',
  HEALTH_SERVICE = 'HEALTH_SERVICE',
  DOCTOR_DOCUMENT = 'DOCTOR_DOCUMENT',
  IN_PERSON = 'IN_PERSON',
  CONSULT_NOW = 'CONSULT_NOW',
  EBOOK = 'EBOOK',
}

export enum EPaymentDetailType {
  PAYMENT = 'PAYMENT',
  DISCOUNT = 'DISCOUNT',
  PAYMENT_EXTEND = 'PAYMENT_EXTEND',
}

export enum EPaymentStatus {
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  PAID_FULL = 'PAID_FULL',
  CANCELLED_BY_SYSTEM = 'CANCELLED_BY_SYSTEM',
  CANCELLED_BY_USER = 'CANCELLED_BY_USER',
  EXPIRED = 'EXPIRED',
}

export type PaymentDetailProperties = {
  paymentDetailId: string;
  paymentId: string;
  paymentType: EPaymentType;
  paymentMethod?: EPaymentMethod;
  paymentVendorType?: EPaymentTypeVendor;
  paymentVendorId?: EPaymentVendorId;
  externalId?: string;
  totalPay: number;
  status: EPaymentStatus;
  meta?: any;
  type: EPaymentDetailType;
  expiredAt: Date;
  updatedAt?: Date;
  createdAt?: Date;
  payment?: PaymentProperties;
};

export type PaymentProperties = {
  invoiceId: string;
  paymentId: string;
  transactionId: string;
  transactionType: EPaymentTransactionType;
  totalNeedToPay: number;
  paymentStatus: EPaymentStatus;
  buyerId: string;
  metaBuyerDetail: any;
  meta?: any;
  expiredAt: Date;
  updatedAt?: Date;
  createdAt: Date;
  paymentDetails?: PaymentDetailProperties[];
};

export type CreditCardProperties = {
  card_number: string;
  exp_month: string;
  exp_year: string;
  cvc: string;
};

export type lineItemsProperties = {
  amount: number;
  currency: string;
  description?: string;
  images?: string[];
  name: string;
  quantity: number;
};

export type CheckoutAttribute = {
  billing: {
    address: {
      city: string;
      country: string;
      line1: string;
      line2: string;
      postal_code: string;
      state: string;
    };
    name?: string;
    email?: string;
    phone?: string;
  };
  checkout_url: string;
  client_key: string;
  description: string;
  line_items: lineItemsProperties[];
  livemode: boolean;
  merchant: string;
  payments: any[];
  payment_intent: any;
  payment_method_types: any[];
  reference_number: string;
  send_email_receipt: boolean;
  show_description: boolean;
  show_line_items: boolean;
  status: string;
  success_url: string;
  created_at: number;
  updated_at: number;
  metadata: any;
};

export type IntentAttribute = {
  amount: number;
  currency: 'PHP';
  description: string; //'Order # 20190604';
  statement_descriptor: string; // 'The Barkery Shop';
  status:
    | 'awaiting_payment_method'
    | 'awaiting_next_action'
    | 'processing'
    | 'succeeded';
  livemode: boolean;
  client_key: string;
  created_at: number; // 1586179682;
  updated_at: number; // 1586179682;
  last_payment_error: any;
  payment_method_allowed: string[]; // ['card'];
  payments: [];
  next_action: any;
  payment_method_options: {
    card: {
      request_three_d_secure: 'any';
    };
  };
  metadata: any;
  // {
  //   yet_another_metadata: 'good metadata';
  //   reference_number: 'X1999';
  // };
};

export type SourceAttribute = {
  amount: number;
  billing: any;
  currency: 'PHP';
  description: any;
  livemode: boolean;
  redirect: {
    checkout_url: string;
    failed: string;
    success: string;
  };
  statement_descriptor: null;
  status: string;
  type: 'gcash' | 'paymaya';
  metadata: null;
  created_at: 1658206587;
  updated_at: 1658206587;
};

export type PaymentMethodAttribute = {
  livemode: boolean;
  type: string;
  billing: {
    address: {
      city: any;
      country: any;
      line1: any;
      line2: any;
      postal_code: any;
      state: any;
    };
    email: string;
    name: string;
    phone: string;
  };
  created_at: number;
  updated_at: number;
  details: {
    exp_month: number;
    exp_year: number;
    last4: string;
  };
  metadata: any;
};

export interface PaymongoPaymentProperties<Attributes> {
  id: string;
  type:
    | 'checkout_session'
    | 'source'
    | 'payment_intent'
    | 'payment_method'
    | '';
  attributes: Attributes;
}

export interface PaymentInitiateResponse {
  data: PaymentProperties;
  paymentCheckout?: PaymongoPaymentProperties<CheckoutAttribute>;
  paymentIntent?: PaymongoPaymentProperties<IntentAttribute>;
  paymentSource?: PaymongoPaymentProperties<SourceAttribute>;
}

export const initialPayment: PaymentProperties = {
  invoiceId: '',
  paymentId: '',
  transactionId: '',
  transactionType: EPaymentTransactionType.TELEMEDICINE,
  totalNeedToPay: 0,
  paymentStatus: EPaymentStatus.WAITING_PAYMENT,
  buyerId: '',
  metaBuyerDetail: {},
  meta: {},
  expiredAt: new Date(),
  createdAt: new Date(),
  paymentDetails: [],
};

export enum ECreditCardPaymentFailedStatus {
  CARD_EXPIRED = 'card_expired',
  CVC_INVALID = 'cvc_invalid',
  GENERIC_DECLINE = 'generic_decline',
  FRAUDULENT = 'fraudulent',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  PROCESSOR_BLOCKED = 'processor_blocked',
  LOST_CARD = 'lost_card',
  STOLEN_CARD = 'stolen_card',
  PROCESSOR_UNAVAILABLE = 'processor_unavailable',
  BLOCKED = 'blocked',
}

export type PaymentDetailRequest = {
  paymentType?: EPaymentType;
  paymentMethod?: EPaymentMethod;
  paymentVendorId?: EPaymentVendorId;
  totalPay: number;
  type: EPaymentDetailType;
  meta?: any;
};

export type PaymentCreateRequest = {
  transactionId: string;
  transactionType: EPaymentTransactionType;
  totalNeedToPay: number;
  buyerId: string;
  buyerMetadata: any;
  paymentDetails: PaymentDetailRequest[];
  meta?: any;
};

export type CreatePaymentMethodRequest = {
  type: 'card' | 'paymaya' | 'dob';
  details: {
    card_number?: string;
    exp_month?: number;
    exp_year?: number;
    cvc?: string;
    bank_code?: 'test_bank_one' | 'bpi';
  };
  billing?: {
    name?: string;
    email?: string;
    phone?: string;
    metadata?: string;
    address?: {
      line1?: string;
      line2?: string;
      city?: string;
      state?: string;
      postal_code?: string;
      country?: string;
    };
  };
};

export type PaymentRefundRequest = {
  amount?: number;
  currency?: 'PHP';
  livemode?: boolean;
  notes: string; // Double payment created
  payment_id?: string; // pay_qOShdAuD3p8tKa6Ac4SRfbjs
  payout_id?: null;
  reason?: 'duplicate' | 'fraudulent' | 'requested_by_customer' | 'others'; // succeeded
  status?: 'pending' | 'processing' | 'succeeded' | 'failed'; // requested_by_customer
  created_at?: number; // 1612746914
  updated_at?: number; // 1612746914
  metadata?: any;
};
