import { ChevronRight, Info } from 'react-feather';
import { useEffect, useState } from 'react';
import { theme } from '../../../../../assets/theme';
import EmptyList from '../../../../EmptyList';
import Separator from '../../../../Separator';
import Spinner from '../../../../Spinner';
import { EmrProperties } from '../../../../../types/emr.type';
import { renderStatusTag } from '../../StatusTag';
import { getName } from '../../../../../helpers/name';

interface Props {
	setNextStep: () => void;
	similarPatients: EmrProperties[];
	isLoading: boolean;
	onItemClick: (emrId: string) => void;
}

export default function ListHistory(props: Props) {
	const { isLoading, similarPatients, setNextStep, onItemClick } = props;
	const [search, setSearch] = useState('');
	const [debouncedSearch, setDebouncedSearch] = useState(search);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedSearch(search);
		}, 500);
		return () => {
			clearTimeout(handler);
		};
	}, [search]);

	function onClickItem(item: EmrProperties) {
		setNextStep();
		onItemClick(item.emrId || '');
	}

	const filteredPatients = similarPatients.filter((patient) =>
		patient.patient
			? patient?.patient?.name
					?.toLowerCase()
					.includes(debouncedSearch.toLowerCase())
			: getName(patient.patientInRelation)
					.toLowerCase()
					.includes(debouncedSearch.toLowerCase()),
	);

	const formatDate = (dateString: string) => {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		};
		return new Date(dateString).toLocaleDateString('en-US', options);
	};

	return (
		<div className="flex-1 flex flex-col gap-3">
			<div className="flex-1 min-h-0">
				<div className="px-0 flex flex-col gap-3">
					{isLoading ? (
						<div className="py-4 mx-auto w-fit">
							<Spinner size={25} color={theme.colors.primary} />
						</div>
					) : filteredPatients.length > 0 ? (
						filteredPatients.map((patient) => (
							<div>
								<div className="py-2 px-1">
									<button
										onClick={() => onClickItem(patient)}
										className="bg-transparent border-none text-left w-full cursor-pointer flex flex-col"
									>
										<div className="flex justify-between w-full">
											<p className="m-0 text-4\.5 mb-2">
												{patient?.patient?.name ||
													getName(patient?.patientInRelation) ||
													'-'}
											</p>
											<ChevronRight
												width={20}
												height={20}
												color={theme.colors.black30}
											/>
										</div>
										<div className="flex justify-between  w-full">
											<div className="flex-1 flex flex-col">
												<p className="m-0 text-3.5 text-charcoal-200">
													Created At
												</p>
												<p className="m-0 text-4 mb-4">
													{formatDate(patient?.createdAt?.toString() || '')}
												</p>
												<p className="m-0 text-3.5 text-charcoal-200">by</p>
												<p className="m-0 text-4 ">
													{patient.doctor.name || '-'}
												</p>
											</div>
											<div className="flex-1 flex flex-col">
												<p className="m-0 text-3.5 text-charcoal-200">Status</p>
												<p className="m-0 text-4 font-semibold mb-4">
													{renderStatusTag(
														(patient.transactionEMRs?.[0]
															?.transactionStatus as string) || '',
													)}
												</p>
												<p className="m-0 text-3.5 text-charcoal-200">
													Settled/Unsettled
												</p>
												<p className="m-0 mb-1">
													{patient.isSettled === true
														? 'Settled'
														: patient.isSettled === false
														? 'Unsettled'
														: '-'}
												</p>
											</div>
										</div>
									</button>
								</div>
								<Separator />
							</div>
						))
					) : (
						<EmptyList
							icon={<Info color={theme.colors.black50} />}
							title="Data Empty"
							description="You have not added this data yet"
						/>
					)}
				</div>
			</div>
		</div>
	);
}
