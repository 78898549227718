import { message } from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { useEffect, useState } from 'react';
import { CheckSquare, Square } from 'react-feather';
import { theme } from '../../../assets/theme';
import { Button } from '../../Button/Button/Button';
import IconButton from '../../Button/IconButton';
import FormInput from '../../Form/FormInput2';
import Separator from '../../Separator';
import WebPDF from '../WebPDF/WebPDF';

interface Props {
	btnSuffix?: string;
	docId: string;
	buttonType: 'confirm' | 'back' | 'none';
	type: 'doctor-document' | 'rx-prescription' | 'lab-request';
	isShowSaveAsTemplate?: boolean;
	templateName?: string;
	setTemplateName?: React.Dispatch<React.SetStateAction<string | undefined>>;
	onClickBtn?: () => void;
}

export default function PreviewDocument(props: Props) {
	const [uri, setUri] = useState<string>();

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!process.env.REACT_APP_WEB_PDF_BASE_URL) {
			console.error('Web PDF Base URL is not set');
			message.error('Please contact our administrator');
		} else {
			setUri(
				`${process.env.REACT_APP_WEB_PDF_BASE_URL}/pdf/${props.type}/${props.docId}`,
			);
		}
	}, [props.docId]);

	function onClickBtn() {
		setIsLoading(true);
		props.onClickBtn?.();
		setIsLoading(false);
	}

	return (
		<div className="flex-1 flex flex-col">
			<div className="py-3 flex-1">
				{!!uri && (
					<div className="rounded-3 p-3 bg-gray-60">
						<ErrorBoundary>
							<WebPDF
								uri={uri}
								print={props.buttonType !== 'confirm' ? true : false}
							/>
						</ErrorBoundary>
					</div>
				)}
			</div>

			{props.buttonType !== 'none' && (
				<div className="pt-6">
					<Separator />
					<div className="py-3 px-4 flex items-center gap-x-2.5">
						{props.isShowSaveAsTemplate && (
							<div className="flex-1 max-w-sm min-w-0">
								<div className="flex gap-x-2">
									<IconButton
										onClick={() =>
											props.setTemplateName?.((old) =>
												old === undefined ? '' : undefined,
											)
										}
									>
										{props.templateName !== undefined ? (
											<CheckSquare
												width={20}
												height={20}
												color={theme.colors.primary}
											/>
										) : (
											<Square
												width={20}
												height={20}
												color={theme.colors.ash800}
											/>
										)}
									</IconButton>
									<p className="m-0 text-4">
										Save this {props.btnSuffix?.toLowerCase()} as template
									</p>
								</div>
								{props.templateName !== undefined && (
									<FormInput
										placeholder="Template name"
										value={props.templateName}
										onChange={props.setTemplateName}
									/>
								)}
							</div>
						)}
						<div className="w-50 ml-auto">
							<Button
								type="SOLID"
								onClick={onClickBtn}
								isLoading={isLoading}
								className="whitespace-nowrap"
							>
								{(props.buttonType === 'confirm' ? 'Confirm' : 'Back to') +
									' ' +
									props.btnSuffix}
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
