export enum EConditionBloodSugar {
	PREMEAL = 'premeal',
	POSTMEAL = 'postmeal',
	BEDTIME = 'bedtime',
	FASTING = 'fasting',
}

export enum EStatusTrend {
	LOW = 'Low',
	NORMAL = 'Normal',
	NORMAL_HIGH = 'Normal High',
	HIGH = 'High',
}

export type VitalSignTrendProperties = {
	title: string;
	value: number;
	unit: string;
	status?: EStatusTrend | string;
};

export type VitalSignBloodPressureProperties = {
	vitalSignBloodPressureId?: string;
	vitalSignId?: string;
	date?: Date;
	systolic?: number;
	diastolic?: number;
	notes?: string;
	updatedAt?: Date;
	createdAt?: Date;
};

export type VitalSignBloodSugarProperties = {
	vitalSignBloodSugarId?: string;
	vitalSignId?: string;
	date?: Date;
	bloodSugar?: number;
	bloodSugarUnit?: string;
	condition?: EConditionBloodSugar;
	notes?: string;
	updatedAt?: Date;
	createdAt?: Date;
};

export type VitalSignHeartRateProperties = {
	vitalSignHeartRateId?: string;
	vitalSignId?: string;
	date?: Date;
	heartRate?: number;
	notes?: string;
	updatedAt?: Date;
	createdAt?: Date;
};

export type VitalSignRespiratoryRateProperties = {
	vitalSignRespiratoryRateId?: string;
	vitalSignId?: string;
	date?: Date;
	respiratoryRate?: number;
	notes?: string;
	updatedAt?: Date;
	createdAt?: Date;
};

export type VitalSignSpO2Properties = {
	vitalSignSpO2Id?: string;
	vitalSignId?: string;
	date?: Date;
	spO2?: number;
	notes?: string;
	updatedAt?: Date;
	createdAt?: Date;
};

export type VitalSignTemperatureProperties = {
	vitalSignTemperatureId?: string;
	vitalSignId?: string;
	date?: Date;
	temperature?: number;
	notes?: string;
	updatedAt?: Date;
	createdAt?: Date;
};

export type VitalSignPediaChartProperties = {
	vitalSignPediaChartId?: string;
	vitalSignId?: string;
	date?: Date;
	headCircumference?: number;
	notes?: string;
	updatedAt?: Date;
	createdAt?: Date;
};

export type VitalSignProperties = {
	vitalSignId?: string;
	emrId?: string;
	height: number;
	heightUnit: string;
	weight: number;
	weightUnit: string;
	updatedAt?: Date;
	createdAt?: Date;
	vitalSignHeartRate?: VitalSignHeartRateProperties;
	vitalSignRespiratoryRate?: VitalSignRespiratoryRateProperties;
	vitalSignSpO2?: VitalSignSpO2Properties;
	vitalSignTemperature?: VitalSignTemperatureProperties;
	vitalSignBloodPressure?: VitalSignBloodPressureProperties;
	vitalSignBloodSugar?: VitalSignBloodSugarProperties;
	vitalSignPediaChart?: VitalSignPediaChartProperties;
};
