import React, { useState, useEffect } from 'react';
import { Card, Typography, Button, Statistic, Progress } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Pointer from '../assets/Pointer.svg';
import BmiIndicatorResultBar from '../assets/BmiIndcatorResultBar.svg';
import VitalSignTypeHeader from '../../Webapp/EMR/DetailEmr/VitalSign/VitalSignTypeHeader';
import Spinner from '../../Spinner';
import { theme } from '../../../assets/theme';
import EmptyList from '../../EmptyList';
import { Info } from 'react-feather';

const { Text } = Typography;

type Props = {
	nextStep?: () => void;
	pageType?: 'calculator' | 'detail-emr' | 'detail-patient' | 'trends';
	height: number;
	weight: number;
	age: number | null | undefined;
	bmiScore: number;
	isLoading?: boolean;
};

const underWeightPointer = (bmi: number) => {
	return (bmi * 13.5) / 18.5;
};

const idealWeightPointer = (bmi: number) => {
	return ((bmi - 18.5) * 7.3) / 6.4 + 13.5;
};

const overWeightPointer = (bmi: number) => {
	return ((bmi - 24.9) * 61.2) / 5 + 20.8;
};

const obesityPointer = (bmi: number) => {
	if (bmi > 35) {
		return 100 - 2.4;
	}
	return ((bmi - 29.9) * 18) / 5.1 + 82;
};

export default function ResultSectionCategoryScore(Props: Props) {
	const [bmiStatus, setBmiStatus] = useState<any>('loading');
	const [colorStatus, setColorStatus] = useState<string>('blue');
	const [pointer, setPointer] = useState<number>(0);

	const { pageType, height, weight, age, bmiScore, isLoading } = Props;

	useEffect(() => {
		switch (true) {
			case bmiScore < 18.5:
				setPointer(underWeightPointer(bmiScore));
				setBmiStatus('Underweight');
				setColorStatus('#D81F64');
				break;
			case 18.5 <= bmiScore && bmiScore <= 24.9:
				setPointer(idealWeightPointer(bmiScore));
				setBmiStatus('Ideal');
				setColorStatus('#7FD53B');
				break;
			case 24.9 < bmiScore && bmiScore < 30:
				setPointer(overWeightPointer(bmiScore));
				setBmiStatus('Overweight');
				setColorStatus('#EFC222');
				break;
			case bmiScore >= 30:
				setPointer(obesityPointer(bmiScore));
				setBmiStatus('Obesity');
				setColorStatus('#E93131');
				break;
		}
	}, [bmiScore]);

	if (isLoading) {
		return (
			<div className="py-4 mx-auto w-fit">
				<Spinner size={25} color={theme.colors.primary} />
			</div>
		);
	}

	return (
		<>
			<Card
				style={{ minHeight: 315, borderRadius: 12 }}
				bodyStyle={{ padding: '16px' }}
			>
				{(pageType === 'detail-emr' ||
					pageType === 'detail-patient' ||
					pageType === 'trends') && (
					<VitalSignTypeHeader
						pageType={pageType}
						typeTitle="BMI"
						nextStep={Props.nextStep || (() => {})}
					/>
				)}
				{height > 0 && weight > 0 ? (
					<>
						<CategoryScoreContainer>
							<div>
								<Text style={{ fontSize: 14 }}>Category</Text>
								<Card
									style={CategoryScoreLabelStyles}
									bodyStyle={{
										padding: '7px 10px',
										fontWeight: 600,
										color: 'white',
										backgroundColor: colorStatus,
									}}
								>
									{bmiStatus}
								</Card>
							</div>
							<div style={{ textAlign: 'right' }}>
								<Text style={{ fontSize: 14 }}>Score</Text>
								<Card
									style={CategoryScoreLabelStyles}
									bodyStyle={{
										padding: '7px 10px',
										fontWeight: 600,
										color: 'black',
										backgroundColor: '#F8FAFB',
									}}
								>
									{Props.bmiScore}
								</Card>
							</div>
						</CategoryScoreContainer>
						<ResultBarContainer>
							<img
								style={{ width: '100%', minHeight: 20, objectFit: 'fill' }}
								src={BmiIndicatorResultBar}
								alt="React Logo"
							/>
							<PointerContainer style={{ paddingLeft: `${pointer}%` }}>
								<img
									src={Pointer}
									alt="pointer"
									style={{
										// border: "1px solid black",
										position: 'absolute',
										height: '100%',
										width: '2.8%',
									}}
								/>
							</PointerContainer>
						</ResultBarContainer>
						{/* <Progress percent={30} />
        <Statistic title="Active Users" value={112893} loading /> */}
						<Text>Detail Information</Text>
						<Card style={DetailInformationCardStyles}>
							<DetailInformationContainer>
								<div style={ItemDetailInformationStyles}>
									<div>Height</div>
									<div style={DetailInformationValueStyles}>
										<span style={DetailInformationNumberStyle}>{height}</span>{' '}
										cm
									</div>
								</div>
								<div style={ItemDetailInformationStyles}>
									<div>Weight</div>
									<div style={DetailInformationValueStyles}>
										<span style={DetailInformationNumberStyle}>{weight}</span>{' '}
										kg
									</div>
								</div>
								<div style={ItemDetailInformationStyles}>
									<div>Age</div>
									<div style={DetailInformationValueStyles}>
										<span style={DetailInformationNumberStyle}>{age}</span> y.o
									</div>
								</div>
							</DetailInformationContainer>
						</Card>
					</>
				) : (
					<EmptyList
						icon={<Info color={theme.colors.black50} />}
						title="Data Empty"
						description="You have not added this data yet"
					/>
				)}

				{pageType === 'calculator' && (
					<Button
						tabIndex={-1}
						size="large"
						icon={<SyncOutlined />}
						style={{
							width: '100%',
							border: '1px solid #D81F64',
							borderRadius: 12,
							marginTop: 24,
						}}
					>
						Check Again
					</Button>
				)}
			</Card>
		</>
	);
}

const CategoryScoreLabelStyles: React.CSSProperties = {
	marginTop: 8,
	borderRadius: '9999px',
	fontSize: 20,
	overflow: 'hidden',
	border: 0,
	textAlign: 'center',
};

const DetailInformationValueStyles: React.CSSProperties = {
	fontSize: 20,
	color: 'black',
};

const ItemDetailInformationStyles: React.CSSProperties = {
	textAlign: 'left',
	fontSize: 14,
	flex: 1,
};

const DetailInformationCardStyles: React.CSSProperties = {
	borderRadius: 20,
	marginTop: 8,
	backgroundColor: '#F1F3F5',
	border: '0px',
};

const DetailInformationNumberStyle: React.CSSProperties = {
	fontSize: 24,
	fontWeight: 600,
	color: 'black',
};

const CategoryScoreContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-self: end;
`;

const PointerContainer = styled.div`
	// border: 1px solid black;
	display: flex;
	align-items: flex-end;
	position: relative;
	width: 100%;
	aspect-ratio: 36;
	padding: 0px;
	transform: translate(0px, -90%);
	margin-bottom: -24px;
`;

const ResultBarContainer = styled.div`
	// border: 1px solid black;
	padding: 0px;
	margin-top: 30px;
	margin-bottom: 30px;
	width: 100%;
`;

const DetailInformationContainer = styled.div`
	// border: 1px solid black;
	display: flex;
	widht: 100%;
`;
